


<template >
  <div class="ocho-cargo">
    <div class="home-cargo">
      <Mapa class="mapas-sec" />
      <v-container class="wrap-content-cargo flex-center">
        <v-row justify="start" align="center" dense class="flex-center">
          <v-col lg="6" md="6" cols="12">
            <div class="wrap-content-title">
              <img
                src="@/assets/logo-cargo.svg"
                class="mb-6"
                height="100px"
                alt=""
                srcset=""
              />
              <h1 class="title-prim">
                La manera <span class="facil-style">fácil</span> de
                <span style="color: #ff5900">entregar </span> tus productos
              </h1>
            </div>
          </v-col>
          <v-col lg="8" md="6" cols="12"> </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="my-12 text-center font-weight-light wrap-vende-mas">
      <h1 class="mb-6 revelar">Vende más</h1>
      <h2 class="revelar">
        Con tan solo un clic <span>ocho Cargo</span> pone a tu disposición una
        flota de motoristas listos para llevar tus productos hasta las manos de
        tus clientes.
      </h2>
    </v-container>
    <v-container>
      <h1 class="text-center" style="color: #ff5900">Servicios</h1>
      <v-row class="mt-6" justify="center" align="center">
        <v-col lg="6" md="6" sm="12" cols="12">
          <div
            class="wrap-services revelar"
            v-for="(item, index) in services"
            :key="index"
          >
            <div class="icon" :style="`background-color:${item.color}`">
              <img :src="item.icon" alt="" srcset="" />
            </div>
            <div class="text">
              <h2>{{ item.title }}</h2>
              <p class="">{{ item.description }}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <img
            src="@/assets/ocho-cargo/tracking.svg"
            class="ml-6"
            width="90%"
            alt=""
            srcset=""
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-12">
      <h1 class="text-center ">
        No cargues más a tu <span style="color: #ff5900">personal</span>
      </h1>
      <p class="mt-6 mx-12 " style="font-weight: 300">
        • Comparte con tus clientes el tracking de sus productos para que lo
        <span style="color: #ff5900"> visualicen en tiempo real.</span>
        <br />
        • Tus clientes pueden ponerse en contacto directo con el Chavo
        (repartidor) a cargo de su entrega para
        <span style="color: #ff5900">no cargar a tu personal.</span>
      </p>
      <v-row class="mt-6 flex-center ">
        <v-col cols="12" md="12" sm="12" lg="12" class="flex-center">
          <img
            src="@/assets/ocho-cargo/panel.jpg"
            class="img-panel"
            width="80%"
            alt=""
            srcset=""
          />
        </v-col>
        <!-- <v-col cols="12" md="5" sm="5" lg="5" class="flex-center">
          <img
            src="@/assets/ocho-cargo/tracking.png"
            class="img-panel"
            width="200px"
            alt=""
            srcset=""
          />
        </v-col> -->
      </v-row>
      <v-row class="mt-6"> </v-row>
    </v-container>
  </div>
</template>

<script>
import Mapa from "@/components/map.vue";
import descarga from "@/components/btnDescarga";
import store from "@/store/index.js";
import ScrollReveal from "scrollreveal";
import Map from "../components/map.vue";
export default {
  name: "ocho-cargo",
  components: {
    descarga,
    Mapa,
  },

  data() {
    return {
      services: [
        {
          icon: require("@/assets/ocho-cargo/icon/01.svg"),
          color: "#AEFCF1",
          primary: "#C8F2EE",
          title: "Delivery a Demanda",
          description:
            "• Entregas abajo de 1 hora. \n• Crea rutas con hasta 5 puntos de entrega.",
        },
        {
          icon: require("@/assets/ocho-cargo/icon/delivery.svg"),

          color: "#FFE2AE",
          primary: "#C8F2EE",
          title: "Own Delivery",

          description:
            "¿Cuentas con tu propia flota de repartidores? \nOcho arma a tu equipo con su tecnología para que gestiones las entregas con tu propia flota.",
        },
        {
          icon: require("@/assets/ocho-cargo/icon/calendar.svg"),
          color: "#BBE5FF",
          primary: "#C8F2EE",
          title: "Calendariza tus entregas",
          description:
            "Escoge la hora a la que necesitas que comiencen a repartir tus productos.",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    ScrollReveal({
      easing: "ease-in-out",
      reset: true,
      delay: 100,
      distance: "50px",
      origin: "bottom",
    }).reveal(".revelar");
  },
  methods: {},
};
</script>
<style  src="@/main.scss" lang="scss"></style>
<style lang="scss"  scoped>
@import "@/_responsive.scss";
.wrap-content-title {
  padding: 50px;
  border-radius: 20px;
  background-image: radial-gradient(#fff, transparent);
  @include responsive(mobile) {
    font-size: 25px !important;
    padding: 20px;
    background-image: radial-gradient(rgba(255, 255, 255, 0.544), transparent);
    img {
      height: 70px;
    }
  }
}

.title-prim {
  font-size: 40px !important;
  color: #202e4f;
  @include responsive(mobile) {
    font-size: 25px !important;
    padding-bottom: 50px;
  }
}

.ocho-cargo {
  background-color: #fff;
  overflow: hidden;
  .home-cargo {
    min-height: 100vh;
    position: relative;

    opacity: 1;
    background-position: bottom;
    background-size: cover;
    @include responsive(mobile) {
      min-height: 600px;
    }
    .wrap-content-cargo {
      z-index: 3 !important;
      position: relative;
      min-height: 100vh;
      @include responsive(mobile) {
        min-height: 100vh;
        padding-top: 0px;
      }

      h1 {
        font-size: 2.5em;
        @include responsive(mobile) {
          font-size: 1.8em;
        }
      }
    }

    .header-mobile {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      display: none;
      @include responsive(mobile) {
        display: block;
      }
    }
    .img-fondo {
      position: absolute;
      width: 1500px;
      left: 0;
      bottom: 20px;
      z-index: 1;
      @include responsive(mobile) {
        left: -1000px;
        bottom: 0px;
        display: none;
      }
    }
  }
}
.flex-cols {
  @include responsive(mobile) {
    flex-direction: column;
    padding: 20px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.wrap-services {
  width: 100%;
  background-color: #f2f2f2;
  min-height: 100px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: aquamarine;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 30px;
    }
  }
  .text {
    margin-left: 20px;
    width: calc(100% - 50px);
    h2 {
      color: #ff5900;
    }
  }
}
.forma-left {
  position: absolute;
  width: 300px;
  left: -200px;
  bottom: -150px;

  @include responsive(mobile) {
    width: 150px;
    left: -100px;
    bottom: 100px;
  }
}
.img-panel {
  border-radius: 12px;
  box-shadow: #00000028 0px 10px 20px;
}
.wrap-vende-mas {
  background-color: #ff5900;
  color: #fff;
  padding-bottom: 50px;
  padding-top: 50px;
  border-radius: 20px;
  @include responsive(mobile) {
    border-radius: 10px;
    margin: 0px;
    padding-bottom: 50px;
    font-family: 20px;
  }
}
.mapas-sec {
  position: absolute;
  bottom: 0;
  @include responsive(mobile) {
    height: 100%;
    left: -350px;
    bottom: 0;
  }
  @include responsive(small) {
    height: 100%;
    left: -350px;
    top: 0 !important;
  }
  @include responsive(Ipad) {
    height: 100%;
    left: -350px;
    top: 0 !important;
  }
  @include responsive(desktop) {

    width: 125% !important;
    left: 0px;
    left: -150px;
    bottom: 0px !important;
  }
  @include responsive(IpadProlandscape) {

    width: 100% !important;
    left: 0px;
    bottom: none;
    bottom: 0px !important;
  }
  @include responsive(large) {

    width: 100% !important;
    left: 0px;
    bottom: 0px !important;
  }
  
}
</style>
