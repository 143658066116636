<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 310"
    style="enable-background: new 0 0 500 310"
    xml:space="preserve"
  >
    <filter id="AI_GaussianBlur_4">
      <feGaussianBlur stdDeviation="1.6"></feGaussianBlur>
    </filter>
    <g id="Layer_1">
      <g>
        <defs>
          <rect id="SVGID_1_" x="0.2" y="0.2" width="499.6" height="309.6" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>
        <rect x="0" y="0" class="st0" width="500" height="310" />
        <path
          class="st1"
          d="M111.6,135.2c-4.2-3.1-15.2,2.2-15.6,5.6c-0.3,3.4,1.4,14.8,0,18.9c-1.4,4.1-1.6,13,2.4,19.6
			c4,6.6,3.7,6.3,4.9,9.6c1.2,3.3,30-12.1,31.2-12.8c1.3-0.7-9.5-21.2-11.1-24.6c-1.6-3.5-4.1-0.6-6.1-4.7
			C115.4,142.7,114.2,137,111.6,135.2z"
        />
        <g class="st2">
          <rect
            x="65.6"
            y="16.3"
            transform="matrix(0.182 -0.9833 0.9833 0.182 36.2908 81.1741)"
            class="st3"
            width="2.8"
            height="4.8"
          />
          <rect
            x="64.6"
            y="20.4"
            transform="matrix(0.182 -0.9833 0.9833 0.182 32.1363 81.4862)"
            class="st3"
            width="0.9"
            height="2.1"
          />
          <rect
            x="64.1"
            y="23"
            transform="matrix(0.182 -0.9833 0.9833 0.182 29.1613 83.1567)"
            class="st3"
            width="0.9"
            height="2.1"
          />
          <rect
            x="66.7"
            y="26.1"
            transform="matrix(0.9881 -0.154 0.154 0.9881 -3.2649 10.755)"
            class="st3"
            width="2.1"
            height="0.6"
          />
          <rect
            x="67"
            y="27.8"
            transform="matrix(0.9881 -0.154 0.154 0.9881 -3.5426 10.8203)"
            class="st3"
            width="2.1"
            height="0.9"
          />
          <rect
            x="93.6"
            y="2.9"
            transform="matrix(0.9881 -0.154 0.154 0.9881 0.6213 14.6114)"
            class="st3"
            width="2.1"
            height="0.9"
          />
          <rect
            x="69.1"
            y="29.8"
            transform="matrix(0.9881 -0.154 0.154 0.9881 -3.8381 11.3355)"
            class="st3"
            width="4.3"
            height="1.4"
          />
          <rect
            x="73.5"
            y="31.7"
            transform="matrix(0.9881 -0.154 0.154 0.9881 -4.078 12.0352)"
            class="st3"
            width="4.3"
            height="1.4"
          />

          <rect
            x="62.2"
            y="25.8"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 25.6242 85.331)"
            class="st3"
            width="3.7"
            height="2.9"
          />
          <rect
            x="60.1"
            y="32.3"
            transform="matrix(0.182 -0.9833 0.9833 0.182 18.1289 88.2032)"
            class="st3"
            width="4"
            height="1.9"
          />
          <polygon
            class="st3"
            points="59.8,43.3 60.5,39.7 59,39.4 58.3,43 58.3,43 57.9,45.1 61,45.7 61.3,43.6 			"
          />
          <polygon
            class="st3"
            points="62.5,47.4 61.5,45.8 64.8,43.5 65.8,45.1 			"
          />
          <polygon
            class="st3"
            points="68.6,50.1 67.5,48.5 70.8,46.2 71.9,47.8 			"
          />
          <polygon
            class="st3"
            points="74.6,52.8 73.5,51.2 76.8,48.9 77.9,50.5 			"
          />
          <rect
            x="70.4"
            y="25.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 32.1028 91.8943)"
            class="st3"
            width="1.8"
            height="2.9"
          />
          <rect
            x="74.6"
            y="25.6"
            transform="matrix(0.182 -0.9833 0.9833 0.182 35.284 95.8256)"
            class="st3"
            width="1.3"
            height="2.2"
          />
          <rect
            x="61.3"
            y="36.6"
            transform="matrix(0.182 -0.9833 0.9833 0.182 13.9583 91.1174)"
            class="st3"
            width="0.8"
            height="1.2"
          />
          <polygon
            class="st3"
            points="80.2,22.4 80.1,23.3 78.9,23.1 79,22.6 77.2,22.3 77.1,22.7 76.8,24.5 78.6,24.8 79.7,25.1 
				81.5,25.4 81.8,23.6 82,22.8 			"
          />
          <polygon
            class="st3"
            points="72.4,43.2 73.2,43.3 73.1,44.5 72.7,44.4 72.5,46.2 72.9,46.3 74.7,46.5 74.9,44.7 75,43.5 
				75.2,41.7 73.4,41.5 72.6,41.4 			"
          />
          <polygon
            class="st3"
            points="85.7,23.5 85.6,24.3 84.4,24.1 84.5,23.7 82.7,23.3 82.6,23.8 82.3,25.5 84.1,25.9 85.3,26.1 
				87,26.4 87.4,24.6 87.5,23.8 			"
          />
          <rect
            x="85"
            y="19.4"
            transform="matrix(0.182 -0.9833 0.9833 0.182 49.1391 101.7325)"
            class="st3"
            width="1.5"
            height="3.9"
          />

          <rect
            x="103.7"
            y="4.5"
            transform="matrix(0.182 -0.9833 0.9833 0.182 77.8805 108.3942)"
            class="st3"
            width="0.8"
            height="5.7"
          />
          <polygon
            class="st3"
            points="69.9,22.3 67.9,22.8 67.7,22 69.7,21.4 			"
          />
          <polygon
            class="st3"
            points="116.9,35.8 114.9,36.4 114.6,35.6 116.7,35 			"
          />
          <rect
            x="78.1"
            y="17.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 46.2281 94.3673)"
            class="st3"
            width="3.5"
            height="3.5"
          />
          <rect
            x="72.7"
            y="19.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 38.5433 90.0538)"
            class="st3"
            width="1.3"
            height="5.4"
          />
          <rect
            x="70.8"
            y="20.1"
            transform="matrix(0.182 -0.9833 0.9833 0.182 37.9705 87.9932)"
            class="st3"
            width="2.1"
            height="2.1"
          />

          <rect
            x="92.6"
            y="16.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 57.2583 107.6317)"
            class="st3"
            width="1.4"
            height="5.5"
          />
          <polygon
            class="st3"
            points="101.7,26.4 101.8,25.7 98.9,25.2 98.7,26.6 100.8,27 100.7,27.6 103.5,28.2 103.8,26.8 			"
          />
          <polygon
            class="st3"
            points="95.5,14 95.5,14.3 94.3,14 94.5,13.2 92.4,12.8 92.2,13.7 91.6,16.9 93.7,17.3 94.9,17.5 97,17.9 
				97.6,14.6 97.6,14.4 			"
          />

          <rect
            x="93.4"
            y="21.1"
            transform="matrix(0.182 -0.9833 0.9833 0.182 58.6238 113.4204)"
            class="st3"
            width="8.2"
            height="0.7"
          />

          <rect
            x="86.3"
            y="9.7"
            transform="matrix(0.1822 -0.9833 0.9833 0.1822 64.0675 97.1057)"
            class="st3"
            width="8.2"
            height="0.7"
          />
          <polygon
            class="st3"
            points="96.4,8.1 96,8.1 94.7,7.8 94.9,6.7 93,6.3 92.8,7.5 92.3,7.4 91.9,9.5 92.9,9.6 93.3,7.6 94.2,7.7 
				94,8.4 95.9,8.8 95.2,12.6 97.8,13.1 98.7,8.6 			"
          />
          <polygon
            class="st3"
            points="81.6,15.5 79.9,15.2 79.7,16.1 81.4,16.5 81.3,17.2 83.8,17.6 84.1,16 			"
          />
          <polygon
            class="st3"
            points="75.8,13.3 76,12.3 74.6,12 73.8,16.5 75.2,16.8 75.3,16.1 76,16.2 76.5,13.4 			"
          />
          <rect
            x="75.1"
            y="17.9"
            transform="matrix(0.182 -0.9833 0.9833 0.182 43.0838 89.841)"
            class="st3"
            width="0.8"
            height="2.2"
          />
          <rect
            x="71.8"
            y="17.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 41.721 85.3543)"
            class="st3"
            width="0.7"
            height="0.7"
          />
          <rect
            x="97.7"
            y="1.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 78.1065 99.2038)"
            class="st3"
            width="2"
            height="2.9"
          />

          <rect
            x="106.3"
            y="12.9"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 74.211 116.1561)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="107.1"
            y="17.2"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 70.5861 120.4427)"
            class="st3"
            width="1.2"
            height="1.2"
          />
          <rect
            x="66"
            y="32.1"
            transform="matrix(0.182 -0.9833 0.9833 0.182 21.7055 92.8753)"
            class="st3"
            width="1.4"
            height="2.5"
          />

          <rect
            x="73.2"
            y="34.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 25.0713 102.0664)"
            class="st3"
            width="1.4"
            height="2.5"
          />
          <polygon class="st3" points="76.9,38 77.3,36.3 79.4,36.9 79,38.5 			" />
          <polygon
            class="st3"
            points="76.9,41.9 77.3,40.3 79.4,40.8 79,42.5 			"
          />

          <rect
            x="108.6"
            y="25.8"
            transform="matrix(0.182 -0.9833 0.9833 0.182 63.3428 129.0188)"
            class="st3"
            width="1.2"
            height="1.2"
          />
          <polygon
            class="st3"
            points="72.9,13.4 71.7,13.2 71.7,13.2 71.3,13.1 71.3,13.1 70.3,12.9 70.1,13.8 71.1,14 70.8,16 71.2,16 
				71.6,14.1 72.8,14.3 			"
          />
          <polygon
            class="st3"
            points="68.1,38.4 65.4,37.9 65.1,39.7 66,39.8 66.1,39 67.9,39.3 			"
          />
          <polygon
            class="st3"
            points="48.7,41.7 53.6,43.8 54.9,40.7 53.3,40 52.6,41.6 49.4,40.2 			"
          />
          <rect
            x="66.6"
            y="12"
            transform="matrix(0.182 -0.9833 0.9833 0.182 41.3968 77.2615)"
            class="st3"
            width="1"
            height="3.4"
          />
          <polygon
            class="st3"
            points="73.5,8.3 73.6,7.6 72.6,7.4 72.4,8.1 69,7.4 70,2.1 68,1.7 66.8,8.8 68.7,9.1 75.8,10.4 76.1,8.7 
							"
          />
          <rect
            x="75.4"
            y="6.9"
            transform="matrix(0.182 -0.9833 0.9833 0.182 54.2318 80.7222)"
            class="st3"
            width="0.4"
            height="1.7"
          />

          <rect
            x="102.8"
            y="13.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 69.0835 113.982)"
            class="st3"
            width="0.4"
            height="3.6"
          />
          <rect
            x="79.4"
            y="11.6"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 53.4834 88.4781)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="79.8"
            y="7.9"
            transform="matrix(0.1819 -0.9833 0.9833 0.1819 58.6533 86.5681)"
            class="st3"
            width="3.1"
            height="0.3"
          />
          <polygon
            class="st3"
            points="78.6,8.3 78.5,9.2 78,9.1 77.5,11.6 78,11.7 78.4,9.6 79,9.7 79.3,8.4 			"
          />
          <polygon
            class="st3"
            points="70.5,41.9 68.5,42 68.5,41.1 62.6,41.4 62.7,42.3 67.6,42.1 67.6,43.2 70.6,43.1 			"
          />
          <polygon
            class="st3"
            points="114.7,19.7 114.5,20.6 114,20.5 113.5,23 114,23.1 114.4,21 115.1,21.1 115.3,19.8 			"
          />
          <polygon
            class="st3"
            points="85.1,12.2 83.5,11.9 83,11.8 82.9,12.4 82.8,13.1 83.2,13.2 83.4,12.5 84.9,12.8 			"
          />
          <polygon
            class="st3"
            points="86.8,15.3 86.7,15.9 85.9,15.7 85.8,16.2 86.6,16.4 88.3,16.7 88.5,15.7 			"
          />
          <polygon
            class="st3"
            points="103.4,19.8 103.3,20.3 101.6,20 101.5,20.6 103.2,20.9 106.5,21.5 106.7,20.4 			"
          />
          <rect
            x="84.4"
            y="14.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 54.308 95.8706)"
            class="st3"
            width="0.7"
            height="1.2"
          />
          <rect
            x="65"
            y="47.3"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 6.3954 103.3481)"
            class="st3"
            width="0.7"
            height="1.2"
          />
          <rect
            x="89"
            y="1.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 71.367 89.2545)"
            class="st3"
            width="0.7"
            height="1.2"
          />

          <rect
            x="86.1"
            y="13.7"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 57.5478 97.1831)"
            class="st3"
            width="2.1"
            height="0.6"
          />

          <rect
            x="104.2"
            y="9.4"
            transform="matrix(0.182 -0.9833 0.9833 0.182 76.5138 111.4313)"
            class="st3"
            width="2.1"
            height="0.6"
          />
          <polygon
            class="st3"
            points="114.6,4.5 114.6,4.7 113,4.4 113.1,3.8 110.1,3.3 110,3.8 109.8,4.9 109.5,4.8 109.1,6.9 112.4,7.5 
				112.6,6.5 114.2,6.8 117.1,7.3 117.5,5.2 117.6,5 			"
          />
          <rect
            x="86.9"
            y="8.6"
            transform="matrix(0.182 -0.9833 0.9833 0.182 63.1552 94.6172)"
            class="st3"
            width="3.1"
            height="1.4"
          />

          <rect
            x="82.9"
            y="3.2"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 65.2027 88.1109)"
            class="st3"
            width="5.3"
            height="3.3"
          />

          <rect
            x="103.3"
            y="1.6"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 83.8414 105.369)"
            class="st3"
            width="3.8"
            height="1.4"
          />
          <polygon
            class="st3"
            points="115.6,10.8 112.4,10.2 111.6,14.5 109.8,14.2 108.8,19.4 112.1,20 112.9,15.7 114.7,16 			"
          />
          <polygon
            class="st3"
            points="15.6,11.3 13.2,9.1 10.3,12.4 8.9,11.2 5.4,15.1 7.8,17.3 10.7,14 12.1,15.3 			"
          />
          <rect
            x="76.3"
            y="2.1"
            transform="matrix(0.182 -0.9833 0.9833 0.182 59.2397 80.0011)"
            class="st3"
            width="2.8"
            height="4.6"
          />
          <rect
            x="77"
            y="5.9"
            transform="matrix(0.182 -0.9833 0.9833 0.182 56.5741 81.7073)"
            class="st3"
            width="0.9"
            height="1.9"
          />
          <rect
            x="23.8"
            y="17.6"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 0.913 41.2385)"
            class="st3"
            width="3"
            height="5"
          />
          <polygon
            class="st3"
            points="32.5,17.2 32.2,18.7 31.3,18.5 30.5,22.8 31.4,23 32.1,19.4 33.2,19.6 33.6,17.4 			"
          />
          <rect
            x="29.6"
            y="13.1"
            transform="matrix(0.182 -0.9833 0.9833 0.182 10.3987 41.9149)"
            class="st3"
            width="1.5"
            height="3.2"
          />

          <rect
            x="69.8"
            y="38.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 18.3593 101.2499)"
            class="st3"
            width="0.4"
            height="1.7"
          />
          <polygon
            class="st3"
            points="70.3,33.9 69.7,35.6 69.3,35.4 69.9,33.8 			"
          />

          <rect
            x="76.6"
            y="46.4"
            transform="matrix(0.1822 -0.9833 0.9833 0.1822 18.1054 114.934)"
            class="st3"
            width="3.1"
            height="0.3"
          />
          <rect
            x="62.6"
            y="37"
            transform="matrix(0.1822 -0.9833 0.9833 0.1822 15.8764 93.4262)"
            class="st3"
            width="3.1"
            height="0.3"
          />

          <rect
            x="71.4"
            y="37.7"
            transform="matrix(0.182 -0.9833 0.9833 0.182 20.7029 102.2357)"
            class="st3"
            width="0.9"
            height="1.9"
          />

          <rect
            x="75.6"
            y="30.5"
            transform="matrix(0.1812 -0.9834 0.9834 0.1812 34.3105 103.6362)"
            class="st3"
            width="7.5"
            height="1.3"
          />
          <polygon
            class="st3"
            points="96.2,30.4 90,28.8 90.5,26.7 96.7,28.3 			"
          />
          <polygon class="st3" points="92,25.7 89.9,25.1 90.5,23 92.6,23.6 			" />
          <polygon
            class="st3"
            points="118.7,27.5 119.2,25.5 115,24.5 114.5,26.5 116.7,27.1 115.2,33.2 117.6,33.8 119.2,27.6 			"
          />
          <polygon
            class="st3"
            points="104.3,33.1 98.1,31.5 98.6,29.4 104.9,31 			"
          />
          <polygon
            class="st3"
            points="112.5,35.7 106.3,34.1 106.8,32 113,33.6 			"
          />
          <polygon
            class="st3"
            points="121,10.1 119.2,9.7 121.2,1.6 123.1,2.1 			"
          />
          <polygon
            class="st3"
            points="119.6,22.1 117.8,21.6 119.8,13.6 121.6,14.1 			"
          />
          <polygon
            class="st3"
            points="61.3,140.5 60.3,140.8 59,135.9 60,135.7 			"
          />
          <polygon
            class="st3"
            points="61.2,142.8 56.3,144.6 56.1,143.9 61,142.1 			"
          />

          <rect
            x="57.1"
            y="129.3"
            transform="matrix(8.644067e-02 -0.9963 0.9963 8.644067e-02 -78.314 177.1957)"
            class="st3"
            width="0.8"
            height="4.1"
          />
          <polygon
            class="st3"
            points="54.1,136.3 53.5,136.5 52.6,134.1 53.3,133.9 			"
          />
          <polygon
            class="st3"
            points="147.9,37.7 146.5,32.2 145.7,32.4 147.1,37.9 			"
          />
          <polygon
            class="st3"
            points="154.9,34.3 155,34.8 153.2,35.3 153.1,34.7 			"
          />
          <polygon
            class="st3"
            points="56.3,123.5 55.6,121.3 54.7,121.6 55.5,123.8 54.9,124 55.3,125.2 58.1,124.2 57.6,123 			"
          />
          <polygon
            class="st3"
            points="60.1,128 59.2,128.3 59,127.8 59.9,127.5 			"
          />
          <polygon
            class="st3"
            points="56.4,128.6 56.4,129 55.6,129 55.6,129.4 56.4,129.4 58.5,129.4 58.5,128.5 			"
          />
          <polygon
            class="st3"
            points="57.8,137.3 56.4,137.8 55.8,136.1 57.2,135.6 			"
          />
          <polygon
            class="st3"
            points="56.7,134.4 56.3,134.6 56,133.8 56.4,133.6 			"
          />
          <polygon
            class="st3"
            points="59.8,121.3 57.5,122.1 57.2,121.3 59.5,120.4 			"
          />
          <polygon
            class="st3"
            points="53.4,124.3 52.9,124.5 52.1,124.8 52.4,125.5 53.2,125.2 53.7,126.5 54.1,126.3 			"
          />
          <polygon
            class="st3"
            points="141.9,30.2 141.7,29.3 142.6,29.1 142.8,29.9 			"
          />
          <polygon
            class="st3"
            points="147.1,27.9 147.3,28.9 145.6,29.3 145.3,28.3 			"
          />
          <rect x="8.7" y="73.1" class="st3" width="1" height="5" />
          <polygon
            class="st3"
            points="9.7,80.2 9.7,79.3 7.1,79.3 7.1,80.6 9.1,80.6 9.1,81.5 11.7,81.5 11.7,80.2 			"
          />
          <rect
            x="7"
            y="67.2"
            transform="matrix(0.2063 -0.9785 0.9785 0.2063 -62.3033 62.7691)"
            class="st3"
            width="1.1"
            height="5.1"
          />

          <rect
            x="2.8"
            y="71.7"
            transform="matrix(0.2063 -0.9785 0.9785 0.2063 -67.2399 61.1955)"
            class="st3"
            width="2.6"
            height="0.7"
          />
          <rect x="11.4" y="76.3" class="st3" width="1" height="2.5" />
          <rect x="0.4" y="68.8" class="st3" width="0.7" height="0.7" />
          <rect x="4.6" y="64.4" class="st3" width="0.4" height="0.9" />
          <rect x="11.4" y="73.9" class="st3" width="0.9" height="0.9" />
          <rect x="10.9" y="71.3" class="st3" width="1" height="1.8" />
          <polygon
            class="st3"
            points="57.4,139.7 57.6,139.4 55.4,137.9 55,138.6 56.6,139.7 56.3,140.2 58.3,141.5 58.9,140.7 			"
          />

          <rect
            x="148.1"
            y="32.8"
            transform="matrix(0.9706 -0.2407 0.2407 0.9706 -3.733 36.7369)"
            class="st3"
            width="0.9"
            height="1.8"
          />
          <polygon
            class="st3"
            points="149.9,38.9 149.2,36 150.1,35.8 150.8,38.6 			"
          />

          <rect
            x="144.4"
            y="41.7"
            transform="matrix(0.9892 -0.1462 0.1462 0.9892 -4.7763 21.7933)"
            class="st3"
            width="2.8"
            height="3.4"
          />
          <polygon
            class="st3"
            points="155.2,38.1 155.6,38 154.9,35.5 154.2,35.7 154.7,37.7 153.4,38 153.6,38.9 155.3,38.5 			"
          />
          <polygon
            class="st3"
            points="152.1,31 152.7,30.9 152.5,30.2 152,30.3 151.8,30.3 150.7,30.6 151.2,32.4 152.4,32.1 			"
          />

          <rect
            x="131.1"
            y="13.5"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 91.8755 143.3256)"
            class="st3"
            width="8.4"
            height="1.7"
          />
          <polygon
            class="st3"
            points="134.6,21.6 126.1,19.7 126.3,18.6 134.8,20.5 			"
          />
          <polygon
            class="st3"
            points="127.5,5.9 135.9,7.7 135.7,8.5 127.4,6.7 			"
          />
          <polygon
            class="st3"
            points="125.8,13.6 124.7,13.4 125.6,9.2 126.7,9.4 			"
          />

          <rect
            x="141.7"
            y="18.1"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 93.751 154.577)"
            class="st3"
            width="3.2"
            height="1.4"
          />

          <rect
            x="146.2"
            y="11.5"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 102.1105 153.7754)"
            class="st3"
            width="1.4"
            height="3.3"
          />
          <polygon
            class="st3"
            points="132.7,2.5 132.5,3.2 131.3,2.9 131.1,3.6 132.4,3.9 135.8,4.6 136.1,3.2 			"
          />

          <rect
            x="139.8"
            y="12.9"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 97.1465 149.6329)"
            class="st3"
            width="4.2"
            height="2.7"
          />

          <rect
            x="126.2"
            y="15.2"
            transform="matrix(0.2174 -0.9761 0.9761 0.2174 83.8012 136.0954)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="129.9"
            y="12.2"
            transform="matrix(0.2174 -0.9761 0.9761 0.2174 89.9251 137.349)"
            class="st3"
            width="1.4"
            height="0.7"
          />
          <polygon
            class="st3"
            points="128.7,0.8 128.5,1.5 128.2,2.9 129.6,3.2 129.9,1.8 132,2.3 132.1,1.5 			"
          />
          <polygon
            class="st3"
            points="143.6,11.3 142.1,10.9 142.5,9.5 143.9,9.8 			"
          />

          <rect
            x="141.5"
            y="5.9"
            transform="matrix(0.2174 -0.9761 0.9761 0.2174 105.3621 144.8643)"
            class="st3"
            width="3"
            height="1.6"
          />
          <polygon
            class="st3"
            points="143.2,2.2 143.3,1.7 139.8,0.9 139.6,2 142.2,2.6 142,3.4 145.2,4.1 145.5,2.7 			"
          />
          <polygon
            class="st3"
            points="146.9,4.8 147.1,3.9 145.9,3.6 145.7,4.5 145.6,4.8 145.2,6.6 148.3,7.3 148.7,5.2 			"
          />
          <polygon
            class="st3"
            points="156.8,46.7 157.2,46.7 157.2,46.3 158.1,46.3 158.2,45.4 157.2,45.4 157.3,42.4 156.5,42.4 
				156.4,45.8 155.6,45.8 155.5,47.1 156.8,47.1 			"
          />
          <polygon
            class="st3"
            points="50.8,123.5 49.1,124.2 48.6,123 50.4,122.3 			"
          />
          <polygon
            class="st3"
            points="49.8,119.9 50.2,121 50.8,120.8 51.2,122 51.7,121.8 51.3,120.6 50.9,119.5 			"
          />
          <polygon
            class="st3"
            points="50.3,117.5 47.9,118.4 47.6,117.6 50,116.7 			"
          />
          <polygon
            class="st3"
            points="48.6,114.1 47,114.7 46.4,113.3 48.1,112.7 			"
          />
          <polygon
            class="st3"
            points="52.2,114.1 50.6,114.7 50.1,113.3 51.7,112.7 			"
          />
          <polygon
            class="st3"
            points="48.7,122.2 47.9,122.5 46.6,118.9 47.4,118.6 			"
          />
          <polygon
            class="st3"
            points="52.4,131.1 51.4,131.5 51.1,130.5 52.1,130.1 			"
          />
          <polygon
            class="st3"
            points="58.1,112.2 58.1,111.5 56.7,111.5 56.7,112.9 58,112.9 58,114 56.7,114 56.6,116.5 58.8,116.5 
				58.8,114 58.9,112.2 			"
          />
          <polygon
            class="st3"
            points="54.5,128.3 54.2,127.7 53.3,128 53.5,128.6 54.3,128.4 54.7,129.6 55.1,129.4 54.7,128.2 			"
          />
          <polygon
            class="st3"
            points="58.7,125.9 60.1,125.4 60.3,126.1 58.9,126.6 			"
          />
          <polygon
            class="st3"
            points="152,28.9 151.8,28.2 152.6,28 152.8,28.7 			"
          />
          <polygon
            class="st3"
            points="153.6,26.8 153.8,27.6 153,27.8 152.8,27 			"
          />
          <polygon
            class="st3"
            points="224.9,1.5 228.5,13.7 224.2,15 220.6,2.8 			"
          />
          <polygon
            class="st3"
            points="239.2,10.6 238.5,8.2 239.8,7.9 240.5,10.2 			"
          />
          <polygon
            class="st3"
            points="233,5.7 232,6 231.5,4.2 230.5,4.5 231,6.3 232.5,11.3 234.5,10.7 			"
          />
          <polygon
            class="st3"
            points="241.7,6.9 240,1.1 242.1,0.5 243.8,6.2 			"
          />
          <polygon
            class="st3"
            points="235.3,1 234.2,1.3 232.2,1.9 232.8,3.9 234.8,3.3 235.7,6.4 236.8,6 			"
          />
          <polygon
            class="st3"
            points="279.1,22.7 277.9,23 275.9,23.6 276.5,25.6 278.5,25 279.5,28 280.6,27.7 			"
          />
          <polygon
            class="st3"
            points="231,18.6 231.7,20.9 227.4,22.2 226.7,19.9 			"
          />
          <polygon
            class="st3"
            points="243,35.2 243.7,37.5 239.4,38.8 238.7,36.5 			"
          />
          <polygon
            class="st3"
            points="235.1,17.3 235.9,17.1 234.4,12 232.8,12.5 234,16.3 232.7,16.7 234.1,21.3 236.1,20.7 			"
          />
          <polygon
            class="st3"
            points="218,14.1 218.8,13.9 216.3,5.5 214.8,6 216.6,12.3 215.4,12.7 217.7,20.3 219.7,19.7 			"
          />
          <polygon
            class="st3"
            points="238.4,9.6 239.4,13.1 237.8,13.6 236.7,10.1 			"
          />
          <polygon
            class="st3"
            points="239.7,20.7 239.2,18.8 241,18.3 241.5,20.1 			"
          />
          <polygon
            class="st3"
            points="231.1,14.8 231.7,16.7 229.8,17.2 229.3,15.4 			"
          />

          <rect
            x="155.6"
            y="33.5"
            transform="matrix(0.9706 -0.2407 0.2407 0.9706 -3.5445 38.5131)"
            class="st3"
            width="0.5"
            height="0.5"
          />
          <polygon
            class="st3"
            points="156.9,35.6 157.1,36.1 156.5,36.3 156.4,35.7 			"
          />
          <polygon
            class="st3"
            points="143.6,36.1 144,36 143.8,35.2 143.4,35.3 143.3,34.8 142.8,34.9 142.7,34.3 142.4,34.4 142.6,35 
				142.6,35.1 143,36.7 143.7,36.5 			"
          />
          <polygon
            class="st3"
            points="149.4,40.5 149.3,39.8 148.9,39.9 148.5,38.7 148.4,38.3 147.2,38.6 146,38.9 146.1,39.3 147.3,39 
				147.7,40.4 148.6,40.2 148.7,40.7 			"
          />

          <rect
            x="50"
            y="107.9"
            transform="matrix(6.216394e-02 -0.9981 0.9981 6.216394e-02 -59.9114 153.0222)"
            class="st3"
            width="2.9"
            height="1"
          />

          <rect
            x="48.9"
            y="108.6"
            transform="matrix(6.216394e-02 -0.9981 0.9981 6.216394e-02 -64.8073 153.3618)"
            class="st3"
            width="0.7"
            height="5.2"
          />

          <rect
            x="48.3"
            y="101.6"
            transform="matrix(6.215375e-02 -0.9981 0.9981 6.215375e-02 -57.7302 146.2637)"
            class="st3"
            width="1.3"
            height="4.5"
          />

          <rect
            x="55.7"
            y="108.8"
            transform="matrix(6.218907e-02 -0.9981 0.9981 6.218907e-02 -55.8503 158.9525)"
            class="st3"
            width="1.9"
            height="0.9"
          />

          <rect
            x="57.9"
            y="104.5"
            transform="matrix(6.211942e-02 -0.9981 0.9981 6.211942e-02 -50.6333 157.1732)"
            class="st3"
            width="0.9"
            height="2"
          />
          <polygon
            class="st3"
            points="48.9,100.5 48.8,100.9 48.1,100.9 48.1,101.3 48.8,101.4 50.9,101.5 51,100.6 			"
          />

          <rect
            x="53.9"
            y="107.5"
            transform="matrix(6.216394e-02 -0.9981 0.9981 6.216394e-02 -56.967 155.4005)"
            class="st3"
            width="0.5"
            height="1"
          />

          <rect
            x="46.4"
            y="106.5"
            transform="matrix(6.218008e-02 -0.9981 0.9981 6.218008e-02 -62.5863 148.8855)"
            class="st3"
            width="3"
            height="2.5"
          />

          <rect
            x="53"
            y="103.5"
            transform="matrix(6.209712e-02 -0.9981 0.9981 6.209712e-02 -53.2626 151.3443)"
            class="st3"
            width="1.8"
            height="1"
          />
          <polygon
            class="st3"
            points="53.9,100.1 53.9,99.8 51.7,99.7 51.7,100.3 53.3,100.4 53.3,100.9 55.2,101.1 55.3,100.2 			"
          />

          <rect
            x="58.8"
            y="103.4"
            transform="matrix(6.215524e-02 -0.9981 0.9981 6.215524e-02 -48.4856 156.9511)"
            class="st3"
            width="0.9"
            height="1.8"
          />

          <rect
            x="62.4"
            y="103"
            transform="matrix(6.213743e-02 -0.9981 0.9981 6.213743e-02 -45.3484 160.7367)"
            class="st3"
            width="0.9"
            height="2.9"
          />
          <polygon
            class="st3"
            points="57.5,100.6 57.5,100 56.8,99.9 56.8,100.5 56.8,100.7 56.7,101.8 58.5,101.9 58.6,100.6 			"
          />
          <polygon
            class="st3"
            points="58.9,96 58.9,95.1 57.1,95 57.1,95.8 55.8,95.8 55.7,96.9 54.3,96.8 54.3,97.5 55.7,97.5 56.1,97.6 
				59.8,97.8 59.9,96 			"
          />

          <rect
            x="58.2"
            y="107.9"
            transform="matrix(6.218180e-02 -0.9981 0.9981 6.218180e-02 -53.9192 160.8214)"
            class="st3"
            width="0.8"
            height="2.3"
          />
          <rect x="161.9" y="47" class="st3" width="0.9" height="1.9" />
          <rect x="162.8" y="43.8" class="st3" width="2" height="0.9" />
          <rect x="163.8" y="42.5" class="st3" width="1.8" height="0.9" />
          <polygon
            class="st3"
            points="162.7,39.3 162.7,38.7 161.9,38.7 161.9,39.3 161.9,39.5 161.9,40.6 163.8,40.6 163.8,39.3 			"
          />
          <polygon
            class="st3"
            points="165.6,45.9 165.6,45.6 164.8,45.6 164.8,45.9 164.3,45.9 164.3,46.4 163.7,46.4 163.7,46.7 
				164.3,46.7 164.4,46.7 166,46.7 166,45.9 			"
          />
          <rect x="163.7" y="49.3" class="st3" width="2.3" height="0.8" />
          <polygon
            class="st3"
            points="210.1,29.6 204.1,31.4 203.6,29.6 209.6,27.8 			"
          />
          <polygon
            class="st3"
            points="201.5,8 199.5,8.3 199.4,7.6 197.4,7.9 198.3,12.9 200.3,12.5 199.8,10.1 201.8,9.7 			"
          />
          <polygon
            class="st3"
            points="203,6 205.1,13.2 204.3,13.4 205.3,16.7 206,16.5 206.5,18.2 208.1,17.8 204.6,5.6 			"
          />
          <polygon
            class="st3"
            points="153.1,47.8 152.8,46.9 152.5,47 151.7,43.6 150.3,44 151.3,47.8 152.5,47.5 152.6,48 			"
          />
          <polygon
            class="st3"
            points="12.1,52.1 11.6,52 11.8,51.6 12.2,51.7 			"
          />
          <polygon
            class="st3"
            points="15.9,55.7 12.9,54.8 13.2,54 16.2,54.8 			"
          />
          <polygon
            class="st3"
            points="17.9,49.2 16.9,48.9 17,48.5 16.1,48.2 15.3,50.6 16.3,50.9 16.6,49.7 17.6,50 			"
          />
          <polygon
            class="st3"
            points="20.4,47.4 19.3,50.9 19,50.8 18.5,52.5 18.8,52.6 18.6,53.4 19.4,53.7 21.1,47.6 			"
          />
          <polygon
            class="st3"
            points="12.5,47.3 12.8,46.4 12.4,46.3 13.3,43 11.9,42.6 10.9,46.5 12.1,46.8 12,47.2 			"
          />
          <polygon
            class="st3"
            points="154.8,50.2 155,49.5 158.7,50.6 158.5,51.3 			"
          />
          <polygon
            class="st3"
            points="160.4,35.6 159.5,31.9 163.2,30.9 164.2,34.7 			"
          />
          <polygon
            class="st3"
            points="146.2,47.7 146.6,46.5 150.3,47.5 149.9,48.8 			"
          />
          <polygon
            class="st3"
            points="149.4,42.9 149,41.1 151.4,40.6 151.8,42.3 			"
          />
          <polygon
            class="st3"
            points="154.2,41.3 153.9,39.9 154.9,39.7 155.2,41 			"
          />
          <polygon
            class="st3"
            points="132.4,37.3 131,41.8 129.6,41.4 131,36.8 			"
          />
          <polygon
            class="st3"
            points="127.3,36.6 128,34.3 130.3,35 129.6,37.3 			"
          />
          <polygon
            class="st3"
            points="124.5,33.9 123.9,33.7 122.7,37.7 123.8,38.1 124.8,34.9 126.9,35.6 127.4,34.1 124.7,33.3 			"
          />
          <polygon
            class="st3"
            points="134.4,35.3 134.1,36.5 134.7,36.7 134.1,38.7 133.9,39.3 135.8,39.9 137.7,40.4 137.9,39.8 
				136,39.2 136.7,37 135.3,36.5 135.6,35.7 			"
          />
          <polygon
            class="st3"
            points="401.7,103.2 405.2,101.9 404.6,100.5 401.2,101.8 			"
          />
          <polygon
            class="st3"
            points="140.3,30.5 139.7,32.5 137.7,31.9 138.3,29.9 			"
          />
          <polygon
            class="st3"
            points="375.3,118.3 375.6,119.5 376.4,119.2 376,118.1 			"
          />
          <polygon
            class="st3"
            points="135.5,22.3 135,23.8 135.5,24 133.9,29.2 136.1,29.9 138,23.8 136.1,23.2 136.3,22.5 			"
          />
          <polygon
            class="st3"
            points="128.9,26.5 128.3,28.5 122.3,26.6 122.9,24.7 			"
          />
          <polygon
            class="st3"
            points="136.4,32.1 135.5,34.8 131.1,33.5 132,30.7 			"
          />
          <polygon
            class="st3"
            points="128.5,30.3 127.9,32.5 126.2,32 126.9,29.8 			"
          />
          <polygon
            class="st3"
            points="218.7,178.3 213.8,181.3 213.4,180.7 218.3,177.7 			"
          />
          <polygon
            class="st3"
            points="221.8,206.8 222.2,205.2 222.8,205.4 222.4,207 			"
          />
          <polygon
            class="st3"
            points="206.9,182.4 207,181.4 202.3,181 202.1,183.4 205.5,183.6 205.5,184.6 210.2,185 210.4,182.7 			"
          />
          <polygon
            class="st3"
            points="214.2,201.8 215.5,200.5 216.7,201.8 215.4,203.1 			"
          />
          <polygon
            class="st3"
            points="217.9,192.6 219.2,191.4 220.5,192.7 219.2,193.9 			"
          />
          <polygon
            class="st3"
            points="231.3,207.6 230.7,208.3 229.9,207.7 230.6,207 			"
          />
          <polygon
            class="st3"
            points="216.6,197.8 212.8,201.4 211.7,200.3 215.5,196.7 			"
          />
          <polygon
            class="st3"
            points="229.4,205.4 230.5,205.3 230.5,206 233.8,205.9 233.8,205.3 231,205.3 231,204.5 229.3,204.5 			"
          />
          <polygon
            class="st3"
            points="200.8,178 200.5,179.8 199.4,179.6 198.5,184.7 199.5,184.8 200.3,180.6 201.6,180.8 202.1,178.3 
							"
          />
          <polygon
            class="st3"
            points="218.5,200.8 219.1,201.4 217.3,203 217.9,203.6 219.6,202 223.2,198.7 222,197.5 			"
          />

          <rect
            x="215"
            y="193.6"
            transform="matrix(6.136349e-02 -0.9981 0.9981 6.136349e-02 9.2163 397.6109)"
            class="st3"
            width="2.1"
            height="0.6"
          />

          <rect
            x="201.1"
            y="186.1"
            transform="matrix(0.9899 -0.1418 0.1418 0.9899 -24.4221 30.669)"
            class="st3"
            width="3.9"
            height="1.2"
          />
          <polygon
            class="st3"
            points="221.2,180.7 221.3,180.9 220.2,181.5 220,181.2 218,182.4 218.2,182.8 218.6,183.5 218.4,183.6 
				219.3,185 221.5,183.6 221.1,183 222.2,182.3 224.2,181 223.3,179.6 223.2,179.5 			"
          />
          <polygon
            class="st3"
            points="218,173.8 219.2,173.1 221.3,176.3 220,177.1 			"
          />
          <polygon
            class="st3"
            points="225.1,184.4 222.3,186.2 224.6,189.9 223,190.9 225.8,195.4 228.6,193.7 226.3,189.9 227.9,189 			
				"
          />
          <polygon
            class="st3"
            points="218.1,216.6 219.2,217.7 221.5,215.3 220.4,214.1 219,215.4 215.6,211.9 214.2,213.3 217.8,216.9 
							"
          />
          <polygon
            class="st3"
            points="228.7,199.4 232.2,195.9 233.4,197.1 229.8,200.6 			"
          />
          <polygon
            class="st3"
            points="224.1,203 227.7,199.4 228.9,200.6 225.3,204.2 			"
          />
          <polygon
            class="st3"
            points="215.7,170.9 213.9,171.4 211.7,163.4 213.5,162.9 			"
          />
          <polygon
            class="st3"
            points="234.2,198.7 235.3,197.6 238.8,201.2 237.7,202.2 			"
          />

          <rect
            x="205.5"
            y="188.4"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -25.1398 350.6396)"
            class="st3"
            width="1.1"
            height="5.2"
          />
          <polygon
            class="st3"
            points="210.7,194.5 205.7,193.4 205.9,192.3 211,193.5 			"
          />
          <polygon
            class="st3"
            points="220.3,209.4 228.7,211.3 228.5,212.1 220.1,210.2 			"
          />

          <rect
            x="219.7"
            y="221.7"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -43.3092 390.5041)"
            class="st3"
            width="4.3"
            height="1.1"
          />
          <polygon
            class="st3"
            points="226.6,209.4 225.4,209.1 225.7,207.9 226.9,208.2 			"
          />

          <rect
            x="223.7"
            y="219.7"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -39.0885 391.3312)"
            class="st3"
            width="1.4"
            height="0.7"
          />
          <polygon
            class="st3"
            points="232.5,201.5 232.3,202.3 232,203.7 233.4,204 233.7,202.6 235.8,203.1 236,202.3 			"
          />
          <polygon
            class="st3"
            points="222.8,162.5 223.2,161 225.5,161.7 225,163.2 			"
          />
          <polygon
            class="st3"
            points="219.6,168.4 220.1,168.7 222.5,165.3 221.6,164.7 219.7,167.3 217.8,166 216.9,167.3 219.2,168.9 
							"
          />
          <polygon
            class="st3"
            points="223.2,218.6 222.1,217.4 225.7,214.1 226.8,215.3 			"
          />
          <polygon
            class="st3"
            points="223.1,157.5 222.4,159.6 220.8,159.1 221.5,157 			"
          />
          <polygon
            class="st3"
            points="349.1,118.1 352.5,119.3 353.1,117.9 349.6,116.6 			"
          />
          <polygon
            class="st3"
            points="165.9,28.2 165.2,28.5 164.9,27.7 165.7,27.5 			"
          />
          <polygon
            class="st3"
            points="364.7,115.4 365.3,115.8 365.7,115.1 365,114.7 			"
          />
          <polygon
            class="st3"
            points="363.6,117.3 364.3,117.7 364.7,117 364,116.6 			"
          />
          <polygon
            class="st3"
            points="362.6,119.2 363.3,119.6 363.6,118.9 363,118.5 			"
          />
          <polygon
            class="st3"
            points="361.6,121.1 362.2,121.5 362.6,120.8 361.9,120.4 			"
          />
          <polygon
            class="st3"
            points="176.7,25.4 172,27.2 171.4,25.8 176.2,24 			"
          />
          <polygon
            class="st3"
            points="172.9,15 171.4,15.6 171.2,15 169.7,15.5 171.1,19.4 172.6,18.8 171.9,16.9 173.4,16.4 			"
          />
          <polygon
            class="st3"
            points="174.5,10.3 176.5,15.9 176,16.1 176.9,18.8 177.5,18.5 178,19.9 179.2,19.4 175.7,9.8 			"
          />
          <polygon
            class="st3"
            points="167.6,17.6 166.1,18.2 166.3,18.7 161.1,20.6 161.9,22.7 168,20.5 167.3,18.6 167.9,18.4 			"
          />
          <polygon
            class="st3"
            points="160.2,14.9 158.3,15.6 156.2,9.7 158.1,9 			"
          />
          <polygon
            class="st3"
            points="160.3,24.3 157.6,25.3 156,21 158.7,20 			"
          />
          <polygon
            class="st3"
            points="169.9,6.6 170.5,8.3 168.1,9.2 167.9,8.6 166.4,9.1 166.8,10 164.3,10.9 163.8,9.4 162.4,9.9 
				163.5,12.9 164.8,12.4 164.7,11.9 167.1,11 167.8,12.9 169.3,12.4 168.5,10.2 172.2,8.8 172,8.3 171.9,7.8 171.3,6.1 			"
          />
          <polygon
            class="st3"
            points="157,16.9 154.8,17.7 154.2,16.1 156.4,15.3 			"
          />
          <polygon
            class="st3"
            points="172.4,39.3 173.8,38.9 175.2,43.3 173.9,43.7 			"
          />
          <polygon
            class="st3"
            points="180.8,43.8 184.2,42.7 185.3,46.1 181.9,47.2 			"
          />
          <polygon
            class="st3"
            points="181.9,50.9 180.8,47.5 173.8,49.7 173.4,48.6 170,49.7 171.1,53.1 174.5,52 174.2,51.1 174.9,53.2 
							"
          />
          <polygon
            class="st3"
            points="181.7,37.9 181.4,37 175.4,39 175.9,40.7 180.7,39.1 181.7,42.4 183.9,41.7 182.6,37.6 			"
          />
          <polygon
            class="st3"
            points="171.8,37.4 170.6,37.7 170.3,36.6 171.4,36.2 			"
          />
          <polygon
            class="st3"
            points="173.9,32.7 172.9,33.1 172.5,32 173.6,31.7 			"
          />
          <polygon
            class="st3"
            points="175.2,34.1 176.6,33.7 177,35 175.6,35.5 			"
          />
          <polygon
            class="st3"
            points="177.9,43.3 179,44.2 178.1,45.3 177,44.3 			"
          />
          <polygon
            class="st3"
            points="183.6,31.4 180.9,32.3 180,29.6 182.7,28.7 			"
          />
          <polygon
            class="st3"
            points="190.2,48.6 188.6,49.1 183.8,34.2 185.4,33.7 			"
          />
          <rect x="123.5" y="83.5" class="st3" width="1" height="2.5" />
          <rect x="111.7" y="101.5" class="st3" width="5.2" height="0.7" />
          <polygon
            class="st3"
            points="121,82.7 124.1,81.3 124.6,82.4 121.5,83.8 			"
          />
          <rect x="110.1" y="96.1" class="st3" width="0.7" height="2.6" />
          <rect x="129.9" y="87.6" class="st3" width="0.9" height="1.9" />
          <rect x="142.4" y="86.6" class="st3" width="2" height="0.9" />
          <rect x="132.9" y="73.3" class="st3" width="0.6" height="1.9" />
          <polygon
            class="st3"
            points="127.5,74.1 126.8,74.1 126.8,76.6 127.5,76.6 127.5,74.8 129.9,74.8 129.9,74.1 			"
          />
          <polygon
            class="st3"
            points="124.6,75.3 124.6,73 123.6,73 123.6,75.3 123,75.3 123,76.6 126,76.6 126,75.3 			"
          />
          <rect x="145.7" y="76.9" class="st3" width="1" height="0.5" />
          <polygon
            class="st3"
            points="120.4,78.3 120.6,78.8 119.9,79.1 120.1,79.6 120.8,79.3 122.8,78.4 122.3,77.4 			"
          />
          <rect x="126.6" y="86.7" class="st3" width="1" height="1" />
          <rect x="111.2" y="99.4" class="st3" width="0.7" height="0.7" />
          <rect x="121.7" y="87.1" class="st3" width="0.9" height="0.9" />
          <polygon
            class="st3"
            points="120.2,77 118,78 117.5,76.9 119.7,75.9 			"
          />
          <polygon
            class="st3"
            points="117.8,78.8 118,79.3 118.5,80.3 119.3,80 118.8,79 120,78.4 119.7,77.9 			"
          />
          <rect x="128.3" y="85.8" class="st3" width="0.9" height="0.9" />
          <rect x="125.2" y="88.4" class="st3" width="1" height="1.8" />
          <polygon
            class="st3"
            points="128.3,81.8 128.4,81.5 126.7,80.3 126.3,80.9 127.6,81.8 127.3,82.2 128.9,83.3 129.4,82.6 			"
          />
          <rect x="131.8" y="83" class="st3" width="1.8" height="0.9" />
          <rect x="134.8" y="83" class="st3" width="2.9" height="0.9" />
          <rect x="137.2" y="80.8" class="st3" width="1.5" height="1.5" />
          <polygon
            class="st3"
            points="135.6,80.1 135.6,79.7 133,79.7 133,80.4 135,80.4 135,81.8 136,81.8 136,80.1 			"
          />
          <polygon
            class="st3"
            points="130.6,79.9 130.6,79.3 129.9,79.3 129.9,79.9 129.9,80 129.9,81.1 131.8,81.1 131.8,79.9 			"
          />
          <polygon
            class="st3"
            points="117.2,74.5 115.5,75.2 114.8,73.7 116.5,73 			"
          />
          <polygon
            class="st3"
            points="122.1,73.9 121.1,74.3 120.6,73.1 121.5,72.7 			"
          />
          <polygon
            class="st3"
            points="118.2,73.1 117.8,72.4 116.9,72.8 117.3,73.5 118,73.2 118.6,74.7 119,74.5 118.4,73 			"
          />
          <rect x="146.2" y="77.7" class="st3" width="1.5" height="0.7" />
          <rect x="149.9" y="77.7" class="st3" width="0.8" height="0.8" />
          <rect x="149" y="76.4" class="st3" width="0.8" height="0.8" />
          <rect x="135.4" y="75.1" class="st3" width="0.4" height="0.4" />
          <rect x="136.2" y="76.3" class="st3" width="0.6" height="0.6" />
          <polygon
            class="st3"
            points="133.5,86.5 133.5,86.1 132.8,86.1 132.8,86.5 132.2,86.5 132.2,87 131.6,87 131.6,87.2 132.2,87.2 
				132.4,87.2 134,87.2 134,86.5 			"
          />
          <polygon
            class="st3"
            points="138.9,86.2 138.2,86.2 138.2,86.6 136.9,86.6 136.5,86.6 136.5,87.9 136.5,89.1 136.9,89.1 
				136.9,87.9 138.4,87.9 138.4,86.9 138.9,86.9 			"
          />
          <rect x="139.5" y="88.3" class="st3" width="2.3" height="1" />
          <rect x="146.2" y="87" class="st3" width="0.5" height="0.5" />
          <rect x="146.1" y="84" class="st3" width="0.8" height="0.5" />
          <rect x="132.3" y="89.1" class="st3" width="2.3" height="0.8" />
          <polygon
            class="st3"
            points="150.8,88.9 147.9,90 147.6,89.1 150.5,88 			"
          />
          <polygon
            class="st3"
            points="150.8,82.6 149.8,82.6 149.8,82.2 148.8,82.2 148.8,84.8 149.8,84.8 149.8,83.5 150.8,83.5 			"
          />
          <polygon
            class="st3"
            points="151.8,94.4 153.5,100.6 152.6,100.8 153.4,103.7 154.3,103.5 154.7,104.9 156.6,104.4 153.7,93.9 
							"
          />
          <polygon
            class="st3"
            points="149.7,91.8 148.8,92.2 149,92.5 145.9,94 146.5,95.3 150.1,93.6 149.5,92.4 149.9,92.2 			"
          />

          <rect
            x="142.4"
            y="77.3"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 36.8779 201.3383)"
            class="st3"
            width="3.1"
            height="0.8"
          />
          <polygon
            class="st3"
            points="144,84.2 142.3,83.8 142.9,81 144.6,81.4 			"
          />
          <polygon
            class="st3"
            points="162.4,109.2 161.3,109.2 161.3,107.6 161.7,107.6 161.7,106.6 161.1,106.6 161.1,105 162.1,105 
				162.1,104.1 160.1,104.1 160.1,105 160.4,105 160.5,106.7 159.2,106.7 159.2,107.6 160.6,107.6 160.7,110.1 161,110.1 
				161.3,110.1 162.4,110.1 			"
          />
          <rect x="138.1" y="62.4" class="st3" width="1.4" height="1.1" />
          <rect x="138.6" y="75.1" class="st3" width="1.2" height="1.2" />
          <rect x="141.5" y="74.1" class="st3" width="3" height="1.1" />
          <rect x="147.4" y="74.1" class="st3" width="4" height="0.8" />
          <polygon
            class="st3"
            points="419.8,115.5 418.6,113.9 417.9,114.4 419.1,116 			"
          />
          <polygon
            class="st3"
            points="425.3,114.4 425.7,114.9 424.2,116 423.8,115.6 			"
          />
          <polygon
            class="st3"
            points="424.6,104.9 424.4,104.1 422,104.9 422.2,105.6 423.9,105.1 424.6,107.4 425.3,107.1 			"
          />
          <polygon
            class="st3"
            points="415.6,112.9 415.1,112.2 415.8,111.6 416.3,112.3 			"
          />
          <polygon
            class="st3"
            points="416.4,108.1 418.1,110.4 416.7,111.5 414.9,109.2 			"
          />
          <polygon
            class="st3"
            points="420.5,115.7 420,115 423.7,112.5 424.2,113.3 			"
          />
          <polygon
            class="st3"
            points="423.1,119.2 421.3,116.8 422,116.3 423.8,118.6 			"
          />
          <polygon
            class="st3"
            points="425.3,116.8 426.2,118 425,118.9 424.1,117.7 			"
          />
          <polygon
            class="st3"
            points="418.4,106.4 418.4,106 415.8,106.7 416,107.4 418,106.9 418.3,108.2 419.3,108 418.8,106.3 			"
          />
          <polygon
            class="st3"
            points="422.1,111.1 422.5,110.7 422.1,110.1 421.6,110.5 421.5,110.6 420.6,111.2 421.7,112.7 422.7,112 
							"
          />
          <polygon
            class="st3"
            points="451.1,118.7 451.3,118.3 442.5,111.8 443.3,110.6 442.3,109.9 440.9,111.9 440.9,111.9 441.9,112.6 
				449.8,118.4 448.4,120.2 450,121.4 451.7,119.1 			"
          />
          <polygon
            class="st3"
            points="432.8,103.5 432.8,103.1 432.4,103.1 432.4,102.2 431.5,102.2 431.6,103.1 428.7,103.1 428.7,103.9 
				432,103.9 432,104.7 433.2,104.7 433.2,103.5 			"
          />
          <polygon
            class="st3"
            points="420.2,109.8 419.7,109.2 420.3,108.7 420.8,109.3 			"
          />
          <polygon
            class="st3"
            points="421.2,105.3 421.5,106 420.7,106.2 420.5,105.5 			"
          />
          <polygon
            class="st3"
            points="426.5,112.5 426.2,112.1 426.6,111.8 426.9,112.2 			"
          />
          <polygon
            class="st3"
            points="428.6,112.6 428.4,112.3 428.7,112 429,112.4 			"
          />
          <polygon
            class="st3"
            points="428.3,113.3 428.6,113.8 428.1,114.2 427.8,113.7 			"
          />
          <polygon
            class="st3"
            points="414.4,109.6 414.2,108.9 413.8,109 413.4,107.8 413.3,107.4 412.1,107.8 410.9,108.2 411.1,108.6 
				412.2,108.2 412.7,109.6 413.5,109.3 413.7,109.9 			"
          />
          <polygon
            class="st3"
            points="432,128.8 431.4,128 431.7,127.8 431.1,127 429.1,128.5 429.7,129.3 430.7,128.5 431.3,129.3 			"
          />
          <polygon
            class="st3"
            points="435.3,129.1 433.2,130.4 433,130.1 432,130.7 432.2,131 431.7,131.3 432.1,132 435.8,129.8 			"
          />
          <polygon
            class="st3"
            points="429.6,126.1 429,125.4 428.7,125.6 426.6,122.8 425.5,123.7 427.9,126.9 428.9,126.1 429.1,126.5 
							"
          />
          <polygon
            class="st3"
            points="429.3,122.2 430.3,121.5 432.6,124.6 431.6,125.3 			"
          />
          <polygon
            class="st3"
            points="424.3,123 423.2,121.6 425.5,119.9 426.5,121.3 			"
          />
          <polygon
            class="st3"
            points="440,126.5 439.2,127.2 438.2,125.9 438.5,125.7 437.9,124.9 437.5,125.3 436.5,124 437.3,123.4 
				436.7,122.7 435.2,123.9 435.7,124.6 436,124.4 436.9,125.7 435.9,126.5 436.5,127.2 437.7,126.3 439.2,128.3 439.4,128.1 
				439.7,127.9 440.6,127.2 			"
          />
          <polygon
            class="st3"
            points="428,119.7 427.2,118.6 428,117.9 428.8,119.1 			"
          />
          <polygon
            class="st3"
            points="431.3,119.6 430.6,118.7 431.5,118 432.2,118.9 			"
          />
          <polygon
            class="st3"
            points="444.6,124.9 439,117.6 439.9,116.9 445.5,124.2 			"
          />
          <polygon
            class="st3"
            points="436.2,117.8 433.7,119.7 433.3,119.2 435.8,117.3 			"
          />
          <polygon
            class="st3"
            points="446.4,117.6 445,118.6 444.1,117.3 443.2,118 444.2,119.3 443.6,119.7 445.3,121.8 448,119.7 			"
          />
          <polygon
            class="st3"
            points="436.5,120.6 433.3,123 432.9,122.4 436,120 			"
          />
          <polygon
            class="st3"
            points="438.9,121.9 438.1,120.8 438.7,120.3 439.5,121.4 			"
          />
          <polygon
            class="st3"
            points="440.8,124.5 439.7,123 440.1,122.6 441.2,124.1 			"
          />
          <polygon
            class="st3"
            points="452.9,120 452.3,119.2 453.1,118.6 453.7,119.4 			"
          />

          <rect
            x="248.9"
            y="61.6"
            transform="matrix(0.9825 -0.1863 0.1863 0.9825 -7.4373 47.5813)"
            class="st3"
            width="1.1"
            height="3.6"
          />

          <rect
            x="254.9"
            y="72.2"
            transform="matrix(0.9745 -0.2245 0.2245 0.9745 -9.7948 59.3493)"
            class="st3"
            width="2.5"
            height="1.1"
          />
          <rect x="254.8" y="51.9" class="st3" width="0.7" height="2.4" />
          <polygon
            class="st3"
            points="249.7,47 248.8,47.2 249.5,50.4 250.4,50.1 249.9,47.9 252.8,47.3 252.6,46.3 			"
          />
          <rect x="233.9" y="44.1" class="st3" width="1.3" height="1.7" />
          <rect x="244.8" y="51.8" class="st3" width="1.1" height="1.1" />
          <rect x="244.1" y="48.6" class="st3" width="1.2" height="2.3" />
          <polygon
            class="st3"
            points="258,71.2 255.9,71.7 255.6,70.6 257.8,70.1 			"
          />

          <rect
            x="253.4"
            y="66.8"
            transform="matrix(0.9794 -0.2021 0.2021 0.9794 -8.3486 52.9841)"
            class="st3"
            width="3.7"
            height="1.1"
          />

          <rect
            x="263"
            y="59.1"
            transform="matrix(0.9825 -0.1864 0.1864 0.9825 -6.5617 50.2345)"
            class="st3"
            width="1.8"
            height="1.9"
          />
          <polygon
            class="st3"
            points="259.6,52.7 259.6,52.3 256.3,52.3 256.3,53.2 258.9,53.2 258.9,54.9 260,54.9 260,52.7 			"
          />
          <polygon
            class="st3"
            points="253.1,61.2 253.1,60.5 252.2,60.5 252.2,61.2 252.2,61.4 252.2,62.8 254.5,62.8 254.5,61.2 			"
          />
          <polygon
            class="st3"
            points="236.5,56.8 236.8,57.1 242.1,51.3 242.8,52 243.4,51.4 242.1,50.1 242.1,50.2 241.5,50.8 
				236.8,55.9 235.6,54.7 234.6,55.8 236.2,57.2 			"
          />
          <polygon
            class="st3"
            points="248.4,55.5 248.3,55 247.8,55.1 247.6,54 246.5,54.2 246.7,55.3 243.3,56 243.5,57 247.5,56.2 
				247.7,57.1 249.1,56.8 248.8,55.4 			"
          />
          <polygon
            class="st3"
            points="252.5,53.3 251.6,53.5 251.4,52.6 252.3,52.4 			"
          />
          <rect
            x="250.1"
            y="51.2"
            transform="matrix(0.9749 -0.2226 0.2226 0.9749 -5.2133 57.0641)"
            class="st3"
            width="1"
            height="1"
          />
          <rect x="254.2" y="50.3" class="st3" width="0.6" height="0.6" />
          <rect x="256" y="48.4" class="st3" width="0.6" height="0.6" />
          <rect x="257" y="49.9" class="st3" width="0.7" height="0.7" />

          <rect
            x="240.7"
            y="43.5"
            transform="matrix(0.9809 -0.1944 0.1944 0.9809 -4.099 47.7227)"
            class="st3"
            width="0.7"
            height="2.4"
          />
          <polygon
            class="st3"
            points="245.3,43.5 245.2,43 242,43.7 242.2,44.6 244.7,44.1 245,45.8 246.2,45.6 245.8,43.4 			"
          />

          <rect
            x="239.6"
            y="42.1"
            transform="matrix(0.9809 -0.1943 0.1943 0.9809 -3.6611 47.4304)"
            class="st3"
            width="0.6"
            height="0.6"
          />
          <rect
            x="233"
            y="40.8"
            transform="matrix(0.9937 -0.1123 0.1123 0.9937 -3.1498 26.7007)"
            class="st3"
            width="4.8"
            height="1"
          />

          <rect
            x="242.3"
            y="41.1"
            transform="matrix(0.9809 -0.1945 0.1945 0.9809 -3.4304 47.9766)"
            class="st3"
            width="0.7"
            height="0.7"
          />
          <polygon
            class="st3"
            points="258.8,74.4 258.7,73.9 257.7,74.1 257.8,74.6 257.2,74.7 257.3,75.3 256.6,75.5 256.6,75.8 
				257.4,75.7 257.6,75.6 259.5,75.2 259.3,74.2 			"
          />
          <polygon
            class="st3"
            points="259.2,78.3 258.3,78.5 258.4,79.1 256.9,79.5 256.4,79.6 256.8,81.1 257.2,82.6 257.7,82.5 
				257.3,81 259,80.5 258.7,79.4 259.4,79.2 			"
          />
          <polygon
            class="st3"
            points="264,82.1 261.3,82.8 260.9,81.7 263.7,80.9 			"
          />
          <rect x="269.3" y="73" class="st3" width="0.6" height="0.6" />
          <polygon
            class="st3"
            points="266,78.4 265.1,78.7 264.9,78.1 265.8,77.9 			"
          />
          <rect x="262.2" y="73.7" class="st3" width="2.9" height="1" />

          <rect
            x="266.3"
            y="89.2"
            transform="matrix(0.9793 -0.2022 0.2022 0.9793 -12.6167 55.9957)"
            class="st3"
            width="2.9"
            height="1.2"
          />
          <polygon
            class="st3"
            points="267.8,83.9 266.3,84.2 266.2,83.7 264.7,84 265.3,87.1 266.8,86.8 266.5,85.3 268,85 			"
          />
          <polygon
            class="st3"
            points="271.7,77.7 272.6,82.2 272.1,82.3 272.5,84.5 273.1,84.4 273.3,85.4 274.5,85.2 272.9,77.4 			"
          />
          <polygon
            class="st3"
            points="268,60.1 268.2,61.3 268.8,61.2 269.4,65.2 271.7,64.9 270.9,60.2 269,60.5 268.9,60 			"
          />

          <rect
            x="260.1"
            y="88.8"
            transform="matrix(0.9744 -0.225 0.225 0.9744 -13.8404 61.0279)"
            class="st3"
            width="1.6"
            height="4.9"
          />

          <rect
            x="264.4"
            y="67.9"
            transform="matrix(0.9794 -0.2021 0.2021 0.9794 -8.5957 55.1123)"
            class="st3"
            width="2.2"
            height="3.6"
          />
          <polygon
            class="st3"
            points="262,62.8 261.3,64 259.6,63 259.9,62.6 258.9,62 258.5,62.6 256.8,61.5 257.4,60.5 256.4,59.9 
				255.2,62 256.1,62.6 256.3,62.3 258.1,63.3 257.3,64.7 258.3,65.3 259.2,63.7 261.9,65.3 262.1,64.9 262.3,64.6 263,63.4 			"
          />

          <rect
            x="263.6"
            y="62.6"
            transform="matrix(0.9825 -0.1864 0.1864 0.9825 -7.1532 50.3996)"
            class="st3"
            width="1.8"
            height="1.3"
          />
          <rect x="260.7" y="96.9" class="st3" width="1.4" height="1.5" />

          <rect
            x="258.3"
            y="86.5"
            transform="matrix(0.9744 -0.2249 0.2249 0.9744 -12.9323 60.7494)"
            class="st3"
            width="3.7"
            height="1.4"
          />
          <rect x="268.4" y="53" class="st3" width="2.2" height="1.7" />
          <polygon
            class="st3"
            points="261.6,47.9 261.6,49.1 260.6,49.1 260.6,49.7 261.6,49.7 261.6,50.2 263.4,50.2 263.4,47.9 			"
          />
          <rect x="263.1" y="95.3" class="st3" width="5" height="1" />
          <rect x="273.8" y="53.7" class="st3" width="1.6" height="1" />
          <rect x="272.7" y="50.5" class="st3" width="2.3" height="0.7" />
          <rect x="267" y="50.5" class="st3" width="1.2" height="1.2" />
          <rect x="170.1" y="68.5" class="st3" width="3" height="1.1" />
          <rect x="171.8" y="67.9" class="st3" width="1.8" height="0.3" />
          <polygon
            class="st3"
            points="168.7,65.5 168.7,66.4 167.9,66.4 167.9,66.9 168.7,66.9 168.7,67.3 170.1,67.3 170.1,65.5 			"
          />
          <rect x="176.1" y="68.5" class="st3" width="4" height="0.8" />
          <rect x="176.7" y="66.7" class="st3" width="1.3" height="0.8" />
          <rect x="179.4" y="66.9" class="st3" width="1.8" height="0.6" />
          <rect x="174" y="65.1" class="st3" width="1" height="1" />
          <polygon
            class="st3"
            points="121.5,46 121.4,46.4 120.9,46.3 121.1,45.8 			"
          />

          <rect
            x="396.1"
            y="102.8"
            transform="matrix(0.9888 -0.1489 0.1489 0.9888 -10.9514 60.3739)"
            class="st3"
            width="3.1"
            height="0.9"
          />
          <polygon
            class="st3"
            points="135.8,49.2 135.5,50.1 135.2,50 134.9,51 137.3,51.7 137.6,50.7 136.4,50.4 136.7,49.4 			"
          />
          <polygon
            class="st3"
            points="134,46.6 137.6,47.7 137.5,48.1 139.1,48.5 139.2,48.2 140.1,48.4 140.3,47.7 134.3,45.9 			"
          />
          <polygon
            class="st3"
            points="137.2,53.1 137,54.1 137.3,54.2 136.3,57.5 137.7,57.9 138.8,54 137.6,53.7 137.7,53.3 			"
          />
          <polygon
            class="st3"
            points="125.1,42.8 124.7,44 121,42.9 121.4,41.7 			"
          />
          <polygon
            class="st3"
            points="130.5,47.6 131.6,47.9 131.1,49.4 130.8,49.3 130.5,50.2 131.1,50.4 130.6,51.9 129.7,51.7 
				129.4,52.5 131.3,53.1 131.5,52.2 131.2,52.1 131.7,50.6 132.9,50.9 133.2,50 131.8,49.6 132.5,47.2 132.2,47.1 131.8,47 
				130.8,46.7 			"
          />
          <polygon
            class="st3"
            points="119.5,40.6 118.7,43.5 117.6,43.2 118.5,40.3 			"
          />
          <polygon
            class="st3"
            points="121.3,49 120.8,50.7 116.2,49.3 116.7,47.6 			"
          />
          <polygon
            class="st3"
            points="128.5,46.4 127.4,50.2 126.7,49.9 127.8,46.1 			"
          />
          <polygon
            class="st3"
            points="126.2,47.7 125.8,49 125,48.8 125.4,47.5 			"
          />
          <polygon
            class="st3"
            points="127.2,44.7 126.6,46.4 126.1,46.3 126.6,44.5 			"
          />
          <polygon
            class="st3"
            points="124,50.3 123.7,51.3 122.8,51 123.1,50.1 			"
          />
          <polygon
            class="st3"
            points="56.3,14.3 54.9,13.8 55,13.3 53.5,12.8 52.5,16.6 53.9,17 54.5,15.2 56,15.6 			"
          />
          <polygon
            class="st3"
            points="60.2,11.4 58.7,17 58.1,16.8 57.3,19.4 57.9,19.6 57.5,20.9 58.8,21.3 61.4,11.8 			"
          />
          <polygon class="st3" points="46.5,7 44.6,6.5 46.3,0.7 48.2,1.2 			" />
          <polygon
            class="st3"
            points="58.7,6 58.2,7.7 55.9,7 56,6.4 54.6,6 54.4,6.9 51.9,6.2 52.3,4.7 51,4.3 50.2,7.3 51.5,7.7 
				51.6,7.2 54.1,7.9 53.5,9.8 55,10.2 55.6,8 59.3,9.1 59.4,8.6 59.6,8 60,6.4 			"
          />
          <polygon
            class="st3"
            points="49.8,15.2 45.3,13.9 45.8,12.3 50.2,13.6 			"
          />
          <polygon class="st3" points="43.9,9.9 39.4,8.6 39.9,7 44.3,8.3 			" />
          <polygon class="st3" points="38,4.6 33.6,3.3 34,1.7 38.4,3 			" />
          <polygon class="st3" points="31.3,5.6 29,4 28.2,5.1 30.5,6.7 			" />
          <polygon class="st3" points="60.6,2.8 54.6,1.1 55,0 60.9,1.7 			" />
          <polygon class="st3" points="34.8,10.5 33.2,9.3 33.9,8.2 35.6,9.4 			" />
          <polygon class="st3" points="39.4,13.8 37.1,12.1 37.6,11.4 40,13 			" />
          <polygon class="st3" points="42.1,5 40.6,4.6 41,3.2 42.5,3.6 			" />
          <polygon class="st3" points="47.7,9.1 45.7,8.6 46,7.8 47.9,8.3 			" />
          <polygon
            class="st3"
            points="54,116.7 52.5,117.2 52.1,116.3 53.6,115.7 			"
          />
          <polygon
            class="st3"
            points="12.8,35.5 12.3,37.6 13.4,37.9 12.9,40.1 13.9,40.4 14.4,38.1 14.9,36 			"
          />

          <rect
            x="12.9"
            y="28.6"
            transform="matrix(0.2163 -0.9763 0.9763 0.2163 -19.4958 37.6627)"
            class="st3"
            width="1.7"
            height="4.8"
          />

          <rect
            x="5.2"
            y="33.6"
            transform="matrix(0.2163 -0.9763 0.9763 0.2163 -26.7165 35.4664)"
            class="st3"
            width="7.1"
            height="1.5"
          />
          <polygon
            class="st3"
            points="22,38.5 22.2,37.8 20.5,37.4 20.2,38.8 21.7,39.2 21.5,40.3 19.9,40 19.3,42.6 21.9,43.2 22.5,40.6 
				22.9,38.7 			"
          />

          <rect
            x="16.1"
            y="33.5"
            transform="matrix(0.2163 -0.9763 0.9763 0.2163 -20.8167 44.0758)"
            class="st3"
            width="1.8"
            height="3"
          />
          <polygon
            class="st3"
            points="56.2,118.3 54.7,118.8 54.6,118.5 56.1,118 			"
          />
          <polygon
            class="st3"
            points="57.1,120 56.3,120.3 55.9,119 56.7,118.7 			"
          />
          <rect x="90.7" y="168.5" class="st3" width="1.2" height="5.9" />
          <polygon
            class="st3"
            points="71.7,180.6 68.6,173.3 69.6,172.9 72.6,180.2 			"
          />
          <polygon
            class="st3"
            points="68.6,163.4 72.8,161.9 73.5,163.7 69.3,165.3 			"
          />
          <polygon
            class="st3"
            points="69.3,169.7 68.5,170 67.3,166.9 68.1,166.6 			"
          />
          <polygon
            class="st3"
            points="89.1,179.5 90.2,179.6 90.3,175.9 89.2,175.8 89.2,178.4 85.8,178.3 85.7,179.4 			"
          />
          <polygon
            class="st3"
            points="93.4,177.8 93.3,181.1 94.7,181.2 94.8,177.8 95.7,177.9 95.7,176 91.5,175.9 91.4,177.7 			"
          />
          <polygon
            class="st3"
            points="83.7,175 84.3,176.6 83.5,177 82.8,175.3 			"
          />
          <polygon
            class="st3"
            points="70.5,159.1 70.7,159.6 69.8,159.9 70,160.4 70.9,160.1 73.4,159.1 73.1,158.1 			"
          />
          <rect x="93.8" y="172.2" class="st3" width="1.2" height="0.2" />
          <polygon
            class="st3"
            points="71.6,172.5 71.2,171.5 72.2,171 72.6,172.1 			"
          />
          <rect x="76.8" y="160.8" class="st3" width="0.9" height="0.9" />
          <polygon
            class="st3"
            points="73.9,166.9 73.4,167.1 73,166.1 73.5,165.9 			"
          />
          <polygon
            class="st3"
            points="70.5,157.8 67.6,158.9 67.1,157.8 70.1,156.7 			"
          />
          <polygon
            class="st3"
            points="64.1,160.9 64.3,161.5 64.7,162.6 65.7,162.2 65.3,161.1 66.9,160.6 66.7,160 			"
          />
          <rect x="93.8" y="169.4" class="st3" width="1.1" height="1.1" />

          <rect
            x="88.6"
            y="165.3"
            transform="matrix(2.642344e-02 -0.9997 0.9997 2.642344e-02 -78.3741 251.4334)"
            class="st3"
            width="2.6"
            height="1.4"
          />
          <polygon
            class="st3"
            points="86.2,169.8 85.6,170.1 87.1,173.6 88.2,173.2 87.1,170.5 88,170.1 86.7,166.9 85.2,167.5 			"
          />
          <polygon
            class="st3"
            points="85.1,164.5 85.4,165 93.8,159.9 94.6,161.3 95.5,160.8 94.1,158.4 94,158.4 93.1,158.9 85.7,163.5 
				84.3,161.2 82.8,162.1 84.5,164.9 			"
          />
          <polygon
            class="st3"
            points="84.4,181.1 84.4,181.7 84.9,181.7 84.9,182.9 86.1,182.9 86.2,181.7 90.2,181.8 90.2,180.6 
				85.5,180.5 85.6,179.4 83.9,179.4 83.9,181.1 			"
          />
          <polygon
            class="st3"
            points="67.2,155.2 64.9,156.1 64.3,154.5 66.6,153.7 			"
          />
          <polygon
            class="st3"
            points="62.9,149.4 63.4,150.8 64.1,150.6 64.7,152.1 65.4,151.8 64.8,150.3 64.3,148.9 			"
          />
          <polygon
            class="st3"
            points="63.5,146.4 60.4,147.6 60,146.5 63.1,145.3 			"
          />
          <polygon
            class="st3"
            points="61.5,152.4 60.5,152.8 58.8,148.2 59.7,147.8 			"
          />
          <polygon
            class="st3"
            points="65.2,158.5 63.9,159 63.5,157.7 64.7,157.2 			"
          />
          <polygon
            class="st3"
            points="67,148.9 66.8,148.5 65.6,148.9 66,149.9 67.1,149.5 67.3,150.2 66.3,150.6 66.9,152.4 68.7,151.7 
				68,150 67.6,148.7 			"
          />
          <polygon
            class="st3"
            points="68.7,153.8 68.4,153 67.2,153.4 67.5,154.2 68.4,153.9 69,155.4 69.5,155.2 68.9,153.7 			"
          />
          <polygon
            class="st3"
            points="66.4,184.5 63.5,185.6 63.1,184.5 66,183.4 			"
          />
          <polygon
            class="st3"
            points="61.4,189 61.6,189.6 62,190.6 63,190.2 62.6,189.2 64.2,188.6 64,188 			"
          />
          <polygon
            class="st3"
            points="55.5,180 53.2,180.9 52.6,179.3 54.9,178.5 			"
          />
          <polygon
            class="st3"
            points="58.8,176.1 59.4,177.5 60.1,177.3 60.6,178.8 61.4,178.5 60.8,177 60.3,175.6 			"
          />
          <polygon
            class="st3"
            points="61.1,185.2 59.9,185.7 59.4,184.4 60.7,184 			"
          />
          <polygon
            class="st3"
            points="62.9,175.7 62.7,175.2 61.6,175.6 61.9,176.6 63,176.2 63.3,176.9 62.2,177.3 62.9,179.1 
				64.6,178.4 64,176.7 63.5,175.4 			"
          />
          <polygon
            class="st3"
            points="64.6,180.5 64.3,179.7 63.1,180.1 63.4,180.9 64.4,180.6 64.9,182.1 65.4,181.9 64.9,180.4 			"
          />
          <polygon
            class="st3"
            points="197,162.4 193.8,164.1 193.1,162.9 196.3,161.2 			"
          />
          <polygon
            class="st3"
            points="192.8,159.9 190.3,161.2 189.4,159.5 191.9,158.2 			"
          />
          <polygon
            class="st3"
            points="187,153.9 187.8,155.4 188.6,155 189.5,156.7 190.3,156.3 189.4,154.6 188.6,153.1 			"
          />
          <polygon
            class="st3"
            points="191.6,152.7 191.4,152.2 190.1,152.9 190.7,153.9 191.8,153.3 192.3,154.2 191.1,154.8 192.1,156.7 
				194,155.7 193,153.8 192.3,152.4 			"
          />
          <polygon
            class="st3"
            points="194.3,158 193.8,157.2 192.5,157.9 193,158.7 194,158.2 194.9,159.9 195.5,159.6 194.6,157.9 			"
          />
          <polygon
            class="st3"
            points="84.3,175.7 83.3,173.2 84.5,172.7 85.5,175.2 			"
          />
          <polygon
            class="st3"
            points="82.7,167.7 83.2,169 81.9,169.5 81.4,168.2 			"
          />
          <polygon
            class="st3"
            points="80.4,171.8 79.9,170.5 81.2,170 81.7,171.3 			"
          />
          <polygon
            class="st3"
            points="66.4,147.7 64.5,148.5 64,147.3 66,146.5 			"
          />

          <rect
            x="95"
            y="179"
            transform="matrix(2.645443e-02 -0.9997 0.9997 2.645443e-02 -89.6552 273.3141)"
            class="st3"
            width="1"
            height="7.4"
          />

          <rect
            x="91"
            y="180.3"
            transform="matrix(2.650398e-02 -0.9996 0.9996 2.650398e-02 -91.2974 268.043)"
            class="st3"
            width="2"
            height="1.2"
          />

          <rect
            x="164.6"
            y="103.2"
            transform="matrix(6.938774e-02 -0.9976 0.9976 6.938774e-02 51.5962 262.95)"
            class="st3"
            width="4.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="169.5,118.2 163.6,121.2 163.2,120.4 169.1,117.5 			"
          />

          <rect
            x="167.9"
            y="112.3"
            transform="matrix(0.1897 -0.9818 0.9818 0.1897 28.1916 259.2781)"
            class="st3"
            width="6.5"
            height="0.6"
          />
          <polygon
            class="st3"
            points="159,115.9 157.8,114.3 161.4,111.6 162.6,113.2 			"
          />
          <rect x="172.4" y="119.7" class="st3" width="1.1" height="1.7" />
          <rect x="173.5" y="115.6" class="st3" width="2.5" height="1.1" />

          <rect
            x="173.8"
            y="107.4"
            transform="matrix(0.1643 -0.9864 0.9864 0.1643 40.1274 263.0637)"
            class="st3"
            width="3"
            height="0.9"
          />
          <polygon
            class="st3"
            points="162.2,93.6 161.3,94 162.7,97 163.5,96.6 162.6,94.5 165.3,93.2 164.9,92.3 			"
          />
          <polygon
            class="st3"
            points="161,88.6 161,85.7 159.8,85.7 159.8,88.6 159,88.6 159,90.3 162.7,90.3 162.7,88.6 			"
          />
          <rect x="165" y="86.2" class="st3" width="1.3" height="0.7" />
          <polygon
            class="st3"
            points="167.6,111.9 168.1,112 167.9,112.9 168.4,113 168.6,112.1 169.1,109.4 168.1,109.2 			"
          />
          <rect x="172.1" y="117.7" class="st3" width="1.3" height="1.3" />
          <rect x="160.4" y="116.3" class="st3" width="0.9" height="0.9" />
          <rect x="164.4" y="122.6" class="st3" width="0.6" height="1.1" />

          <rect
            x="164"
            y="112"
            transform="matrix(0.1897 -0.9818 0.9818 0.1897 23.6081 253.8404)"
            class="st3"
            width="3.1"
            height="1.1"
          />
          <polygon
            class="st3"
            points="166.3,115 166.9,115.1 168,115.3 168.2,114.2 167.1,114 167.5,112.4 166.9,112.3 			"
          />

          <rect
            x="183.2"
            y="143.5"
            transform="matrix(0.1897 -0.9818 0.9818 0.1897 8.2522 298.2048)"
            class="st3"
            width="3.1"
            height="1.1"
          />
          <polygon
            class="st3"
            points="185.5,146.4 186.1,146.6 187.2,146.8 187.4,145.7 186.3,145.5 186.7,143.9 186.1,143.8 			"
          />

          <rect
            x="170.9"
            y="106"
            transform="matrix(6.937265e-02 -0.9976 0.9976 6.937265e-02 53.2212 270.2507)"
            class="st3"
            width="1.2"
            height="1.1"
          />

          <rect
            x="169.9"
            y="103.2"
            transform="matrix(6.929767e-02 -0.9976 0.9976 6.929767e-02 55.5582 267.2845)"
            class="st3"
            width="2.3"
            height="1.2"
          />
          <polygon
            class="st3"
            points="170.6,100.4 170.7,100 168,99.8 167.9,100.7 169.9,100.8 169.9,101.5 172.4,101.7 172.5,100.6 			
				"
          />
          <rect x="174.8" y="113.9" class="st3" width="2.2" height="1.2" />
          <rect x="178.4" y="114.5" class="st3" width="3.7" height="1.2" />
          <rect x="181.9" y="110.6" class="st3" width="1.9" height="1.9" />
          <polygon
            class="st3"
            points="180.9,108.2 181,107.7 176.9,107 176.7,108.1 179.9,108.7 179.6,110.9 181.1,111.1 181.5,108.3 			
				"
          />
          <polygon
            class="st3"
            points="174.5,110.9 174.5,110.1 173.6,110.1 173.6,110.9 173.6,111.1 173.6,112.5 175.9,112.5 175.9,110.9 
							"
          />
          <polygon
            class="st3"
            points="179.7,91.2 179.6,90.6 169.1,93.1 168.7,91.3 167.6,91.6 168.3,94.6 168.3,94.6 169.4,94.3 
				178.7,92.1 179.3,95 181.2,94.5 180.4,91.1 			"
          />
          <polygon
            class="st3"
            points="173.6,96.6 173.6,96.1 173.1,96.1 173.1,95 172,95 172,96.1 168.4,96.1 168.4,97.1 172.6,97.1 
				172.6,98.1 174,98.1 174,96.6 			"
          />
          <rect x="153.6" y="88.6" class="st3" width="2.4" height="1.6" />
          <polygon
            class="st3"
            points="154,83.4 154,84.9 154.8,84.9 154.8,86.5 155.5,86.5 155.5,84.9 155.5,83.4 			"
          />
          <rect x="154.3" y="74.2" class="st3" width="3.3" height="1.2" />
          <rect x="157.1" y="92.5" class="st3" width="1" height="4.9" />
          <rect x="156.7" y="111.4" class="st3" width="1.3" height="1.3" />
          <polygon
            class="st3"
            points="158,84.4 158,83.9 156.7,83.9 156.7,84.9 157.9,84.9 157.9,85.7 156.7,85.7 156.7,87.5 158.6,87.5 
				158.6,85.7 158.6,84.4 			"
          />
          <polygon
            class="st3"
            points="157.9,89.4 157.9,88.6 156.6,88.6 156.6,89.4 157.6,89.4 157.6,91 158.1,91 158.1,89.4 			"
          />
          <rect x="165.7" y="87.2" class="st3" width="1.9" height="0.9" />
          <rect x="169.9" y="89.8" class="st3" width="1" height="1" />
          <rect x="174.3" y="89" class="st3" width="1" height="1" />

          <rect
            x="174.6"
            y="104.1"
            transform="matrix(0.1642 -0.9864 0.9864 0.1642 43.1557 259.9767)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="177.3"
            y="102.1"
            transform="matrix(0.1643 -0.9864 0.9864 0.1643 47.3335 260.8747)"
            class="st3"
            width="0.7"
            height="0.7"
          />

          <rect
            x="178.2"
            y="104.2"
            transform="matrix(0.1642 -0.9864 0.9864 0.1642 46.0679 263.6802)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="177,118.3 177,117.9 176,117.9 176,118.3 175.3,118.3 175.3,119 174.6,119 174.6,119.3 175.3,119.3 
				175.6,119.3 177.6,119.3 177.6,118.3 			"
          />
          <polygon
            class="st3"
            points="181.8,116.9 180.8,116.9 180.8,117.4 179.2,117.4 178.7,117.4 178.7,119 178.7,120.6 179.2,120.6 
				179.2,119 181.1,119 181.1,117.9 181.8,117.9 			"
          />
          <rect x="196.8" y="108.4" class="st3" width="2.9" height="1.2" />
          <rect x="194.8" y="110.3" class="st3" width="0.6" height="0.6" />
          <rect x="190" y="109.8" class="st3" width="1" height="0.6" />
          <rect x="174.6" y="122.6" class="st3" width="3" height="1" />
          <polygon
            class="st3"
            points="222.1,121 219.3,111.7 220.8,111.2 223.7,120.5 			"
          />
          <polygon
            class="st3"
            points="224.4,124.4 225.4,124.1 225.9,125.8 224.8,126 			"
          />
          <polygon
            class="st3"
            points="226.9,119.5 224.4,120.1 224.7,121.2 227.2,120.6 			"
          />
          <polygon
            class="st3"
            points="223.6,122.5 224.9,122.2 225.2,123.5 224,123.8 			"
          />
          <polygon
            class="st3"
            points="227.7,118.8 225.5,119.3 225.3,118.2 227.4,117.6 			"
          />
          <polygon
            class="st3"
            points="228.4,114.2 224.8,115.2 224.5,114 228.2,113.1 			"
          />
          <polygon
            class="st3"
            points="228.5,121.9 228.4,121.5 227.4,121.7 227.6,122.2 226.9,122.3 227,122.9 226.3,123.1 226.4,123.5 
				227.1,123.3 227.3,123.2 229.3,122.7 229.1,121.8 			"
          />
          <polygon
            class="st3"
            points="230.1,125.9 227.2,126.7 227.5,127.6 230.3,126.9 			"
          />
          <rect x="195.1" y="97.6" class="st3" width="4" height="1.2" />
          <polygon
            class="st3"
            points="199.4,114 200.4,113.8 200.4,114.1 201.4,113.9 200.8,111.5 199.9,111.7 200.2,112.9 199.2,113.1 
							"
          />
          <polygon
            class="st3"
            points="207,95.2 208.5,99.6 208.1,99.8 208.8,101.9 209.3,101.7 209.6,102.8 210.6,102.4 207.9,94.8 			"
          />
          <polygon
            class="st3"
            points="194.1,113.5 192.9,113.7 193,114.1 188.7,114.8 188.9,116.6 194,115.8 193.7,114.2 194.3,114.2 			
				"
          />
          <rect x="188.4" y="93.5" class="st3" width="1.6" height="4.9" />
          <polygon
            class="st3"
            points="211.3,85.7 209.8,86.3 208.1,81.7 209.6,81.1 			"
          />
          <rect x="184.9" y="114.6" class="st3" width="2.3" height="2.3" />
          <polygon
            class="st3"
            points="161.3,76.1 161.3,77.5 159.3,77.5 159.3,77 158.1,77 158.1,77.7 156.1,77.7 156.1,76.5 154.9,76.5 
				154.9,79 156.1,79 156.1,78.6 158.1,78.6 158.1,80.2 159.3,80.2 159.3,78.3 162.5,78.3 162.5,77.9 162.5,77.5 162.5,76.1 			"
          />
          <rect x="184.9" y="109.2" class="st3" width="1.8" height="1.3" />
          <rect x="178.8" y="98.8" class="st3" width="1.5" height="1.5" />
          <rect x="187.1" y="102.5" class="st3" width="3.8" height="1.4" />
          <rect x="189.3" y="101.7" class="st3" width="2.2" height="0.4" />
          <polygon
            class="st3"
            points="185.3,95.8 185.3,98.4 184.3,98.4 184.3,99.9 185.3,99.9 185.3,101 187.1,101 187.1,95.8 			"
          />
          <polygon
            class="st3"
            points="205,73.7 207.5,73.4 207.7,74.4 209.2,74.2 209.1,73.2 210.1,73.1 209.9,71.3 204.8,71.9 			"
          />
          <polygon
            class="st3"
            points="201.9,104.7 197,105.8 196.8,104.9 201.7,103.8 			"
          />
          <polygon
            class="st3"
            points="199,103 197.3,103.4 197.1,102.3 198.7,102 			"
          />

          <rect
            x="200.6"
            y="101.7"
            transform="matrix(0.9768 -0.214 0.214 0.9768 -17.1725 45.5372)"
            class="st3"
            width="2.3"
            height="0.8"
          />
          <rect x="192" y="98.2" class="st3" width="1.2" height="1.2" />

          <rect
            x="202.9"
            y="111.6"
            transform="matrix(0.982 -0.1889 0.1889 0.982 -17.5258 40.7059)"
            class="st3"
            width="3.8"
            height="1.4"
          />

          <rect
            x="203.2"
            y="109.3"
            transform="matrix(0.982 -0.189 0.189 0.982 -17.0855 40.5891)"
            class="st3"
            width="2.2"
            height="1.1"
          />

          <rect
            x="202.1"
            y="106"
            transform="matrix(0.982 -0.1889 0.1889 0.982 -16.4874 40.2108)"
            class="st3"
            width="1.2"
            height="1.2"
          />
          <rect x="154.8" y="80.9" class="st3" width="2" height="1.3" />
          <rect x="160.4" y="83.9" class="st3" width="1.9" height="0.4" />
          <rect x="162.7" y="85.7" class="st3" width="1.1" height="1.8" />
          <polygon
            class="st3"
            points="367.3,119.3 368.1,117.1 369,117.4 368.2,119.6 368.8,119.8 368.4,121.1 365.6,120.1 366,118.9 			
				"
          />
          <polygon
            class="st3"
            points="354,122 355.7,122.6 356.2,121.4 354.4,120.8 			"
          />
          <polygon
            class="st3"
            points="361.9,115.9 361,116.6 360.7,116.1 359.7,116.9 359.3,116.4 360.3,115.6 361.2,114.9 			"
          />

          <rect
            x="361.9"
            y="108.2"
            transform="matrix(4.009026e-02 -0.9992 0.9992 4.009026e-02 238.3839 467.2343)"
            class="st3"
            width="0.9"
            height="2.6"
          />
          <polygon
            class="st3"
            points="361.6,113.9 362.3,114.3 364.3,110.9 363.6,110.5 			"
          />
          <polygon
            class="st3"
            points="360.1,111.4 360.3,111 361.2,111.5 360.8,112.2 360,111.8 359.7,112.3 360.5,112.7 359.9,114 
				358.6,113.4 359.2,112.1 359.7,111.2 			"
          />
          <polygon
            class="st3"
            points="357.4,115.5 357.6,114.8 358.6,115.2 358.4,115.8 357.6,115.5 357.2,116.7 356.8,116.6 357.2,115.4 
							"
          />

          <rect
            x="371.8"
            y="109.5"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 745.176 226.3442)"
            class="st3"
            width="1.6"
            height="7.4"
          />
          <polygon
            class="st3"
            points="356.4,120.1 357.8,120.6 358.7,117.7 357.3,117.2 			"
          />
          <polygon
            class="st3"
            points="354.2,112.1 355,112.4 355.5,111.1 354.7,110.8 			"
          />
          <polygon
            class="st3"
            points="136.9,69.2 138,69.3 138.5,65.6 137.4,65.4 137,68.1 133.6,67.6 133.4,68.7 			"
          />
          <polygon
            class="st3"
            points="132.3,59 131.9,62.4 133.3,62.5 133.7,59.2 134.6,59.3 134.9,57.4 130.6,56.9 130.3,58.7 			"
          />
          <polygon
            class="st3"
            points="113.9,64.1 113.8,64.8 126,66.4 125.8,68.5 127.1,68.7 127.6,65.2 127.5,65.1 126.2,65 115.4,63.5 
				115.9,60.2 113.7,59.9 113.1,64 			"
          />
          <polygon
            class="st3"
            points="117.8,60.2 117.7,60.8 118.3,60.9 118.1,62.1 119.4,62.3 119.6,61 123.6,61.6 123.8,60.4 
				119.1,59.8 119.2,58.7 117.5,58.4 117.3,60.1 			"
          />

          <rect
            x="118.3"
            y="54.5"
            transform="matrix(0.1324 -0.9912 0.9912 0.1324 48.5678 165.263)"
            class="st3"
            width="0.7"
            height="0.7"
          />

          <rect
            x="116"
            y="56.5"
            transform="matrix(0.1324 -0.9912 0.9912 0.1324 44.6127 164.6049)"
            class="st3"
            width="0.6"
            height="0.6"
          />

          <rect
            x="114.9"
            y="54.4"
            transform="matrix(0.1325 -0.9912 0.9912 0.1325 45.6661 161.9239)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <rect
            x="131"
            y="65"
            transform="matrix(0.1325 -0.9912 0.9912 0.1325 48.3172 187.4112)"
            class="st3"
            width="0.4"
            height="2.2"
          />
          <rect
            x="127.3"
            y="59"
            transform="matrix(0.1325 -0.9912 0.9912 0.1325 52.2727 178.9068)"
            class="st3"
            width="2"
            height="1.2"
          />
          <polygon
            class="st3"
            points="41,60.8 41.9,57.4 40.5,57 39.7,60.4 38.8,60.2 38.3,62.1 42.6,63.1 43.1,61.3 			"
          />
          <polygon class="st3" points="40,65.4 38.5,65 38.7,64.2 40.2,64.6 			" />
          <polygon
            class="st3"
            points="34.9,61.2 32.2,60.5 32.6,58.7 35.4,59.4 			"
          />
          <polygon
            class="st3"
            points="34.6,52.8 34.2,54.6 35,54.8 34.6,56.6 35.4,56.8 35.9,55 36.3,53.3 			"
          />
          <polygon
            class="st3"
            points="37.2,50.3 33.4,49.4 33.7,48.1 37.5,49 			"
          />
          <polygon
            class="st3"
            points="31.2,54.8 30.1,54.5 31.5,48.9 32.6,49.2 			"
          />
          <polygon
            class="st3"
            points="30.8,63.1 29.3,62.7 29.7,61.2 31.2,61.5 			"
          />
          <polygon
            class="st3"
            points="38.8,55.1 38.9,54.5 37.6,54.2 37.3,55.3 38.5,55.6 38.3,56.6 37,56.2 36.5,58.3 38.6,58.9 
				39.1,56.8 39.5,55.3 			"
          />
          <polygon
            class="st3"
            points="37.3,60.8 37.5,59.9 36,59.5 35.8,60.5 36.9,60.7 36.5,62.6 37.1,62.7 37.6,60.9 			"
          />
          <polygon
            class="st3"
            points="39.1,53.5 36.7,53 37.1,51.5 39.4,52.1 			"
          />
          <polygon
            class="st3"
            points="43.8,56.2 41.6,55.6 41.7,55.2 43.9,55.7 			"
          />
          <polygon
            class="st3"
            points="43.6,59.1 42.3,58.8 42.8,56.8 44.1,57.1 			"
          />
          <polygon
            class="st3"
            points="86.6,52.9 85.6,52.7 86.7,47.7 87.7,48 			"
          />

          <rect
            x="83.4"
            y="51.2"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 13.0664 123.8468)"
            class="st3"
            width="0.7"
            height="5.2"
          />

          <rect
            x="84.2"
            y="43.5"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 21.1739 118.4496)"
            class="st3"
            width="0.5"
            height="5.1"
          />

          <rect
            x="79.6"
            y="48.2"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 15.9384 116.9918)"
            class="st3"
            width="2.6"
            height="0.7"
          />

          <rect
            x="90.4"
            y="52.6"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 19.8351 130.7142)"
            class="st3"
            width="1.9"
            height="0.9"
          />
          <polygon class="st3" points="27.6,29.8 20.5,25.1 19,27.3 26.1,32 			" />

          <rect
            x="95.5"
            y="42.4"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 33.9001 127.5762)"
            class="st3"
            width="1.9"
            height="0.6"
          />
          <polygon
            class="st3"
            points="91.8,38.2 91,38.1 90.5,40.6 91.2,40.8 91.6,39 93.9,39.5 94.1,38.8 			"
          />
          <polygon
            class="st3"
            points="88.6,38.9 89.1,36.6 88.2,36.4 87.7,38.7 87.1,38.5 86.8,39.8 89.7,40.4 90,39.2 			"
          />

          <rect
            x="87.3"
            y="41.1"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 27.9868 118.0425)"
            class="st3"
            width="0.5"
            height="1"
          />
          <polygon
            class="st3"
            points="11.4,19.6 10.9,20.1 10.1,19.3 9.7,19.8 10.5,20.5 12.8,22.7 13.7,21.8 			"
          />
          <rect
            x="89"
            y="51.1"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 19.4144 127.42)"
            class="st3"
            width="0.2"
            height="1"
          />
          <polygon
            class="st3"
            points="81.8,51.6 81.1,51.5 81.2,50.7 81.9,50.9 			"
          />

          <rect
            x="83.4"
            y="49"
            transform="matrix(0.2166 -0.9763 0.9763 0.2166 17.5565 120.4063)"
            class="st3"
            width="0.9"
            height="0.4"
          />

          <rect
            x="84"
            y="40.4"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 25.5271 115.0748)"
            class="st3"
            width="0.9"
            height="2.4"
          />
          <polygon
            class="st3"
            points="82.6,42.2 82.5,42.6 82.3,43.5 83.2,43.7 83.3,42.8 84.6,43.1 84.7,42.6 			"
          />

          <rect
            x="89"
            y="49.2"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 21.6371 126.2093)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <rect
            x="88.6"
            y="47"
            transform="matrix(0.2169 -0.9762 0.9762 0.2169 23.7433 124.4851)"
            class="st3"
            width="1.8"
            height="1"
          />
          <polygon
            class="st3"
            points="18.7,20.3 19,19.9 16.6,17.9 15.9,18.6 17.8,20.2 17.3,20.8 19.5,22.6 20.3,21.7 			"
          />
          <polygon
            class="st3"
            points="95.5,49.2 93.8,48.8 94,47.9 95.7,48.3 			"
          />

          <rect
            x="96.4"
            y="59.9"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 15.9167 142.6097)"
            class="st3"
            width="0.9"
            height="2.9"
          />

          <rect
            x="99.6"
            y="47"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 31.9175 135.3378)"
            class="st3"
            width="1.5"
            height="1.5"
          />
          <polygon
            class="st3"
            points="90.9,31.9 91,31.6 88.5,31 88.3,31.7 90.3,32.2 90,33.5 90.9,33.7 91.3,32 			"
          />
          <polygon
            class="st3"
            points="93.6,44.6 93.7,44 93,43.9 92.8,44.4 92.8,44.6 92.6,45.7 94.4,46.1 94.6,44.8 			"
          />
          <polygon
            class="st3"
            points="101.5,36.5 101.6,36 93.4,34.2 93.7,32.8 92.7,32.6 92.2,34.9 92.3,34.9 93.1,35.1 100.4,36.8 
				99.9,39 101.4,39.3 102,36.6 			"
          />
          <polygon
            class="st3"
            points="95.2,37.8 95.3,37.4 95,37.3 95.1,36.5 94.3,36.3 94.1,37.2 91.4,36.5 91.2,37.3 94.4,38 94.2,38.8 
				95.3,39 95.6,37.9 			"
          />
          <polygon class="st3" points="17.1,7 15,5.2 16.2,3.7 18.4,5.5 			" />
          <polygon
            class="st3"
            points="84.2,33.7 83.9,34.8 84.5,35 84.2,36.2 84.8,36.3 85.1,35.1 85.3,33.9 			"
          />

          <rect
            x="84.2"
            y="29.9"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 35.8331 107.1289)"
            class="st3"
            width="0.9"
            height="2.6"
          />
          <polygon class="st3" points="82,35 81.2,34.9 82,31.1 82.8,31.3 			" />
          <polygon
            class="st3"
            points="267.3,170.8 263.7,174 261.8,171.9 265.5,168.7 			"
          />
          <polygon
            class="st3"
            points="273.2,183.5 270.1,186.2 269.5,185.6 272.6,182.8 			"
          />
          <polygon
            class="st3"
            points="273,186.5 271.4,187.9 270.8,187.2 272.4,185.9 			"
          />
          <polygon
            class="st3"
            points="276.6,185.1 275.5,186.9 275,186.6 276,184.7 			"
          />
          <polygon
            class="st3"
            points="278.3,186 277.2,187.8 276.5,187.4 277.5,185.6 			"
          />
          <polygon
            class="st3"
            points="292.3,187.7 291.3,192 290,191.6 291,187.4 			"
          />
          <polygon
            class="st3"
            points="295.8,184.4 294.7,188.6 293.4,188.3 294.5,184.1 			"
          />
          <polygon
            class="st3"
            points="288.7,159.4 286,164.9 284.3,164.1 287,158.5 			"
          />
          <polygon
            class="st3"
            points="276.5,189.6 274.3,191.6 271.8,188.8 274,186.9 			"
          />
          <polygon
            class="st3"
            points="306.8,186.3 303.7,189 303.1,188.4 306.2,185.7 			"
          />
          <polygon
            class="st3"
            points="306.5,189.3 305,190.7 304.4,190.1 306,188.7 			"
          />
          <polygon
            class="st3"
            points="310.1,187.9 309.1,189.7 308.6,189.4 309.6,187.6 			"
          />
          <polygon
            class="st3"
            points="311.8,188.8 310.8,190.7 310.1,190.2 311.1,188.4 			"
          />
          <polygon
            class="st3"
            points="310,192.4 307.8,194.4 305.4,191.6 307.6,189.7 			"
          />
          <polygon
            class="st3"
            points="301.1,211 297.7,207.1 298.5,206.4 301.9,210.3 			"
          />
          <polygon
            class="st3"
            points="298.9,212.7 297.2,210.7 298,210 299.7,212 			"
          />
          <polygon
            class="st3"
            points="298.6,219.6 297.7,218.6 300.2,216.3 301.1,217.3 			"
          />
          <polygon
            class="st3"
            points="296.8,221.3 295,219.3 295.8,218.6 297.6,220.5 			"
          />
          <polygon
            class="st3"
            points="292.2,217 289.8,214.2 293.3,211.2 295.7,214 			"
          />
          <polygon
            class="st3"
            points="283.6,198.9 282.2,200.2 279.6,197.1 281,195.9 			"
          />
          <polygon
            class="st3"
            points="289.9,182.4 287.7,184.4 286.5,183 288.7,181.1 			"
          />
          <polygon
            class="st3"
            points="292.2,180 290.5,181.5 289.6,180.5 291.3,179 			"
          />
          <polygon
            class="st3"
            points="292.8,169.1 293.4,169.7 292.5,170.5 292.2,170.2 290.8,171.4 291.1,171.7 292.3,173.1 293.7,171.9 
				294.6,171.1 295.9,169.9 294.7,168.5 294.2,167.9 			"
          />
          <polygon
            class="st3"
            points="293.7,162.8 294.3,163.4 293.4,164.2 293.1,163.9 291.8,165.1 292.1,165.4 293.2,166.8 294.6,165.6 
				295.5,164.8 296.9,163.6 295.7,162.2 295.1,161.6 			"
          />

          <rect
            x="281.2"
            y="181.4"
            transform="matrix(0.2066 -0.9784 0.9784 0.2066 45.4799 420.9341)"
            class="st3"
            width="2.1"
            height="2.1"
          />
          <polygon
            class="st3"
            points="286.1,170.3 287.9,168.8 293.4,175 291.6,176.5 			"
          />
          <polygon
            class="st3"
            points="283.3,179.1 287.3,175.5 288.2,176.5 284.2,180.1 			"
          />
          <polygon
            class="st3"
            points="283.1,180.3 284.7,178.9 286.1,180.5 284.5,181.9 			"
          />
          <polygon
            class="st3"
            points="291.3,161.1 289.9,163.4 290.8,163.9 292.1,161.6 292.8,161.9 294.7,158.7 293.3,157.8 			"
          />
          <polygon
            class="st3"
            points="287.7,151.7 287,150.9 284.6,153 287.6,156.4 290,154.3 289.6,153.8 290.8,152.7 288.9,150.6 			"
          />
          <polygon
            class="st3"
            points="283.7,174.8 285.4,173.3 285.9,174 284.3,175.4 			"
          />
          <polygon
            class="st3"
            points="281.1,176.1 281.6,175.7 282.1,176.2 281.6,176.7 			"
          />
          <polygon
            class="st3"
            points="281.1,157.1 280.2,157.9 280.2,157.9 279.9,158.2 279.9,158.2 279.1,158.9 279.7,159.5 280.4,158.8 
				281.8,160.3 282.1,160.1 280.8,158.5 281.7,157.7 			"
          />
          <polygon
            class="st3"
            points="270.3,180.1 272.8,177.8 273.5,178.6 270.9,180.9 			"
          />
          <polygon
            class="st3"
            points="273.4,171.2 273.9,170.8 273.2,170 272.7,170.4 270.4,167.8 274.5,164.2 273.2,162.7 267.8,167.4 
				269.1,168.9 273.8,174.3 275.1,173.2 			"
          />
          <polygon
            class="st3"
            points="275.8,172.9 274.6,171.7 275,171.4 276.1,172.7 			"
          />
          <polygon
            class="st3"
            points="282.8,166.7 282,167.3 281.4,166.6 282.1,165.9 			"
          />
          <polygon
            class="st3"
            points="282.5,162.7 280.6,164.3 278.6,162 280.4,160.4 			"
          />
          <polygon
            class="st3"
            points="276.4,164.7 276.3,165.6 275.8,165.6 275.6,168.2 276.1,168.2 276.3,166 276.9,166.1 277,164.8 			
				"
          />
          <polygon
            class="st3"
            points="279.5,173.5 276.5,170 278.6,168.2 281.6,171.6 			"
          />
          <polygon
            class="st3"
            points="277.6,173.6 276.3,172.1 277,171.5 278.3,173 			"
          />
          <polygon
            class="st3"
            points="294.9,177.1 299.9,182.8 298.9,183.6 293.9,178 			"
          />
          <polygon
            class="st3"
            points="267.5,181.5 263.6,177.2 264.1,176.8 262.3,174.8 261.8,175.2 260.9,174.2 260,175 266.6,182.3 			
				"
          />
          <polygon
            class="st3"
            points="301.4,179.2 300.7,179.7 298.3,176.7 298.9,176.2 			"
          />
          <rect
            x="80.9"
            y="39.4"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 24.7712 110.7724)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="87,35.1 87.1,34.7 86.2,34.5 86,35.3 86.9,35.5 86.7,36.1 85.9,35.9 85.6,37.3 87,37.6 87.3,36.2 
				87.5,35.2 			"
          />
          <polygon
            class="st3"
            points="86.1,39 86.2,38.3 85.3,38.1 85.1,38.7 85.9,38.9 85.6,40.1 86,40.2 86.3,39 			"
          />
          <polygon
            class="st3"
            points="92.8,106.2 92.2,104.3 100.9,101.4 101.5,103.2 			"
          />
          <polygon
            class="st3"
            points="89.6,107.2 86.6,98.1 87.8,97.7 90.8,106.8 			"
          />
          <polygon
            class="st3"
            points="102.3,94.1 104.3,100.3 102.6,100.9 100.5,94.7 			"
          />

          <rect
            x="93.3"
            y="89.5"
            transform="matrix(8.356704e-02 -0.9965 0.9965 8.356704e-02 -5.4383 177.7459)"
            class="st3"
            width="1.2"
            height="4.7"
          />
          <polygon
            class="st3"
            points="114.1,107.6 118.1,106.2 117.6,104.6 113.5,105.9 113.2,104.9 110.9,105.6 112.6,110.7 114.9,110 
							"
          />
          <polygon class="st3" points="99.1,88.3 98.5,86.5 99.4,86.2 100,88 			" />
          <polygon
            class="st3"
            points="90.3,97.4 89.8,96.1 91.1,95.7 91.5,97 			"
          />
          <polygon class="st3" points="95.2,99 95,98.2 96.5,97.7 96.7,98.5 			" />
          <polygon
            class="st3"
            points="108.7,94.3 106.7,90.4 108.2,89.6 110.2,93.6 			"
          />
          <polygon
            class="st3"
            points="105.5,90 104.7,90.4 103.3,91.1 104,92.5 105.4,91.8 106.5,93.9 107.3,93.5 			"
          />
          <polygon
            class="st3"
            points="110.8,88.9 110.2,87 112,86.4 112.6,88.2 			"
          />
          <polygon
            class="st3"
            points="117.6,91.6 118.7,91.3 118.1,89.5 117,89.9 117.5,91.3 115.3,92 115.5,92.8 117.7,92 			"
          />
          <polygon
            class="st3"
            points="17.8,15.3 19.5,16.7 18.8,17.5 17.1,16.1 			"
          />

          <rect
            x="91"
            y="42.9"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 29.2807 123.0929)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="90.5,41.4 91.2,41.6 91.1,42.4 90.3,42.2 			"
          />
          <polygon
            class="st3"
            points="3.4,27.6 2.2,27.3 1.3,31.3 2.5,31.6 3.1,28.8 6.8,29.6 7.1,28.4 			"
          />
          <polygon
            class="st3"
            points="9,26.9 9.1,26.3 8.5,26.1 8.8,24.8 7.4,24.5 7.1,25.8 2.8,24.9 2.5,26.1 7.6,27.3 7.3,28.4 
				9.1,28.8 9.5,27 			"
          />
          <rect
            x="2.1"
            y="35"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 -32.6898 30.584)"
            class="st3"
            width="1.2"
            height="1.2"
          />
          <polygon class="st3" points="1.3,32.7 2.6,32.9 2.3,34.2 1.1,33.9 			" />
          <polygon
            class="st3"
            points="97.9,42.8 97.4,42.6 97.5,42.2 98,42.3 			"
          />

          <rect
            x="99.2"
            y="41.1"
            transform="matrix(0.2166 -0.9763 0.9763 0.2166 37.5774 129.3843)"
            class="st3"
            width="0.4"
            height="0.4"
          />

          <rect
            x="99.7"
            y="42.4"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 36.6486 131.0062)"
            class="st3"
            width="0.6"
            height="0.6"
          />
          <polygon
            class="st3"
            points="94.9,51.7 95,51.3 94.3,51.1 94.2,51.5 93.7,51.4 93.6,51.9 93,51.7 92.9,52 93.5,52.1 93.7,52.2 
				95.2,52.5 95.4,51.8 			"
          />
          <polygon
            class="st3"
            points="92.7,57.5 93.1,56.9 91.9,56.2 91.5,56.8 90.7,56.4 90.2,57.2 89.3,56.6 89,57.1 89.9,57.7 
				90.2,57.8 92.6,59.3 93.4,58 			"
          />
          <polygon
            class="st3"
            points="100.9,51.2 100.1,51.1 100,51.5 98.8,51.2 98.4,51.1 98.1,52.3 97.9,53.6 98.3,53.6 98.5,52.4 
				100,52.8 100.2,51.9 100.7,52 			"
          />

          <rect
            x="100.9"
            y="54.3"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 25.2395 142.4093)"
            class="st3"
            width="1"
            height="2.3"
          />

          <rect
            x="104.2"
            y="55.7"
            transform="matrix(0.2166 -0.9763 0.9763 0.2166 27.2155 145.7241)"
            class="st3"
            width="0.5"
            height="0.5"
          />
          <polygon class="st3" points="98.9,56.5 98.2,56.3 98.3,55.9 99,56 			" />
          <rect
            x="93"
            y="54"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 19.2944 134.4202)"
            class="st3"
            width="0.8"
            height="2.3"
          />

          <rect
            x="58.4"
            y="72.2"
            transform="matrix(7.124705e-02 -0.9975 0.9975 7.124705e-02 -15.9094 128.3378)"
            class="st3"
            width="5"
            height="1"
          />

          <rect
            x="55.4"
            y="73"
            transform="matrix(7.128783e-02 -0.9975 0.9975 7.128783e-02 -23.6223 125.8427)"
            class="st3"
            width="0.7"
            height="5.2"
          />

          <rect
            x="58.5"
            y="65"
            transform="matrix(0.1187 -0.9929 0.9929 0.1187 -15.185 118.0423)"
            class="st3"
            width="0.9"
            height="5.1"
          />

          <rect
            x="64.9"
            y="72.7"
            transform="matrix(8.380967e-02 -0.9965 0.9965 8.380967e-02 -12.5533 132.615)"
            class="st3"
            width="1.9"
            height="0.9"
          />

          <rect
            x="70"
            y="64.1"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 -7.3366 119.6806)"
            class="st3"
            width="1.9"
            height="0.6"
          />
          <polygon
            class="st3"
            points="67.6,61.8 66.8,61.6 66.3,64.1 67,64.3 67.4,62.5 69.7,63 69.9,62.3 			"
          />
          <polygon
            class="st3"
            points="63.9,61.3 64.2,59 63.2,58.9 63,61.2 62.4,61.2 62.3,62.4 65.2,62.8 65.3,61.5 			"
          />
          <polygon
            class="st3"
            points="60.8,62.2 60.3,61.3 60.8,61 61.3,61.9 			"
          />

          <rect
            x="62.8"
            y="73.8"
            transform="matrix(7.134237e-02 -0.9975 0.9975 7.134237e-02 -15.0848 132.4042)"
            class="st3"
            width="1.6"
            height="1"
          />

          <rect
            x="57.8"
            y="70.8"
            transform="matrix(0.2166 -0.9763 0.9763 0.2166 -23.6843 112.5089)"
            class="st3"
            width="0.9"
            height="0.4"
          />

          <rect
            x="63.1"
            y="63.2"
            transform="matrix(0.1187 -0.9929 0.9929 0.1187 -7.9782 119.9196)"
            class="st3"
            width="0.9"
            height="2.4"
          />
          <polygon
            class="st3"
            points="59,63.7 58.9,64.2 58.7,65 59.6,65.2 59.8,64.4 61,64.6 61.1,64.2 			"
          />

          <rect
            x="63.2"
            y="71.1"
            transform="matrix(7.120004e-02 -0.9975 0.9975 7.120004e-02 -12.2609 130.0196)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <rect
            x="63"
            y="67.3"
            transform="matrix(0.1188 -0.9929 0.9929 0.1188 -10.984 123.1812)"
            class="st3"
            width="1.8"
            height="1"
          />

          <rect
            x="74.8"
            y="67.5"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 -7.8343 127.0066)"
            class="st3"
            width="0.9"
            height="1.8"
          />
          <polygon
            class="st3"
            points="68,66.3 68.1,65.8 67.4,65.6 67.3,66.2 67.3,66.3 67,67.4 68.8,67.8 69.1,66.6 			"
          />
          <polygon
            class="st3"
            points="67.9,57 67.8,57.4 68.2,57.5 68.2,58.3 69,58.4 69.1,57.5 71.9,57.7 72,56.9 68.7,56.7 68.8,55.9 
				67.6,55.8 67.5,57 			"
          />
          <polygon
            class="st3"
            points="62.2,54.9 62.2,54.5 61.3,54.4 61.2,55.2 62,55.3 61.9,55.9 61.1,55.8 60.8,57.2 62.2,57.5 62.5,56 
				62.6,55 			"
          />
          <polygon
            class="st3"
            points="61.4,58.8 61.5,58.2 60.6,58 60.4,58.7 61.2,58.8 61,60 61.4,60.1 61.6,58.9 			"
          />

          <rect
            x="68.2"
            y="63.5"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 -8.6448 117.0244)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="64.9"
            y="55.4"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 -3.353 107.5046)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="70.7,69.6 70.2,69.3 69.6,70.2 70.1,70.5 69.6,71.1 70.2,71.5 69.7,72.3 70,72.5 70.5,71.8 
				70.7,71.6 72,69.7 71,69 			"
          />
          <polygon
            class="st3"
            points="67.1,80 67.5,79.3 66.3,78.6 65.9,79.3 65.1,78.8 64.6,79.6 63.6,79.1 63.3,79.6 64.3,80.1 
				64.5,80.3 67,81.7 67.8,80.4 			"
          />
          <rect x="66.7" y="76.2" class="st3" width="2.3" height="0.8" />

          <rect
            x="109.2"
            y="56"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 29.7315 152.1754)"
            class="st3"
            width="0.9"
            height="3.1"
          />
          <polygon
            class="st3"
            points="112.6,51.7 111.6,51.4 111.7,51.1 110.7,50.9 110.2,53.3 111.2,53.5 111.4,52.3 112.4,52.5 			"
          />
          <polygon
            class="st3"
            points="110.6,60.2 109.8,63.8 109.4,63.8 109,65.5 109.4,65.5 109.2,66.4 110,66.6 111.4,60.4 			"
          />
          <polygon
            class="st3"
            points="109,51.2 108,51 108,51.4 104.6,50.6 104.3,52 108.2,52.9 108.5,51.7 108.9,51.8 			"
          />

          <rect
            x="99.3"
            y="61.2"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 18.9202 147.2637)"
            class="st3"
            width="3.9"
            height="1.3"
          />

          <rect
            x="101"
            y="49.8"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 30.6998 139.6807)"
            class="st3"
            width="2.9"
            height="1.8"
          />
          <polygon
            class="st3"
            points="108.3,60.4 108.1,61.5 106.6,61.1 106.6,60.7 105.7,60.5 105.6,61.1 104,60.7 104.2,59.8 
				103.3,59.6 102.9,61.5 103.8,61.7 103.9,61.4 105.4,61.7 105.2,63 106.1,63.2 106.4,61.8 108.8,62.3 108.9,62 109,61.6 
				109.2,60.6 			"
          />
          <polygon
            class="st3"
            points="105.2,45.6 103.8,45.3 104.1,44.3 105.4,44.6 			"
          />

          <rect
            x="102.3"
            y="41.8"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 39.1487 133.603)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="106.2"
            y="40.6"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 42.4762 137.1849)"
            class="st3"
            width="1.1"
            height="3"
          />

          <rect
            x="107.9"
            y="40.4"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 44.2694 137.8208)"
            class="st3"
            width="0.3"
            height="1.8"
          />
          <polygon
            class="st3"
            points="104.7,38 104.5,38.9 103.7,38.7 103.5,39.2 104.4,39.4 104.3,39.8 105.7,40.1 106.1,38.3 			"
          />

          <rect
            x="112.7"
            y="41.4"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 46.2127 144.3245)"
            class="st3"
            width="0.8"
            height="4"
          />
          <polygon
            class="st3"
            points="113.4,42 112.1,41.7 112.3,40.9 113.5,41.2 			"
          />
          <polygon
            class="st3"
            points="113.6,47.7 111.9,47.3 112,46.7 113.8,47.1 			"
          />
          <rect
            x="109.8"
            y="38.9"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 47.9404 138.4649)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="237.6,119.7 237.4,119 234.3,119.9 234.7,121.4 237,120.8 237.2,121.5 240.3,120.6 239.9,119 			"
          />
          <polygon
            class="st3"
            points="244.2,115.5 245.5,115.2 245.9,116.5 244.6,116.8 			"
          />

          <rect
            x="236.2"
            y="123.4"
            transform="matrix(0.9767 -0.2148 0.2148 0.9767 -21.1617 54.4098)"
            class="st3"
            width="7.1"
            height="2.4"
          />

          <rect
            x="245.6"
            y="121.9"
            transform="matrix(0.9767 -0.2148 0.2148 0.9767 -20.6314 56.392)"
            class="st3"
            width="7.1"
            height="2.4"
          />
          <polygon
            class="st3"
            points="269.6,109.1 270.3,111.3 268,112 267.3,109.8 			"
          />
          <polygon
            class="st3"
            points="269.3,119.2 269.9,121 267.4,121.8 266.8,120 			"
          />
          <polygon
            class="st3"
            points="264.6,112.7 266,117.4 263.7,118.1 262.3,113.5 			"
          />
          <polygon
            class="st3"
            points="261.2,112.9 262,115.7 261.4,115.9 260.6,113.1 			"
          />
          <polygon
            class="st3"
            points="257.7,122.3 259.7,121.7 260.1,123 261.3,122.7 260.9,121.3 261.7,121.1 261,118.9 257,120.1 			"
          />
          <polygon
            class="st3"
            points="260.6,102.1 262.5,108.3 260.8,108.8 258.9,102.6 			"
          />
          <polygon
            class="st3"
            points="257.6,106.4 258.3,108.5 253.7,109.9 253,107.8 			"
          />
          <polygon
            class="st3"
            points="268.8,100.4 269.7,103.3 268.4,103.6 267.5,100.8 			"
          />
          <polygon
            class="st3"
            points="252.7,114.2 253.2,115.7 251.1,116.3 250.6,114.8 			"
          />
          <polygon class="st3" points="22.8,4.5 21,3 21.9,1.9 23.7,3.4 			" />
          <polygon
            class="st3"
            points="90.4,35.7 88.9,35.4 89,35.1 90.4,35.5 			"
          />

          <rect
            x="187.4"
            y="267.6"
            transform="matrix(0.9989 -4.671996e-02 4.671996e-02 0.9989 -12.316 9.0962)"
            class="st3"
            width="2.1"
            height="0.9"
          />

          <rect
            x="226.3"
            y="250.1"
            transform="matrix(0.9855 -0.1695 0.1695 0.9855 -39.4434 42.1304)"
            class="st3"
            width="1.5"
            height="3.9"
          />
          <polygon
            class="st3"
            points="212.5,284.7 217.4,281.7 217.8,282.3 212.9,285.3 			"
          />
          <polygon
            class="st3"
            points="222.7,297.2 224.3,298.5 223.8,299.2 222.2,297.9 			"
          />
          <polygon
            class="st3"
            points="187.8,285.4 182.6,283.8 183,282.4 188.2,284 			"
          />
          <polygon
            class="st3"
            points="219.5,288.1 219.6,287.5 216.9,287 216.7,288.3 218.6,288.7 218.5,289.2 221.2,289.7 221.4,288.4 
							"
          />
          <polygon
            class="st3"
            points="188.2,278.7 188.1,279 187,278.7 187.2,277.8 185.2,277.2 184.9,278.1 184,281.2 186,281.8 
				187.2,282.1 189.2,282.7 190.1,279.6 190.2,279.3 			"
          />
          <polygon
            class="st3"
            points="180.2,293.7 180.9,293.9 178.6,301.8 177.9,301.6 			"
          />

          <rect
            x="192.8"
            y="255.9"
            transform="matrix(0.9978 -6.654751e-02 6.654751e-02 0.9978 -16.6161 13.6683)"
            class="st3"
            width="8.2"
            height="0.7"
          />
          <polygon
            class="st3"
            points="182,306.1 182.1,305.6 182.5,304.3 183.6,304.6 184.1,302.8 183,302.5 183.1,302 181.1,301.5 
				180.8,302.4 182.9,303 182.6,303.8 181.9,303.6 181.4,305.4 177.7,304.4 176.9,307 181.4,308.3 			"
          />
          <polygon
            class="st3"
            points="191.4,266.4 194.2,267.3 193.7,269.2 190.9,268.4 			"
          />

          <rect
            x="211.4"
            y="288.5"
            transform="matrix(2.317939e-02 -0.9997 0.9997 2.317939e-02 -82.0351 494.5986)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="213.1"
            y="293.5"
            transform="matrix(2.311244e-02 -0.9997 0.9997 2.311244e-02 -85.2674 501.1252)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="235.2"
            y="288.3"
            transform="matrix(0.182 -0.9833 0.9833 0.182 -91.2511 468.6235)"
            class="st3"
            width="1.7"
            height="1.7"
          />

          <rect
            x="207.7"
            y="290.2"
            transform="matrix(2.312412e-02 -0.9997 0.9997 2.312412e-02 -89.1539 493.455)"
            class="st3"
            width="0.5"
            height="4.3"
          />
          <polygon
            class="st3"
            points="243.6,279.8 243.4,281 242.7,280.9 242,284.4 242.8,284.6 243.3,281.6 244.2,281.8 244.5,280 			"
          />
          <polygon
            class="st3"
            points="219.8,253.1 220.3,253.1 220.4,253.9 220.9,253.9 220.9,253 220.8,251.3 219.7,251.4 			"
          />
          <polygon
            class="st3"
            points="209.1,297.3 209.1,298 207.1,297.9 207.1,298.6 209.1,298.7 213,298.8 213.1,297.4 			"
          />

          <rect
            x="206"
            y="255.6"
            transform="matrix(0.9978 -6.651329e-02 6.651329e-02 0.9978 -16.5809 14.2901)"
            class="st3"
            width="0.7"
            height="1.2"
          />

          <rect
            x="217.7"
            y="254.9"
            transform="matrix(0.9978 -6.648695e-02 6.648695e-02 0.9978 -16.4838 15.1056)"
            class="st3"
            width="2.1"
            height="0.6"
          />
          <polygon
            class="st3"
            points="211.9,281.8 212.4,281.5 213.5,283.2 213,283.6 			"
          />
          <polygon
            class="st3"
            points="211.6,274.8 211.6,275 210,274.7 210.1,274.2 207.1,273.6 207,274.2 206.9,275.2 206.5,275.1 
				206.2,277.2 209.4,277.8 209.6,276.8 211.2,277.1 214.2,277.6 214.5,275.6 214.6,275.4 			"
          />

          <rect
            x="212.4"
            y="254.4"
            transform="matrix(0.9978 -6.658476e-02 6.658476e-02 0.9978 -16.5132 14.8085)"
            class="st3"
            width="3.1"
            height="1.4"
          />

          <rect
            x="244.4"
            y="287.1"
            transform="matrix(0.9851 -0.1719 0.1719 0.9851 -46.1503 46.6232)"
            class="st3"
            width="3.3"
            height="5.3"
          />

          <rect
            x="189.9"
            y="287.4"
            transform="matrix(0.182 -0.9833 0.9833 0.182 -126.7143 425.7499)"
            class="st3"
            width="5.3"
            height="3.3"
          />

          <rect
            x="231.4"
            y="270.8"
            transform="matrix(0.182 -0.9833 0.9833 0.182 -76.1765 451.5049)"
            class="st3"
            width="3.8"
            height="1.4"
          />

          <rect
            x="236"
            y="270.3"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 -71.8403 455.6081)"
            class="st3"
            width="3.8"
            height="1.4"
          />

          <rect
            x="240.7"
            y="269.7"
            transform="matrix(0.1821 -0.9833 0.9833 0.1821 -67.5018 459.7154)"
            class="st3"
            width="3.8"
            height="1.4"
          />
          <polygon
            class="st3"
            points="208.5,280.7 206.8,280.7 206.9,283 206,283 206.2,285.7 207.8,285.6 207.7,283.4 208.6,283.3 			"
          />
          <polygon
            class="st3"
            points="244.7,256.7 242.9,250.5 244.9,249.9 246.7,256.1 			"
          />
          <polygon
            class="st3"
            points="183.5,290 181.5,289.2 182.2,287.2 184.2,287.9 			"
          />
          <polygon
            class="st3"
            points="196.9,261.5 198.7,261.3 198.5,257.5 196.6,257.6 196.7,259.7 191.1,260.1 191.2,262.3 197,261.9 
							"
          />
          <polygon
            class="st3"
            points="222.3,295.2 217.2,298.2 216.2,296.5 221.3,293.5 			"
          />
          <polygon
            class="st3"
            points="225.2,300.9 227.5,306.9 225.5,307.7 223.2,301.7 			"
          />
          <polygon
            class="st3"
            points="243.7,307.3 241.8,306.8 243.9,298.8 245.7,299.3 			"
          />
          <polygon
            class="st3"
            points="232.5,286.9 233.2,288.6 225.5,291.7 224.8,289.9 			"
          />
          <polygon
            class="st3"
            points="238.2,306.9 237.7,308.8 231.7,307 232.3,305.1 			"
          />
          <polygon
            class="st3"
            points="218.1,302.5 219.3,302.1 220.6,305.8 219.4,306.2 			"
          />

          <rect
            x="197.4"
            y="264.6"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 398.2142 530.4322)"
            class="st3"
            width="3.4"
            height="1.3"
          />

          <rect
            x="200.3"
            y="290.2"
            transform="matrix(0.1111 -0.9938 0.9938 0.1111 -111.2008 458.3714)"
            class="st3"
            width="0.7"
            height="2.2"
          />
          <polygon
            class="st3"
            points="191.7,301.8 191.6,302.9 195.4,303.4 195.5,302.2 192.9,301.9 193.3,298.4 192.1,298.3 			"
          />
          <polygon
            class="st3"
            points="193.1,306.4 189.7,306 189.6,307.4 193,307.8 192.9,308.7 194.8,308.9 195.3,304.6 193.4,304.4 			
				"
          />

          <rect
            x="196.6"
            y="307.1"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -130.2515 469.4206)"
            class="st3"
            width="1.5"
            height="0.8"
          />
          <polygon
            class="st3"
            points="240.7,252.9 241,252.8 240.2,250.3 239.5,250.5 240.2,252.5 238.8,252.9 239.1,253.8 240.8,253.3 
							"
          />
          <polygon
            class="st3"
            points="200.8,298.2 199.9,298.1 199.8,299.2 200.7,299.3 200.9,299.3 202.5,299.5 202.8,296.7 201,296.5 
							"
          />
          <polygon
            class="st3"
            points="215.2,304.8 215.2,304.1 202.8,304.2 202.8,302.1 201.5,302.1 201.5,305.6 201.5,305.6 202.9,305.6 
				213.8,305.6 213.8,308.9 216,308.9 216,304.8 			"
          />
          <polygon
            class="st3"
            points="190.6,296.8 190,296.7 189.9,297.3 188.6,297.1 188.5,298.4 189.8,298.5 189.3,302.7 190.5,302.8 
				191,298 192.2,298.1 192.3,296.4 190.6,296.2 			"
          />

          <rect
            x="198.7"
            y="301.1"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -122.7045 466.1122)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="196.7"
            y="302.1"
            transform="matrix(0.1111 -0.9938 0.9938 0.1111 -125.4924 465.1098)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="199.9"
            y="289.6"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -110.1746 456.6944)"
            class="st3"
            width="0.6"
            height="0.6"
          />

          <rect
            x="198.4"
            y="287.8"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -109.7319 453.5064)"
            class="st3"
            width="0.5"
            height="0.5"
          />

          <rect
            x="199.9"
            y="286.9"
            transform="matrix(0.1113 -0.9938 0.9938 0.1113 -107.5849 454.252)"
            class="st3"
            width="0.7"
            height="0.7"
          />

          <rect
            x="201.5"
            y="281"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -100.0857 451.3233)"
            class="st3"
            width="1.6"
            height="1.2"
          />

          <rect
            x="198.9"
            y="283.3"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -104.8855 450.7304)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="197"
            y="278.9"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -101.1578 445.975)"
            class="st3"
            width="3.4"
            height="1.2"
          />

          <rect
            x="196.6"
            y="278"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -100.7308 443.6454)"
            class="st3"
            width="2.1"
            height="0.3"
          />

          <rect
            x="229.4"
            y="297"
            transform="matrix(2.557215e-03 -1 1 2.557215e-03 -68.5011 527.7599)"
            class="st3"
            width="1.8"
            height="2.4"
          />

          <rect
            x="238.6"
            y="300.1"
            transform="matrix(2.533956e-03 -1 1 2.533956e-03 -62.1339 539.9657)"
            class="st3"
            width="2"
            height="2"
          />

          <rect
            x="232.3"
            y="300.5"
            transform="matrix(2.535700e-03 -1 1 2.535700e-03 -70.3992 535.467)"
            class="st3"
            width="1.8"
            height="5"
          />
          <polygon
            class="st3"
            points="236.3,293.3 240.4,292.3 240.7,293.5 236.6,294.5 			"
          />
          <polygon
            class="st3"
            points="194.2,282.4 195.3,282.5 195.2,283.5 195.8,283.6 195.9,282.6 196.3,282.7 196.5,281 194.4,280.8 
							"
          />

          <rect
            x="197.1"
            y="271.6"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -93.1802 439.9836)"
            class="st3"
            width="4.6"
            height="0.9"
          />

          <rect
            x="196.4"
            y="272.1"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -95.6467 438.2732)"
            class="st3"
            width="1.5"
            height="1"
          />

          <rect
            x="194.6"
            y="275.2"
            transform="matrix(0.1112 -0.9938 0.9938 0.1112 -100.657 439.0643)"
            class="st3"
            width="1.1"
            height="1.1"
          />
          <polygon
            class="st3"
            points="237.1,254.4 236.7,252.9 237,252.9 237.4,254.3 			"
          />
          <polygon
            class="st3"
            points="24.5,11.5 23.5,10.7 24.8,9.1 25.8,9.9 			"
          />
          <polygon
            class="st3"
            points="164.4,137.3 163.6,138 160.2,134.3 160.9,133.6 			"
          />
          <polygon
            class="st3"
            points="165.6,138.6 161.8,142.2 161.3,141.7 165.1,138.1 			"
          />
          <polygon
            class="st3"
            points="154.8,136.1 158.6,132.6 159.5,133.6 155.8,137.1 			"
          />
          <polygon
            class="st3"
            points="157.5,140.6 156.8,141.2 154.4,138.6 155.1,138 			"
          />
          <polygon
            class="st3"
            points="160.1,140.9 159.4,141.6 158.7,140.9 159.4,140.2 			"
          />
          <polygon
            class="st3"
            points="144.1,130.8 143.4,131.4 141.3,128.6 142,128 			"
          />
          <polygon
            class="st3"
            points="146.7,130.2 145.9,130.9 145.2,130.1 146,129.4 			"
          />
          <polygon
            class="st3"
            points="159.7,137.1 159.2,137.5 158.4,136.6 158.9,136.2 			"
          />
          <polygon
            class="st3"
            points="156.1,131.9 155,132.9 154.2,132.1 155.3,131 			"
          />
          <polygon
            class="st3"
            points="381.2,10.4 379.4,6.1 380.9,5.5 382.7,9.8 			"
          />
          <polygon
            class="st3"
            points="377.4,12 375.6,7.7 377.1,7 378.9,11.4 			"
          />
          <polygon
            class="st3"
            points="373.6,13.6 371.9,9.2 373.4,8.6 375.2,12.9 			"
          />
          <polygon
            class="st3"
            points="369.9,15.1 368.1,10.8 369.6,10.2 371.4,14.5 			"
          />
          <polygon
            class="st3"
            points="366.1,16.7 364.3,12.4 365.9,11.7 367.7,16.1 			"
          />
          <polygon
            class="st3"
            points="362.4,18.3 360.6,13.9 362.1,13.3 363.9,17.6 			"
          />
          <polygon
            class="st3"
            points="437.8,99.2 435.9,101.5 434.8,100.6 436.7,98.3 			"
          />
          <polygon
            class="st3"
            points="440.3,101.3 438.4,103.5 437.3,102.6 439.2,100.3 			"
          />
          <polygon
            class="st3"
            points="442.8,103.3 440.9,105.6 439.8,104.7 441.7,102.4 			"
          />
          <polygon
            class="st3"
            points="445.2,105.4 443.4,107.7 442.3,106.7 444.1,104.5 			"
          />
          <polygon
            class="st3"
            points="448.5,99.7 448,100.3 446.9,99.4 447.4,98.8 			"
          />
          <polygon
            class="st3"
            points="451,101.8 450.5,102.4 449.4,101.5 449.9,100.8 			"
          />
          <polygon
            class="st3"
            points="453.5,103.8 452.9,104.5 451.8,103.6 452.4,102.9 			"
          />
          <polygon
            class="st3"
            points="456,105.9 455.4,106.5 454.3,105.6 454.9,105 			"
          />
          <polygon
            class="st3"
            points="440.2,96.3 439.7,96.9 438.6,96 439.1,95.4 			"
          />
          <polygon
            class="st3"
            points="442.7,98.3 442.2,99 441.1,98.1 441.6,97.4 			"
          />
          <polygon
            class="st3"
            points="445.2,100.4 444.7,101.1 443.6,100.2 444.1,99.5 			"
          />
          <polygon
            class="st3"
            points="447.7,102.5 447.2,103.1 446.1,102.2 446.6,101.6 			"
          />
          <polygon
            class="st3"
            points="410,104.3 410.5,105.9 407.9,106.7 407.4,105.1 			"
          />
          <polygon
            class="st3"
            points="416,102.5 416.5,104.1 413.9,104.9 413.4,103.3 			"
          />
          <polygon
            class="st3"
            points="422,100.6 422.5,102.2 419.9,103 419.4,101.4 			"
          />
          <polygon
            class="st3"
            points="428,98.8 428.5,100.4 425.9,101.2 425.4,99.6 			"
          />

          <rect
            x="350.3"
            y="7.8"
            transform="matrix(9.670863e-02 -0.9953 0.9953 9.670863e-02 307.6514 358.2583)"
            class="st3"
            width="1.8"
            height="3.7"
          />

          <rect
            x="349.9"
            y="11.8"
            transform="matrix(9.670580e-02 -0.9953 0.9953 9.670580e-02 303.2376 361.5493)"
            class="st3"
            width="1.8"
            height="3.7"
          />

          <rect
            x="349.5"
            y="15.9"
            transform="matrix(9.670580e-02 -0.9953 0.9953 9.670580e-02 298.8228 364.8402)"
            class="st3"
            width="1.8"
            height="3.7"
          />

          <rect
            x="349.1"
            y="20"
            transform="matrix(9.670863e-02 -0.9953 0.9953 9.670863e-02 294.4071 368.131)"
            class="st3"
            width="1.8"
            height="3.7"
          />
          <polygon class="st3" points="210.4,6 207.6,6.8 207.1,5.5 210,4.6 			" />
          <polygon
            class="st3"
            points="211.3,9.1 208.5,9.9 208.1,8.6 210.9,7.7 			"
          />
          <polygon
            class="st3"
            points="212.3,12.2 209.5,13 209.1,11.7 211.9,10.8 			"
          />
          <polygon
            class="st3"
            points="213.2,15.2 210.4,16.1 210,14.8 212.8,13.9 			"
          />
          <polygon
            class="st3"
            points="394.8,0.8 396.3,4.5 389.4,7.4 387.9,3.7 			"
          />
          <polygon class="st3" points="365,6.6 363.7,3.5 369.6,1 370.9,4.1 			" />
          <polygon
            class="st3"
            points="385.8,6 384.2,6.6 383.3,4.3 382.8,4.5 383.7,6.8 385,10 387.1,9.1 			"
          />
          <polygon
            class="st3"
            points="366.6,22.9 367.9,25.5 367,26 365.6,23.3 			"
          />
          <polygon
            class="st3"
            points="368.8,21.7 370.2,24.3 369.2,24.8 367.9,22.2 			"
          />
          <polygon
            class="st3"
            points="371.1,20.5 372.5,23.2 371.5,23.6 370.2,21 			"
          />
          <polygon
            class="st3"
            points="373.4,19.4 374.8,22 373.8,22.5 372.5,19.8 			"
          />
          <polygon
            class="st3"
            points="375.7,18.2 377,20.8 376.1,21.3 374.8,18.7 			"
          />
          <polygon
            class="st3"
            points="380.6,25.1 380.2,26.2 378.7,25.6 379.1,24.5 			"
          />
          <polygon
            class="st3"
            points="374.6,28.9 374.2,30 372.7,29.4 373.1,28.3 			"
          />
          <polygon
            class="st3"
            points="378,17 379.3,19.7 378.4,20.1 377.1,17.5 			"
          />
          <polygon
            class="st3"
            points="385.5,13.2 386.9,15.8 385.6,16.5 384.3,13.8 			"
          />
          <polygon
            class="st3"
            points="388.4,11.7 389.8,14.4 388.5,15 387.2,12.4 			"
          />
          <polygon
            class="st3"
            points="391.3,10.3 392.6,12.9 391.4,13.6 390,10.9 			"
          />
          <polygon
            class="st3"
            points="394.2,8.8 395.5,11.4 394.3,12.1 392.9,9.5 			"
          />
          <polygon
            class="st3"
            points="363.7,30.7 360.8,25 362.1,24.4 365,30 			"
          />
          <polygon
            class="st3"
            points="364.7,31.1 365.7,33 365,33.3 364.1,31.4 			"
          />
          <polygon
            class="st3"
            points="364.5,27.2 365.5,26.7 366.7,29.1 367,29 365.8,26.6 364.1,23.3 362.9,24 			"
          />
          <polygon
            class="st3"
            points="379.7,37 379.7,32.5 368.5,32.5 368.5,39.8 376,39.8 376,42 387.9,42 387.9,37 			"
          />
          <polygon
            class="st3"
            points="408.5,12.5 407.3,10.3 403.8,12.3 405.1,14.5 400.6,17.2 401.7,19.1 394,23.6 393.6,23 386.8,27 
				390.8,33.8 396,30.8 397,32.6 402.4,29.5 399.3,24.3 403.3,21.9 404.3,23.6 413.4,18.3 409.7,11.9 			"
          />
          <rect x="353.9" y="53.5" class="st3" width="7.3" height="3" />
          <rect x="354.7" y="46.4" class="st3" width="7.3" height="3" />
          <rect x="355.5" y="39.3" class="st3" width="7.3" height="3" />
          <rect x="356.4" y="32.2" class="st3" width="7.3" height="3" />
          <rect x="364.6" y="52.2" class="st3" width="2.2" height="2.2" />
          <rect x="369.5" y="45.6" class="st3" width="4.9" height="1.5" />
          <rect x="374.4" y="47.9" class="st3" width="2.9" height="1.5" />
          <rect x="372.7" y="51.1" class="st3" width="2.4" height="5.3" />
          <rect x="380.6" y="52.2" class="st3" width="2.4" height="5.3" />
          <polygon
            class="st3"
            points="429,19.3 428.4,18.2 428.4,18.2 427.9,17.2 425.2,18.5 425.7,19.6 419.8,22.6 420.4,23.7 
				426.3,20.7 426.3,20.7 			"
          />
          <polygon
            class="st3"
            points="104.2,109.7 102.8,109.7 102.8,113.6 103.8,113.6 103.8,116.7 107.8,116.7 107.8,113.6 109.5,113.6 
				109.5,109.7 107.1,109.7 107.1,112.6 104.2,112.6 			"
          />
          <polygon
            class="st3"
            points="177.4,59.1 177.4,58.2 172.9,58.2 172.9,58.8 169.1,58.9 169.1,61.4 172.9,61.4 172.9,62.5 
				177.4,62.5 177.4,60.9 173.9,60.9 173.9,59.1 			"
          />
          <polygon
            class="st3"
            points="152.9,16.8 152.9,15.4 149.1,15.4 149.1,16.3 145.9,16.3 145.9,20.4 149.1,20.4 149.1,22.2 
				152.9,22.2 152.9,19.7 150,19.7 150,16.8 			"
          />
          <polygon
            class="st3"
            points="97.3,109.7 97.3,110.7 98.8,110.7 98.8,113.9 98.7,113.9 98.7,115.6 99.5,115.6 99.5,114.4 
				99.6,114.4 99.6,110.4 99.2,110.4 99.2,109.7 			"
          />
          <polygon
            class="st3"
            points="271.2,204.9 270,206 271.8,208 267.4,212 267.3,211.8 265.2,213.8 266.1,214.8 267.7,213.4 
				267.8,213.6 273.1,208.9 272.6,208.3 273.5,207.4 			"
          />
          <polygon
            class="st3"
            points="153.9,116 153.9,116.9 155.4,116.9 155.4,120.1 155.3,120.1 155.3,121.8 156.1,121.8 156.1,120.6 
				156.3,120.6 156.3,116.7 155.8,116.7 155.8,116 			"
          />
          <polygon
            class="st3"
            points="167.7,1 166.5,1 166.5,2.9 162.3,2.9 162.3,2.8 160.3,2.8 160.3,3.7 161.8,3.7 161.8,4 166.8,4 
				166.8,3.4 167.7,3.4 			"
          />
          <polygon
            class="st3"
            points="158.2,4.7 157,4.7 157,6.6 152.9,6.6 152.9,6.5 150.8,6.5 150.8,7.4 152.3,7.4 152.3,7.6 157.3,7.6 
				157.3,7.1 158.2,7.1 			"
          />
          <polygon
            class="st3"
            points="94,109.7 94,110.7 95.4,110.7 95.4,113.9 95.4,113.9 95.4,115.6 96.1,115.6 96.1,114.4 96.3,114.4 
				96.3,110.4 95.8,110.4 95.8,109.7 			"
          />
          <polygon
            class="st3"
            points="90.6,109.7 90.6,110.7 92.1,110.7 92.1,113.9 92,113.9 92,115.6 92.8,115.6 92.8,114.4 92.9,114.4 
				92.9,110.4 92.5,110.4 92.5,109.7 			"
          />
          <polygon
            class="st3"
            points="62.1,113.1 63.1,112.6 65.6,117 64.7,117.6 			"
          />

          <rect
            x="91.2"
            y="116.2"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -27.0352 210.5494)"
            class="st3"
            width="1.1"
            height="5.1"
          />

          <rect
            x="100.9"
            y="114.8"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -13.7347 216.4818)"
            class="st3"
            width="0.9"
            height="0.6"
          />

          <rect
            x="85.9"
            y="110.2"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -25.9209 198.6324)"
            class="st3"
            width="0.9"
            height="4.1"
          />

          <rect
            x="74.8"
            y="108.6"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -35.3735 185.8025)"
            class="st3"
            width="0.8"
            height="3.9"
          />

          <rect
            x="63.2"
            y="106.9"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -45.0003 174.242)"
            class="st3"
            width="2.9"
            height="5.4"
          />

          <rect
            x="79.5"
            y="111.9"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -31.0051 194.1616)"
            class="st3"
            width="4.2"
            height="1.4"
          />

          <rect
            x="68"
            y="111.8"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -42.6912 181.5751)"
            class="st3"
            width="2.8"
            height="0.7"
          />

          <rect
            x="71.6"
            y="113.6"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -40.8086 188.5186)"
            class="st3"
            width="4.5"
            height="2"
          />

          <rect
            x="84.5"
            y="114.3"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -29.862 200.0051)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="349.2"
            y="88.4"
            transform="matrix(3.556597e-02 -0.9994 0.9994 3.556597e-02 248.4609 435.0426)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="347.8"
            y="91.4"
            transform="matrix(3.568720e-02 -0.9994 0.9994 3.568720e-02 244.0565 436.5049)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="351.1"
            y="88.4"
            transform="matrix(3.568720e-02 -0.9994 0.9994 3.568720e-02 250.1832 436.9944)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="357.9"
            y="89.5"
            transform="matrix(3.566564e-02 -0.9994 0.9994 3.566564e-02 255.6238 444.8928)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="355.9"
            y="89.4"
            transform="matrix(3.566151e-02 -0.9994 0.9994 3.566151e-02 253.8021 442.8636)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="357"
            y="94.7"
            transform="matrix(3.567718e-02 -0.9994 0.9994 3.567718e-02 247.4567 451.1309)"
            class="st3"
            width="1"
            height="5.2"
          />

          <rect
            x="348.3"
            y="85.9"
            transform="matrix(3.576658e-02 -0.9994 0.9994 3.576658e-02 250.4338 431.8294)"
            class="st3"
            width="1.3"
            height="0.6"
          />

          <rect
            x="355.8"
            y="92.2"
            transform="matrix(3.566151e-02 -0.9994 0.9994 3.566151e-02 250.9807 445.399)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="357.4"
            y="93.5"
            transform="matrix(3.566151e-02 -0.9994 0.9994 3.566151e-02 252.3551 449.4883)"
            class="st3"
            width="3.4"
            height="1"
          />

          <rect
            x="346.4"
            y="96"
            transform="matrix(3.557945e-02 -0.9994 0.9994 3.557945e-02 238.7799 440.1263)"
            class="st3"
            width="2"
            height="0.7"
          />

          <rect
            x="344.5"
            y="102.7"
            transform="matrix(6.939971e-02 -0.9976 0.9976 6.939971e-02 220.6652 443.0643)"
            class="st3"
            width="6.6"
            height="1.2"
          />

          <rect
            x="349.6"
            y="106.6"
            transform="matrix(3.565373e-02 -0.9994 0.9994 3.565373e-02 230.587 453.2729)"
            class="st3"
            width="1.1"
            height="1.1"
          />

          <rect
            x="354.3"
            y="106.6"
            transform="matrix(3.574153e-02 -0.9994 0.9994 3.574153e-02 235.0941 457.9651)"
            class="st3"
            width="1.1"
            height="1.1"
          />

          <rect
            x="356.9"
            y="104.9"
            transform="matrix(3.564415e-02 -0.9994 0.9994 3.564415e-02 240.5305 460.5182)"
            class="st3"
            width="4"
            height="1.5"
          />

          <rect
            x="358.9"
            y="99.8"
            transform="matrix(3.577724e-02 -0.9994 0.9994 3.577724e-02 246.714 456.056)"
            class="st3"
            width="1.6"
            height="0.7"
          />

          <rect
            x="352.6"
            y="97.3"
            transform="matrix(3.567223e-02 -0.9994 0.9994 3.567223e-02 240.5387 449.188)"
            class="st3"
            width="0.8"
            height="5.4"
          />
          <polygon
            class="st3"
            points="184.5,18.2 183.2,18.6 182.5,16.1 183.8,15.8 			"
          />
          <polygon
            class="st3"
            points="187.7,18.7 186.5,19 186.1,17.8 187.4,17.4 			"
          />
          <polygon
            class="st3"
            points="191.7,16.7 192.9,16.4 193.3,17.6 192,18 			"
          />
          <polygon
            class="st3"
            points="194.8,16.1 196.4,15.7 196.9,17.3 195.2,17.8 			"
          />
          <polygon
            class="st3"
            points="195,9.6 194.2,9.8 193.8,8.1 194.6,7.9 			"
          />
          <polygon
            class="st3"
            points="189.8,10.6 188.9,10.8 188.5,9.1 189.3,8.8 			"
          />
          <polygon
            class="st3"
            points="182.5,10 184.3,9.6 184.5,10.4 182.7,10.9 			"
          />

          <rect
            x="351.9"
            y="93.8"
            transform="matrix(3.567990e-02 -0.9994 0.9994 3.567990e-02 245.2708 443.5807)"
            class="st3"
            width="1.2"
            height="1.9"
          />

          <rect
            x="356"
            y="84.2"
            transform="matrix(3.565561e-02 -0.9994 0.9994 3.565561e-02 259.0882 438.4295)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="359.3"
            y="86.9"
            transform="matrix(3.567413e-02 -0.9994 0.9994 3.567413e-02 259.6687 443.8871)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="354.1"
            y="129.9"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 709.1182 260.7446)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="357.6"
            y="129.9"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 716.1951 260.7388)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="358.8"
            y="124.9"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 718.6062 250.7346)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="357.8,132.7 358.7,132.4 358.4,131.5 357.5,131.8 			"
          />
          <polygon
            class="st3"
            points="356,133.4 354.3,134.6 354.8,135.4 356.5,134.2 			"
          />

          <rect
            x="356.1"
            y="124.6"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 713.7554 250.7347)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="355.3"
            y="127.7"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 713.3494 256.4831)"
            class="st3"
            width="2.8"
            height="1"
          />

          <rect
            x="352.7"
            y="84"
            transform="matrix(3.572041e-02 -0.9994 0.9994 3.572041e-02 255.7468 434.492)"
            class="st3"
            width="0.7"
            height="1.3"
          />
          <polygon
            class="st3"
            points="100.9,120.6 101.1,119.8 101.9,120 101.7,120.8 			"
          />
          <polygon
            class="st3"
            points="100.4,122.4 100.6,121.6 101.4,121.8 101.2,122.6 			"
          />
          <polygon
            class="st3"
            points="97.6,128.8 97.9,127.9 98.8,128.2 98.5,129.1 			"
          />
          <polygon
            class="st3"
            points="98.2,126.9 98.4,126 99.3,126.3 99.1,127.2 			"
          />
          <polygon
            class="st3"
            points="88.3,131.4 88.5,130.5 90.6,131.1 90.4,132 			"
          />
          <polygon
            class="st3"
            points="102.9,120.9 103.1,120.3 104.4,120.7 104.2,121.2 			"
          />

          <rect
            x="93.2"
            y="125.6"
            transform="matrix(1.889364e-03 -1 1 1.889364e-03 -33.5159 220.5478)"
            class="st3"
            width="1"
            height="3"
          />

          <rect
            x="93.2"
            y="128.4"
            transform="matrix(1.889165e-03 -1 1 1.889165e-03 -36.3797 223.3953)"
            class="st3"
            width="1"
            height="3"
          />

          <rect
            x="91"
            y="117.7"
            transform="matrix(1.860595e-03 -1 1 1.860595e-03 -27.5572 209.8424)"
            class="st3"
            width="0.7"
            height="2"
          />

          <rect
            x="82.3"
            y="118.6"
            transform="matrix(1.908393e-03 -1 1 1.908393e-03 -36.9271 202.1668)"
            class="st3"
            width="1"
            height="2"
          />

          <rect
            x="80.8"
            y="121.5"
            transform="matrix(1.869523e-03 -1 1 1.869523e-03 -40.8098 203.0681)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="81.3"
            y="125.5"
            transform="matrix(1.967919e-03 -1 1 1.967919e-03 -44.3222 207.55)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="81.8"
            y="128.3"
            transform="matrix(1.949168e-03 -1 1 1.949168e-03 -46.6808 211.2244)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="85.7"
            y="128.6"
            transform="matrix(1.953923e-03 -1 1 1.953923e-03 -43.512 215.0799)"
            class="st3"
            width="0.7"
            height="1.4"
          />

          <rect
            x="85.6"
            y="122.4"
            transform="matrix(1.956959e-03 -1 1 1.956959e-03 -36.5146 208.8169)"
            class="st3"
            width="1.4"
            height="0.7"
          />

          <rect
            x="82.8"
            y="105.9"
            transform="matrix(6.589042e-02 -0.9978 0.9978 6.589042e-02 -27.9482 182.9931)"
            class="st3"
            width="2"
            height="1"
          />

          <rect
            x="76.7"
            y="101.4"
            transform="matrix(6.588402e-02 -0.9978 0.9978 6.588402e-02 -29.594 172.2215)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="71.5"
            y="100.9"
            transform="matrix(6.587753e-02 -0.9978 0.9978 6.587753e-02 -33.9189 166.6674)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="68.3"
            y="101.3"
            transform="matrix(6.582575e-02 -0.9978 0.9978 6.582575e-02 -37.2285 164.0687)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="67.7"
            y="105.1"
            transform="matrix(6.582081e-02 -0.9978 0.9978 6.582081e-02 -41.2423 166.7729)"
            class="st3"
            width="1.4"
            height="0.7"
          />

          <rect
            x="39.1"
            y="126.9"
            transform="matrix(6.583068e-02 -0.9978 0.9978 6.583068e-02 -89.7607 158.6084)"
            class="st3"
            width="1.4"
            height="0.7"
          />
          <polygon
            class="st3"
            points="101.8,205.2 99.4,206.2 99,205.3 101.5,204.3 			"
          />
          <polygon
            class="st3"
            points="106,211.6 104.4,212.2 103.8,210.8 105.4,210.1 			"
          />
          <polygon
            class="st3"
            points="109.6,211.4 108,212.1 107.4,210.6 109,210 			"
          />
          <polygon
            class="st3"
            points="116.1,208.2 116.1,207.5 114.6,207.6 114.7,209 116,208.9 116,210 114.7,210 114.7,212.5 
				116.9,212.5 116.9,210 116.8,208.2 			"
          />

          <rect
            x="107.2"
            y="205.2"
            transform="matrix(3.089149e-02 -0.9995 0.9995 3.089149e-02 -100.3134 307.9787)"
            class="st3"
            width="2.9"
            height="1"
          />

          <rect
            x="106.2"
            y="206"
            transform="matrix(3.085257e-02 -0.9995 0.9995 3.085257e-02 -105.2651 308.565)"
            class="st3"
            width="0.7"
            height="5.2"
          />

          <rect
            x="105.3"
            y="199"
            transform="matrix(3.086654e-02 -0.9995 0.9995 3.086654e-02 -98.4185 301.0165)"
            class="st3"
            width="1.3"
            height="4.5"
          />

          <rect
            x="113.4"
            y="205.4"
            transform="matrix(0.9999 -1.507895e-02 1.507895e-02 0.9999 -3.0987 1.7394)"
            class="st3"
            width="0.9"
            height="1.9"
          />

          <rect
            x="114.2"
            y="202.1"
            transform="matrix(0.9999 -1.512929e-02 1.512929e-02 0.9999 -3.0516 1.7656)"
            class="st3"
            width="2"
            height="0.9"
          />
          <rect x="111.5" y="209.7" class="st3" width="1" height="2.3" />

          <rect
            x="103.6"
            y="203.9"
            transform="matrix(3.089488e-02 -0.9995 0.9995 3.089488e-02 -103.2264 303.907)"
            class="st3"
            width="3"
            height="2.5"
          />
          <polygon
            class="st3"
            points="107.2,194.3 106.4,193.7 108.1,191.4 108.9,192 			"
          />
          <polygon
            class="st3"
            points="109.9,194.3 109.1,193.7 110.8,191.4 111.6,192 			"
          />
          <polygon
            class="st3"
            points="112.6,194.3 111.8,193.7 113.5,191.4 114.3,192 			"
          />
          <rect x="107.9" y="196" class="st3" width="5.2" height="0.7" />

          <rect
            x="102.6"
            y="191"
            transform="matrix(3.085553e-02 -0.9995 0.9995 3.085553e-02 -91.3625 290.3971)"
            class="st3"
            width="3"
            height="2.5"
          />

          <rect
            x="111.2"
            y="200.2"
            transform="matrix(0.9999 -1.519020e-02 1.519020e-02 0.9999 -3.0495 1.7205)"
            class="st3"
            width="1"
            height="2.7"
          />

          <rect
            x="114.6"
            y="199"
            transform="matrix(0.9999 -1.512652e-02 1.512652e-02 0.9999 -3.0045 1.7691)"
            class="st3"
            width="1.8"
            height="0.9"
          />

          <rect
            x="119.5"
            y="200"
            transform="matrix(3.084804e-02 -0.9995 0.9995 3.084804e-02 -85.1538 315.1235)"
            class="st3"
            width="0.9"
            height="2.9"
          />

          <rect
            x="114.6"
            y="205.7"
            transform="matrix(0.9999 -1.514009e-02 1.514009e-02 0.9999 -3.1073 1.776)"
            class="st3"
            width="2.3"
            height="0.8"
          />
          <polygon
            class="st3"
            points="101.8,208 100.3,208.6 99.9,207.7 101.4,207.1 			"
          />
          <polygon
            class="st3"
            points="119,210.7 119.7,209.9 121.6,211.6 120.9,212.4 			"
          />

          <rect
            x="119.2"
            y="205.1"
            transform="matrix(0.9995 -3.118273e-02 3.118273e-02 0.9995 -6.3812 3.9)"
            class="st3"
            width="5.4"
            height="2.9"
          />

          <rect
            x="125.2"
            y="209"
            transform="matrix(0.9995 -3.117139e-02 3.117139e-02 0.9995 -6.4972 4.0172)"
            class="st3"
            width="0.7"
            height="2.8"
          />

          <rect
            x="124.9"
            y="198.8"
            transform="matrix(3.464085e-02 -0.9994 0.9994 3.464085e-02 -78.1864 318.0554)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="124.8"
            y="201.9"
            transform="matrix(3.456580e-02 -0.9994 0.9994 3.456580e-02 -80.8886 320.6896)"
            class="st3"
            width="1.4"
            height="0.7"
          />

          <rect
            x="74.7"
            y="105.5"
            transform="matrix(6.585337e-02 -0.9978 0.9978 6.585337e-02 -35.8761 174.0732)"
            class="st3"
            width="0.7"
            height="1.4"
          />

          <rect
            x="92.4"
            y="120.5"
            transform="matrix(1.919486e-03 -1 1 1.919486e-03 -27.9288 214.1449)"
            class="st3"
            width="1.9"
            height="1.2"
          />
          <polygon
            class="st3"
            points="102.3,129.6 102.7,128.2 104.1,128.6 103.7,130 			"
          />
          <polygon
            class="st3"
            points="99.7,130.9 100,129.9 101,130.2 100.7,131.2 			"
          />
          <polygon
            class="st3"
            points="103.9,123.9 104.3,122.6 105,122.8 104.6,124.1 			"
          />

          <rect
            x="349.3"
            y="80.7"
            transform="matrix(0.1233 -0.9924 0.9924 0.1233 225.7523 418.783)"
            class="st3"
            width="1.1"
            height="1.8"
          />

          <rect
            x="352.8"
            y="75.2"
            transform="matrix(0.1233 -0.9924 0.9924 0.1233 234.7119 416.9883)"
            class="st3"
            width="1.1"
            height="0.9"
          />

          <rect
            x="360.2"
            y="80.7"
            transform="matrix(9.826174e-02 -0.9952 0.9952 9.826174e-02 244.7858 433.0251)"
            class="st3"
            width="2.3"
            height="1.5"
          />

          <rect
            x="360.8"
            y="67.9"
            transform="matrix(9.839527e-02 -0.9951 0.9951 9.839527e-02 255.8163 422.3816)"
            class="st3"
            width="0.3"
            height="4.3"
          />

          <rect
            x="359.9"
            y="75.2"
            transform="matrix(9.830596e-02 -0.9952 0.9952 9.830596e-02 249.695 427.057)"
            class="st3"
            width="1.3"
            height="1.1"
          />

          <rect
            x="362.2"
            y="71.1"
            transform="matrix(9.824628e-02 -0.9952 0.9952 9.824628e-02 255.6944 425.5103)"
            class="st3"
            width="0.9"
            height="1.1"
          />

          <rect
            x="362"
            y="72.8"
            transform="matrix(9.836061e-02 -0.9952 0.9952 9.836061e-02 253.7995 426.7639)"
            class="st3"
            width="0.9"
            height="1.1"
          />

          <rect
            x="356"
            y="81.3"
            transform="matrix(9.831359e-02 -0.9952 0.9952 9.831359e-02 240.1416 428.7395)"
            class="st3"
            width="1.3"
            height="1.1"
          />

          <rect
            x="347.4"
            y="73.4"
            transform="matrix(0.1233 -0.9924 0.9924 0.1233 234.2425 413.1245)"
            class="st3"
            width="7.1"
            height="1.2"
          />

          <rect
            x="356.9"
            y="76.5"
            transform="matrix(9.832263e-02 -0.9952 0.9952 9.832263e-02 245.7986 425.3835)"
            class="st3"
            width="1.5"
            height="1"
          />

          <rect
            x="353.3"
            y="81.5"
            transform="matrix(3.569393e-02 -0.9994 0.9994 3.569393e-02 258.8669 432.7094)"
            class="st3"
            width="0.7"
            height="1.5"
          />

          <rect
            x="52.9"
            y="79"
            transform="matrix(9.905446e-02 -0.9951 0.9951 9.905446e-02 -30.7145 125.3952)"
            class="st3"
            width="2"
            height="1.3"
          />

          <rect
            x="52.2"
            y="88.3"
            transform="matrix(9.906349e-02 -0.9951 0.9951 9.906349e-02 -40.6043 132.9414)"
            class="st3"
            width="1.7"
            height="1.3"
          />

          <rect
            x="54.8"
            y="89.9"
            transform="matrix(9.899481e-02 -0.9951 0.9951 9.899481e-02 -40.3028 136.7221)"
            class="st3"
            width="1"
            height="1.4"
          />

          <rect
            x="57.1"
            y="88.5"
            transform="matrix(9.905934e-02 -0.9951 0.9951 9.905934e-02 -35.9462 138.8545)"
            class="st3"
            width="3.1"
            height="1.6"
          />

          <rect
            x="61.6"
            y="80.4"
            transform="matrix(9.901812e-02 -0.9951 0.9951 9.901812e-02 -25.0285 135.7755)"
            class="st3"
            width="1.6"
            height="2.5"
          />

          <rect
            x="62.7"
            y="86.1"
            transform="matrix(9.901883e-02 -0.9951 0.9951 9.901883e-02 -29.3784 140.8843)"
            class="st3"
            width="0.9"
            height="1.2"
          />

          <rect
            x="55.8"
            y="80.4"
            transform="matrix(9.900328e-02 -0.9951 0.9951 9.900328e-02 -29.908 131.4602)"
            class="st3"
            width="3.6"
            height="3.6"
          />

          <rect
            x="354.6"
            y="72.5"
            transform="matrix(9.828103e-02 -0.9952 0.9952 9.828103e-02 249.6109 421.5941)"
            class="st3"
            width="5.7"
            height="1.2"
          />

          <rect
            x="365.1"
            y="92.7"
            transform="matrix(3.568715e-02 -0.9994 0.9994 3.568715e-02 259.1231 457.118)"
            class="st3"
            width="2.7"
            height="3.3"
          />

          <rect
            x="370.5"
            y="79.8"
            transform="matrix(3.569800e-02 -0.9994 0.9994 3.569800e-02 276.9306 449.3779)"
            class="st3"
            width="1.6"
            height="2.8"
          />
          <polygon
            class="st3"
            points="199.9,44.9 197.2,43.8 197.7,42.5 200.4,43.5 			"
          />
          <rect x="194.5" y="47.3" class="st3" width="1.1" height="1" />
          <polygon
            class="st3"
            points="196.1,41.7 193.7,42.5 193,40.5 195.4,39.7 			"
          />
          <polygon
            class="st3"
            points="201.2,39 199.2,39.7 198.8,38.5 200.8,37.8 			"
          />

          <rect
            x="353.7"
            y="68.7"
            transform="matrix(0.1233 -0.9924 0.9924 0.1233 241.2525 412.9767)"
            class="st3"
            width="1.4"
            height="2.5"
          />
          <rect
            x="3"
            y="43.7"
            transform="matrix(0.1828 -0.9832 0.9832 0.1828 -40.5443 39.4403)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="0.1"
            y="45.3"
            transform="matrix(0.1828 -0.9832 0.9832 0.1828 -44.5565 37.903)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <rect
            x="6.6"
            y="47.4"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -41.2795 46.0951)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="4.7"
            y="47"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -42.5048 43.9027)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="2.4"
            y="46.8"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -44.0854 41.4216)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="4.1"
            y="52.3"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -47.7446 47.632)"
            class="st3"
            width="1.3"
            height="0.6"
          />
          <rect
            x="4.3"
            y="49.1"
            transform="matrix(0.1829 -0.9831 0.9831 0.1829 -44.8358 45.2106)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="7.3"
            y="49.9"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -43.136 48.8556)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="2.8"
            y="40.9"
            transform="matrix(0.1828 -0.9832 0.9832 0.1828 -37.8375 37.8542)"
            class="st3"
            width="2"
            height="1.5"
          />
          <rect
            x="6.3"
            y="52.8"
            transform="matrix(0.1829 -0.9831 0.9831 0.1829 -46.4937 50.7341)"
            class="st3"
            width="2"
            height="1"
          />
          <rect
            x="1.5"
            y="52.3"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -50.2782 45.1987)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="4.3"
            y="61.5"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -57.0988 55.4073)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="4.9"
            y="58.1"
            transform="matrix(0.1829 -0.9831 0.9831 0.1829 -53.2109 53.5221)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="5.9"
            y="55.6"
            transform="matrix(0.1827 -0.9832 0.9832 0.1827 -49.5807 52.1281)"
            class="st3"
            width="1.4"
            height="0.7"
          />

          <rect
            x="1.4"
            y="56.3"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -54.6162 48.3726)"
            class="st3"
            width="0.7"
            height="1.4"
          />

          <rect
            x="0.4"
            y="48.2"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -47.4465 41.1426)"
            class="st3"
            width="1.2"
            height="1.9"
          />

          <rect
            x="6.5"
            y="42.1"
            transform="matrix(0.1828 -0.9831 0.9831 0.1828 -36.1648 42.1403)"
            class="st3"
            width="1.5"
            height="1.5"
          />
          <rect
            x="8.4"
            y="45"
            transform="matrix(0.1829 -0.9831 0.9831 0.1829 -37.4423 46.0113)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="1.1"
            y="41.5"
            transform="matrix(0.1829 -0.9831 0.9831 0.1829 -40.2217 35.8551)"
            class="st3"
            width="0.7"
            height="1.3"
          />
          <polygon
            class="st3"
            points="49.7,34.2 49.3,34.9 48.6,34.5 49,33.8 			"
          />
          <polygon class="st3" points="35.1,35 34.7,35.7 34,35.4 34.4,34.6 			" />
          <polygon class="st3" points="33.7,45.3 33.4,46 32.6,45.6 33,44.9 			" />
          <polygon class="st3" points="36.3,46 36,46.7 35.2,46.4 35.6,45.6 			" />
          <polygon
            class="st3"
            points="39,46.7 38.6,47.5 37.9,47.1 38.2,46.4 			"
          />
          <polygon
            class="st3"
            points="39.1,29.2 38.6,30.1 37.8,29.7 38.2,28.8 			"
          />
          <polygon
            class="st3"
            points="38.2,31 37.7,31.9 36.9,31.4 37.3,30.6 			"
          />
          <polygon
            class="st3"
            points="31.6,30.4 31.2,31.2 30.4,30.8 30.8,30 			"
          />
          <polygon
            class="st3"
            points="47.8,33.5 47.5,34 46.4,33.4 46.6,32.9 			"
          />
          <polygon
            class="st3"
            points="40.6,32.2 40.2,33.1 39.3,32.7 39.8,31.8 			"
          />
          <polygon
            class="st3"
            points="40.6,30.1 41,29.3 41.9,29.7 41.5,30.5 			"
          />
          <polygon
            class="st3"
            points="56,36.8 55.7,37.5 53.8,36.6 54.2,35.9 			"
          />
          <polygon
            class="st3"
            points="39.7,35.7 38.8,37.3 37.8,36.8 38.6,35.1 			"
          />
          <polygon
            class="st3"
            points="34.6,27.5 34,28.9 32.6,28.2 33.3,26.9 			"
          />
          <polygon
            class="st3"
            points="37.4,26.8 36.9,27.7 36,27.2 36.5,26.3 			"
          />
          <polygon class="st3" points="32,32.8 31.4,34 30.7,33.7 31.3,32.5 			" />

          <rect
            x="370.2"
            y="112.4"
            transform="matrix(0.1874 -0.9823 0.9823 0.1874 190.6077 456.027)"
            class="st3"
            width="1.4"
            height="0.8"
          />

          <rect
            x="369.7"
            y="115.5"
            transform="matrix(0.1874 -0.9823 0.9823 0.1874 187.1023 457.9539)"
            class="st3"
            width="1.4"
            height="0.8"
          />

          <rect
            x="366.4"
            y="123.5"
            transform="matrix(7.227518e-02 -0.9974 0.9974 7.227518e-02 216.9494 481.2375)"
            class="st3"
            width="1.6"
            height="1"
          />

          <rect
            x="370.2"
            y="117.5"
            transform="matrix(0.1874 -0.9823 0.9823 0.1874 184.8378 460.9402)"
            class="st3"
            width="1.6"
            height="2.5"
          />

          <rect
            x="369.4"
            y="120.9"
            transform="matrix(0.1875 -0.9823 0.9823 0.1875 181.5476 462.273)"
            class="st3"
            width="1.6"
            height="1"
          />

          <rect
            x="368.3"
            y="111.5"
            transform="matrix(0.1875 -0.9823 0.9823 0.1875 189.4075 453.33)"
            class="st3"
            width="0.9"
            height="1.3"
          />

          <rect
            x="361.2"
            y="126.9"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 489.4503 -234.6263)"
            class="st3"
            width="1.7"
            height="1"
          />
          <polygon
            class="st3"
            points="365.2,126.6 363.1,122.8 362.2,123.2 364.3,127.1 			"
          />
          <polygon
            class="st3"
            points="366.1,113.4 368.2,109.7 367.6,109.3 365.4,113 			"
          />
          <polygon
            class="st3"
            points="34.9,205.3 27.7,210.2 27.1,209.3 34.3,204.4 			"
          />

          <rect
            x="64.7"
            y="194.7"
            transform="matrix(0.9915 -0.1302 0.1302 0.9915 -24.8388 10.202)"
            class="st3"
            width="1.8"
            height="0.8"
          />

          <rect
            x="68.8"
            y="195"
            transform="matrix(0.9915 -0.1302 0.1302 0.9915 -24.9154 10.6772)"
            class="st3"
            width="0.8"
            height="1.8"
          />
          <polygon
            class="st3"
            points="68.5,187 69.6,191 67.3,191.6 66.2,187.6 			"
          />
          <polygon
            class="st3"
            points="44.2,157.2 43.6,155.8 44.9,155.2 45.6,156.5 			"
          />
          <polygon
            class="st3"
            points="51.1,156.2 51.8,157.7 49,158.9 48.3,157.4 			"
          />
          <polygon
            class="st3"
            points="52,145.5 52.5,145.2 51.1,142 50.1,142.5 51.2,144.9 50.4,145.3 51.7,148.2 53,147.7 			"
          />
          <polygon
            class="st3"
            points="52.2,150 53.1,149.6 52.6,148.5 51.7,148.9 51.5,149 49.8,149.8 51.1,152.6 53,151.7 			"
          />
          <polygon
            class="st3"
            points="53.5,162.1 52.9,164.1 50.9,163.5 51.5,161.5 			"
          />
          <polygon
            class="st3"
            points="45.3,189.8 43.8,189.6 43.7,190.2 38.2,189.6 38,191.8 44.4,192.5 44.6,190.6 45.3,190.6 			"
          />
          <polygon
            class="st3"
            points="49.5,163.7 48.7,166.4 44.3,165.1 45.1,162.3 			"
          />
          <polygon
            class="st3"
            points="70.9,203.2 70.1,202.3 72.8,200.1 73.5,200.9 			"
          />
          <polygon
            class="st3"
            points="49.8,202.1 42.5,204.8 41.9,202.9 49.2,200.2 			"
          />
          <polygon
            class="st3"
            points="57.1,156.2 55,157 54.4,155.4 56.6,154.6 			"
          />
          <polygon
            class="st3"
            points="57.5,162.9 56.1,163.4 55.1,160.4 56.4,160 			"
          />
          <polygon
            class="st3"
            points="51.9,198.8 53.3,199.8 53.8,199.2 55.2,200.3 55.7,199.6 54.3,198.5 52.9,197.4 			"
          />
          <polygon
            class="st3"
            points="53.3,190.4 49.7,191.6 49.3,190.4 52.9,189.1 			"
          />
          <polygon
            class="st3"
            points="50.8,197.1 49.7,197.5 47.9,192.2 49,191.9 			"
          />
          <polygon
            class="st3"
            points="59.5,193.1 59.6,192.5 58.2,192.4 58.1,193.6 59.3,193.7 59.3,194.6 58,194.5 57.8,196.5 
				59.9,196.7 60.1,194.7 60.2,193.2 			"
          />
          <polygon
            class="st3"
            points="58.4,198.9 58.1,198.1 56.8,198.5 57.1,199.4 58.1,199 58.7,200.8 59.3,200.6 58.7,198.8 			"
          />
          <polygon
            class="st3"
            points="58.9,188.4 56.6,189 56.2,187.6 58.5,187 			"
          />
          <polygon
            class="st3"
            points="65.2,201.6 66,201.7 66.2,199.6 65.7,199.5 65.9,197.8 63.7,197.5 63.5,199.2 62.5,199.1 
				62.3,201.2 63.6,201.4 63.8,199.8 65.4,200 			"
          />
          <polygon
            class="st3"
            points="43.3,174.1 42.5,172.3 43.6,171.8 44.4,173.6 			"
          />
          <polygon
            class="st3"
            points="45.4,178.8 44.6,177 45.7,176.6 46.5,178.3 			"
          />
          <polygon
            class="st3"
            points="46.1,170.4 45.5,169.2 47.3,168.4 47.9,169.6 			"
          />
          <polygon
            class="st3"
            points="50.4,177.7 49.1,174.7 50.1,174.3 51.4,177.2 			"
          />

          <rect
            x="119.1"
            y="121.4"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -24.7663 213.3833)"
            class="st3"
            width="3.2"
            height="1.4"
          />

          <rect
            x="123.6"
            y="114.9"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -16.4087 212.5755)"
            class="st3"
            width="1.4"
            height="3.3"
          />

          <rect
            x="117.2"
            y="116.2"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -21.3743 208.4317)"
            class="st3"
            width="4.2"
            height="2.7"
          />

          <rect
            x="119.7"
            y="112.9"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -16.6696 206.5387)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="123.5"
            y="134.7"
            transform="matrix(0.2174 -0.9761 0.9761 0.2174 -34.4074 228.0324)"
            class="st3"
            width="3"
            height="1.6"
          />
          <polygon
            class="st3"
            points="125.2,130.9 125.3,130.4 121.8,129.6 121.6,130.7 124.2,131.3 124,132.2 127.2,132.9 127.5,131.5 
							"
          />
          <polygon
            class="st3"
            points="128.9,133.6 129.1,132.6 127.9,132.3 127.7,133.3 127.6,133.6 127.2,135.3 130.2,136 130.7,134 			
				"
          />

          <rect
            x="165"
            y="155.3"
            transform="matrix(0.9906 -0.1368 0.1368 0.9906 -19.8173 24.1817)"
            class="st3"
            width="2.1"
            height="2.1"
          />
          <polygon
            class="st3"
            points="140.3,167.1 137,168.8 136.3,167.4 139.5,165.7 			"
          />
          <polygon
            class="st3"
            points="139.4,159.8 140.1,159.4 141.9,162.5 141.2,162.9 			"
          />
          <polygon
            class="st3"
            points="149,161.9 144.2,163.6 143.7,162.2 148.5,160.4 			"
          />
          <polygon
            class="st3"
            points="147.1,150 145.5,150.6 145.3,150 143.8,150.6 145.2,154.4 146.7,153.8 146,152 147.6,151.4 			"
          />
          <polygon
            class="st3"
            points="148.6,145.3 150.7,150.9 150.1,151.1 151.1,153.8 151.6,153.6 152.1,154.9 153.3,154.5 149.8,144.8 
							"
          />
          <polygon
            class="st3"
            points="132.9,128.4 133.7,129.8 134.2,129.6 136.8,134.5 138.8,133.4 135.8,127.7 134,128.6 133.7,128 			
				"
          />
          <polygon
            class="st3"
            points="134.4,149.9 132.4,150.6 130.3,144.7 132.2,144 			"
          />
          <polygon
            class="st3"
            points="135.8,159.4 133.3,160.7 131.1,156.6 133.7,155.3 			"
          />
          <polygon
            class="st3"
            points="144,141.7 144.6,143.3 142.2,144.2 142,143.6 140.6,144.1 140.9,145 138.4,145.9 137.9,144.4 
				136.5,145 137.6,147.9 139,147.4 138.8,147 141.3,146.1 142,148 143.4,147.4 142.6,145.3 146.4,143.9 146.2,143.4 146,142.8 
				145.4,141.2 			"
          />
          <polygon
            class="st3"
            points="131.1,152 129,152.7 128.4,151.1 130.5,150.3 			"
          />
          <polygon
            class="st3"
            points="148,167.8 147,168.1 146.7,167.1 147.7,166.7 			"
          />
          <polygon
            class="st3"
            points="150.7,171.9 150.4,173.1 151,173.2 150.7,174.4 151.3,174.6 151.6,173.3 151.8,172.2 			"
          />
          <polygon
            class="st3"
            points="156.8,170.1 155,168.3 155.6,167.6 157.5,169.5 			"
          />
          <polygon
            class="st3"
            points="151.9,170.5 151.3,170 154.1,167.3 154.6,167.9 			"
          />
          <polygon
            class="st3"
            points="155.7,173.1 156,172.8 155.4,172.1 154.8,172.6 155.3,173.3 154.8,173.7 154.3,173 153.2,173.9 
				154.1,175 155.2,174.1 156,173.4 			"
          />
          <polygon
            class="st3"
            points="143.4,158.7 143.9,158.3 143.2,157.6 142.8,158 143.3,158.6 142.4,159.4 142.7,159.8 143.6,158.9 
							"
          />
          <polygon
            class="st3"
            points="156.9,172.8 155.8,171.6 156.6,170.9 157.6,172.2 			"
          />
          <polygon
            class="st3"
            points="136.1,164.7 135.3,165.1 134.7,163.8 135.5,163.4 			"
          />
          <polygon
            class="st3"
            points="130.3,120.1 130.3,118.7 126.5,118.7 126.5,119.6 123.4,119.6 123.4,123.7 126.5,123.7 126.5,125.5 
				130.3,125.5 130.3,123 127.4,123 127.4,120.1 			"
          />
          <polygon
            class="st3"
            points="141.8,136 140.6,136 140.6,137.9 136.5,137.9 136.5,137.8 134.4,137.8 134.4,138.8 135.9,138.8 
				135.9,139 140.9,139 140.9,138.4 141.8,138.4 			"
          />
          <polygon
            class="st3"
            points="132.4,139.7 131.2,139.7 131.2,141.6 127,141.6 127,141.5 124.9,141.5 124.9,142.5 126.4,142.5 
				126.4,142.7 131.5,142.7 131.5,142.1 132.4,142.1 			"
          />
          <polygon
            class="st3"
            points="201,211.4 202.1,212.6 199.9,214.7 198.8,213.5 			"
          />
          <polygon
            class="st3"
            points="210.3,209.7 210.7,210.1 213.1,207.5 212.3,206.8 210.5,208.7 209.8,208.1 207.6,210.5 208.6,211.5 
							"
          />
          <polygon
            class="st3"
            points="204.6,216 205.4,216.5 206,215.4 205.2,214.9 204.9,214.8 203.3,214 201.9,216.7 203.7,217.7 			"
          />
          <polygon
            class="st3"
            points="144.5,230.6 145.3,232.5 139.6,235 138.7,233.2 			"
          />
          <polygon
            class="st3"
            points="131.1,207.1 131.1,206 129.2,205.9 129.3,201.7 129.4,201.7 129.5,199.6 128.5,199.6 128.4,201.1 
				128.2,201.1 128.1,206.2 128.7,206.2 128.7,207.1 			"
          />

          <rect
            x="169"
            y="194.7"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -57.4173 319.5147)"
            class="st3"
            width="3"
            height="1.6"
          />
          <polygon
            class="st3"
            points="168.8,191.7 169,191.1 165.5,190.4 165.2,191.4 167.9,192 167.7,192.9 170.9,193.6 171.2,192.2 			
				"
          />
          <polygon
            class="st3"
            points="174.4,193.6 174.6,192.7 173.4,192.4 173.2,193.4 173.1,193.6 172.7,195.4 175.8,196.1 176.2,194.1 
							"
          />
          <polygon
            class="st3"
            points="183.7,214.8 181.8,215.3 180.2,209.3 182.2,208.7 			"
          />
          <polygon
            class="st3"
            points="164,201.4 165.6,201.4 165.6,204.7 171.3,204.7 171.3,204.5 174,204.5 174,206.1 172,206.1 
				172,206.5 165.2,206.5 165.2,205.5 164,205.5 			"
          />
          <polygon
            class="st3"
            points="165.5,242.7 165.8,244.2 162.6,244.9 163.8,250.4 164,250.4 164.6,253.1 162.9,253.4 162.5,251.5 
				162.2,251.5 160.8,244.9 161.7,244.7 161.5,243.5 			"
          />
          <polygon
            class="st3"
            points="148.9,162.5 150,161.7 150.5,162.4 149.4,163.2 			"
          />
          <polygon
            class="st3"
            points="146.3,164.4 147.4,163.6 147.9,164.2 146.8,165 			"
          />
          <polygon
            class="st3"
            points="150.4,163.9 151.2,163.4 151.9,164.5 151.2,165 			"
          />
          <polygon
            class="st3"
            points="147.8,167.9 149.6,166.6 150.1,167.3 148.3,168.5 			"
          />
          <polygon
            class="st3"
            points="194.4,214.3 190.9,210.6 192,209.5 195.5,213.2 			"
          />
          <polygon
            class="st3"
            points="188.2,213.8 187.4,213 188.3,212.3 189,213.1 			"
          />
          <polygon
            class="st3"
            points="187.6,220.3 187.9,219.6 191.4,221.1 191.1,221.8 			"
          />
          <polygon
            class="st3"
            points="193.7,214.4 194.9,215.1 194.4,215.8 193.3,215.1 			"
          />
          <polygon
            class="st3"
            points="191,212.8 192.2,213.5 191.7,214.2 190.6,213.5 			"
          />
          <polygon
            class="st3"
            points="193,216.4 193.8,216.8 193.1,218 192.3,217.5 			"
          />
          <polygon
            class="st3"
            points="187.2,213.9 189.1,215.1 188.7,215.7 186.8,214.6 			"
          />
          <polygon
            class="st3"
            points="55.3,26.5 53.1,29.3 52,28.5 54.2,25.7 			"
          />
          <polygon
            class="st3"
            points="58,28.6 57.3,28.4 56.1,28.1 55.5,30.5 56.8,30.8 55.9,34.5 56.6,34.7 			"
          />
          <polygon
            class="st3"
            points="269.7,298.9 271.2,296.9 272.1,297.5 270.6,299.5 			"
          />
          <polygon
            class="st3"
            points="267.2,297 268.7,295 269.5,295.6 268,297.6 			"
          />
          <polygon
            class="st3"
            points="263.3,299.5 264.1,297.1 264.8,297.4 264.1,299.8 			"
          />
          <polygon
            class="st3"
            points="258.2,295.9 259.9,294.2 260.7,294.9 258.9,296.7 			"
          />
          <polygon
            class="st3"
            points="281.5,292.8 277.4,294.1 277,292.8 281.1,291.5 			"
          />
          <polygon
            class="st3"
            points="282.1,298.1 279,300.5 278.2,299.5 281.3,297.1 			"
          />
          <polygon
            class="st3"
            points="262.4,294.1 264.5,291.3 268,294 265.9,296.8 			"
          />

          <rect
            x="266.8"
            y="287.4"
            transform="matrix(3.557548e-02 -0.9994 0.9994 3.557548e-02 -28.9016 546.673)"
            class="st3"
            width="4"
            height="1.9"
          />
          <polygon
            class="st3"
            points="247.3,263.3 248.7,260.5 247.6,259.9 246.1,262.7 246.1,262.7 245.2,264.3 247.6,265.5 248.5,263.9 
							"
          />

          <rect
            x="251.6"
            y="265.3"
            transform="matrix(0.9979 -6.509784e-02 6.509784e-02 0.9979 -16.7827 17.0574)"
            class="st3"
            width="3.5"
            height="1.6"
          />
          <polygon
            class="st3"
            points="272.4,275.1 271.5,274.2 274.4,271.4 275.3,272.4 			"
          />
          <polygon
            class="st3"
            points="280.5,278.3 279.3,278.7 277.9,274.9 279.1,274.5 			"
          />

          <rect
            x="277.8"
            y="287.6"
            transform="matrix(3.559833e-02 -0.9994 0.9994 3.559833e-02 -20.0792 557.3116)"
            class="st3"
            width="1.8"
            height="2.9"
          />

          <rect
            x="282"
            y="287.4"
            transform="matrix(3.560578e-02 -0.9994 0.9994 3.560578e-02 -15.7749 560.7164)"
            class="st3"
            width="1.3"
            height="2.2"
          />

          <rect
            x="260.3"
            y="308.3"
            transform="matrix(3.554966e-02 -0.9994 0.9994 3.554966e-02 -57.2271 558.4722)"
            class="st3"
            width="0.8"
            height="1.2"
          />
          <polygon
            class="st3"
            points="248.7,240.9 249,241.7 247.8,242.1 247.7,241.7 246,242.2 246.1,242.6 246.7,244.4 248.4,243.8 
				249.5,243.5 251.3,242.9 250.7,241.2 250.5,240.4 			"
          />
          <polygon
            class="st3"
            points="257.7,271.3 258.6,271.2 258.6,272.4 258.2,272.5 258.2,274.3 258.7,274.3 260.5,274.2 260.4,272.4 
				260.4,271.2 260.3,269.4 258.5,269.4 257.6,269.5 			"
          />

          <rect
            x="266.9"
            y="301.3"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -79.4451 507.2293)"
            class="st3"
            width="2.5"
            height="1"
          />

          <rect
            x="268.1"
            y="266.5"
            transform="matrix(3.560317e-02 -0.9994 0.9994 3.560317e-02 -7.1095 527.7318)"
            class="st3"
            width="3.6"
            height="2.1"
          />

          <rect
            x="279.4"
            y="281.3"
            transform="matrix(3.560126e-02 -0.9994 0.9994 3.560126e-02 -13.6899 553.8305)"
            class="st3"
            width="1.3"
            height="5.4"
          />

          <rect
            x="277.4"
            y="282.5"
            transform="matrix(3.561088e-02 -0.9994 0.9994 3.561088e-02 -14.7897 551.7816)"
            class="st3"
            width="2.1"
            height="2.1"
          />

          <rect
            x="272.7"
            y="286.6"
            transform="matrix(3.558610e-02 -0.9994 0.9994 3.558610e-02 -24.001 550.7681)"
            class="st3"
            width="1.4"
            height="2.5"
          />
          <polygon
            class="st3"
            points="274.3,296.8 276.6,297.7 276.1,299 273.7,298.1 			"
          />

          <rect
            x="261.7"
            y="263.7"
            transform="matrix(0.1026 -0.9947 0.9947 0.1026 -27.7201 498.7513)"
            class="st3"
            width="1.7"
            height="2.1"
          />

          <rect
            x="262.3"
            y="267.6"
            transform="matrix(0.1026 -0.9947 0.9947 0.1026 -31.0884 502.8358)"
            class="st3"
            width="1.7"
            height="2.1"
          />
          <polygon
            class="st3"
            points="274.5,303.2 273.8,303.2 273.9,302.3 274.2,302.3 274.3,300.9 273.9,300.9 272.5,300.8 272.5,302.2 
				272.4,303.1 272.4,304.5 273.7,304.6 274.4,304.6 			"
          />

          <rect
            x="274.6"
            y="308.4"
            transform="matrix(0.1264 -0.992 0.992 0.1264 -66.5013 543.1877)"
            class="st3"
            width="1.1"
            height="1.9"
          />

          <rect
            x="271.1"
            y="307.6"
            transform="matrix(0.1923 -0.9813 0.9813 0.1923 -83.1122 515.848)"
            class="st3"
            width="1.3"
            height="1.6"
          />

          <rect
            x="271"
            y="304.5"
            transform="matrix(0.1923 -0.9813 0.9813 0.1923 -80.2614 513.2202)"
            class="st3"
            width="1.3"
            height="1.6"
          />
          <polygon
            class="st3"
            points="275,291.1 272.3,291 272.2,292.8 273.1,292.8 273.2,291.9 275,292 			"
          />
          <polygon
            class="st3"
            points="255.5,307.7 251.6,304 249.3,306.5 250.6,307.7 251.8,306.5 254.4,308.9 			"
          />
          <polygon
            class="st3"
            points="255.9,261.1 254.2,261.4 254.1,260.6 249.1,261.6 249.3,262.4 253.4,261.6 253.6,262.5 256.1,262 
							"
          />

          <rect
            x="274.7"
            y="279.8"
            transform="matrix(3.559355e-02 -0.9994 0.9994 3.559355e-02 -15.3351 545.4886)"
            class="st3"
            width="0.4"
            height="1.7"
          />

          <rect
            x="277.6"
            y="297"
            transform="matrix(0.1811 -0.9835 0.9835 0.1811 -64.3013 517.2359)"
            class="st3"
            width="1.7"
            height="0.4"
          />

          <rect
            x="262.4"
            y="273.6"
            transform="matrix(3.557895e-02 -0.9994 0.9994 3.557895e-02 -19.1132 527.7949)"
            class="st3"
            width="3.1"
            height="0.3"
          />

          <rect
            x="269.8"
            y="291.8"
            transform="matrix(3.557895e-02 -0.9994 0.9994 3.557895e-02 -30.0346 552.7461)"
            class="st3"
            width="3.1"
            height="0.3"
          />

          <rect
            x="276.1"
            y="278.6"
            transform="matrix(3.561085e-02 -0.9994 0.9994 3.561085e-02 -12.6222 545.9698)"
            class="st3"
            width="0.9"
            height="1.9"
          />
          <polygon
            class="st3"
            points="251.6,245.3 253.8,252.5 252.6,252.9 250.3,245.7 			"
          />
          <polygon
            class="st3"
            points="254.3,287.5 250.3,285.8 250.8,284.6 254.8,286.2 			"
          />
          <polygon
            class="st3"
            points="253.3,283.6 249.3,282 249.8,280.7 253.8,282.3 			"
          />
          <polygon
            class="st3"
            points="252.3,279.7 248.3,278.1 248.8,276.8 252.8,278.4 			"
          />
          <polygon
            class="st3"
            points="255.1,291.7 251.1,290.1 251.6,288.8 255.6,290.5 			"
          />
          <polygon
            class="st3"
            points="252.7,274.4 252,273.7 253,272.6 253.7,273.3 			"
          />
          <polygon
            class="st3"
            points="274.9,270.7 274.1,270.1 275.1,269 275.8,269.6 			"
          />

          <rect
            x="247.5"
            y="273.8"
            transform="matrix(0.9912 -0.1324 0.1324 0.9912 -34.1401 35.2851)"
            class="st3"
            width="1.5"
            height="1"
          />
          <polygon
            class="st3"
            points="255.4,285.7 254.6,285 255.5,284 256.3,284.7 			"
          />
          <polygon
            class="st3"
            points="264.3,283.8 263.7,284.5 262.8,283.7 263.1,283.3 261.8,282.1 261.5,282.5 260.3,283.8 261.6,285 
				262.5,285.8 263.9,287 265.1,285.6 265.7,285 			"
          />
          <polygon
            class="st3"
            points="269.6,279 271,282.2 267.8,283.6 266.4,280.4 			"
          />
          <polygon
            class="st3"
            points="260.3,277.3 257.2,276.3 257.4,275.6 255.4,274.9 255.1,275.7 255.1,275.7 254.8,277 256.8,277.6 
				256.8,277.6 259.9,278.5 			"
          />
          <polygon
            class="st3"
            points="260.1,288.8 255.2,294.5 254.2,293.6 259.1,288 			"
          />
          <polygon
            class="st3"
            points="255.4,299.8 261.3,304.4 260.9,305 263.7,307.2 264.1,306.6 265.6,307.7 266.6,306.4 256.4,298.5 
							"
          />
          <polygon
            class="st3"
            points="242.4,259.1 242.2,259.7 240.6,259.1 240.7,258.6 			"
          />
          <polygon
            class="st3"
            points="259,304.5 257.2,307.6 256,306.9 257.8,303.8 			"
          />
          <polygon
            class="st3"
            points="278.7,302.6 278,302.6 276.7,302.6 276.7,305 278,305.1 278,308.8 278.7,308.8 			"
          />
          <polygon
            class="st3"
            points="50.6,25.9 48.8,28.1 47.4,26.9 49.1,24.8 			"
          />
          <polygon
            class="st3"
            points="42.9,22.8 44.3,23.9 44.8,23.2 46.3,24.3 46.8,23.7 45.4,22.5 44,21.4 			"
          />
          <polygon
            class="st3"
            points="41.7,19.4 39.3,22.5 38.3,21.7 40.7,18.6 			"
          />
          <polygon
            class="st3"
            points="43.1,26.5 42.6,27.5 37.6,24.9 38.1,23.8 			"
          />
          <polygon class="st3" points="52,30.9 51,32.1 49.8,31.1 50.8,29.9 			" />
          <polygon
            class="st3"
            points="46.8,28.4 46.2,29.3 45.3,28.6 46,27.8 			"
          />
          <polygon
            class="st3"
            points="46.6,19.9 46.2,19.6 45.3,20.7 46.2,21.4 47,20.4 47.8,21 47,22 48.6,23.3 49.9,21.6 48.3,20.3 
				47.1,19.4 			"
          />
          <polygon
            class="st3"
            points="51.2,23.6 50.4,23.1 49.5,24.2 50.3,24.8 51,23.9 52.4,25.1 52.8,24.5 51.4,23.4 			"
          />
          <polygon
            class="st3"
            points="45.4,19.1 43.9,20.9 42.8,20 44.2,18.2 			"
          />
          <polygon
            class="st3"
            points="43.7,36.5 41.3,38.4 42.1,39.4 44.5,37.4 			"
          />
          <polygon class="st3" points="47.4,38 44.9,39.9 45.7,40.9 48.1,39 			" />
          <polygon
            class="st3"
            points="27,85.4 25.2,96.3 25.9,96.5 25.7,97.5 28.9,98 29,97 31,97.3 32.8,86.4 			"
          />
          <polygon
            class="st3"
            points="8.5,0.5 2.6,7 3.1,7.4 2.5,8 4.4,9.8 4.9,9.1 6.1,10.2 12,3.7 			"
          />
          <polygon
            class="st3"
            points="83.2,189 75.8,191.8 76,192.3 75.3,192.6 76.1,194.8 76.8,194.5 77.3,195.8 84.7,193 			"
          />
          <polygon
            class="st3"
            points="36.9,92.3 37,91.4 33.4,90.8 33.3,91.7 34.1,91.9 34,92.7 33.8,92.7 32.8,98.6 35.6,99.1 36.5,93.1 
				34.4,92.8 34.6,91.9 			"
          />

          <rect
            x="36.2"
            y="88.1"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -58.438 111.6995)"
            class="st3"
            width="0.7"
            height="4.4"
          />

          <rect
            x="38.7"
            y="94.9"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -62.2809 120.2835)"
            class="st3"
            width="2"
            height="3.9"
          />
          <polygon
            class="st3"
            points="38.9,98.8 39,98.2 38.1,98.1 38,99 38.5,99.1 38.2,100.7 41.3,101.2 41.6,99.3 			"
          />

          <rect
            x="42.6"
            y="95.7"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -58.1986 123.8387)"
            class="st3"
            width="2.6"
            height="1"
          />
          <polygon
            class="st3"
            points="42.4,101.8 42.3,102.6 39,102.3 38.8,104.1 43.1,104.5 43.3,101.9 			"
          />
          <polygon
            class="st3"
            points="41.8,92.9 41.7,93.7 40.3,93.5 40.2,94.3 38.2,94 38.1,94.7 40.4,95.1 40.6,94.4 41.5,94.5 
				42.1,94.6 43.2,94.8 43.5,93.1 			"
          />
          <polygon
            class="st3"
            points="42,83.3 42.5,80.3 41.5,80.1 41.8,78.5 43.3,78.8 43.8,75.8 40.8,75.3 40.3,78.3 40.8,78.4 
				40.5,79.9 39.2,79.7 39.4,78.6 37.8,78.3 37.6,79.5 37.6,79.9 36.2,88.3 39.6,88.9 41,89.1 42,89.3 42.9,83.5 			"
          />

          <rect
            x="27.4"
            y="81.3"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -58.5192 96.8917)"
            class="st3"
            width="0.9"
            height="3.2"
          />

          <rect
            x="23.4"
            y="41.4"
            transform="matrix(0.2158 -0.9764 0.9764 0.2158 -21.6063 57.4259)"
            class="st3"
            width="3.1"
            height="1.5"
          />
          <polygon class="st3" points="38.5,44 31.7,41.7 32.2,40.2 39,42.5 			" />
          <polygon
            class="st3"
            points="48.1,48 48.2,47.5 46.5,47.2 46.4,48 47.3,48.1 47.1,49.4 52.8,50.3 53,48.8 			"
          />
          <polygon class="st3" points="29.5,39 26.5,38 26.8,37.2 29.8,38.2 			" />

          <rect
            x="28.8"
            y="80.5"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -56.1568 97.3603)"
            class="st3"
            width="0.9"
            height="2.5"
          />
          <polygon
            class="st3"
            points="35.5,83.3 35,83.2 35.2,82.3 33.5,82 33.4,82.9 31.8,82.6 31.6,83.6 33.2,83.8 33.1,84.8 32.2,84.7 
				32.1,85.5 33.7,85.8 33.9,84.9 34.7,85.1 34.8,84.8 35.2,84.9 			"
          />
          <rect
            x="23"
            y="72.7"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -50.63 87.2623)"
            class="st3"
            width="6.2"
            height="1.5"
          />
          <rect x="22.9" y="96.3" class="st3" width="0.7" height="4" />
          <polygon
            class="st3"
            points="23.8,79 23,82.9 22.9,82.9 22.9,86.9 24.1,86.9 24.1,83.1 24.9,79.2 			"
          />
          <polygon
            class="st3"
            points="29.6,77.5 29.3,77.4 28.3,77.2 28.1,78.5 29,78.7 28.8,80.2 30.8,80.6 31.2,77.7 			"
          />

          <rect
            x="35.7"
            y="79.4"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -49.051 102.8695)"
            class="st3"
            width="0.7"
            height="1.8"
          />
          <polygon
            class="st3"
            points="35.6,77.9 34.2,77.7 34.8,74.3 34,74.2 33.2,74.1 32.4,73.9 32.6,72.5 30.9,72.2 31,71.5 29.7,71.3 
				29.5,72.6 30.5,72.7 30.4,73.6 28.5,73.3 28.1,75.4 32.9,76.1 32.1,80.8 33.7,81 34.1,78.7 35.4,78.9 			"
          />
          <polygon
            class="st3"
            points="76.1,185.2 75.7,183.9 78.9,182.8 78.6,182.1 78.4,181.3 78.1,180.5 79.5,180 78.9,178.4 
				79.6,178.1 79.2,177 78,177.4 78.3,178.3 77.5,178.6 76.9,176.8 74.9,177.4 76.4,182 72,183.5 72.5,185 74.7,184.2 75.2,185.5 
							"
          />
          <polygon
            class="st3"
            points="116.2,130.9 114.9,131.3 113.8,128 113.1,128.3 112.3,128.5 111.5,128.8 111.1,127.4 109.4,127.9 
				109.2,127.2 108,127.6 108.4,128.8 109.4,128.5 109.6,129.4 107.8,129.9 108.4,131.9 112.9,130.5 114.4,135 115.9,134.5 
				115.2,132.2 116.5,131.8 			"
          />
          <polygon
            class="st3"
            points="33,69.8 32.7,71.4 33.5,71.6 33.2,73.2 34,73.3 34.3,71.7 34.4,70.9 34.6,70.1 			"
          />

          <rect
            x="26.9"
            y="68.5"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -43.7355 86.1155)"
            class="st3"
            width="3.9"
            height="0.7"
          />

          <rect
            x="41.1"
            y="71.7"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -35.7815 101.8455)"
            class="st3"
            width="2"
            height="0.5"
          />

          <rect
            x="36.8"
            y="72.6"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -41.3283 98.2253)"
            class="st3"
            width="0.8"
            height="1.8"
          />

          <rect
            x="33.8"
            y="69.8"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -39.7018 94.125)"
            class="st3"
            width="3.6"
            height="1.3"
          />

          <rect
            x="38.6"
            y="68.5"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -36.0109 96.6339)"
            class="st3"
            width="0.7"
            height="2.1"
          />

          <rect
            x="40.5"
            y="67.6"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -33.1463 97.6388)"
            class="st3"
            width="0.8"
            height="1.5"
          />

          <rect
            x="44"
            y="71.7"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -33.4812 106.0567)"
            class="st3"
            width="3.4"
            height="2.1"
          />
          <polygon
            class="st3"
            points="44.4,80.3 44.3,80.9 43.4,80.8 43.2,81.8 44.1,82 44.1,82.4 45.1,82.5 44.7,84.8 45.7,84.9 
				46.1,82.7 46.2,82 46.4,80.7 			"
          />

          <rect
            x="43.7"
            y="91.2"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 -53.4062 120.2765)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="29.4"
            y="99.6"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -73.7157 113.1432)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="43.1"
            y="88.1"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 -50.5433 118.1144)"
            class="st3"
            width="2.5"
            height="1.5"
          />
          <polygon
            class="st3"
            points="28.6,100.8 26.8,102.8 26.1,102.2 27.9,100.2 			"
          />
          <polygon
            class="st3"
            points="32.2,101.1 30.3,103.1 29.7,102.5 31.5,100.5 			"
          />
          <polygon
            class="st3"
            points="35.8,101.4 33.9,103.4 33.2,102.8 35.1,100.8 			"
          />
          <polygon
            class="st3"
            points="242.4,144.6 246.8,154.7 247.4,154.5 247.9,155.4 250.8,154.1 250.4,153.2 252.2,152.4 247.8,142.3 
							"
          />
          <polygon
            class="st3"
            points="270.1,159 270.8,158.4 268.4,155.7 267.8,156.2 268.3,156.9 267.6,157.4 267.5,157.3 262.9,161.2 
				264.7,163.3 269.3,159.4 267.9,157.8 268.6,157.2 			"
          />

          <rect
            x="256.3"
            y="149.5"
            transform="matrix(2.055505e-02 -0.9998 0.9998 2.055505e-02 99.7665 405.1871)"
            class="st3"
            width="0.7"
            height="4.4"
          />
          <polygon
            class="st3"
            points="274.5,151 276.9,154 275.3,155.2 272.9,152.2 			"
          />
          <polygon
            class="st3"
            points="276.8,152.2 276.3,152.6 276.9,153.3 277.7,152.7 277.3,152.3 278.6,151.3 276.7,148.9 275.2,150 
							"
          />
          <polygon
            class="st3"
            points="272.2,149.6 272.7,150.3 270.7,151.9 270.1,151.2 			"
          />
          <polygon
            class="st3"
            points="276.3,147.7 276.9,147.1 279.2,149.6 280.5,148.4 277.6,145.2 275.7,147 			"
          />
          <polygon
            class="st3"
            points="270.7,154.6 271.3,154.1 272.2,155.2 272.9,154.7 274,156.2 274.7,155.7 273.2,153.9 272.6,154.4 
				272,153.6 271.7,153.2 271,152.3 269.6,153.3 			"
          />
          <polygon
            class="st3"
            points="271.6,142.1 274.7,141.9 274.7,141 276.3,140.9 276.4,142.4 279.4,142.2 279.2,139.2 276.2,139.4 
				276.2,139.9 274.6,140 274.5,138.6 275.7,138.6 275.6,137 274.4,137 274,137.1 265.5,137.6 265.7,141.1 265.8,142.5 265.8,143.5 
				271.7,143.1 			"
          />

          <rect
            x="229.7"
            y="132.8"
            transform="matrix(2.053130e-02 -0.9998 0.9998 2.053130e-02 91.0584 361.813)"
            class="st3"
            width="0.9"
            height="3.2"
          />

          <rect
            x="231"
            y="131.8"
            transform="matrix(2.057835e-02 -0.9998 0.9998 2.057835e-02 93.6536 361.7601)"
            class="st3"
            width="0.9"
            height="2.5"
          />
          <polygon
            class="st3"
            points="247.4,137.4 246.9,137.6 246.6,136.7 245,137.2 245.3,138.1 243.7,138.5 244,139.4 245.5,139 
				245.8,139.9 245,140.1 245.3,141 246.9,140.5 246.6,139.7 247.4,139.4 247.4,139.2 247.8,139.1 			"
          />
          <polygon
            class="st3"
            points="240.2,142.3 238.8,142.6 237.3,136.6 238.8,136.2 			"
          />

          <rect
            x="260.4"
            y="162.9"
            transform="matrix(0.9898 -0.1424 0.1424 0.9898 -20.8196 38.8162)"
            class="st3"
            width="0.7"
            height="4"
          />
          <polygon
            class="st3"
            points="228.7,137 230.2,140.7 230.1,140.8 232.3,144.1 233.3,143.4 231.2,140.4 229.8,136.6 			"
          />
          <polygon
            class="st3"
            points="240.1,146.8 239.8,146.8 238.8,146.8 238.8,148.1 239.7,148.1 239.7,149.7 241.7,149.7 241.7,146.9 
							"
          />
          <polygon
            class="st3"
            points="258.4,146.5 257.2,147.9 256.7,147.4 257.9,146.1 			"
          />
          <polygon
            class="st3"
            points="263.6,144.6 262.3,144.6 262.4,141.2 261.6,141.2 260.8,141.1 259.9,141.1 259.9,139.7 258.2,139.7 
				258.2,138.9 256.9,138.9 256.9,140.2 257.9,140.2 257.9,141.1 256,141 255.9,143.1 260.7,143.2 260.6,147.9 262.2,148 
				262.3,145.6 263.6,145.6 			"
          />
          <polygon
            class="st3"
            points="250.2,131.9 250.2,133.6 251,133.6 250.9,135.2 251.7,135.3 251.8,133.6 251.8,132.8 251.8,132 			
				"
          />
          <polygon
            class="st3"
            points="253,144.4 251.9,144.7 250.7,141 251.9,140.6 			"
          />

          <rect
            x="258.6"
            y="132.5"
            transform="matrix(2.042664e-02 -0.9998 0.9998 2.042664e-02 121.4851 389.5827)"
            class="st3"
            width="2"
            height="0.5"
          />

          <rect
            x="254.5"
            y="134"
            transform="matrix(2.055496e-02 -0.9998 0.9998 2.055496e-02 114.7605 387.0217)"
            class="st3"
            width="0.8"
            height="1.8"
          />

          <rect
            x="251.1"
            y="131.5"
            transform="matrix(2.051407e-02 -0.9998 0.9998 2.051407e-02 115.5463 382.3182)"
            class="st3"
            width="3.6"
            height="1.3"
          />

          <rect
            x="214.2"
            y="32.3"
            transform="matrix(2.052667e-02 -0.9998 0.9998 2.052667e-02 179.8221 249.676)"
            class="st3"
            width="6.2"
            height="1.5"
          />

          <rect
            x="211.1"
            y="23.3"
            transform="matrix(2.052667e-02 -0.9998 0.9998 2.052667e-02 185.8126 237.7273)"
            class="st3"
            width="6.2"
            height="1.5"
          />
          <polygon
            class="st3"
            points="221.3,36.5 221,36.5 220.1,36.5 220,37.8 221,37.8 221,39.4 222.9,39.4 223,36.5 			"
          />
          <polygon
            class="st3"
            points="235.5,25 235.2,25 234.2,25 234.2,27.3 235.1,27.4 235.1,30.3 237.1,30.3 237.2,25 			"
          />
          <polygon
            class="st3"
            points="266.1,22.9 265.8,22.9 264.8,22.9 264.8,25.3 265.7,25.3 265.7,28.2 267.7,28.3 267.8,23 			"
          />

          <rect
            x="209.6"
            y="31.7"
            transform="matrix(2.055609e-02 -0.9998 0.9998 2.055609e-02 173.0576 241.9202)"
            class="st3"
            width="0.7"
            height="1.8"
          />
          <polygon
            class="st3"
            points="227.3,36.1 226,36.1 226,32.7 225.2,32.7 224.4,32.6 223.6,32.6 223.6,31.2 221.9,31.2 221.9,30.4 
				220.6,30.4 220.6,31.7 221.6,31.7 221.6,32.6 219.7,32.5 219.6,34.6 224.4,34.7 224.3,39.4 225.9,39.5 225.9,37.1 227.3,37.1 			
				"
          />
          <polygon
            class="st3"
            points="223.6,28.5 223.6,30.1 224.4,30.1 224.4,31.8 225.2,31.8 225.2,30.1 225.2,29.3 225.2,28.5 			"
          />

          <rect
            x="217.5"
            y="27.7"
            transform="matrix(2.051532e-02 -0.9998 0.9998 2.051532e-02 186.8489 246.8771)"
            class="st3"
            width="3.9"
            height="0.7"
          />

          <rect
            x="224.5"
            y="28.1"
            transform="matrix(2.051541e-02 -0.9998 0.9998 2.051541e-02 192.9539 254.4119)"
            class="st3"
            width="3.6"
            height="1.3"
          />

          <rect
            x="255.7"
            y="129.7"
            transform="matrix(2.055429e-02 -0.9998 0.9998 2.055429e-02 120.0142 384.1146)"
            class="st3"
            width="0.7"
            height="2.1"
          />

          <rect
            x="257.5"
            y="128.6"
            transform="matrix(2.059351e-02 -0.9998 0.9998 2.059351e-02 123.2641 384.5135)"
            class="st3"
            width="0.8"
            height="1.5"
          />

          <rect
            x="262.8"
            y="131.4"
            transform="matrix(2.053544e-02 -0.9998 0.9998 2.053544e-02 126.6393 394.1197)"
            class="st3"
            width="3.4"
            height="2.1"
          />
          <polygon
            class="st3"
            points="284.7,144.6 284.2,145 283.6,144.2 282.8,144.9 283.4,145.6 283,145.9 283.7,146.7 281.9,148.1 
				282.5,148.9 284.3,147.5 284.8,147.1 285.9,146.2 			"
          />

          <rect
            x="270.5"
            y="144.8"
            transform="matrix(2.061532e-02 -0.9998 0.9998 2.061532e-02 120.2007 413.1401)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="252.9"
            y="156.8"
            transform="matrix(2.050410e-02 -0.9998 0.9998 2.050410e-02 90.9882 407.2787)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="266.4"
            y="146.4"
            transform="matrix(2.059351e-02 -0.9998 0.9998 2.059351e-02 114.9481 411.7175)"
            class="st3"
            width="2.5"
            height="1.5"
          />
          <polygon
            class="st3"
            points="251.6,163 249,163.7 248.7,162.8 251.3,162.1 			"
          />
          <polygon
            class="st3"
            points="254.5,165.2 251.8,165.9 251.6,165 254.2,164.3 			"
          />
          <polygon
            class="st3"
            points="243.9,152.7 241.2,153.4 241,152.5 243.6,151.8 			"
          />
          <polygon
            class="st3"
            points="257.3,167.3 254.7,168.1 254.5,167.2 257.1,166.4 			"
          />

          <rect
            x="260.3"
            y="152.9"
            transform="matrix(2.061532e-02 -0.9998 0.9998 2.061532e-02 102.0273 410.9342)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="263.9"
            y="126.5"
            transform="matrix(2.055505e-02 -0.9998 0.9998 2.055505e-02 130.1526 390.3591)"
            class="st3"
            width="0.7"
            height="4.4"
          />

          <rect
            x="267.9"
            y="130"
            transform="matrix(2.061532e-02 -0.9998 0.9998 2.061532e-02 132.4129 396.1075)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="259.7,154.3 258.1,156.6 257.4,156.1 258.9,153.8 			"
          />
          <polygon
            class="st3"
            points="263.3,154.1 261.7,156.4 261,155.9 262.5,153.6 			"
          />
          <polygon
            class="st3"
            points="266.9,153.9 265.3,156.2 264.6,155.7 266.1,153.4 			"
          />

          <rect
            x="241"
            y="132.6"
            transform="matrix(0.9976 -6.920367e-02 6.920367e-02 0.9976 -8.626 17.0283)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="242.4,132 243.8,129.6 244.6,130 243.2,132.4 			"
          />
          <polygon
            class="st3"
            points="238.9,132.5 240.2,130.1 241,130.6 239.7,132.9 			"
          />
          <polygon
            class="st3"
            points="235.3,133 236.7,130.6 237.5,131.1 236.1,133.5 			"
          />

          <rect
            x="184.4"
            y="2.9"
            transform="matrix(0.9761 -0.2173 0.2173 0.9761 3.6816 40.2473)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="184,4.6 183.1,7.1 182.2,6.8 183.2,4.2 			"
          />
          <polygon
            class="st3"
            points="187.5,3.5 186.5,6.1 185.6,5.7 186.6,3.2 			"
          />
          <polygon
            class="st3"
            points="190.9,2.5 189.9,5 189.1,4.7 190.1,2.1 			"
          />
          <polygon
            class="st3"
            points="193.9,22.8 194.1,21.9 190.5,21.4 190.4,22.2 191.2,22.4 191,23.2 190.8,23.2 189.9,29.2 
				192.6,29.6 193.6,23.7 191.5,23.3 191.6,22.5 			"
          />

          <rect
            x="195.8"
            y="25.4"
            transform="matrix(0.1627 -0.9867 0.9867 0.1627 137.778 217.081)"
            class="st3"
            width="2"
            height="3.9"
          />
          <polygon
            class="st3"
            points="196,29.4 196.1,28.7 195.2,28.6 195,29.5 195.5,29.6 195.3,31.2 198.3,31.7 198.7,29.8 			"
          />

          <rect
            x="199.6"
            y="26.3"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 141.8578 220.6342)"
            class="st3"
            width="2.6"
            height="1"
          />
          <polygon
            class="st3"
            points="199.4,32.3 199.4,33.1 196,32.8 195.9,34.6 200.2,35 200.4,32.4 			"
          />
          <polygon
            class="st3"
            points="198.7,19.6 198.5,20.4 197.1,20.2 197,21 195.1,20.7 195,21.5 197.3,21.8 197.4,21.1 198.4,21.2 
				198.9,21.3 200,21.5 200.3,19.9 			"
          />

          <rect
            x="200.8"
            y="21.7"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 146.6447 217.0753)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="77.5,91.8 77.4,93.8 74.6,93.6 75,87.5 70.5,87.3 70,96.7 74.4,97 74.5,95.9 77.2,96 77.2,97.2 
				81.5,97.4 81.8,92.1 			"
          />
          <polygon
            class="st3"
            points="168.2,146.9 167.6,147.8 166.3,147 168.2,144.2 166.1,142.8 163.3,147.2 165.3,148.6 165.7,148 
				166.9,148.9 166.6,149.4 168.6,150.7 170.2,148.2 			"
          />
          <polygon
            class="st3"
            points="73.5,75.1 74.5,75.1 74.4,77 75.4,77 75.5,75.2 75.8,70 73.8,69.8 			"
          />
          <polygon
            class="st3"
            points="69.9,78.7 71,78.8 73,78.9 73.1,76.8 71.1,76.7 71.3,73.5 70.2,73.5 			"
          />

          <rect
            x="86.8"
            y="62.2"
            transform="matrix(5.926992e-02 -0.9982 0.9982 5.926992e-02 18.8105 147.625)"
            class="st3"
            width="1.8"
            height="3.3"
          />
          <polygon
            class="st3"
            points="82.8,64.4 82.2,64.4 82,68.3 83.2,68.4 83.4,65.4 84.3,65.5 84.5,61.9 83,61.8 			"
          />
          <polygon
            class="st3"
            points="78.6,84.3 78.6,83.8 73.2,83.5 73.1,84.5 77.2,84.8 77.1,85.6 82.1,85.9 82.2,84.6 			"
          />

          <rect
            x="85"
            y="65.8"
            transform="matrix(5.924589e-02 -0.9982 0.9982 5.924589e-02 14.2626 148.1846)"
            class="st3"
            width="1.4"
            height="1.4"
          />

          <rect
            x="87"
            y="81.7"
            transform="matrix(6.872242e-02 -0.9976 0.9976 6.872242e-02 -2.7234 166.8051)"
            class="st3"
            width="1.9"
            height="6.3"
          />
          <polygon
            class="st3"
            points="72.9,60.3 74.6,60.6 74.5,61.3 76.2,61.6 77,57.3 75.2,56.9 74.9,59.1 73.1,58.8 			"
          />
          <polygon
            class="st3"
            points="80.2,72.7 80.5,65.3 81.2,65.3 81.3,61.9 80.6,61.8 80.6,60.1 79,60 78.6,72.7 			"
          />

          <rect
            x="54.6"
            y="62.6"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 -18.6677 105.1059)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="55.3"
            y="59.4"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 -15.0392 103.2275)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="55.9"
            y="56.2"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 -11.4101 101.3502)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <rect
            x="56.6"
            y="53"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 -7.7813 99.4723)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <polygon
            class="st3"
            points="177.2,236.6 179,235.5 179.3,236.1 177.5,237.1 			"
          />
          <polygon
            class="st3"
            points="176.2,234.9 178,233.8 178.5,234.6 176.7,235.6 			"
          />
          <polygon
            class="st3"
            points="171.4,234.4 175.1,232.2 175.8,233.4 172.1,235.6 			"
          />
          <polygon
            class="st3"
            points="168.5,234.8 170.3,232.1 171.1,232.7 169.4,235.3 			"
          />

          <rect
            x="179.9"
            y="232.4"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -41.9102 39.547)"
            class="st3"
            width="2.9"
            height="3.7"
          />

          <rect
            x="180.2"
            y="225.9"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -40.7004 39.3683)"
            class="st3"
            width="1.9"
            height="4"
          />
          <polygon
            class="st3"
            points="176.9,211.3 177.6,214.9 179.1,214.6 178.4,211 178.4,211 178,208.9 174.9,209.5 175.3,211.6 			"
          />
          <polygon
            class="st3"
            points="167.6,210.1 169.2,211.1 166.9,214.5 165.4,213.5 			"
          />
          <polygon
            class="st3"
            points="168.9,214.6 170.4,215.7 168.2,219 166.7,218 			"
          />
          <polygon
            class="st3"
            points="161.3,214.9 162.9,215.9 160.7,219.3 159.1,218.3 			"
          />

          <rect
            x="173.5"
            y="236.6"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -42.6555 38.3586)"
            class="st3"
            width="2.9"
            height="1.8"
          />

          <rect
            x="170.1"
            y="238.2"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -43.0024 37.6745)"
            class="st3"
            width="2.2"
            height="1.3"
          />
          <polygon
            class="st3"
            points="199,225.6 199.6,223.6 200.2,223.7 199.6,225.7 			"
          />
          <polygon
            class="st3"
            points="197.1,225 197.7,223 198.5,223.3 198,225.3 			"
          />
          <polygon
            class="st3"
            points="188,235.8 189.1,231.6 190.4,232 189.3,236.2 			"
          />
          <polygon
            class="st3"
            points="198.1,221.1 199.8,218.7 202.8,220.8 201.1,223.2 			"
          />
          <polygon
            class="st3"
            points="196.4,228.5 198.1,226 199.5,227.1 197.8,229.5 			"
          />
          <polygon
            class="st3"
            points="192,241.4 193.3,239.5 194.4,240.3 193.1,242.1 			"
          />
          <polygon
            class="st3"
            points="193,237.5 194.3,235.6 195.4,236.4 194.1,238.2 			"
          />
          <polygon
            class="st3"
            points="194,233.5 195.3,231.7 196.4,232.5 195.1,234.3 			"
          />

          <rect
            x="179.3"
            y="223.7"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -39.9788 39.0671)"
            class="st3"
            width="1.2"
            height="0.8"
          />
          <polygon
            class="st3"
            points="162.3,222.9 162.6,223.7 161.5,224.2 161.3,223.8 159.6,224.4 159.8,224.9 160.5,226.5 162.1,225.9 
				163.2,225.4 164.9,224.7 164.2,223.1 163.9,222.3 			"
          />

          <rect
            x="175.5"
            y="228.8"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -41.0796 38.5589)"
            class="st3"
            width="2.5"
            height="1.4"
          />

          <rect
            x="167.9"
            y="229"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -41.2746 37.0821)"
            class="st3"
            width="2.5"
            height="1.4"
          />

          <rect
            x="163.5"
            y="227.5"
            transform="matrix(0.9813 -0.1927 0.1927 0.9813 -40.9262 36.0032)"
            class="st3"
            width="2.1"
            height="1.7"
          />
          <polygon
            class="st3"
            points="161.5,208.7 161.8,209.7 159.2,210.7 158.8,209.6 			"
          />
          <polygon
            class="st3"
            points="172.7,227.4 175.4,226.8 175,225.1 174.2,225.3 174.3,226.1 172.5,226.5 			"
          />
          <polygon
            class="st3"
            points="170.5,223.4 171.3,221.6 172.1,222 174.7,216.7 173.8,216.3 171.7,220.7 170.7,220.2 169.4,222.9 
							"
          />

          <rect
            x="172.1"
            y="215.2"
            transform="matrix(0.9811 -0.1937 0.1937 0.9811 -38.4714 37.5263)"
            class="st3"
            width="1.2"
            height="0.7"
          />

          <rect
            x="170.5"
            y="224.7"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -40.3093 37.431)"
            class="st3"
            width="1.7"
            height="0.4"
          />

          <rect
            x="171.5"
            y="230"
            transform="matrix(0.9989 -4.729087e-02 4.729087e-02 0.9989 -10.7231 8.3975)"
            class="st3"
            width="1.2"
            height="1.7"
          />

          <rect
            x="197.3"
            y="203"
            transform="matrix(0.2173 -0.9761 0.9761 0.2173 -44.556 353.1808)"
            class="st3"
            width="1.4"
            height="2.7"
          />

          <rect
            x="192.5"
            y="205.9"
            transform="matrix(0.1129 -0.9936 0.9936 0.1129 -33.619 375.2111)"
            class="st3"
            width="1.6"
            height="1"
          />
          <rect
            x="180.7"
            y="198"
            transform="matrix(0.113 -0.9936 0.9936 0.113 -36.2403 356.4058)"
            class="st3"
            width="1.6"
            height="1"
          />
          <polygon
            class="st3"
            points="166.8,200.4 165.6,199.3 166.3,198.6 167.4,199.7 			"
          />
          <polygon
            class="st3"
            points="169.8,200.4 168.7,199.3 169.3,198.6 170.5,199.7 			"
          />

          <rect
            x="178.7"
            y="195.7"
            transform="matrix(0.1129 -0.9936 0.9936 0.1129 -35.6467 352.4018)"
            class="st3"
            width="1.6"
            height="1"
          />
          <polygon
            class="st3"
            points="198.6,207.8 200.9,206 202,207.4 199.7,209.2 			"
          />
          <polygon
            class="st3"
            points="196.4,210.7 198.1,209.3 198.9,210.4 197.2,211.7 			"
          />
          <polygon
            class="st3"
            points="176,200.1 177.8,198.7 178.6,199.8 176.9,201.2 			"
          />
          <polygon
            class="st3"
            points="191.3,201.2 189.3,202.8 188.4,201.7 190.4,200.2 			"
          />
          <polygon
            class="st3"
            points="193.3,200.1 192.5,198.6 193.5,198 194.4,199.5 			"
          />
          <polygon
            class="st3"
            points="164.3,221 163.9,221.9 162,221.2 162.4,220.2 			"
          />
          <polygon
            class="st3"
            points="176.7,225.5 177.3,224.7 179.7,226.6 179.1,227.4 			"
          />

          <rect
            x="169.1"
            y="226"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -40.6244 37.2067)"
            class="st3"
            width="1.9"
            height="0.9"
          />

          <rect
            x="165.6"
            y="231.1"
            transform="matrix(0.9813 -0.1927 0.1927 0.9813 -42.142 36.4355)"
            class="st3"
            width="1.3"
            height="7.5"
          />
          <polygon
            class="st3"
            points="145.6,217.5 146.6,217.2 147.6,221.1 146.6,221.4 			"
          />
          <polygon
            class="st3"
            points="145.2,215.8 150.1,213.7 150.3,214.3 145.5,216.4 			"
          />
          <polygon
            class="st3"
            points="158.7,222.6 153.9,224.3 153.7,223.9 158.6,222.1 			"
          />

          <rect
            x="154.5"
            y="225.7"
            transform="matrix(0.9873 -0.1587 0.1587 0.9873 -34.1053 27.4955)"
            class="st3"
            width="1.1"
            height="3.1"
          />
          <polygon
            class="st3"
            points="155.1,216.2 155.8,220 157.3,219.8 156.7,216 157.7,215.8 157.4,213.7 152.5,214.5 152.9,216.6 			
				"
          />
          <polygon
            class="st3"
            points="150.6,215.7 151.3,215.4 151.6,216.1 150.9,216.3 			"
          />

          <rect
            x="168.2"
            y="219.9"
            transform="matrix(0.9873 -0.1587 0.1587 0.9873 -32.8142 29.5347)"
            class="st3"
            width="0.7"
            height="0.7"
          />
          <polygon
            class="st3"
            points="150,218.5 150.4,218.3 150.7,219.1 150.3,219.2 			"
          />

          <rect
            x="176.8"
            y="216.2"
            transform="matrix(0.9873 -0.1586 0.1586 0.9873 -32.1121 30.8076)"
            class="st3"
            width="0.4"
            height="0.9"
          />
          <polygon
            class="st3"
            points="157.6,229.5 159.8,228.6 160.2,229.4 158,230.4 			"
          />
          <polygon
            class="st3"
            points="161,231.9 160.8,231.4 160.6,230.6 159.8,230.9 160,231.7 158.8,232.1 158.9,232.5 			"
          />
          <polygon
            class="st3"
            points="160,233.2 160.4,235.1 160.9,234.9 161.3,236.9 161.9,236.8 161.5,234.8 161.1,233 			"
          />

          <rect
            x="159.5"
            y="237.7"
            transform="matrix(0.9873 -0.1587 0.1587 0.9873 -35.7544 28.5402)"
            class="st3"
            width="2.6"
            height="0.9"
          />

          <rect
            x="163.4"
            y="233.5"
            transform="matrix(0.9811 -0.1935 0.1935 0.9811 -42.4659 36.1561)"
            class="st3"
            width="0.8"
            height="3.9"
          />
          <polygon
            class="st3"
            points="162.6,230.3 163.6,230.7 163.2,231.6 162.2,231.2 			"
          />
          <polygon
            class="st3"
            points="158.7,236.4 159.1,236.8 160.2,235.6 159.4,234.9 158.4,236 157.7,235.4 158.8,234.3 157.3,233 
				155.7,234.9 157.1,236.2 158.1,237.1 			"
          />
          <polygon
            class="st3"
            points="138.8,223.7 139.2,224 140.3,222.8 139.5,222.1 138.5,223.2 137.9,222.7 138.9,221.5 137.5,220.2 
				135.8,222.1 137.3,223.4 138.3,224.3 			"
          />
          <polygon
            class="st3"
            points="156.7,231.4 156.8,232.1 157.7,231.9 157.6,231.3 156.9,231.4 156.7,230.2 156.2,230.2 156.4,231.5 
							"
          />
          <polygon
            class="st3"
            points="180,220.5 179.9,220.1 179.5,220.2 179.3,219.3 178.4,219.5 178.6,220.4 175.9,221.1 176.1,221.9 
				179.3,221.1 179.5,221.8 180.6,221.5 180.3,220.4 			"
          />
          <polygon
            class="st3"
            points="172.5,213 172.6,213.4 173.5,213.2 173.4,212.4 172.5,212.6 172.4,212 173.2,211.8 172.9,210.4 
				171.5,210.7 171.8,212.1 172.1,213.1 			"
          />

          <rect
            x="170.5"
            y="210"
            transform="matrix(0.9873 -0.1588 0.1588 0.9873 -31.2333 29.7906)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="187.6,226.7 187.9,226 189.7,226.9 189.4,227.5 			"
          />
          <polygon
            class="st3"
            points="185,231.2 185.7,231.1 187,230.9 186.6,228.4 185.3,228.6 184.8,224.9 184.1,225 			"
          />
          <polygon
            class="st3"
            points="90.1,202.4 88.5,201.2 88.8,200.7 90.5,201.9 			"
          />
          <polygon
            class="st3"
            points="89,204 87.3,202.8 87.8,202.1 89.5,203.3 			"
          />
          <polygon
            class="st3"
            points="90.4,208.7 87,206.1 87.8,205 91.2,207.6 			"
          />

          <rect
            x="89.7"
            y="209"
            transform="matrix(0.179 -0.9839 0.9839 0.179 -133.1616 263.0678)"
            class="st3"
            width="2.6"
            height="4.7"
          />
          <polygon
            class="st3"
            points="85.6,201.8 84,199.3 87,197.2 88.7,199.7 			"
          />
          <polygon
            class="st3"
            points="69.2,208.9 68.3,207.2 71.9,205.4 72.8,207 			"
          />
          <polygon
            class="st3"
            points="91.9,205.9 90.3,203.5 91.7,202.5 93.4,204.9 			"
          />
          <polygon
            class="st3"
            points="94.6,208.4 93.4,206.5 94.5,205.7 95.7,207.6 			"
          />
          <polygon
            class="st3"
            points="77.5,232 78.4,232 78.3,234.1 77.8,234.1 77.7,237.3 78.1,237.3 79.9,237.4 80.1,234.2 80.2,232.1 
				80.3,228.9 78.5,228.8 77.6,228.8 			"
          />
          <polygon
            class="st3"
            points="78.5,214.4 79.7,216.6 78.5,217.2 77.3,215 			"
          />
          <polygon
            class="st3"
            points="78.9,217.8 80.1,220.1 78.9,220.7 77.7,218.5 			"
          />
          <polygon
            class="st3"
            points="79.3,221.3 80.5,223.5 79.3,224.2 78.1,221.9 			"
          />
          <polygon
            class="st3"
            points="79.7,224.8 80.9,227 79.7,227.6 78.5,225.4 			"
          />
          <polygon
            class="st3"
            points="78.5,198.6 79.7,200.8 78.5,201.4 77.3,199.2 			"
          />
          <polygon
            class="st3"
            points="86.9,211.2 88.3,213.2 87.2,214 85.8,211.9 			"
          />
          <polygon
            class="st3"
            points="86.2,216.9 87.7,215.9 88.9,217.7 87.4,218.6 			"
          />
          <polygon
            class="st3"
            points="82.3,202.8 79.1,204.7 80.3,206.8 81.3,206.2 80.7,205.1 82.9,203.9 			"
          />
          <polygon
            class="st3"
            points="87.9,209.6 86.3,210.3 85.8,209.2 87.4,208.5 			"
          />
          <polygon
            class="st3"
            points="64.7,212.6 63.8,212.2 66.6,205.3 67.5,205.6 			"
          />
          <polygon
            class="st3"
            points="82.5,211.1 81.6,209.4 82.4,209 83.3,210.7 			"
          />
          <rect x="84.9" y="236.7" class="st3" width="5.2" height="1.3" />
          <polygon
            class="st3"
            points="91.4,223.3 89.6,221.6 90.2,220.9 92,222.6 			"
          />
          <polygon
            class="st3"
            points="92.2,219.3 91.8,219.6 91.2,220.1 91.7,220.8 92.4,220.2 93.2,221.2 93.6,220.9 			"
          />
          <polygon
            class="st3"
            points="93.8,219.6 95.4,218.6 95,218.1 96.7,216.9 96.3,216.5 94.7,217.6 93.1,218.7 			"
          />
          <polygon
            class="st3"
            points="98.5,216.2 97.1,213.9 99.4,212.5 100.7,214.8 			"
          />
          <polygon
            class="st3"
            points="93,216.7 92.5,216 95.7,213.8 96.1,214.5 			"
          />
          <rect
            x="90.1"
            y="217.3"
            transform="matrix(1 -2.480908e-03 2.480908e-03 1 -0.5401 0.2254)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="97.3,219.6 97.5,219.1 95.9,218.6 95.5,219.6 97,220.1 96.7,220.8 95.3,220.4 94.7,222.2 97,223 
				97.6,221.2 98.1,219.9 			"
          />
          <polygon
            class="st3"
            points="155.2,248.8 155.6,247.6 152.2,246.5 151.4,248.7 154.6,249.8 154,251.5 150.8,250.4 149.4,254.5 
				154.7,256.2 156,252.2 157,249.4 			"
          />
          <polygon
            class="st3"
            points="93.5,223.4 94,223.1 93.5,222.2 93,222.6 93.4,223.2 92.3,223.9 92.5,224.3 93.6,223.6 			"
          />
          <polygon
            class="st3"
            points="75.3,206 74.6,206.2 73.4,206.6 74.2,208.9 75.4,208.5 76.6,212.1 77.2,211.9 			"
          />
          <polygon
            class="st3"
            points="185.8,220.1 184.5,222.7 183.2,222.1 184.5,219.4 			"
          />

          <rect
            x="92.8"
            y="84"
            transform="matrix(8.391229e-02 -0.9965 0.9965 8.391229e-02 1.2297 171.0601)"
            class="st3"
            width="1.7"
            height="1.7"
          />

          <rect
            x="102.6"
            y="71.3"
            transform="matrix(0.9969 -7.804824e-02 7.804824e-02 0.9969 -5.3008 8.4655)"
            class="st3"
            width="6.2"
            height="1.5"
          />
          <polygon
            class="st3"
            points="84.7,74.5 86.1,75 83.8,80.8 82.5,80.2 			"
          />
          <polygon
            class="st3"
            points="110.5,82.5 110.6,82.9 110.6,83.8 111.9,83.7 111.8,82.8 113.4,82.6 113.3,80.6 110.4,80.9 			"
          />
          <polygon
            class="st3"
            points="52.4,70.1 52.4,70.4 52.3,71 53.9,71.3 54,70.6 56.1,70.9 56.3,69.5 52.6,69 			"
          />
          <polygon
            class="st3"
            points="79.9,74.4 81.5,75.1 81.3,75.7 79.6,75.1 			"
          />
          <polygon
            class="st3"
            points="109.5,76.6 109.6,78 106.3,78.2 106.3,79 106.4,79.8 106.5,80.7 105,80.8 105.2,82.5 104.5,82.6 
				104.6,83.8 105.8,83.7 105.7,82.7 106.6,82.6 106.8,84.6 108.8,84.4 108.5,79.6 113.2,79.3 113,77.7 110.7,77.9 110.5,76.5 			
				"
          />
          <polygon
            class="st3"
            points="102.3,81 103.9,80.9 103.9,80.1 105.5,80 105.5,79.2 103.8,79.3 103,79.4 102.2,79.4 			"
          />
          <polygon
            class="st3"
            points="104.3,84.3 103.6,85.2 98.7,81.7 99.4,80.7 			"
          />

          <rect
            x="100.5"
            y="77.7"
            transform="matrix(0.9969 -7.807602e-02 7.807602e-02 0.9969 -5.8032 8.2277)"
            class="st3"
            width="3.6"
            height="1.3"
          />

          <rect
            x="87.8"
            y="69.9"
            transform="matrix(8.081633e-03 -1 1 8.081633e-03 18.1199 160.0378)"
            class="st3"
            width="3.9"
            height="2"
          />
          <polygon
            class="st3"
            points="91.8,71.4 91.2,71.4 91.2,72.3 92.1,72.3 92.1,71.8 93.7,71.8 93.8,68.7 91.9,68.7 			"
          />
          <polygon
            class="st3"
            points="100,69.1 100.8,69.6 99.5,71.9 98.7,71.4 			"
          />
          <polygon
            class="st3"
            points="94.2,67.5 95,67.5 95.2,70.8 97,70.7 96.7,66.4 94.1,66.6 			"
          />
          <polygon
            class="st3"
            points="91.9,81.2 92.4,80.5 93.6,81.2 94,80.4 95.7,81.4 96.1,80.7 94,79.6 93.6,80.2 92.8,79.7 92.3,79.5 
				91.3,78.9 90.5,80.4 			"
          />
          <polygon
            class="st3"
            points="90.2,77.3 91,77.8 90.6,78.5 89.8,78.1 			"
          />
          <polygon
            class="st3"
            points="192.8,31.9 191,33.9 190.3,33.3 192.2,31.3 			"
          />
          <polygon
            class="st3"
            points="23.8,49.4 24.1,48.4 26,48.9 25.7,49.9 			"
          />
          <rect
            x="22.4"
            y="50.6"
            transform="matrix(0.2402 -0.9707 0.9707 0.2402 -32.2476 61.0651)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon class="st3" points="23,54.8 22.8,55.8 21.8,55.5 22,54.5 			" />
          <polygon
            class="st3"
            points="23.1,57.7 22.8,59 21.5,58.7 21.9,57.4 			"
          />
          <polygon
            class="st3"
            points="24.9,59.6 25.1,58.9 26.4,59.2 26.3,59.9 			"
          />

          <rect
            x="26.9"
            y="52.7"
            transform="matrix(0.2402 -0.9707 0.9707 0.2402 -30.5155 67.098)"
            class="st3"
            width="1.4"
            height="0.7"
          />
          <polygon
            class="st3"
            points="48.8,54.9 49.1,53.9 51,54.3 50.7,55.3 			"
          />
          <polygon
            class="st3"
            points="47.3,56.9 47.5,56 48.5,56.2 48.3,57.2 			"
          />
          <polygon class="st3" points="48,60.2 47.8,61.2 46.8,60.9 47.1,60 			" />
          <polygon
            class="st3"
            points="48.2,63.2 47.8,64.5 46.5,64.2 46.9,62.9 			"
          />
          <polygon
            class="st3"
            points="48.8,62 48.9,61.3 50.3,61.7 50.1,62.3 			"
          />
          <polygon
            class="st3"
            points="194,121 192.9,119.5 196.2,117.1 197.2,118.7 			"
          />
          <polygon
            class="st3"
            points="203.9,118.9 202.8,117.4 206.1,115.1 207.2,116.6 			"
          />
          <polygon
            class="st3"
            points="210.8,120 211.7,118.3 215.2,120.1 214.4,121.8 			"
          />
          <polygon
            class="st3"
            points="209.5,106.8 210.4,106.5 210.7,107.7 210.3,107.8 210.9,109.5 211.3,109.4 213,108.8 212.5,107.1 
				212.1,106 211.5,104.2 209.8,104.8 209,105.1 			"
          />

          <rect
            x="198.9"
            y="117.2"
            transform="matrix(0.182 -0.9833 0.9833 0.182 47.1799 292.1923)"
            class="st3"
            width="0.7"
            height="1.2"
          />
          <polygon
            class="st3"
            points="197.7,142.4 196.7,142.7 195.3,137.8 196.3,137.5 			"
          />
          <polygon
            class="st3"
            points="195.2,144.2 190.2,145.7 190,145 195,143.6 			"
          />
          <polygon
            class="st3"
            points="190.2,136.2 195.1,135 195.4,135.8 190.4,137 			"
          />

          <rect
            x="213.1"
            y="132.5"
            transform="matrix(8.392512e-02 -0.9965 0.9965 8.392512e-02 63.6598 335.0567)"
            class="st3"
            width="1.9"
            height="0.9"
          />

          <rect
            x="215.6"
            y="119.1"
            transform="matrix(8.392636e-02 -0.9965 0.9965 8.392636e-02 79.1876 325.2832)"
            class="st3"
            width="1.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="207.9,131.6 208.2,132.1 206.7,133.2 206.3,132.7 			"
          />
          <polygon
            class="st3"
            points="203.2,129.9 202.5,130 203,132.5 203.8,132.4 203.4,130.6 205.7,130.1 205.5,129.4 			"
          />
          <polygon
            class="st3"
            points="199.3,130.2 199.5,127.9 198.6,127.8 198.3,130.1 197.7,130 197.6,131.3 200.5,131.6 200.6,130.3 
							"
          />
          <polygon
            class="st3"
            points="177.7,156.7 177.9,154.3 177,154.2 176.7,156.6 176.1,156.5 176,157.8 178.9,158.1 179,156.8 			"
          />
          <polygon
            class="st3"
            points="196.2,131 195.7,130.2 196.1,129.9 196.6,130.8 			"
          />

          <rect
            x="211"
            y="133.6"
            transform="matrix(7.125385e-02 -0.9975 0.9975 7.125385e-02 62.9595 335.7656)"
            class="st3"
            width="1.6"
            height="1"
          />

          <rect
            x="193.1"
            y="139"
            transform="matrix(0.9903 -0.1389 0.1389 0.9903 -17.4967 28.2126)"
            class="st3"
            width="0.4"
            height="0.9"
          />

          <rect
            x="198.4"
            y="132.1"
            transform="matrix(0.1188 -0.9929 0.9929 0.1188 42.9113 314.9563)"
            class="st3"
            width="0.9"
            height="2.4"
          />
          <polygon
            class="st3"
            points="214.9,112.2 213.9,112.6 212.1,113.4 212.7,114.9 214.6,114.2 215.5,116.6 216.5,116.1 			"
          />

          <rect
            x="211.5"
            y="130.9"
            transform="matrix(7.131788e-02 -0.9975 0.9975 7.131788e-02 65.7622 333.3605)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="210.5"
            y="115.3"
            transform="matrix(7.131788e-02 -0.9975 0.9975 7.131788e-02 80.4974 317.9574)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="198.3"
            y="136.1"
            transform="matrix(0.1187 -0.9929 0.9929 0.1187 39.9119 318.2242)"
            class="st3"
            width="1.8"
            height="1"
          />
          <polygon
            class="st3"
            points="218.7,123.7 217,124 216.8,123.2 218.5,122.8 			"
          />
          <polygon
            class="st3"
            points="203.3,135.2 203.5,134.6 202.7,134.5 202.6,135 202.6,135.2 202.3,136.3 204.2,136.7 204.4,135.4 
							"
          />
          <polygon
            class="st3"
            points="203.2,125.9 203.2,126.3 203.6,126.3 203.5,127.2 204.4,127.2 204.4,126.4 207.2,126.6 207.3,125.8 
				204,125.5 204.1,124.8 202.9,124.7 202.8,125.9 			"
          />
          <polygon
            class="st3"
            points="202,120.6 202.2,120.2 201.3,119.8 201,120.6 201.8,120.9 201.6,121.5 200.8,121.1 200.2,122.5 
				201.5,123 202.1,121.7 202.5,120.8 			"
          />
          <polygon
            class="st3"
            points="207.2,122 207.3,121.4 206.3,121.2 206.2,121.9 207,122 206.8,123.2 207.2,123.3 207.4,122.1 			"
          />

          <rect
            x="204.7"
            y="138.1"
            transform="matrix(0.2168 -0.9762 0.9762 0.2168 25.384 308.6769)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="200.3"
            y="124.3"
            transform="matrix(0.2167 -0.9762 0.9762 0.2167 35.4296 293.5398)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="214.2,126.4 213.6,126.3 213.4,127.4 214,127.5 213.9,128.3 214.5,128.4 214.4,129.3 214.8,129.4 
				215,128.5 215,128.3 215.4,126 214.3,125.8 			"
          />
          <polygon
            class="st3"
            points="201.7,139.3 199.4,140 199.6,140.8 201.9,140.1 			"
          />
          <polygon
            class="st3"
            points="183.8,120.9 183.7,120.4 182,120.8 182.2,121.5 183.1,121.3 183.4,122.5 189,121 188.6,119.6 			"
          />

          <rect
            x="177.4"
            y="138.3"
            transform="matrix(0.1628 -0.9867 0.9867 0.1628 10.5911 295.0574)"
            class="st3"
            width="3.4"
            height="6"
          />
          <polygon
            class="st3"
            points="211.7,128.4 209.7,129 208.7,125.7 210.7,125.1 			"
          />
          <polygon
            class="st3"
            points="219,130.2 220,129.8 220.7,131.6 221.6,131.2 220.9,129.4 219,124.6 217.1,125.3 			"
          />
          <polygon
            class="st3"
            points="217.3,135 218.4,134.6 220.2,133.9 219.5,132 217.6,132.7 216.4,129.7 215.4,130.1 			"
          />
          <polygon
            class="st3"
            points="207,134.8 207.5,136.5 206.8,136.7 207.3,138.3 211.6,137.1 211.1,135.4 209,136 208.5,134.3 			"
          />

          <rect
            x="189.9"
            y="131.4"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 21.6009 292.3496)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="190.6"
            y="128.2"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 25.2286 290.4707)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="191.3"
            y="125.1"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 28.8585 288.5939)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="191.9"
            y="121.9"
            transform="matrix(0.2044 -0.9789 0.9789 0.2044 32.4861 286.7149)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <polygon
            class="st3"
            points="187.6,140.7 187.6,140.9 187.8,141.5 189.4,141.2 189.2,140.6 191.2,140.1 191,138.8 187.3,139.5 
							"
          />
          <polygon
            class="st3"
            points="184.9,126.2 185.7,123 187.6,123.4 186.8,126.7 			"
          />

          <rect
            x="182.7"
            y="124.9"
            transform="matrix(0.2401 -0.9707 0.9707 0.2401 17.4792 273.1638)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="183.4,129.1 183.1,130 182.1,129.8 182.4,128.8 			"
          />
          <polygon
            class="st3"
            points="183.5,132 183.2,133.3 181.9,133 182.2,131.7 			"
          />
          <polygon
            class="st3"
            points="184.1,130.8 184.2,130.2 185.6,130.5 185.4,131.2 			"
          />
          <polygon
            class="st3"
            points="29.4,46.9 29,48.3 25.7,47.5 26.1,46.1 			"
          />
          <rect
            x="6.1"
            y="64.3"
            transform="matrix(0.1806 -0.9835 0.9835 0.1806 -57.9405 60.052)"
            class="st3"
            width="2"
            height="1"
          />
          <rect
            x="8.6"
            y="66.2"
            transform="matrix(0.1807 -0.9835 0.9835 0.1807 -58.1016 63.6576)"
            class="st3"
            width="1"
            height="1"
          />
          <rect
            x="12.6"
            y="67.2"
            transform="matrix(0.1807 -0.9835 0.9835 0.1807 -55.8544 68.2988)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="14.2"
            y="65.6"
            transform="matrix(0.1807 -0.9835 0.9835 0.1807 -52.9536 68.9035)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="13"
            y="62.4"
            transform="matrix(0.1806 -0.9836 0.9836 0.1806 -50.4669 64.8868)"
            class="st3"
            width="1.4"
            height="0.7"
          />

          <rect
            x="9.2"
            y="59.3"
            transform="matrix(0.1807 -0.9835 0.9835 0.1807 -51.2177 58.5269)"
            class="st3"
            width="0.7"
            height="1.4"
          />

          <rect
            x="20.6"
            y="74.7"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -57.33 82.2303)"
            class="st3"
            width="1.3"
            height="2.5"
          />

          <rect
            x="21.8"
            y="71.9"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -53.0873 80.6609)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="23.4"
            y="67.4"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -47.368 78.6601)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="23.1"
            y="63.3"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -43.5485 75.3537)"
            class="st3"
            width="1.7"
            height="1.7"
          />

          <rect
            x="19.2"
            y="61.9"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -45.803 70.0355)"
            class="st3"
            width="0.9"
            height="1.8"
          />
          <polygon
            class="st3"
            points="21.6,70.2 17.7,72.9 16,70.5 19.9,67.7 			"
          />
          <polygon
            class="st3"
            points="149.2,258.6 138.3,260.3 138.4,261 137.4,261.2 137.9,264.3 138.9,264.2 139.2,266.2 150.1,264.4 
							"
          />
          <polygon
            class="st3"
            points="150.3,273.7 151.5,273.5 150.5,267.2 149.3,267.4 149.6,268.8 148.4,269 148.4,268.7 140.5,269.9 
				141.2,274.7 149.1,273.5 148.6,269.8 149.7,269.6 			"
          />
          <polygon
            class="st3"
            points="222.5,283.8 220.7,279.7 221.4,279.4 223.2,283.5 			"
          />
          <polygon
            class="st3"
            points="192.3,190.5 193.1,191.4 196.5,188.3 195.7,187.5 195,188.2 194.2,187.4 194.4,187.2 189.3,181.6 
				186.7,183.9 191.8,189.6 193.8,187.8 194.5,188.6 			"
          />
          <polygon
            class="st3"
            points="192.2,193.4 196.3,189.6 196.9,190.3 192.8,194.1 			"
          />
          <polygon
            class="st3"
            points="279.9,194.6 277.3,191.7 278.8,190.4 281.4,193.3 			"
          />
          <polygon
            class="st3"
            points="136.9,266.6 137.6,266.5 137.4,265.6 136.5,265.7 136.6,266.2 135,266.5 135.5,269.6 137.4,269.3 
							"
          />
          <polygon
            class="st3"
            points="135.2,270.8 134.5,271 133.7,267.7 131.9,268.1 132.9,272.3 135.4,271.7 			"
          />
          <polygon
            class="st3"
            points="283.5,190.7 282.8,191.3 281.9,190.2 281.3,190.8 280,189.3 279.4,189.8 281,191.6 281.6,191.1 
				282.2,191.8 282.6,192.2 283.3,193.1 284.6,192 			"
          />
          <polygon
            class="st3"
            points="230.6,282.3 233.5,281 233.1,280.1 234.6,279.5 235.2,280.9 237.9,279.6 236.7,276.9 234,278.1 
				234.2,278.6 232.7,279.2 232.2,278 233.3,277.5 232.6,276 231.5,276.5 231.1,276.7 223.3,280.2 224.7,283.4 225.3,284.6 
				225.7,285.5 231,283.2 			"
          />

          <rect
            x="129.3"
            y="247.6"
            transform="matrix(0.9974 -7.152955e-02 7.152955e-02 0.9974 -17.4079 9.9944)"
            class="st3"
            width="3.2"
            height="0.9"
          />

          <rect
            x="130.7"
            y="246.1"
            transform="matrix(0.9974 -7.152249e-02 7.152249e-02 0.9974 -17.2985 10.0718)"
            class="st3"
            width="2.5"
            height="0.9"
          />

          <rect
            x="149.9"
            y="220.9"
            transform="matrix(0.9974 -7.152946e-02 7.152946e-02 0.9974 -15.4422 11.4014)"
            class="st3"
            width="3.2"
            height="0.9"
          />

          <rect
            x="151.4"
            y="219.4"
            transform="matrix(0.9974 -7.152249e-02 7.152249e-02 0.9974 -15.333 11.4787)"
            class="st3"
            width="2.5"
            height="0.9"
          />
          <polygon
            class="st3"
            points="139.1,256.2 139,255.4 140.4,255.2 140.1,252.5 138.6,252.7 138.3,250.1 136.8,250.3 137.1,252.9 
				135.6,253.1 135.4,251.8 134,252 134.3,254.7 135.8,254.5 135.9,255.8 136.3,255.8 136.4,256.5 			"
          />
          <polygon
            class="st3"
            points="289.2,187.2 288.7,187.1 288.9,186.2 287.2,185.9 287.1,186.8 285.5,186.6 285.3,187.5 286.9,187.8 
				286.8,188.7 286,188.6 285.8,189.5 287.5,189.8 287.6,188.9 288.4,189 288.5,188.8 288.9,188.8 			"
          />

          <rect
            x="126.2"
            y="236.2"
            transform="matrix(0.9974 -7.154185e-02 7.154185e-02 0.9974 -16.7911 9.6965)"
            class="st3"
            width="1.5"
            height="6.2"
          />
          <polygon
            class="st3"
            points="130.8,252.5 130.6,251.8 134.4,250.6 134.6,251.3 			"
          />
          <polygon
            class="st3"
            points="126.1,245.2 126.1,249.1 126.1,249.1 127,253 128.1,252.8 127.2,249.1 127.2,245.1 			"
          />
          <polygon
            class="st3"
            points="131.3,242.3 130.9,242.3 130,242.4 130.1,243.7 131,243.6 131.1,245.2 133.1,245.1 132.9,242.2 			
				"
          />
          <polygon
            class="st3"
            points="230,276.7 229.2,275 229.9,274.7 230.6,276.4 			"
          />
          <polygon
            class="st3"
            points="137.2,241.4 135.8,241.5 135.6,238.1 134.8,238.1 134,238.2 133.1,238.2 133,236.8 131.3,236.9 
				131.3,236.2 130,236.3 130.1,237.6 131.1,237.5 131.2,238.4 129.2,238.5 129.4,240.6 134.2,240.3 134.5,245 136.1,244.9 
				135.9,242.5 137.3,242.4 			"
          />
          <polygon
            class="st3"
            points="126.7,275.5 127,277.1 127.8,276.9 128.1,278.6 128.9,278.4 128.6,276.8 128.4,276 128.3,275.2 			
				"
          />
          <polygon
            class="st3"
            points="255.6,159.3 255.3,160.9 256.1,161 255.8,162.6 256.6,162.8 256.9,161.1 257,160.3 257.1,159.5 			
				"
          />
          <polygon
            class="st3"
            points="199.5,242.6 198.7,241.9 203.1,237.2 203.9,237.9 			"
          />
          <polygon
            class="st3"
            points="92,198.1 89.2,194.7 90.5,193.5 93.4,196.9 			"
          />
          <polygon
            class="st3"
            points="80.8,189.2 77.9,185.8 78.6,185.2 81.5,188.6 			"
          />
          <polygon
            class="st3"
            points="78.1,190.3 75.3,186.9 76,186.3 78.8,189.7 			"
          />
          <polygon
            class="st3"
            points="96.1,190.4 96.2,192.8 96.9,192.8 96.9,194 95.7,194 95.8,196.3 98.1,196.3 98,194 97.7,194 
				97.6,192.7 98.6,192.7 98.7,193.6 99.9,193.6 99.9,192.7 99.9,192.4 99.7,185.8 97.1,185.9 96,185.9 95.2,185.9 95.3,190.4 			
				"
          />

          <rect
            x="85.5"
            y="184.7"
            transform="matrix(1 -2.099791e-03 2.099791e-03 1 -0.3885 0.1817)"
            class="st3"
            width="1.8"
            height="0.7"
          />
          <polygon
            class="st3"
            points="87.3,189 86.4,188.2 89,185.3 89.9,186.1 			"
          />

          <rect
            x="131"
            y="277.1"
            transform="matrix(0.9847 -0.1742 0.1742 0.9847 -46.3109 27.217)"
            class="st3"
            width="1.8"
            height="0.8"
          />

          <rect
            x="128.8"
            y="273.4"
            transform="matrix(0.9847 -0.1741 0.1741 0.9847 -45.939 26.7386)"
            class="st3"
            width="1.3"
            height="3.6"
          />

          <rect
            x="131.2"
            y="272.9"
            transform="matrix(0.9847 -0.1742 0.1742 0.9847 -45.573 27.2163)"
            class="st3"
            width="2.1"
            height="0.7"
          />
          <polygon
            class="st3"
            points="100.6,201 99.8,201.1 99.1,197.9 97.3,198.3 98.3,202.5 100.8,201.9 			"
          />
          <polygon
            class="st3"
            points="130.7,194.7 132,195.7 132.5,195.1 133.7,196.2 134.3,195.6 133,194.5 132.4,194 131.8,193.5 			"
          />
          <polygon
            class="st3"
            points="135.5,197 136.7,195.6 137.2,196.1 136,197.5 			"
          />
          <polygon
            class="st3"
            points="139.2,206.6 140.3,205.2 140.9,205.7 139.7,207.1 			"
          />
          <polygon
            class="st3"
            points="140.2,208.3 141.4,206.9 142,207.4 140.8,208.8 			"
          />

          <rect
            x="145.2"
            y="201.3"
            transform="matrix(7.994244e-02 -0.9968 0.9968 7.994244e-02 -67.4857 331.2018)"
            class="st3"
            width="0.9"
            height="1.8"
          />
          <polygon
            class="st3"
            points="134,191.8 133.1,192.8 130.4,190.4 131.2,189.4 			"
          />

          <rect
            x="96.6"
            y="203"
            transform="matrix(0.9847 -0.1742 0.1742 0.9847 -33.9289 20.1099)"
            class="st3"
            width="2.1"
            height="0.7"
          />

          <rect
            x="132.5"
            y="257.8"
            transform="matrix(0.988 -0.1545 0.1545 0.988 -38.3171 23.656)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="131.9,257.4 129.4,256.2 129.8,255.4 132.2,256.5 			"
          />
          <polygon
            class="st3"
            points="132.7,260.9 130.2,259.8 130.6,258.9 133.1,260 			"
          />

          <rect
            x="165.9"
            y="245.2"
            transform="matrix(0.9996 -2.968328e-02 2.968328e-02 0.9996 -7.2197 5.0454)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="176.1"
            y="246.9"
            transform="matrix(0.9996 -2.969384e-02 2.969384e-02 0.9996 -7.2855 5.3509)"
            class="st3"
            width="0.9"
            height="2.2"
          />
          <polygon
            class="st3"
            points="167.3,246.7 169.7,248.2 169.2,248.9 166.9,247.5 			"
          />
          <polygon
            class="st3"
            points="167,243.1 169.3,244.6 168.8,245.4 166.5,243.9 			"
          />
          <polygon
            class="st3"
            points="133.5,264.4 131,263.3 131.4,262.4 133.9,263.6 			"
          />

          <rect
            x="133.3"
            y="233.2"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -121.8442 321.1092)"
            class="st3"
            width="1.3"
            height="2.5"
          />

          <rect
            x="134.5"
            y="230.5"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -117.6028 319.5375)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="135.4"
            y="257"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 -142.8912 341.8795)"
            class="st3"
            width="1.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="134.3,228.8 130.4,231.5 128.7,229.1 132.6,226.3 			"
          />
          <polygon
            class="st3"
            points="306.2,208.9 305.5,209.6 302.3,206.2 303,205.5 			"
          />
          <polygon
            class="st3"
            points="310.1,213.1 309.4,213.8 306.2,210.3 306.9,209.6 			"
          />
          <polygon
            class="st3"
            points="312.5,215.5 311.7,216.2 310.1,214.5 310.8,213.8 			"
          />
          <polygon
            class="st3"
            points="327.3,209.3 328.2,208.4 329.8,210 328.8,211 			"
          />
          <polygon
            class="st3"
            points="313.9,210.2 314.5,210.9 313.7,211.7 313,211 312.1,211.9 313.7,213.7 314.7,212.8 314.2,212.2 
				315,211.4 315.6,211.9 316.3,211.2 314.7,209.4 			"
          />

          <rect
            x="330.3"
            y="257.7"
            transform="matrix(0.9993 -3.836495e-02 3.836495e-02 0.9993 -9.733 12.8813)"
            class="st3"
            width="1"
            height="4.7"
          />

          <rect
            x="327.7"
            y="262.5"
            transform="matrix(0.9993 -3.836529e-02 3.836529e-02 0.9993 -9.875 12.7844)"
            class="st3"
            width="1"
            height="2.4"
          />
          <polygon
            class="st3"
            points="330.7,266.9 330.7,267.8 329.5,267.9 329.5,266.9 328.2,267 328.3,269.4 329.6,269.4 329.6,268.6 
				330.8,268.5 330.8,269.3 331.9,269.3 331.8,266.9 			"
          />
          <polygon
            class="st3"
            points="312.6,209.2 311.9,209.9 308.7,206.5 309.4,205.8 			"
          />
          <polygon
            class="st3"
            points="314.8,205.8 314.1,206.5 311.2,203.4 311.9,202.7 			"
          />
          <polygon
            class="st3"
            points="314.2,208.1 313.8,208.5 313.3,208 313.8,207.6 			"
          />
          <polygon
            class="st3"
            points="316.2,206.9 315.7,207.4 315.1,206.8 315.7,206.3 			"
          />
          <polygon
            class="st3"
            points="311,203.2 310.3,203.9 309.6,203.2 310.3,202.5 			"
          />
          <polygon
            class="st3"
            points="309,199.8 303.7,204.8 303.2,204.3 308.5,199.3 			"
          />
          <polygon
            class="st3"
            points="307.5,204.1 306.9,204.6 305.9,203.4 306.4,202.9 			"
          />
          <polygon
            class="st3"
            points="315,193.1 309.3,198.5 308.7,198 314.5,192.5 			"
          />
          <polygon
            class="st3"
            points="316.6,197.2 314.7,199 314.4,198.7 316.2,196.9 			"
          />
          <polygon
            class="st3"
            points="319.7,197.7 319.4,197.9 317.8,196.2 317.3,196.7 320.3,199.9 319.5,200.7 319.3,200.4 317.6,202 
				318.9,203.3 320.6,201.7 319.9,201.1 320.7,200.3 321,200.6 321.5,200.1 320.3,198.8 320.6,198.6 			"
          />
          <polygon
            class="st3"
            points="324.6,205.2 322.9,203 324.1,202 326.3,204.8 325.1,205.8 319.4,210.2 319,209.6 			"
          />
          <polygon
            class="st3"
            points="327.9,230.5 325.3,228.3 324.7,229 328,231.9 328.3,231.6 328.6,231.2 331.7,229 330.9,228.3 			"
          />
          <polygon
            class="st3"
            points="320.4,205.1 319.8,205.8 318.4,204.3 319.1,203.6 			"
          />
          <polygon
            class="st3"
            points="318.6,204.6 317.1,206.1 316.7,205.7 318.3,204.2 			"
          />
          <polygon
            class="st3"
            points="318.4,203.7 316.1,205.8 315.5,205.2 317.8,203 			"
          />
          <polygon
            class="st3"
            points="317.3,202.4 316.6,203 315,201.3 315.7,200.7 			"
          />
          <polygon
            class="st3"
            points="314.4,200.9 313.6,201.6 312.8,200.8 313.6,200.1 			"
          />
          <polygon
            class="st3"
            points="315.5,196.4 312.3,199.5 311.6,198.7 314.8,195.6 			"
          />
          <polygon
            class="st3"
            points="317.7,194.7 317.1,195.3 315.5,193.6 316.1,193 			"
          />
          <polygon
            class="st3"
            points="319.4,207.8 316.9,210.2 316.3,209.5 318.8,207.2 			"
          />
          <polygon
            class="st3"
            points="318.2,206.8 316.6,208.4 315.9,207.6 317.5,206.1 			"
          />

          <rect
            x="321.8"
            y="31.5"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 256.1191 350.8351)"
            class="st3"
            width="4.6"
            height="1.5"
          />

          <rect
            x="319.7"
            y="38.9"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 248.0708 356.5715)"
            class="st3"
            width="7.2"
            height="1.5"
          />

          <rect
            x="319.8"
            y="46.7"
            transform="matrix(0.1107 -0.9938 0.9938 0.1107 239.6179 362.6187)"
            class="st3"
            width="5.3"
            height="1.5"
          />
          <rect x="321.5" y="53.2" class="st3" width="1.9" height="3.4" />
          <polygon
            class="st3"
            points="330.3,44.8 330.2,46.3 328.4,46.1 328.6,44.6 326.7,44.4 326.3,48.1 328.2,48.3 328.3,47.1 
				330.1,47.3 329.9,48.5 331.5,48.7 331.9,45 			"
          />

          <rect
            x="326.4"
            y="38.1"
            transform="matrix(0.1107 -0.9938 0.9938 0.1107 254.8587 362.4102)"
            class="st3"
            width="7.1"
            height="1.4"
          />

          <rect
            x="332.5"
            y="37.5"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 260.5314 367.7254)"
            class="st3"
            width="6.5"
            height="1.5"
          />

          <rect
            x="341.6"
            y="48.6"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 257.5782 386.587)"
            class="st3"
            width="6.5"
            height="1.5"
          />

          <rect
            x="332.2"
            y="42.1"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 253.455 368.4652)"
            class="st3"
            width="1"
            height="0.9"
          />

          <rect
            x="335.2"
            y="43"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 255.2377 372.4359)"
            class="st3"
            width="1.2"
            height="1.1"
          />

          <rect
            x="329.4"
            y="52.3"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 240.8942 374.8927)"
            class="st3"
            width="1.2"
            height="1.1"
          />

          <rect
            x="336.4"
            y="51.1"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 247.1128 381.8766)"
            class="st3"
            width="1.2"
            height="3.5"
          />

          <rect
            x="334.1"
            y="33"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 264.1583 362.7538)"
            class="st3"
            width="1.5"
            height="1.4"
          />

          <rect
            x="332.7"
            y="23.6"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 271.4241 356.2847)"
            class="st3"
            width="4.2"
            height="5.6"
          />

          <rect
            x="329.5"
            y="29.5"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 264.2178 355.4463)"
            class="st3"
            width="2.4"
            height="1.1"
          />

          <rect
            x="344.2"
            y="22.2"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 278.8463 367.5654)"
            class="st3"
            width="1.2"
            height="11.6"
          />

          <rect
            x="345.1"
            y="32.7"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 272.8452 374.0754)"
            class="st3"
            width="0.8"
            height="3.8"
          />
          <polygon
            class="st3"
            points="350.6,39.1 350.1,39 350.5,35.5 349.5,35.4 348.7,42.1 347.1,41.9 347.2,41.3 343.8,41 343.5,43.8 
				346.9,44.2 347,42.8 348.6,43 348.6,43.5 349.6,43.6 349.9,41 350.4,41.1 			"
          />
          <polygon
            class="st3"
            points="316.3,55.4 316.5,51.1 318.7,51.2 318.5,56.6 316.3,56.6 305.9,56.2 305.9,55.1 			"
          />
          <polygon
            class="st3"
            points="347.2,55 342,54.5 341.8,55.9 348.6,56.5 348.6,55.8 348.7,55.1 349.2,50.2 347.6,50.1 			"
          />

          <rect
            x="340.1"
            y="45.1"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 258.2494 380.2329)"
            class="st3"
            width="3.1"
            height="1.4"
          />

          <rect
            x="339.2"
            y="42.7"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 257.957 376.7648)"
            class="st3"
            width="0.8"
            height="3.1"
          />

          <rect
            x="339"
            y="40.4"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 259.6271 375.5192)"
            class="st3"
            width="1.4"
            height="4.6"
          />

          <rect
            x="339.9"
            y="38.6"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 264.8969 374.487)"
            class="st3"
            width="3.6"
            height="1.2"
          />

          <rect
            x="339.6"
            y="34.4"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 267.7358 369.7065)"
            class="st3"
            width="1.7"
            height="1.6"
          />

          <rect
            x="343.3"
            y="28.7"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 274.2477 370.3942)"
            class="st3"
            width="1.6"
            height="6.5"
          />

          <rect
            x="349"
            y="31.6"
            transform="matrix(0.1107 -0.9939 0.9939 0.1107 279.9852 377.2069)"
            class="st3"
            width="3.6"
            height="1.2"
          />

          <rect
            x="335.1"
            y="45.4"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 250.9085 376.2931)"
            class="st3"
            width="1.3"
            height="5"
          />

          <rect
            x="335.8"
            y="43.9"
            transform="matrix(0.1108 -0.9938 0.9938 0.1108 254.0457 374.9656)"
            class="st3"
            width="1.5"
            height="3.2"
          />
          <polygon
            class="st3"
            points="79.6,138 79,136.6 85,134.1 85.6,135.5 			"
          />
          <polygon
            class="st3"
            points="78.3,153.5 77.7,152.1 83.7,149.6 84.2,151 			"
          />
          <polygon
            class="st3"
            points="81.3,141.5 80.8,140.5 81.9,140 82.3,141.1 			"
          />
          <polygon
            class="st3"
            points="74.2,148.2 72.9,144.9 74,144.5 75.3,147.7 			"
          />
          <polygon
            class="st3"
            points="89.3,136.1 88.7,134.8 90.1,134.2 90.7,135.6 			"
          />
          <polygon
            class="st3"
            points="94.6,146.8 93.1,143.3 93.8,143 95.2,146.5 			"
          />
          <polygon
            class="st3"
            points="93.4,154.4 93.2,154 96.5,152.6 96.1,151.7 89.9,154.3 89.2,152.8 89.8,152.5 88.5,149.4 
				85.8,150.5 87.1,153.6 88.4,153.1 89.1,154.6 88.6,154.8 89,155.8 91.4,154.8 91.6,155.2 			"
          />
          <polygon
            class="st3"
            points="77.2,156.3 75.1,151.5 73.8,152.1 76.5,158.3 77.1,158 77.8,157.7 82.3,155.8 81.7,154.3 			"
          />
          <polygon
            class="st3"
            points="140,212.9 134.5,207.3 135.2,206.6 140.8,212.2 			"
          />
          <polygon
            class="st3"
            points="132.2,201.7 136.4,200.1 137.1,201.9 132.9,203.5 			"
          />
          <polygon
            class="st3"
            points="130.4,212.5 129.7,213 127.8,210.3 128.5,209.8 			"
          />
          <polygon
            class="st3"
            points="144.3,210.5 143.8,210.7 143.4,209.8 142.9,210.1 143.3,211 144.4,213.5 145.4,213 			"
          />
          <polygon
            class="st3"
            points="134.8,217.8 134.4,216.8 138.8,214.9 139.3,216 			"
          />
          <polygon
            class="st3"
            points="142.2,214.8 141.6,215 141.2,213.9 141.8,213.7 			"
          />
          <polygon
            class="st3"
            points="147.9,212.3 146.6,209.4 147.7,208.9 148.9,211.8 			"
          />
          <polygon
            class="st3"
            points="140.5,200.9 140.4,201.5 140.2,202.6 141.3,202.7 141.5,201.6 143.1,201.9 143.2,201.3 			"
          />

          <rect
            x="149.5"
            y="203.4"
            transform="matrix(3.459695e-02 -0.9994 0.9994 3.459695e-02 -59.9162 348.3088)"
            class="st3"
            width="1.7"
            height="3.5"
          />
          <polygon
            class="st3"
            points="155.8,204.2 154.4,204.8 154.7,205.5 153.2,206.2 153.5,206.9 155,206.2 156.4,205.6 			"
          />

          <rect
            x="159.1"
            y="201.6"
            transform="matrix(3.443574e-02 -0.9994 0.9994 3.443574e-02 -46.7713 355.9058)"
            class="st3"
            width="3.4"
            height="1.2"
          />
          <polygon
            class="st3"
            points="152.7,203 152.3,202 156.8,200 157.2,201 			"
          />
          <polygon
            class="st3"
            points="146.8,207 146.3,205.8 147.5,205.2 148.1,206.5 			"
          />
          <polygon
            class="st3"
            points="156.5,208.3 156.9,208.1 156.5,207 155.5,207.4 155.9,208.4 155.2,208.7 154.8,207.7 153.1,208.4 
				153.8,210.1 155.5,209.4 156.7,208.9 			"
          />
          <polygon
            class="st3"
            points="151.8,210.2 152.5,209.9 152,208.7 151.2,209.1 151.6,210 150.2,210.6 150.4,211.2 151.9,210.5 			
				"
          />
          <polygon
            class="st3"
            points="159.4,207.7 158.5,205.8 159.7,205.3 160.5,207.2 			"
          />
          <polygon
            class="st3"
            points="188.3,195.9 188.6,196.4 187.8,197 188.1,197.4 188.9,196.8 191.1,195.2 190.4,194.3 			"
          />
          <polygon
            class="st3"
            points="187.9,194.7 185.4,196.6 184.7,195.6 187.2,193.8 			"
          />
          <polygon
            class="st3"
            points="177.5,204.3 177.8,204.8 178.5,205.7 179.4,205 178.7,204.1 180.1,203.1 179.7,202.6 			"
          />
          <polygon
            class="st3"
            points="184,193.2 182.1,194.6 181.1,193.3 183,191.8 			"
          />
          <polygon
            class="st3"
            points="176.4,189.5 177.4,190.7 178,190.2 178.9,191.5 179.5,191.1 178.6,189.8 177.7,188.6 			"
          />
          <polygon
            class="st3"
            points="176.2,186.4 173.5,188.4 172.8,187.5 175.5,185.4 			"
          />
          <polygon
            class="st3"
            points="172.1,188.3 171.2,188.9 168.3,184.9 169.1,184.3 			"
          />
          <polygon
            class="st3"
            points="184.8,201.3 183.7,202.1 182.9,201 184,200.2 			"
          />
          <polygon
            class="st3"
            points="180.2,187.9 179.9,187.5 178.9,188.2 179.6,189 180.5,188.4 180.9,189 180,189.7 181.2,191.2 
				182.6,190 181.5,188.6 180.7,187.5 			"
          />
          <polygon
            class="st3"
            points="185,191.4 184.5,190.7 183.5,191.5 184,192.1 184.8,191.5 185.8,192.8 186.2,192.5 185.2,191.2 			
				"
          />
          <polygon
            class="st3"
            points="179.3,186.9 177.7,188.1 176.9,187.1 178.6,185.8 			"
          />
          <polygon
            class="st3"
            points="133.3,214.9 131.9,211.6 133,211.2 134.4,214.4 			"
          />
          <polygon
            class="st3"
            points="144.1,224.9 143.2,219.8 141.8,220 143,226.7 143.7,226.6 144.4,226.4 149.3,225.6 149,224.1 			"
          />
          <polygon
            class="st3"
            points="79,149.7 78.4,148.4 81.3,147.2 81.8,148.5 			"
          />
          <polygon
            class="st3"
            points="83.1,146 81.9,143.1 82.6,142.8 83.8,145.7 			"
          />
          <polygon
            class="st3"
            points="84.5,146.2 82.8,141.9 84,141.4 85.8,145.6 			"
          />
          <polygon
            class="st3"
            points="88.3,147.5 87.8,146.4 91.1,145 91.6,146.2 			"
          />
          <polygon
            class="st3"
            points="90.7,142 90.1,140.5 91.6,139.8 92.3,141.3 			"
          />
          <polygon
            class="st3"
            points="78.1,145.4 76.2,140.8 77.4,140.3 79.3,144.9 			"
          />
          <polygon
            class="st3"
            points="80.2,144.2 79,141.3 80.4,140.7 81.6,143.6 			"
          />
          <polygon
            class="st3"
            points="185.8,181.6 183.7,179.3 184.3,178.8 186.3,181.1 			"
          />
          <polygon
            class="st3"
            points="187,180.5 184,177 185.1,176.1 188.1,179.6 			"
          />
          <polygon
            class="st3"
            points="182.3,183.2 181.3,182.1 182.4,181.2 183.4,182.3 			"
          />

          <rect
            x="176.1"
            y="180.5"
            transform="matrix(6.108769e-02 -0.9981 0.9981 6.108769e-02 -13.1209 348.306)"
            class="st3"
            width="5"
            height="1.3"
          />

          <rect
            x="179.9"
            y="176.9"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 359.1874 -3.7966)"
            class="st3"
            width="3.2"
            height="1.5"
          />
          <rect x="365.2" y="84.4" class="st3" width="0.9" height="1.9" />
          <polygon
            class="st3"
            points="376.6,66.2 378.6,65.1 379.1,65.9 377.1,67 			"
          />
          <polygon
            class="st3"
            points="379,63.7 379.7,63.3 379.9,63.7 379.3,64.1 			"
          />
          <rect x="366.9" y="85.5" class="st3" width="2.3" height="0.8" />
          <polygon
            class="st3"
            points="404.6,82 400.1,85 398.7,82.8 403.2,79.8 			"
          />

          <rect
            x="374.1"
            y="70.2"
            transform="matrix(4.771475e-02 -0.9989 0.9989 4.771475e-02 286.1266 441.2948)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="381.9"
            y="70.7"
            transform="matrix(4.771475e-02 -0.9989 0.9989 4.771475e-02 293.029 449.6364)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="426.6,106.8 427.5,106.4 427.9,107.2 427.1,107.7 			"
          />
          <polygon
            class="st3"
            points="398.8,79.6 398.3,79.3 398.9,78.1 399.4,78.3 			"
          />

          <rect
            x="366.6"
            y="68.3"
            transform="matrix(4.774258e-02 -0.9989 0.9989 4.774258e-02 279.3755 433.8738)"
            class="st3"
            width="1.4"
            height="4.2"
          />

          <rect
            x="369.5"
            y="74.7"
            transform="matrix(4.776292e-02 -0.9989 0.9989 4.776292e-02 277.5767 441.6765)"
            class="st3"
            width="1.9"
            height="1.2"
          />
          <polygon
            class="st3"
            points="427.6,109 428.8,108.4 429.1,109 427.9,109.6 			"
          />

          <rect
            x="361.6"
            y="87.5"
            transform="matrix(4.014134e-02 -0.9992 0.9992 4.014134e-02 261.4313 448.1117)"
            class="st3"
            width="4.7"
            height="1"
          />

          <rect
            x="360.9"
            y="94.8"
            transform="matrix(4.014134e-02 -0.9992 0.9992 4.014134e-02 253.4184 454.4414)"
            class="st3"
            width="4.7"
            height="1"
          />

          <rect
            x="360.6"
            y="102"
            transform="matrix(4.014134e-02 -0.9992 0.9992 4.014134e-02 245.9475 461.0652)"
            class="st3"
            width="4.7"
            height="1"
          />
          <rect x="363.2" y="105.5" class="st3" width="4.3" height="1.8" />
          <polygon
            class="st3"
            points="368.7,99.8 368.7,100.8 367.5,100.8 367.5,99.8 366.2,99.8 366.2,102.3 367.5,102.3 367.5,101.5 
				368.7,101.5 368.7,102.3 369.8,102.3 369.8,99.8 			"
          />
          <rect x="367.5" y="95.9" class="st3" width="1" height="1.7" />
          <rect x="371.4" y="93" class="st3" width="1" height="4.3" />
          <rect x="377.1" y="102.9" class="st3" width="0.6" height="0.6" />
          <rect x="371" y="104" class="st3" width="0.8" height="0.8" />
          <rect x="369.9" y="93.9" class="st3" width="1" height="1" />
          <rect x="365.1" y="88.2" class="st3" width="7.3" height="0.7" />
          <rect x="367.5" y="89.4" class="st3" width="0.7" height="1.6" />
          <polygon
            class="st3"
            points="397.5,75.7 390.3,72.2 390.7,71.5 397.8,75 			"
          />
          <polygon
            class="st3"
            points="394.1,78.7 391.8,77.5 392,77.1 394.3,78.2 			"
          />
          <polygon
            class="st3"
            points="385.8,93.2 385.5,92.7 389.7,90.3 389,89 381,93.4 379.9,91.4 380.6,91 378.3,86.9 375,88.7 
				377.3,92.9 378.9,92 380,94 379.4,94.3 380.1,95.6 383.2,93.9 383.5,94.5 			"
          />
          <polygon
            class="st3"
            points="369.8,106.5 369.8,103.7 368.3,103.7 368.3,107.3 369.8,107.3 377,107.3 377,106.5 			"
          />
          <polygon
            class="st3"
            points="373,73.4 376.4,73.4 376.4,72.5 372,72.5 372,73 372,73.4 372,76.8 373,76.8 			"
          />
          <polygon
            class="st3"
            points="386.1,82 385.5,83.1 383.1,81.8 383.7,80.7 			"
          />
          <polygon
            class="st3"
            points="383.6,82 382.2,84.5 381.6,84.2 383,81.7 			"
          />
          <polygon
            class="st3"
            points="381.3,84.5 380.8,85.4 376.8,83.5 377.2,82.6 			"
          />
          <polygon
            class="st3"
            points="381,79.9 380.4,80.9 377.7,79.4 378.3,78.4 			"
          />
          <polygon
            class="st3"
            points="376.4,77 375.7,78.3 374.5,77.6 375.2,76.3 			"
          />
          <polygon
            class="st3"
            points="394.5,77.4 390.5,75.4 391,74.5 395,76.4 			"
          />
          <polygon
            class="st3"
            points="396.9,78.8 396.1,78.5 397.2,76.4 397.9,76.7 			"
          />
          <rect x="370.9" y="101.1" class="st3" width="3.4" height="0.9" />
          <rect x="375.7" y="99.7" class="st3" width="2.2" height="1" />
          <polygon
            class="st3"
            points="329.6,166.6 330.2,167.2 328.8,168.5 328.2,167.9 			"
          />
          <polygon
            class="st3"
            points="332.2,170.5 330.3,171.1 330.5,171.9 332.4,171.3 			"
          />
          <polygon
            class="st3"
            points="333.8,174.3 334.1,173.9 335.7,174.9 335.4,175.4 			"
          />
          <polygon
            class="st3"
            points="333.2,176.5 332.6,176.1 331.2,178.2 331.8,178.7 332.8,177.2 334.8,178.5 335.2,177.9 			"
          />
          <polygon
            class="st3"
            points="327.9,164.6 328.6,165.4 328.4,165.5 327.7,164.8 			"
          />
          <polygon
            class="st3"
            points="329.6,164.3 329,163.6 329.6,163 330.2,163.7 			"
          />
          <polygon
            class="st3"
            points="331.2,160.9 331.8,161.7 330.5,162.9 329.8,162.1 			"
          />
          <polygon
            class="st3"
            points="337,183.3 336,184.8 335.3,184.3 336.2,182.8 			"
          />
          <polygon
            class="st3"
            points="340.9,175.6 338.3,177 337.9,176.2 340.4,174.8 			"
          />

          <rect
            x="341.7"
            y="175.1"
            transform="matrix(0.9827 -0.1851 0.1851 0.9827 -26.5438 66.8116)"
            class="st3"
            width="5.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="337.6,173.6 338.5,172.4 339.7,173.2 338.9,174.4 			"
          />
          <polygon
            class="st3"
            points="336.4,171.5 336.1,171.3 334.7,173.5 335.3,173.9 336.4,172.2 337.6,173 338.1,172.2 336.6,171.2 
							"
          />
          <polygon
            class="st3"
            points="337.1,180.4 336.6,180 336.2,180.6 336.6,181 336.8,181.1 337.7,181.7 338.7,180.2 337.7,179.4 			
				"
          />
          <polygon
            class="st3"
            points="395.6,84 395.9,83.3 385,77.1 386.1,75.3 384.9,74.6 383.1,77.7 383.1,77.8 384.3,78.4 394,83.9 
				392.3,86.9 394.3,88 396.3,84.4 			"
          />
          <polygon
            class="st3"
            points="298.3,63 297.9,62.7 292.8,69.5 291.7,68.6 291.1,69.3 293.1,70.8 293.1,70.8 293.6,70.1 
				298.1,64.1 300,65.5 300.9,64.3 298.7,62.6 			"
          />
          <polygon
            class="st3"
            points="353,178.5 353,178.1 352.6,178.1 352.6,177.2 351.7,177.2 351.7,178.1 348.9,178.1 348.9,178.9 
				352.2,178.9 352.2,179.7 353.4,179.7 353.4,178.5 			"
          />
          <rect x="333.1" y="182.1" class="st3" width="0.8" height="0.8" />
          <rect x="332.3" y="180.9" class="st3" width="0.8" height="0.8" />
          <polygon
            class="st3"
            points="292.3,100.5 291.6,100.5 291.6,103 292.3,103 292.3,101.2 294.7,101.2 294.7,100.5 			"
          />
          <polygon
            class="st3"
            points="295.5,106.3 295.5,105.7 294.7,105.7 294.7,106.3 294.7,106.4 294.7,107.5 296.6,107.5 296.6,106.3 
							"
          />
          <polygon
            class="st3"
            points="295.6,99.3 295.6,98.9 295.2,98.9 295.2,98 294.4,98 294.4,98.9 291.6,98.9 291.6,99.7 294.8,99.7 
				294.8,100.5 296,100.5 296,99.3 			"
          />
          <rect x="292.6" y="105.1" class="st3" width="0.8" height="0.8" />
          <rect x="291.8" y="103.8" class="st3" width="0.8" height="0.8" />
          <polygon
            class="st3"
            points="343.9,168 344.3,168.3 344,168.7 343.6,168.5 			"
          />
          <polygon
            class="st3"
            points="341.9,168.6 342.3,168.8 342,169.2 341.6,169 			"
          />
          <polygon
            class="st3"
            points="342,167.8 341.5,167.5 341.8,167 342.3,167.3 			"
          />
          <polygon
            class="st3"
            points="418.9,88.3 419.4,87.6 421.5,89.3 421,89.9 			"
          />
          <polygon
            class="st3"
            points="434,97.2 432.5,99.1 431.5,98.4 433,96.4 			"
          />
          <polygon
            class="st3"
            points="438.8,92.5 435.9,95.4 435,94.5 437.9,91.6 			"
          />
          <polygon
            class="st3"
            points="433.7,90.5 435,88.9 436.6,90.2 435.3,91.8 			"
          />
          <polygon
            class="st3"
            points="425.2,82.4 424.9,82 422.6,84.9 423.4,85.5 425.1,83.3 426.7,84.5 427.5,83.5 425.6,81.9 			"
          />
          <rect
            x="427.1"
            y="90"
            transform="matrix(0.9823 -0.1875 0.1875 0.9823 -9.747 81.8169)"
            class="st3"
            width="1.3"
            height="4.8"
          />
          <polygon
            class="st3"
            points="427.8,87.2 427.1,88.1 426.2,87.4 426.9,86.5 			"
          />
          <polygon
            class="st3"
            points="429.7,87.7 430.6,86.5 431.7,87.4 430.8,88.6 			"
          />
          <polygon
            class="st3"
            points="422.8,91.6 421.8,90.8 422.6,89.8 423.6,90.6 			"
          />
          <polygon
            class="st3"
            points="430.8,93.8 429.9,93.1 430.6,92.2 431.4,92.9 			"
          />
          <polygon
            class="st3"
            points="430.3,95.7 431.4,96.6 430.5,97.8 429.4,96.9 			"
          />
          <polygon
            class="st3"
            points="318.8,174.2 319.4,174.9 320.7,173.7 320.1,173.1 321,172.2 320.3,171.4 321.3,170.5 320.9,170 
				319.9,170.9 319.6,171.2 316.9,173.6 318,174.9 			"
          />
          <polygon
            class="st3"
            points="327.5,171 326.8,171 326.8,171.4 325.5,171.4 325.1,171.4 325.1,172.6 325.1,173.9 325.5,173.9 
				325.5,172.6 326.9,172.6 326.9,171.7 327.5,171.7 			"
          />
          <polygon
            class="st3"
            points="331,174.7 329.7,176.6 328.9,176.1 330.2,174.2 			"
          />
          <rect x="348.6" y="123.9" class="st3" width="0.5" height="0.5" />
          <rect x="321.7" y="170.9" class="st3" width="0.8" height="0.5" />
          <polygon
            class="st3"
            points="310.6,167 307,164.1 307.6,163.3 311.2,166.3 			"
          />
          <rect x="309.3" y="168.5" class="st3" width="0.5" height="0.5" />
          <rect x="306.6" y="164.7" class="st3" width="0.8" height="0.5" />
          <polygon
            class="st3"
            points="323.5,173.5 321.5,174.7 321.9,175.4 323.9,174.2 			"
          />
          <polygon
            class="st3"
            points="327.6,167.1 326,165.3 325.4,165.9 327,167.6 			"
          />

          <rect
            x="308.3"
            y="172.3"
            transform="matrix(0.1261 -0.992 0.992 0.1261 98.9028 457.8011)"
            class="st3"
            width="1.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="307.7,161.3 305.9,162.1 306.3,162.9 308.1,162 			"
          />
          <polygon
            class="st3"
            points="304.8,170 305.7,169.6 305.8,169.7 304.9,170.2 			"
          />
          <polygon
            class="st3"
            points="305,168.3 304.2,168.7 303.8,167.8 304.6,167.5 			"
          />
          <polygon
            class="st3"
            points="302.2,165.7 303.1,165.3 303.9,167 303,167.4 			"
          />
          <polygon
            class="st3"
            points="308.2,160.5 306.6,161.3 306.3,160.5 307.9,159.7 			"
          />
          <polygon
            class="st3"
            points="303.1,163.1 302.9,162.5 302.2,162.8 302.5,163.4 302.5,163.5 303,164.5 304.7,163.7 304.1,162.6 
							"
          />
          <polygon
            class="st3"
            points="310,159.9 309.3,160 309.3,161.4 310,161.4 310,162.4 310.9,162.4 311,163.6 311.5,163.6 
				311.5,162.4 311.5,162.1 311.4,159.1 310,159.1 			"
          />
          <polygon
            class="st3"
            points="311.6,169.1 309.6,170.3 311.1,172.8 313.1,171.6 			"
          />
          <polygon
            class="st3"
            points="305.6,171.7 304.9,172 306.9,176.2 307.6,175.9 			"
          />
          <polygon
            class="st3"
            points="304.6,140.6 304,141.1 302.8,139.7 303.4,139.1 			"
          />
          <polygon
            class="st3"
            points="304.6,144.9 306.1,143.6 305.5,142.9 304,144.2 			"
          />
          <polygon
            class="st3"
            points="306.1,157.7 305.3,158.1 304,155.4 304.8,155 			"
          />
          <polygon
            class="st3"
            points="299.8,147.9 300.7,147.1 298.1,144.1 297.2,144.8 299,147 296.2,149.4 297,150.3 			"
          />
          <polygon
            class="st3"
            points="306.6,138.8 305.8,139.5 305.7,139.4 306.5,138.7 			"
          />
          <polygon
            class="st3"
            points="306.9,140.6 307.6,140 308.2,140.7 307.5,141.2 			"
          />
          <polygon
            class="st3"
            points="310.2,142.3 309.5,142.9 308.3,141.5 309.1,140.9 			"
          />
          <polygon
            class="st3"
            points="304.3,145.7 305.6,144.5 306.2,145.2 304.9,146.4 			"
          />
          <polygon
            class="st3"
            points="292.9,146.1 295.1,144.2 295.7,144.8 293.5,146.8 			"
          />
          <polygon
            class="st3"
            points="300.7,160.2 300.9,160.7 304.6,158.8 304.1,157.8 301.2,159.3 300.3,157.3 298.9,157.9 300.2,160.4 
							"
          />
          <polygon
            class="st3"
            points="308.7,149.5 309.3,150.2 310.2,149.4 309.6,148.7 309.4,148.5 308.3,147.2 306.1,149.1 307.4,150.6 
							"
          />
          <polygon
            class="st3"
            points="317.1,165.5 317.7,165.7 320.3,154.3 322.3,154.7 322.6,153.5 319.3,152.7 319.3,152.7 319,154 
				316.7,164.1 313.6,163.4 313.1,165.5 316.9,166.3 			"
          />
          <polygon
            class="st3"
            points="309.6,155.1 310,155.5 310.5,155.1 311.4,156.2 312.4,155.3 311.5,154.2 314.8,151.4 314,150.4 
				310.1,153.7 309.4,152.8 308,154 309.2,155.4 			"
          />
          <polygon
            class="st3"
            points="311.5,147.8 312.5,147 313.3,148 312.3,148.7 			"
          />
          <polygon
            class="st3"
            points="315.6,148.6 314.6,149.4 313.8,148.4 314.8,147.6 			"
          />
          <polygon
            class="st3"
            points="299.7,151.2 300.4,150.9 300.7,151.6 300,151.9 			"
          />
          <polygon
            class="st3"
            points="298.8,154.4 299.4,154.1 299.7,154.7 299.1,155 			"
          />
          <polygon
            class="st3"
            points="297.8,153.6 297,154 296.6,153.2 297.4,152.8 			"
          />
          <polygon
            class="st3"
            points="315.7,155.5 316.3,154.9 316.8,155.5 316.3,156 			"
          />
          <polygon
            class="st3"
            points="316,158.7 316.4,158.2 316.9,158.7 316.5,159.2 			"
          />
          <polygon
            class="st3"
            points="314.8,158.3 314.2,159 313.5,158.4 314.1,157.7 			"
          />
          <polygon
            class="st3"
            points="302.6,143.7 302.9,144 303.4,143.5 303.2,143.3 303.6,142.9 303.3,142.5 303.7,142.1 303.6,141.9 
				303.1,142.3 303,142.4 301.8,143.5 302.3,144 			"
          />
          <polygon
            class="st3"
            points="300.1,141.5 301.9,140 301.4,139.4 299.6,140.9 			"
          />
          <polygon
            class="st3"
            points="304.2,138.5 306,137 305.4,136.4 303.7,137.9 			"
          />
          <polygon
            class="st3"
            points="376.3,147.2 376.9,147.8 375.6,149.2 375,148.6 			"
          />
          <polygon
            class="st3"
            points="374.4,145.4 375.2,146.1 375.1,146.3 374.3,145.5 			"
          />
          <polygon
            class="st3"
            points="377.9,140.9 376.7,141 376.8,143.4 378,143.3 378.1,145 379.5,144.9 379.6,146.8 380.5,146.8 
				380.4,144.9 380.4,144.4 380.2,139.5 377.8,139.6 			"
          />
          <polygon
            class="st3"
            points="377.7,151.7 376,150 375.5,150.6 377.1,152.2 			"
          />
          <polygon
            class="st3"
            points="374.3,147.9 372.6,146.2 372.1,146.8 373.8,148.4 			"
          />
          <polygon
            class="st3"
            points="289.8,86.9 290.5,87.1 290.1,88.6 289.4,88.5 			"
          />
          <polygon
            class="st3"
            points="292.8,84.8 291.2,84.4 291,85.1 292.6,85.5 			"
          />
          <polygon
            class="st3"
            points="287.7,86.4 288.5,86.6 288.5,86.7 287.6,86.5 			"
          />
          <polygon
            class="st3"
            points="288.7,85.3 288,85.1 288.1,84.4 288.9,84.6 			"
          />
          <polygon
            class="st3"
            points="289.5,79.8 290.3,80 289.9,81.5 289.1,81.3 			"
          />
          <polygon
            class="st3"
            points="292.3,83 291,82.2 291.4,81.5 292.7,82.3 			"
          />
          <polygon
            class="st3"
            points="293,86.7 293.1,86.4 292.4,86.3 292.4,86.6 291.9,86.5 291.8,86.9 291.3,86.7 291.3,87 291.8,87.1 
				291.9,87.1 293.2,87.5 293.4,86.8 			"
          />

          <rect
            x="290.7"
            y="88.7"
            transform="matrix(0.2437 -0.9698 0.9698 0.2437 133.1089 350.0356)"
            class="st3"
            width="0.7"
            height="2"
          />
          <polygon
            class="st3"
            points="288.6,88.2 286.7,87.7 286.5,88.4 288.4,88.9 			"
          />
          <rect x="375.8" y="109" class="st3" width="3.1" height="0.9" />
          <polygon
            class="st3"
            points="334.8,192.8 334,191.8 334.3,191.5 333.5,190.5 331,192.7 331.9,193.7 333.1,192.6 334,193.6 			"
          />
          <polygon
            class="st3"
            points="335,191 337.5,195 337.1,195.3 338.3,197.2 338.8,197 339.4,197.9 340.3,197.4 335.8,190.4 			"
          />
          <polygon
            class="st3"
            points="373.1,157.3 372,158.4 371.6,158 370.5,159.1 373.3,161.9 374.4,160.8 373,159.4 374.1,158.3 			"
          />
          <polygon
            class="st3"
            points="372.5,152.5 376.6,156.6 376.2,157.1 378.1,159 378.6,158.6 379.5,159.5 380.4,158.6 373.4,151.6 
							"
          />
          <polygon
            class="st3"
            points="336.3,198.4 335.1,198.7 335.2,199.2 330.9,200.4 331.4,202.1 336.4,200.7 336,199.2 336.5,199 			
				"
          />
          <polygon
            class="st3"
            points="329.6,200.5 328.4,201.6 325.1,197.7 326.4,196.6 			"
          />
          <polygon
            class="st3"
            points="343.8,172.5 342.8,174 340.5,172.5 341.4,171 			"
          />
          <polygon
            class="st3"
            points="329.4,186.2 330.3,187.3 328.7,188.6 328.4,188.2 327.4,189 327.9,189.6 326.3,191 325.5,190 
				324.6,190.7 326.2,192.7 327.1,191.9 326.9,191.6 328.5,190.3 329.5,191.5 330.5,190.7 329.3,189.3 331.8,187.2 331.5,186.9 
				331.2,186.5 330.3,185.5 			"
          />
          <polygon
            class="st3"
            points="339.4,170.1 338.7,171.3 337.8,170.7 338.6,169.5 			"
          />
          <rect x="299.1" y="104.1" class="st3" width="1.5" height="1.5" />
          <polygon
            class="st3"
            points="300.2,101.9 300.2,101.6 297.5,101.6 297.5,102.3 299.6,102.3 299.6,103.7 300.5,103.7 300.5,101.9 
							"
          />
          <polygon
            class="st3"
            points="302,109.2 301.5,109.7 301.8,110 300.9,111 300.6,111.3 301.5,112.1 302.4,113 302.7,112.7 
				301.8,111.8 302.8,110.7 302.2,110.1 302.5,109.7 			"
          />
          <polygon
            class="st3"
            points="305.7,109.4 305,110.2 305.3,110.4 303,113 304,113.9 306.7,110.9 305.8,110.1 306,109.8 			"
          />
          <rect x="304.1" y="99.3" class="st3" width="1.3" height="3.9" />
          <rect x="301.6" y="105.8" class="st3" width="1.8" height="2.9" />
          <rect x="301.6" y="102.6" class="st3" width="1.4" height="1.1" />
          <polygon
            class="st3"
            points="339,165.8 339.9,166.5 339.3,167.4 338.3,166.8 			"
          />
          <rect x="329.6" y="197" class="st3" width="0.5" height="0.5" />
          <rect x="331.1" y="195.5" class="st3" width="0.4" height="0.4" />
          <rect x="331.9" y="196.7" class="st3" width="0.6" height="0.6" />
          <rect x="334.3" y="195.5" class="st3" width="1.2" height="1.2" />
          <polygon
            class="st3"
            points="326.4,194.3 323.4,196.7 320.5,193.3 323.5,190.8 			"
          />
          <polygon
            class="st3"
            points="323.7,190 321.9,191.5 321.7,191.2 323.4,189.8 			"
          />
          <polygon
            class="st3"
            points="334.1,161.8 333.1,163.8 331.3,162.8 330.6,164 332.4,164.9 332,165.7 335,167.4 337.1,163.4 			"
          />
          <rect x="370.7" y="155.4" class="st3" width="3" height="1.1" />
          <rect x="372.4" y="154.7" class="st3" width="1.8" height="0.3" />
          <polygon
            class="st3"
            points="369.3,152.3 369.3,153.3 368.5,153.3 368.5,153.8 369.3,153.8 369.3,154.2 370.7,154.2 370.7,152.3 
							"
          />
          <polygon
            class="st3"
            points="329.7,181.3 325.7,184.6 325.1,183.9 329.1,180.5 			"
          />
          <polygon
            class="st3"
            points="327.6,185.6 326.3,186.7 325.6,185.9 326.9,184.8 			"
          />
          <polygon
            class="st3"
            points="330.8,183 329,184.5 328.5,183.9 330.3,182.4 			"
          />
          <polygon
            class="st3"
            points="301.4,68.4 297.6,69.5 297.4,68.7 301.2,67.6 			"
          />
          <rect x="298" y="65.9" class="st3" width="1.3" height="0.8" />
          <rect x="300.6" y="66.2" class="st3" width="1.8" height="0.6" />
          <polygon
            class="st3"
            points="323.3,186.8 322.3,187.7 321.5,186.7 322.5,185.9 			"
          />
          <polygon
            class="st3"
            points="322.3,178.6 322.9,178.1 324.1,179.6 323.5,180.1 			"
          />
          <polygon
            class="st3"
            points="315.5,177.6 313.9,178.9 314.5,179.6 316,178.3 			"
          />
          <polygon
            class="st3"
            points="340.9,193.4 341.6,193 343,195.5 342.3,195.9 			"
          />
          <polygon
            class="st3"
            points="318.3,185.6 318.8,186.2 321,184.5 320.5,183.8 319,185.1 317.3,183.1 316.7,183.6 			"
          />
          <polygon
            class="st3"
            points="313.3,183.6 314.1,183 314.3,183.1 313.5,183.8 			"
          />
          <polygon
            class="st3"
            points="318.8,181.4 318,181.7 317.7,180.9 318.5,180.6 			"
          />
          <polygon
            class="st3"
            points="326,180.4 325.3,179.5 326.8,178.3 327.5,179.1 			"
          />
          <polygon
            class="st3"
            points="316.1,176.6 310.7,181.1 310.1,180.4 315.5,175.9 			"
          />
          <polygon
            class="st3"
            points="357.3,198.2 354.5,199.3 354.2,198.4 357,197.4 			"
          />
          <polygon
            class="st3"
            points="343.2,197.1 344.6,196.6 345.1,198 343.8,198.5 			"
          />
          <polygon
            class="st3"
            points="338.1,186 337.8,185.9 337.1,188.4 337.8,188.6 338.3,186.6 339.7,186.9 339.9,186 338.2,185.6 			
				"
          />
          <polygon
            class="st3"
            points="312.7,181.5 312.4,181.1 311.8,181.6 312.2,182 312.3,182.2 313,183 314.4,181.7 313.6,180.8 			"
          />
          <polygon
            class="st3"
            points="366.3,184.7 366.2,184.3 360.4,186.6 360,185.6 359.4,185.9 360,187.5 360,187.5 360.7,187.3 
				365.8,185.3 366.4,186.8 367.5,186.4 366.7,184.5 			"
          />
          <polygon
            class="st3"
            points="315.1,183.6 314.7,183.9 315,184.3 314.3,184.8 314.9,185.6 315.6,185 317.5,187.3 318.2,186.8 
				316,184 316.6,183.5 315.8,182.5 314.8,183.3 			"
          />
          <polygon
            class="st3"
            points="322.1,181 322.6,181.7 322,182.2 321.5,181.5 			"
          />
          <polygon
            class="st3"
            points="321.5,183.8 321,183.1 321.6,182.6 322.2,183.2 			"
          />
          <polygon
            class="st3"
            points="340.4,192.4 339.7,192.7 339.4,192.1 340,191.7 			"
          />
          <polygon
            class="st3"
            points="339.8,189.8 339.4,190 339.2,189.5 339.6,189.4 			"
          />
          <polygon
            class="st3"
            points="340.4,190.3 340.9,190.1 341.1,190.7 340.6,190.9 			"
          />
          <polygon
            class="st3"
            points="317.4,178.8 317.2,178.5 316.6,179 316.8,179.3 316.4,179.6 316.7,180 316.3,180.4 316.5,180.6 
				316.9,180.2 317,180.1 318.3,179.1 317.8,178.5 			"
          />
          <polygon
            class="st3"
            points="343.9,178.5 343.2,178.8 343.4,179.2 342.2,179.7 341.8,179.8 342.3,181 342.7,182.1 343.1,182 
				342.6,180.8 344,180.3 343.7,179.4 344.2,179.2 			"
          />
          <polygon
            class="st3"
            points="350.5,201.1 348.4,201.9 348,201 350.2,200.2 			"
          />
          <polygon
            class="st3"
            points="352.4,199.9 351.9,200.1 351.7,199.6 352.2,199.4 			"
          />
          <polygon
            class="st3"
            points="356.2,191 355.3,191.3 355.2,191 354.3,191.3 355.2,193.7 356.1,193.3 355.6,192.2 356.6,191.8 			
				"
          />
          <polygon
            class="st3"
            points="355.7,185.8 357.1,189.3 356.7,189.4 357.4,191 357.7,190.9 358,191.7 358.8,191.4 356.5,185.5 			
				"
          />
          <polygon
            class="st3"
            points="353,192.6 352.1,193 352.2,193.3 349,194.6 349.5,195.9 353.3,194.4 352.8,193.3 353.2,193.1 			"
          />
          <polygon
            class="st3"
            points="348.4,191 347.2,191.5 345.8,187.9 347,187.4 			"
          />
          <polygon
            class="st3"
            points="348.6,196.9 346.9,197.5 345.9,194.8 347.5,194.2 			"
          />
          <polygon
            class="st3"
            points="354.3,185.8 354.7,186.8 353.2,187.4 353.1,187 352.2,187.4 352.4,187.9 350.9,188.5 350.5,187.6 
				349.7,187.9 350.4,189.8 351.2,189.4 351.1,189.2 352.6,188.6 353.1,189.7 354,189.4 353.4,188.1 355.7,187.2 355.6,186.8 
				355.5,186.5 355.1,185.5 			"
          />
          <polygon
            class="st3"
            points="346.4,192.3 345.1,192.8 344.7,191.9 346,191.3 			"
          />
          <polygon
            class="st3"
            points="343.7,189 342.7,189.5 342.2,188.4 343.3,187.9 			"
          />
          <polygon
            class="st3"
            points="347.6,186.3 344.9,187.3 344.5,186.3 347.3,185.3 			"
          />
          <polygon
            class="st3"
            points="347.6,184.7 345.9,185.4 345.8,185.1 347.5,184.5 			"
          />
          <polygon
            class="st3"
            points="342.1,184 342.4,184.8 341.6,185.1 341.8,185.7 342.6,185.4 342.7,185.7 344.1,185.2 343.4,183.5 
							"
          />
          <polygon
            class="st3"
            points="354,183.4 350.3,184.9 350,184.2 353.7,182.7 			"
          />
          <polygon
            class="st3"
            points="351.5,182.5 350.3,183 350,182.2 351.2,181.7 			"
          />
          <polygon
            class="st3"
            points="354.5,181.4 352.8,182 352.6,181.5 354.3,180.8 			"
          />
          <polygon
            class="st3"
            points="348.1,182.3 347.2,182.7 346.9,181.8 347.7,181.4 			"
          />
          <polygon
            class="st3"
            points="377.1,166 374.3,163.2 374.8,162.6 377.6,165.4 			"
          />
          <polygon
            class="st3"
            points="376.9,163.3 376,162.4 376.6,161.8 377.5,162.7 			"
          />
          <polygon
            class="st3"
            points="379.4,166.4 378.1,165.1 381.8,160.9 383.2,162.1 			"
          />
          <polygon
            class="st3"
            points="375.7,160.1 375,159.4 375.7,158.7 376.4,159.4 			"
          />
          <rect x="390.2" y="109.6" class="st3" width="2.9" height="1.4" />
          <rect x="380.1" y="105.3" class="st3" width="13.6" height="1.4" />
          <rect x="391.7" y="113" class="st3" width="2.9" height="1.4" />
          <rect x="378.3" y="124.2" class="st3" width="2.9" height="1.4" />
          <rect x="374.6" y="122.3" class="st3" width="2.9" height="1.4" />
          <rect x="370.3" y="125.1" class="st3" width="2.1" height="3.8" />
          <rect x="376" y="113.9" class="st3" width="0.8" height="0.8" />
          <polygon
            class="st3"
            points="382,112.9 378.9,116.2 378.3,115.7 381.4,112.3 			"
          />
          <rect x="384.6" y="114.7" class="st3" width="5.6" height="1" />
          <rect x="382.7" y="114.7" class="st3" width="1" height="1" />
          <rect x="381.1" y="114.7" class="st3" width="1" height="1" />
          <rect x="376" y="111.3" class="st3" width="0.6" height="1.3" />
          <rect x="382.7" y="117.4" class="st3" width="1" height="1" />
          <rect x="385.5" y="117.4" class="st3" width="1" height="1" />
          <polygon
            class="st3"
            points="310.3,116.1 309.5,115.6 310.1,114.8 310.9,115.4 			"
          />
          <polygon
            class="st3"
            points="308.7,115 307.9,114.4 308.5,113.6 309.3,114.2 			"
          />
          <polygon
            class="st3"
            points="307.1,117.2 306.3,116.6 306.9,115.9 307.7,116.4 			"
          />
          <polygon
            class="st3"
            points="309.2,117.5 310.8,118.7 310.3,119.5 308.7,118.3 			"
          />
          <polygon
            class="st3"
            points="295.6,111 297.5,110.3 297.9,111.2 296,111.9 			"
          />
          <polygon
            class="st3"
            points="297.2,112.9 299.1,112.2 299.4,113.1 297.6,113.8 			"
          />
          <polygon
            class="st3"
            points="298.8,114.7 300.7,114.1 301,115 299.1,115.6 			"
          />
          <polygon
            class="st3"
            points="384.4,122.8 384,122.2 389.2,118.2 389.6,118.7 			"
          />
          <rect x="378.3" y="117.9" class="st3" width="1.9" height="1.2" />
          <rect x="383.6" y="109.4" class="st3" width="1.5" height="1.5" />
          <rect x="386" y="112" class="st3" width="1" height="1" />
          <polygon
            class="st3"
            points="289.1,106.3 288.2,106.7 287.8,105.9 288.6,105.4 			"
          />
          <polygon
            class="st3"
            points="289.8,107.9 290.7,107.5 291.1,108.3 290.2,108.7 			"
          />

          <rect
            x="282.9"
            y="99.1"
            transform="matrix(0.9947 -0.1031 0.1031 0.9947 -8.8982 29.7697)"
            class="st3"
            width="1.5"
            height="3.7"
          />
          <polygon
            class="st3"
            points="289.2,103.3 288.3,103.8 287.8,102.8 288.7,102.4 			"
          />
          <rect x="378.7" y="110.2" class="st3" width="1.3" height="0.7" />

          <rect
            x="294.6"
            y="92.6"
            transform="matrix(3.689943e-02 -0.9993 0.9993 3.689943e-02 191.1928 384.4608)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="296.5"
            y="92.7"
            transform="matrix(3.690390e-02 -0.9993 0.9993 3.690390e-02 192.9512 386.4264)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="310.7"
            y="100.2"
            transform="matrix(3.692606e-02 -0.9993 0.9993 3.692606e-02 199.0459 407.8649)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="308.7"
            y="100.1"
            transform="matrix(3.692606e-02 -0.9993 0.9993 3.692606e-02 197.2276 405.8333)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="307.1"
            y="100.1"
            transform="matrix(3.682100e-02 -0.9993 0.9993 3.682100e-02 195.7933 404.2066)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="303"
            y="93.9"
            transform="matrix(3.689561e-02 -0.9993 0.9993 3.689561e-02 195.5043 396.8227)"
            class="st3"
            width="1.3"
            height="6.2"
          />

          <rect
            x="308.6"
            y="102.8"
            transform="matrix(3.682100e-02 -0.9993 0.9993 3.682100e-02 194.4355 408.3737)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="311.4"
            y="103"
            transform="matrix(3.682066e-02 -0.9993 0.9993 3.682066e-02 197.0753 411.3229)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="310.9"
            y="107.6"
            transform="matrix(3.686887e-02 -0.9993 0.9993 3.686887e-02 192.5329 415.6809)"
            class="st3"
            width="2"
            height="0.7"
          />
          <polygon
            class="st3"
            points="307.1,108.1 307.7,107.4 311.2,110.4 310.6,111.1 			"
          />

          <rect
            x="308.2"
            y="94.6"
            transform="matrix(3.686513e-02 -0.9993 0.9993 3.686513e-02 202.312 400.6216)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="310.5"
            y="97.3"
            transform="matrix(3.691646e-02 -0.9993 0.9993 3.691646e-02 201.8605 405.0201)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="302.8"
            y="94.8"
            transform="matrix(3.690729e-02 -0.9993 0.9993 3.690729e-02 196.5098 394.9251)"
            class="st3"
            width="0.7"
            height="1.3"
          />
          <polygon
            class="st3"
            points="363.3,132.7 362.6,132 366,128.7 366.7,129.5 			"
          />
          <polygon
            class="st3"
            points="359.2,136.7 358.5,135.9 361.9,132.7 362.6,133.4 			"
          />
          <polygon
            class="st3"
            points="355,140.6 354.3,139.8 357.7,136.6 358.4,137.4 			"
          />
          <polygon
            class="st3"
            points="355.8,144.6 354.9,143.7 356.5,142.2 357.4,143.1 			"
          />
          <polygon
            class="st3"
            points="362,140.4 361.3,141.1 360.5,140.2 361.2,139.5 360.3,138.6 358.5,140.2 359.4,141.2 360,140.7 
				360.8,141.5 360.3,142.1 361,142.9 362.8,141.2 			"
          />
          <polygon
            class="st3"
            points="363,139.1 362.3,138.4 365.7,135.3 366.3,135.9 			"
          />
          <polygon
            class="st3"
            points="366.4,141.4 365.7,140.6 368.8,137.7 369.5,138.4 			"
          />
          <polygon
            class="st3"
            points="364.1,140.8 363.7,140.3 364.2,139.9 364.6,140.3 			"
          />
          <polygon
            class="st3"
            points="365.3,142.7 364.8,142.2 365.4,141.6 365.9,142.2 			"
          />
          <polygon
            class="st3"
            points="369,137.5 368.3,136.8 369,136.1 369.7,136.8 			"
          />
          <polygon
            class="st3"
            points="372.4,135.5 367.4,130.2 367.9,129.7 372.9,135 			"
          />
          <polygon
            class="st3"
            points="368.1,134 367.6,133.4 368.8,132.3 369.3,132.9 			"
          />
          <polygon
            class="st3"
            points="379.8,133.5 374.4,127.8 374.9,127.2 380.4,133 			"
          />
          <polygon
            class="st3"
            points="375.6,135.1 373.9,133.2 374.2,132.9 376,134.8 			"
          />
          <polygon
            class="st3"
            points="375.7,138.5 375.5,138.3 377.2,136.7 376.7,136.2 373.5,139.2 372.7,138.4 373,138.2 371.4,136.5 
				370,137.8 371.6,139.5 372.3,138.8 373.1,139.6 372.8,139.9 373.3,140.4 374.5,139.2 374.8,139.4 			"
          />
          <polygon
            class="st3"
            points="357.9,145.8 359.9,143.9 358.9,142.7 356.3,145.2 357.4,146.3 362.3,151.5 362.9,151 			"
          />
          <polygon
            class="st3"
            points="365,150.3 367.5,147.9 366.8,147.2 363.6,150.3 363.9,150.6 364.2,151 366.6,153.5 367.3,152.8 			
				"
          />
          <polygon
            class="st3"
            points="367.1,147 366.4,146.3 367.9,144.9 368.5,145.6 			"
          />
          <polygon
            class="st3"
            points="367.6,145.2 366.1,143.6 366.5,143.3 368,144.8 			"
          />
          <polygon
            class="st3"
            points="368.5,144.9 366.4,142.7 367,142 369.2,144.3 			"
          />
          <polygon
            class="st3"
            points="369.8,143.8 369.2,143.2 370.9,141.5 371.4,142.2 			"
          />
          <polygon
            class="st3"
            points="371.3,140.9 370.5,140.1 371.3,139.3 372.1,140.1 			"
          />
          <polygon
            class="st3"
            points="376.5,134.1 373.4,130.8 374.2,130.1 377.3,133.3 			"
          />
          <polygon
            class="st3"
            points="378.1,136.2 377.6,135.7 379.3,134 379.8,134.6 			"
          />
          <polygon
            class="st3"
            points="364.4,145.9 362,143.4 362.7,142.8 365,145.3 			"
          />
          <polygon
            class="st3"
            points="365.4,144.7 363.8,143.1 364.6,142.4 366.1,144 			"
          />
          <polygon
            class="st3"
            points="302.9,76.3 303.8,75.9 304.2,77.1 303.3,77.4 303.8,78.7 306,77.9 305.6,76.6 304.9,76.8 
				304.5,75.7 305.2,75.5 304.8,74.4 302.6,75.2 			"
          />
          <polygon
            class="st3"
            points="301.5,77 301.8,77.9 297.5,79.5 297.2,78.6 			"
          />
          <polygon
            class="st3"
            points="298.7,71.7 299,72.7 295,74.1 294.7,73.2 			"
          />
          <polygon
            class="st3"
            points="301.1,75.1 301.3,75.7 300.7,75.9 300.5,75.3 			"
          />
          <polygon
            class="st3"
            points="300.9,72.8 301.1,73.5 300.4,73.8 300.1,73 			"
          />
          <polygon
            class="st3"
            points="295.4,76 295.7,77 294.8,77.3 294.5,76.4 			"
          />
          <polygon
            class="st3"
            points="290.8,77 295.5,82.6 294.9,83.1 290.3,77.5 			"
          />
          <polygon
            class="st3"
            points="296.2,79.4 296.4,80.1 294.9,80.6 294.7,80 			"
          />
          <polygon
            class="st3"
            points="303.8,69.3 304.9,72.6 304.1,72.9 303,69.6 			"
          />
          <polygon
            class="st3"
            points="301.6,71 302.3,73.1 301.4,73.4 300.7,71.3 			"
          />
          <polygon
            class="st3"
            points="203.5,145 202.6,142.1 207.2,140.8 208.1,143.7 			"
          />
          <polygon
            class="st3"
            points="262.7,206.7 263.9,208.2 264.6,207.7 263.4,206.1 			"
          />

          <rect
            x="254.2"
            y="203"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 51.1515 459.1364)"
            class="st3"
            width="1.9"
            height="1.9"
          />
          <polygon
            class="st3"
            points="253.6,208 254.1,208.6 256.1,207 255.6,206.4 254.2,207.6 253.5,206.6 252.9,207.1 			"
          />
          <polygon
            class="st3"
            points="213.8,148.2 214.1,147.2 216.7,148.1 216.3,149.1 			"
          />
          <polygon
            class="st3"
            points="212.7,146.8 212.4,147.7 211.5,147.4 211.8,146.5 			"
          />
          <polygon
            class="st3"
            points="209,147 209.3,146.1 211,146.7 210.7,147.6 			"
          />
          <polygon
            class="st3"
            points="261.9,206.3 263,207.7 262.3,208.3 261.2,206.9 			"
          />
          <polygon
            class="st3"
            points="254.4,200.5 253.5,199.3 254.6,198.4 255.5,199.6 			"
          />
          <polygon
            class="st3"
            points="251.6,201.7 251.3,201.9 252.9,204 253.5,203.5 252.2,201.9 253.3,201.1 252.7,200.3 251.3,201.4 
							"
          />
          <polygon
            class="st3"
            points="260.5,211.1 260.1,211.5 260.5,212 261,211.7 261.1,211.6 262,210.9 260.8,209.4 259.8,210.2 			"
          />
          <polygon
            class="st3"
            points="260.8,217.2 260.4,216.8 255,222 254.1,221.1 253.5,221.7 255,223.3 255,223.2 255.6,222.7 
				260.4,218.1 261.9,219.6 262.8,218.6 261.1,216.8 			"
          />
          <polygon
            class="st3"
            points="250.3,207.8 249.9,208.1 250.2,208.4 249.5,208.9 250,209.6 250.7,209.1 252.5,211.3 253.1,210.8 
				251.1,208.2 251.7,207.8 250.9,206.8 250,207.6 			"
          />
          <polygon
            class="st3"
            points="257.2,208 257.7,208.7 257.1,209.1 256.6,208.5 			"
          />
          <polygon
            class="st3"
            points="264.4,202.8 262.5,200.5 259.4,203 258,201.3 256.8,202.2 258.3,204.1 258.5,204.4 257.7,205 
				258.6,206.2 259.8,205.2 260.4,205.9 			"
          />
          <polygon
            class="st3"
            points="233.3,266.1 236.2,265.5 235.4,261.5 237.6,261.1 237.3,259.6 234.9,260 234.6,260.1 234.4,259.1 
				232.9,259.4 233.2,260.9 232.3,261.1 			"
          />
          <polygon
            class="st3"
            points="256.6,210.6 256.2,210 256.8,209.5 257.3,210.1 			"
          />
          <polygon
            class="st3"
            points="251.7,204.9 252,205.3 251.7,205.6 251.3,205.2 			"
          />
          <polygon
            class="st3"
            points="248.5,206.2 249.1,206.9 248.3,207.6 247.7,206.8 			"
          />
          <polygon
            class="st3"
            points="249.3,204.6 248.5,203.6 249.5,202.8 250.3,203.8 			"
          />
          <polygon
            class="st3"
            points="267.9,202 267,200.9 266.1,201.8 267,202.8 266.3,203.4 267.4,204.7 266.6,205.4 267.3,206.2 
				268.1,205.5 268.3,205.3 270.3,203.6 268.5,201.5 			"
          />
          <polygon
            class="st3"
            points="254.8,194.3 255.2,195.4 255.6,195.3 256.3,197.3 256.5,197.9 257.5,197.5 258.6,197.1 258.4,196.5 
				257.3,196.9 256.5,194.6 255.7,194.9 255.4,194 			"
          />
          <polygon
            class="st3"
            points="256.7,191.6 258.1,193.4 257.3,194 255.9,192.2 			"
          />
          <polygon
            class="st3"
            points="255,190.2 255.3,190.6 254.9,190.9 254.6,190.5 			"
          />
          <polygon
            class="st3"
            points="259.5,193.5 260,194.1 259.6,194.4 259.2,193.8 			"
          />
          <polygon
            class="st3"
            points="219.2,148.1 218.5,150.3 219.2,150.6 220,148.3 			"
          />
          <polygon
            class="st3"
            points="250.5,180.5 253.1,183.8 252.1,184.6 249.5,181.3 			"
          />
          <polygon
            class="st3"
            points="248.4,186.2 247.5,185.1 247.9,184.8 247.1,183.8 244.4,186 245.3,187 246.6,185.9 247.5,187 			"
          />
          <polygon
            class="st3"
            points="238.5,192 241.3,189.7 241.6,190 242.9,188.9 242.7,188.6 243.4,188 242.9,187.4 237.9,191.3 			"
          />
          <polygon
            class="st3"
            points="248.5,189.5 249.3,190.5 249.7,190.2 252.6,193.9 254.1,192.7 250.7,188.4 249.4,189.5 249,189 			
				"
          />
          <polygon
            class="st3"
            points="248.6,195.1 249.8,195.4 248.8,199.2 247.6,198.9 			"
          />
          <polygon
            class="st3"
            points="262.4,194.4 263.6,195.8 261.3,197.5 260.2,196.1 			"
          />
          <polygon
            class="st3"
            points="241,192.9 242.1,192 243.4,193.6 243,193.9 243.7,194.9 244.3,194.4 245.6,196 244.6,196.8 
				245.4,197.7 247.3,196.2 246.6,195.3 246.3,195.5 245,193.9 246.3,192.9 245.5,191.9 244.1,193 242.1,190.5 241.7,190.8 
				241.4,191.1 240.3,192 			"
          />
          <polygon
            class="st3"
            points="251.3,196.8 252.2,197.8 251.3,198.5 250.5,197.4 			"
          />
          <polygon
            class="st3"
            points="248,200.4 248.7,201.3 247.8,202 247.1,201.1 			"
          />
          <polygon
            class="st3"
            points="245.3,199.5 243.4,197.2 243.4,197.2 243.4,197.2 241.6,197.1 241.5,198.9 243.2,198.9 244.3,200.3 
							"
          />
          <polygon
            class="st3"
            points="217.6,143.9 215.4,139.8 215.4,139.8 215.4,139.8 212.7,139.2 212.1,141.9 214.6,142.4 215.9,144.8 
							"
          />
          <polygon
            class="st3"
            points="175.6,165.2 171.6,167.4 171.6,167.5 171.6,167.5 171,170.1 173.7,170.7 174.2,168.2 176.5,166.8 
							"
          />
          <polygon
            class="st3"
            points="243.8,203.8 244.6,203.3 245.1,203.9 245.5,203.6 245,202.9 245.3,202.7 244.4,201.6 243,202.7 			
				"
          />
          <polygon
            class="st3"
            points="239.4,193.1 241.9,196.2 241.3,196.6 238.8,193.5 			"
          />
          <polygon
            class="st3"
            points="239.2,195.7 240,196.8 239.4,197.3 238.6,196.3 			"
          />
          <polygon
            class="st3"
            points="237.3,193.2 238.4,194.6 237.9,195 236.8,193.6 			"
          />
          <polygon
            class="st3"
            points="233.9,194.5 234.5,195.3 233.7,195.9 233.1,195.1 			"
          />
          <polygon
            class="st3"
            points="225.6,169.6 226,170.3 225.2,170.7 224.9,169.9 			"
          />
          <polygon
            class="st3"
            points="222.7,168 223.3,168.6 222.6,169.1 222.1,168.5 			"
          />
          <polygon
            class="st3"
            points="224.3,144.5 223.1,145 222.6,143.8 223.8,143.3 			"
          />
          <polygon
            class="st3"
            points="221.9,145.6 220.7,146.1 220.2,144.9 221.4,144.4 			"
          />
          <polygon
            class="st3"
            points="199.6,145.3 199.1,144.1 200.4,143.5 200.9,144.8 			"
          />
          <polygon
            class="st3"
            points="223.9,170.7 224.1,171.2 222.9,171.7 222.7,171.2 			"
          />
          <polygon
            class="st3"
            points="222.3,149.7 221.7,148.4 223,147.9 223.6,149.1 			"
          />
          <polygon
            class="st3"
            points="224.7,151.7 225.2,153 223.9,153.5 223.4,152.3 			"
          />
          <polygon
            class="st3"
            points="230.2,165.3 230.5,166 228.6,166.7 228.3,166.1 			"
          />
          <polygon
            class="st3"
            points="226,165 226.7,166.7 225.6,167.2 224.9,165.4 			"
          />
          <polygon
            class="st3"
            points="221.3,138.8 219.4,139.6 218.6,137.7 220.5,137 			"
          />
          <polygon
            class="st3"
            points="224.8,140.5 223.5,141.1 223,139.7 224.3,139.2 			"
          />
          <polygon
            class="st3"
            points="226.9,146 228.2,146.5 227.9,147.2 226.6,146.7 			"
          />
          <polygon
            class="st3"
            points="236,186 236.6,186.8 233,189.7 232.3,188.9 			"
          />
          <polygon
            class="st3"
            points="240.5,182.4 241.1,183.2 237.4,186.2 236.8,185.4 			"
          />
          <polygon
            class="st3"
            points="244.9,178.9 245.5,179.7 241.9,182.6 241.2,181.8 			"
          />
          <polygon
            class="st3"
            points="244.5,174.8 245.3,175.8 243.6,177.2 242.7,176.1 			"
          />
          <polygon
            class="st3"
            points="237.9,178.4 238.7,177.8 239.4,178.8 238.7,179.4 239.5,180.4 241.4,178.9 240.6,177.9 240,178.4 
				239.2,177.4 239.8,176.9 239.1,176.1 237.3,177.6 			"
          />
          <polygon
            class="st3"
            points="236.8,179.6 237.4,180.4 233.8,183.3 233.2,182.5 			"
          />
          <polygon
            class="st3"
            points="233.6,177.1 234.3,177.9 230.9,180.6 230.3,179.8 			"
          />
          <polygon
            class="st3"
            points="235.8,177.9 236.2,178.4 235.7,178.8 235.4,178.3 			"
          />
          <polygon
            class="st3"
            points="234.8,175.8 235.3,176.4 234.7,176.9 234.2,176.3 			"
          />
          <polygon
            class="st3"
            points="230.8,180.7 231.4,181.5 230.6,182.1 230,181.3 			"
          />
          <polygon
            class="st3"
            points="230.7,156.9 233.6,163.6 232.4,164.1 229.5,157.4 			"
          />
          <polygon
            class="st3"
            points="231.3,184.3 231.8,184.9 230.5,185.9 230.1,185.3 			"
          />
          <polygon
            class="st3"
            points="254.8,185.5 260.1,191.5 259.5,192 254.2,186.1 			"
          />
          <polygon
            class="st3"
            points="225,173.8 227,176.8 226.5,177 224.6,174.1 			"
          />
          <polygon
            class="st3"
            points="231.7,167.4 231.8,167.7 229.6,168.6 229.9,169.2 234,167.5 234.4,168.5 234.1,168.7 235,170.8 
				236.7,170.1 235.8,167.9 235,168.3 234.5,167.3 234.8,167.1 234.6,166.5 233,167.1 232.9,166.9 			"
          />
          <polygon
            class="st3"
            points="242.5,173.4 240.3,175.2 241.3,176.4 244,174.2 243.1,173 238.6,167.3 238,167.8 			"
          />
          <polygon
            class="st3"
            points="228.6,152.5 225.4,153.9 225.8,154.8 229.9,153 229.7,152.5 229.5,152.1 228.1,149 227.2,149.4 			
				"
          />
          <polygon
            class="st3"
            points="231.6,169.8 232.2,170.6 230.6,171.8 230,171.1 			"
          />

          <rect
            x="231.9"
            y="171.7"
            transform="matrix(0.1115 -0.9938 0.9938 0.1115 36.3566 385.1341)"
            class="st3"
            width="3.2"
            height="1.1"
          />
          <polygon
            class="st3"
            points="231.8,173.4 233.8,175.8 233.1,176.4 231.1,173.9 			"
          />
          <polygon
            class="st3"
            points="230.5,174.4 231,175.1 229.2,176.5 228.7,175.9 			"
          />
          <polygon
            class="st3"
            points="228.7,177.2 229.4,178 228.5,178.7 227.9,177.9 			"
          />
          <polygon
            class="st3"
            points="224.5,175.5 227,179.2 226.1,179.8 223.6,176.1 			"
          />
          <polygon
            class="st3"
            points="226.3,171.5 226.6,172.2 222.5,174.1 222.2,173.4 			"
          />
          <polygon
            class="st3"
            points="236,172.7 238.2,175.4 237.5,176 235.4,173.3 			"
          />
          <polygon
            class="st3"
            points="235,173.9 236.3,175.6 235.5,176.2 234.2,174.5 			"
          />
          <polygon
            class="st3"
            points="304.7,217.4 304.8,217.6 303.5,218.7 303.2,218.2 300.8,220.2 301.2,220.6 301.8,221.4 301.6,221.6 
				302.9,223.3 305.6,221.1 304.9,220.3 306.2,219.3 308.6,217.3 307.2,215.6 307.1,215.5 			"
          />
          <polygon
            class="st3"
            points="315.4,218.3 317,217.3 319.6,221.6 318,222.5 			"
          />
          <polygon
            class="st3"
            points="325.3,212.7 325.7,213.7 324.8,214.1 324.3,213.2 			"
          />
          <polygon
            class="st3"
            points="323,214.1 323.4,214.8 321.8,215.6 321.5,214.9 			"
          />
          <polygon
            class="st3"
            points="335.8,222.6 336.6,223.6 332.3,227.1 331.4,226.1 			"
          />
          <polygon
            class="st3"
            points="337.4,220.9 338,221.7 337.2,222.3 336.6,221.5 			"
          />
          <polygon
            class="st3"
            points="300.3,224 301.1,225 300.1,225.8 299.3,224.8 			"
          />
          <polygon
            class="st3"
            points="324.5,218.3 327.1,222.2 326.5,222.5 324,218.7 			"
          />
          <polygon
            class="st3"
            points="333.1,213 333.9,214 331.8,215.7 331,214.7 			"
          />

          <rect
            x="292.6"
            y="196.1"
            transform="matrix(0.1115 -0.9938 0.9938 0.1115 66.2659 467.7272)"
            class="st3"
            width="4.2"
            height="1.5"
          />
          <polygon
            class="st3"
            points="305.3,196 302.2,198.8 301.2,197.6 304.3,194.8 			"
          />
          <polygon
            class="st3"
            points="302.1,191.3 300.2,193.1 298.7,191.5 300.7,189.7 			"
          />
          <polygon
            class="st3"
            points="333.4,217.7 336,220.9 335.1,221.7 332.5,218.4 			"
          />
          <polygon
            class="st3"
            points="331.7,219.1 332.4,219.9 330,221.8 329.3,221 			"
          />
          <polygon
            class="st3"
            points="329.4,222.7 330.3,223.8 329.1,224.7 328.2,223.6 			"
          />
          <polygon
            class="st3"
            points="323.9,220.5 327.1,225.4 325.9,226.1 322.7,221.2 			"
          />
          <polygon
            class="st3"
            points="326.1,215.2 326.6,216.2 321.2,218.7 320.8,217.8 			"
          />
          <polygon
            class="st3"
            points="337.5,218.4 339.3,220.6 338.3,221.5 336.5,219.2 			"
          />
          <polygon
            class="st3"
            points="191.5,168.7 193.3,170.7 191.6,172.1 189.9,170.2 188.1,171.8 192.5,176.7 194.3,175.1 192.9,173.5 
				194.5,172.1 194.9,172.4 196.2,171.2 193,167.4 			"
          />
          <polygon
            class="st3"
            points="213.3,188 213.8,188.6 213.2,189.1 212.7,188.5 			"
          />
          <polygon
            class="st3"
            points="214.5,184.9 216.6,187.6 216,188.2 213.8,185.5 			"
          />
          <polygon
            class="st3"
            points="213.4,186.1 214.8,187.8 214,188.4 212.6,186.7 			"
          />
          <polygon
            class="st3"
            points="199.3,55.5 198.4,55.9 196.7,51.5 197.7,51.1 			"
          />
          <polygon
            class="st3"
            points="201.4,60.9 200.4,61.2 198.8,56.8 199.7,56.5 			"
          />
          <polygon
            class="st3"
            points="203.4,66.2 202.5,66.6 200.8,62.2 201.8,61.8 			"
          />
          <polygon
            class="st3"
            points="207.8,65.7 206.5,66 206,63.9 207.3,63.5 			"
          />
          <polygon
            class="st3"
            points="216.4,58.2 216.6,59.1 215.4,59.4 215.2,58.5 213.9,58.8 214.5,61.2 215.8,60.8 215.6,60.1 
				216.8,59.8 217,60.6 218,60.3 217.4,58 			"
          />
          <polygon
            class="st3"
            points="204.6,56.3 203.7,56.5 202.6,52 203.5,51.8 			"
          />
          <polygon
            class="st3"
            points="214.7,51.1 213.7,51.4 212.7,47.2 213.7,47 			"
          />
          <polygon
            class="st3"
            points="205.1,61.3 204,60.7 205.4,57.8 206.6,58.4 			"
          />

          <rect
            x="206.9"
            y="54.4"
            transform="matrix(0.9713 -0.2378 0.2378 0.9713 -7.0688 50.8467)"
            class="st3"
            width="0.6"
            height="0.6"
          />
          <polygon
            class="st3"
            points="209.9,54.9 209.2,55.1 209,54.3 209.8,54.1 			"
          />
          <polygon
            class="st3"
            points="224.9,61.8 224.1,61.9 223.9,61.2 224.7,61 			"
          />
          <polygon
            class="st3"
            points="210.4,49 209.5,49.3 209.2,48.3 210.2,48.1 			"
          />
          <polygon
            class="st3"
            points="209.5,44.7 202.4,46.4 202.2,45.7 209.3,44 			"
          />

          <rect
            x="203.8"
            y="48.4"
            transform="matrix(0.9713 -0.2378 0.2378 0.9713 -5.8358 49.9475)"
            class="st3"
            width="0.7"
            height="1.6"
          />
          <polygon
            class="st3"
            points="215.3,43.1 211.2,44.1 210,39.1 214,38.1 			"
          />
          <rect
            x="224.5"
            y="44"
            transform="matrix(0.9713 -0.2378 0.2378 0.9713 -4.033 54.9773)"
            class="st3"
            width="2.6"
            height="0.5"
          />
          <polygon
            class="st3"
            points="230.2,45.8 229.9,45.9 229.3,43.6 228.6,43.8 229.7,48.1 228.6,48.3 228.5,48 226.2,48.5 
				226.7,50.3 228.9,49.8 228.7,48.9 229.8,48.6 229.9,49 230.6,48.8 230.2,47.1 230.5,47.1 			"
          />
          <polygon
            class="st3"
            points="209.8,64.4 209.2,61.7 207.7,62.1 208.5,65.5 210,65.2 217,63.5 216.8,62.7 			"
          />
          <polygon
            class="st3"
            points="231.7,59.4 228.3,60.1 228.5,61 232.8,60.2 232.8,59.7 232.7,59.2 232,55.9 231,56.1 			"
          />
          <polygon
            class="st3"
            points="231.7,54.7 230.4,55 229.7,52.3 231,51.9 			"
          />

          <rect
            x="226.8"
            y="52.5"
            transform="matrix(0.9713 -0.2378 0.2378 0.9713 -6.0087 55.8125)"
            class="st3"
            width="3"
            height="0.7"
          />
          <polygon
            class="st3"
            points="211.9,57.3 208.8,58.1 208.6,57.2 211.7,56.4 			"
          />
          <polygon
            class="st3"
            points="220.8,58.2 218.8,58.7 218,55.4 220,54.9 			"
          />
          <polygon
            class="st3"
            points="225.2,49 218,50.7 217.4,48.5 224.6,46.7 			"
          />
          <polygon
            class="st3"
            points="223.4,46.2 222.4,46.5 222.1,45.4 223.2,45.1 			"
          />
          <polygon
            class="st3"
            points="226.7,42.9 222.3,44 222.1,42.9 226.4,41.8 			"
          />
          <polygon
            class="st3"
            points="219,47.7 218.2,47.8 217.6,45.6 218.4,45.4 			"
          />
          <polygon
            class="st3"
            points="228.5,57.2 223.9,58.4 223.6,57.2 228.2,56.1 			"
          />
          <polygon
            class="st3"
            points="227.7,55.2 224.7,55.9 224.4,54.6 227.4,53.8 			"
          />

          <rect
            x="49.8"
            y="80.1"
            transform="matrix(0.1444 -0.9895 0.9895 0.1444 -35.7002 119.9856)"
            class="st3"
            width="3.5"
            height="1"
          />

          <rect
            x="49.2"
            y="86.6"
            transform="matrix(0.1445 -0.9895 0.9895 0.1445 -42.9315 124.5958)"
            class="st3"
            width="2.7"
            height="1"
          />

          <rect
            x="49.2"
            y="90.5"
            transform="matrix(0.1444 -0.9895 0.9895 0.1444 -47.2459 127.3647)"
            class="st3"
            width="1.6"
            height="1"
          />

          <rect
            x="47.3"
            y="95.8"
            transform="matrix(0.1444 -0.9895 0.9895 0.1444 -54.0317 130.3877)"
            class="st3"
            width="2.2"
            height="1.3"
          />
          <polygon
            class="st3"
            points="64.6,134.7 64.6,135.6 63.4,135.6 63.4,134.6 62.1,134.6 62,137 63.3,137.1 63.4,136.3 64.6,136.3 
				64.5,137.1 65.6,137.1 65.7,134.7 			"
          />

          <rect
            x="62.2"
            y="127.7"
            transform="matrix(4.081666e-02 -0.9992 0.9992 4.081666e-02 -66.1956 187.3814)"
            class="st3"
            width="4.6"
            height="1"
          />

          <rect
            x="66.3"
            y="127.1"
            transform="matrix(4.083060e-02 -0.9992 0.9992 4.083060e-02 -61.8004 190.7561)"
            class="st3"
            width="4.3"
            height="1"
          />

          <rect
            x="66.2"
            y="130.2"
            transform="matrix(4.071806e-02 -0.9992 0.9992 4.071806e-02 -66.6133 191.6957)"
            class="st3"
            width="0.6"
            height="0.6"
          />

          <rect
            x="65.3"
            y="132"
            transform="matrix(4.083223e-02 -0.9992 0.9992 4.083223e-02 -69.1977 192.5982)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="67.1"
            y="124.2"
            transform="matrix(4.072429e-02 -0.9992 0.9992 4.072429e-02 -59.7284 187.0961)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="65.1"
            y="117.2"
            transform="matrix(4.076359e-02 -0.9992 0.9992 4.076359e-02 -57.9045 181.3214)"
            class="st3"
            width="0.7"
            height="7.3"
          />

          <rect
            x="63.8"
            y="122.1"
            transform="matrix(4.075162e-02 -0.9992 0.9992 4.075162e-02 -60.3309 181.9983)"
            class="st3"
            width="1.6"
            height="0.7"
          />

          <rect
            x="74.8"
            y="117.5"
            transform="matrix(4.077468e-02 -0.9992 0.9992 4.077468e-02 -48.2046 190.693)"
            class="st3"
            width="0.8"
            height="5.8"
          />

          <rect
            x="65.7"
            y="82.8"
            transform="matrix(0.1444 -0.9895 0.9895 0.1444 -26.8464 137.2004)"
            class="st3"
            width="0.5"
            height="2.6"
          />
          <polygon
            class="st3"
            points="67.1,89 66.8,89 66.9,86.6 66.2,86.6 66,91 64.9,91 64.9,90.6 62.6,90.5 62.6,92.4 64.9,92.5 
				64.9,91.6 66,91.6 66,92 66.7,92 66.8,90.3 67.1,90.3 			"
          />
          <polygon
            class="st3"
            points="69.5,140.5 67.7,138.4 66.5,139.4 68.8,142.1 70,141.1 75.4,136.4 74.9,135.9 			"
          />
          <polygon
            class="st3"
            points="78.1,130.5 74.7,130.8 74.8,131.7 79.2,131.3 79.1,130.8 79.1,130.4 78.8,127 77.8,127.1 			"
          />

          <rect
            x="71.8"
            y="131.8"
            transform="matrix(4.081655e-02 -0.9992 0.9992 4.081655e-02 -62.2617 199.6052)"
            class="st3"
            width="2"
            height="1"
          />

          <rect
            x="71.1"
            y="130.2"
            transform="matrix(4.077094e-02 -0.9992 0.9992 4.077094e-02 -62.7743 197.2108)"
            class="st3"
            width="0.5"
            height="2.1"
          />

          <rect
            x="70.9"
            y="128.7"
            transform="matrix(4.074080e-02 -0.9992 0.9992 4.074080e-02 -61.7317 196.2501)"
            class="st3"
            width="0.9"
            height="3.1"
          />

          <rect
            x="71.4"
            y="127.5"
            transform="matrix(4.067758e-02 -0.9992 0.9992 4.067758e-02 -58.1852 195.2334)"
            class="st3"
            width="2.3"
            height="0.9"
          />

          <rect
            x="71"
            y="124.8"
            transform="matrix(4.075665e-02 -0.9992 0.9992 4.075665e-02 -56.6576 191.7275)"
            class="st3"
            width="1.1"
            height="1.1"
          />

          <rect
            x="73.3"
            y="120.8"
            transform="matrix(4.075911e-02 -0.9992 0.9992 4.075911e-02 -52.1042 191.8292)"
            class="st3"
            width="1.1"
            height="4.5"
          />
          <polygon
            class="st3"
            points="79.2,81.3 78.5,80.9 79.9,79 80.5,79.4 			"
          />

          <rect
            x="68.7"
            y="135"
            transform="matrix(4.076510e-02 -0.9992 0.9992 4.076510e-02 -70.2271 200.247)"
            class="st3"
            width="0.9"
            height="3.4"
          />

          <rect
            x="68.7"
            y="132.8"
            transform="matrix(4.075343e-02 -0.9992 0.9992 4.075343e-02 -67.4247 197.5589)"
            class="st3"
            width="1"
            height="2.2"
          />
          <polygon
            class="st3"
            points="72.1,219.7 72,222.2 69,222 69.1,219.6 65.7,219.5 65.4,225.6 68.8,225.7 68.9,223.8 71.9,223.9 
				71.9,225.9 74.6,226 74.8,219.9 			"
          />

          <rect
            x="143.5"
            y="247.6"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -45.6496 33.4147)"
            class="st3"
            width="6.2"
            height="1.3"
          />

          <rect
            x="141.8"
            y="242.6"
            transform="matrix(0.9807 -0.1953 0.1953 0.9807 -44.7199 32.926)"
            class="st3"
            width="5.7"
            height="1.4"
          />

          <rect
            x="148.7"
            y="244.4"
            transform="matrix(0.9807 -0.1953 0.1953 0.9807 -44.946 33.835)"
            class="st3"
            width="0.9"
            height="0.9"
          />

          <rect
            x="151.2"
            y="244.8"
            transform="matrix(0.9808 -0.1952 0.1952 0.9808 -44.9745 34.3368)"
            class="st3"
            width="1"
            height="1"
          />

          <rect
            x="140.4"
            y="244.7"
            transform="matrix(0.9808 -0.1952 0.1952 0.9808 -45.1837 32.2714)"
            class="st3"
            width="1.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="92.9,232 91.9,231.7 93.4,227.7 94.4,228.1 			"
          />
          <polygon
            class="st3"
            points="93.5,227.5 92.6,227.1 92.9,226.2 93.9,226.5 			"
          />
          <polygon
            class="st3"
            points="159.3,255.8 156.5,256.4 156.9,257.9 160.3,257.2 160,255.7 158.5,248.7 157.8,248.8 			"
          />
          <polygon
            class="st3"
            points="170.2,254.3 169.6,250.9 170.5,250.7 171.3,255.1 170.9,255.2 170.4,255.3 167.1,255.9 166.9,254.9 
							"
          />
          <polygon
            class="st3"
            points="151.7,235.3 151.4,233.6 147.9,234.3 148.2,236 148,236 148.8,239.8 149.6,239.6 148.9,235.9 			"
          />

          <rect
            x="146.6"
            y="236.5"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -43.759 33.3428)"
            class="st3"
            width="1.2"
            height="4.2"
          />

          <rect
            x="142.2"
            y="237.2"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -43.6559 32.6506)"
            class="st3"
            width="3.1"
            height="1.2"
          />

          <rect
            x="140"
            y="239.2"
            transform="matrix(0.9807 -0.1953 0.1953 0.9807 -44.1432 32.1126)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="88.3"
            y="233"
            transform="matrix(4.075221e-02 -0.9992 0.9992 4.075221e-02 -147.4451 313.6603)"
            class="st3"
            width="2.7"
            height="1.3"
          />

          <rect
            x="87.3"
            y="230.9"
            transform="matrix(4.079366e-02 -0.9992 0.9992 4.079366e-02 -148.1447 310.4094)"
            class="st3"
            width="0.7"
            height="2.9"
          />
          <polygon
            class="st3"
            points="87.7,231.2 84.3,228.6 85,227.6 88.4,230.2 			"
          />
          <polygon
            class="st3"
            points="90,229.1 89,228.7 90.3,225.8 91.3,226.3 			"
          />

          <rect
            x="87.1"
            y="223.6"
            transform="matrix(4.073883e-02 -0.9992 0.9992 4.073883e-02 -139.8831 303.0162)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="155.7"
            y="237.1"
            transform="matrix(0.9807 -0.1953 0.1953 0.9807 -43.7462 35.1315)"
            class="st3"
            width="1.1"
            height="4.6"
          />

          <rect
            x="152"
            y="238.8"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -43.9859 34.4313)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <polygon
            class="st3"
            points="168.5,276.1 168.4,275.2 169.9,275 170,275.4 172.3,275.1 172.3,274.7 172,272.9 169.7,273.2 
				168.1,273.4 165.8,273.8 166.1,275.6 166.2,276.4 			"
          />

          <rect
            x="167.8"
            y="278.4"
            transform="matrix(0.9951 -9.929075e-02 9.929075e-02 0.9951 -26.8941 18.1459)"
            class="st3"
            width="2.1"
            height="1.7"
          />

          <rect
            x="30.7"
            y="107.4"
            transform="matrix(6.217200e-02 -0.9981 0.9981 6.217200e-02 -77.0771 134.5226)"
            class="st3"
            width="4.6"
            height="1.7"
          />
          <polygon
            class="st3"
            points="36.1,108.6 39.3,107.2 39.9,108.4 36.6,109.9 			"
          />
          <polygon
            class="st3"
            points="41.8,111.8 41.5,111.2 41.2,110 40,110.3 40.4,111.5 38.6,112.1 38.8,112.8 			"
          />
          <polygon
            class="st3"
            points="163.8,283.6 164,285.5 164.6,285.5 164.8,287.5 165.4,287.4 165.2,285.4 165,283.5 			"
          />

          <rect
            x="162.8"
            y="288.2"
            transform="matrix(0.9979 -6.445970e-02 6.445970e-02 0.9979 -18.2619 11.1815)"
            class="st3"
            width="2.6"
            height="0.9"
          />

          <rect
            x="167"
            y="284.3"
            transform="matrix(0.995 -9.974878e-02 9.974878e-02 0.995 -27.7129 18.1254)"
            class="st3"
            width="0.8"
            height="3.9"
          />
          <polygon
            class="st3"
            points="166.7,281 167.6,281.5 167.1,282.4 166.2,281.9 			"
          />
          <polygon
            class="st3"
            points="39.5,119.1 40.1,119.6 41.7,117.8 40.6,116.8 39.1,118.4 38.2,117.6 39.6,115.9 37.6,114.1 
				35.1,116.8 37.2,118.7 38.7,120 			"
          />
          <polygon
            class="st3"
            points="33.5,115.1 33.6,116.1 35.1,115.8 34.9,114.9 33.8,115.1 33.5,113.3 32.9,113.4 33.1,115.2 			"
          />

          <rect
            x="41.6"
            y="119.8"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -23.0377 10.5766)"
            class="st3"
            width="1.1"
            height="4.6"
          />

          <rect
            x="44.6"
            y="126.4"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -24.1055 11.3061)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="46.5"
            y="131"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -24.953 11.7638)"
            class="st3"
            width="1.4"
            height="2.9"
          />

          <rect
            x="48.4"
            y="135.5"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -25.8014 12.222)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <polygon
            class="st3"
            points="190,55 189.8,54 191,53.8 191.2,54.7 192.5,54.5 192,52.1 190.7,52.4 190.9,53.1 189.7,53.3 
				189.6,52.6 188.5,52.8 188.9,55.2 			"
          />

          <rect
            x="192.5"
            y="56.3"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -7.5905 37.4186)"
            class="st3"
            width="1"
            height="4.6"
          />
          <rect
            x="187.1"
            y="58"
            transform="matrix(0.9821 -0.1886 0.1886 0.9821 -7.8782 36.4512)"
            class="st3"
            width="1"
            height="3.2"
          />

          <rect
            x="188.6"
            y="56.5"
            transform="matrix(0.9821 -0.1886 0.1886 0.9821 -7.3239 36.65)"
            class="st3"
            width="0.6"
            height="0.6"
          />

          <rect
            x="186.3"
            y="56.4"
            transform="matrix(0.9821 -0.1886 0.1886 0.9821 -7.3634 36.2232)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <rect
            x="181.9"
            y="53.4"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -6.8863 35.3546)"
            class="st3"
            width="1"
            height="1"
          />
          <polygon
            class="st3"
            points="178.7,54.9 179.2,57.7 180.7,57.4 180,53.9 178.5,54.2 171.5,55.5 171.6,56.3 			"
          />
          <rect x="241.3" y="211.7" class="st3" width="0.6" height="2.6" />
          <rect x="242.6" y="215.1" class="st3" width="2.8" height="1.2" />
          <rect x="203.8" y="210.6" class="st3" width="2.8" height="1.2" />
          <rect x="244" y="213.3" class="st3" width="2.4" height="1.3" />
          <polygon
            class="st3"
            points="245.3,209.5 245.9,210.1 246.6,209.3 246,208.8 245.9,208.6 244.8,207.5 243,209.4 244.2,210.6 			
				"
          />
          <polygon
            class="st3"
            points="235.5,208.9 235.5,208.3 234.4,208.3 234.4,208.9 233.7,208.9 233.7,209.5 232.8,209.5 232.8,209.9 
				233.7,209.9 233.9,209.9 236.1,209.9 236.1,208.9 			"
          />
          <rect x="167.8" y="173.6" class="st3" width="3.3" height="1.1" />
          <polygon
            class="st3"
            points="201.1,167 197.6,168.2 197.1,166.8 200.5,165.5 			"
          />
          <polygon
            class="st3"
            points="203.3,169.5 202.6,169.8 202.3,169.1 203,168.8 			"
          />
          <polygon
            class="st3"
            points="200.6,173.5 195.9,175.2 195.4,173.8 200.1,172 			"
          />
          <polygon
            class="st3"
            points="181.5,164.4 182.6,163.6 185.2,167.5 184.1,168.3 			"
          />
          <polygon
            class="st3"
            points="176.1,180.1 175.4,178 171.2,179.4 171,178.7 168.9,179.4 169.6,181.4 171.6,180.8 171.5,180.2 
				171.8,181.4 			"
          />
          <polygon
            class="st3"
            points="189.5,165.7 190.1,165.4 187.6,161.5 186.5,162.1 188.5,165.2 186.4,166.5 187.3,168 189.9,166.3 
							"
          />
          <polygon
            class="st3"
            points="181.4,162 180.2,162.4 179.8,161.2 181,160.9 			"
          />
          <polygon
            class="st3"
            points="176.9,173.6 175.7,174 175.3,172.8 176.5,172.5 			"
          />
          <polygon
            class="st3"
            points="183.4,157.4 182.4,157.7 182.1,156.7 183.1,156.4 			"
          />
          <polygon
            class="st3"
            points="184.7,158.8 186.1,158.4 186.5,159.7 185.2,160.2 			"
          />
          <polygon
            class="st3"
            points="238.5,214.4 236.1,213.2 237,211.6 239.3,212.8 			"
          />
          <polygon
            class="st3"
            points="161.9,166.9 161.2,165 158.3,166 158.9,167.9 161.2,167.1 162.5,170.8 163.8,170.4 162.5,166.7 			
				"
          />
          <polygon
            class="st3"
            points="238.3,207.4 237,205.7 240.3,203.1 241.6,204.8 			"
          />
          <polygon
            class="st3"
            points="197.3,170.9 196.5,171.2 196,169.9 196.8,169.6 			"
          />
          <polygon
            class="st3"
            points="231.8,222.6 230.7,221.9 231.4,220.8 232.5,221.5 			"
          />
          <polygon
            class="st3"
            points="233.2,212.6 234.4,213 234.1,214.3 232.8,213.9 			"
          />
          <polygon
            class="st3"
            points="236.3,218 234.5,217 235.6,215.2 237.4,216.3 			"
          />
          <polygon
            class="st3"
            points="233.1,218 229.8,216 230.5,214.7 233.8,216.8 			"
          />
          <polygon
            class="st3"
            points="200.7,193.1 198.8,194.9 197.6,193.6 199.5,191.8 			"
          />
          <polygon
            class="st3"
            points="207,202.3 205.9,203.2 205.1,202.1 206.1,201.3 			"
          />
          <polygon
            class="st3"
            points="197.2,197.5 198,196.4 199,197.2 198.2,198.3 			"
          />
          <polygon
            class="st3"
            points="203.3,196.4 201.7,197.7 200.4,196 202.1,194.8 			"
          />
          <polygon
            class="st3"
            points="203.9,200.3 200.8,202.7 199.9,201.5 203,199.2 			"
          />
          <polygon
            class="st3"
            points="180.6,171.5 179,172.9 179.7,173.8 181.8,172.1 181.1,171.2 177.6,167 177.1,167.3 			"
          />

          <rect
            x="175.8"
            y="267.2"
            transform="matrix(0.9891 -0.1473 0.1473 0.9891 -37.5195 29.0853)"
            class="st3"
            width="3.6"
            height="1.4"
          />

          <rect
            x="177.7"
            y="262.3"
            transform="matrix(0.9891 -0.1473 0.1473 0.9891 -36.8185 29.2504)"
            class="st3"
            width="2.8"
            height="1.9"
          />
          <polygon
            class="st3"
            points="178.9,256.1 179.2,257.8 178.3,257.9 178.6,259.8 177.7,259.9 177.5,258.1 177.2,256.3 			"
          />
          <polygon
            class="st3"
            points="174.7,257.9 174.6,257.3 176,257.1 176.2,258.3 174.9,258.5 175,259.4 176.3,259.2 176.6,261.3 
				174.5,261.7 174.2,259.5 173.9,258 			"
          />
          <polygon
            class="st3"
            points="175.5,263.7 175.4,262.8 176.9,262.6 177,263.5 175.8,263.7 176.1,265.6 175.5,265.7 175.2,263.8 
							"
          />
          <polygon
            class="st3"
            points="175.4,252.2 174.8,248.6 173.3,248.9 173.9,252.5 173,252.6 173.3,254.6 177.8,253.8 177.5,251.9 
							"
          />
          <polygon
            class="st3"
            points="209.7,261.7 211.5,263.7 209.9,265.1 208.1,263.2 206.3,264.8 210.8,269.7 212.5,268.1 211.2,266.6 
				212.8,265.1 213.1,265.4 214.4,264.2 211.2,260.4 			"
          />
          <polygon
            class="st3"
            points="219.3,260 215.8,261.3 215.3,259.8 218.7,258.5 			"
          />
          <polygon
            class="st3"
            points="218.9,266.5 214.1,268.2 213.6,266.8 218.3,265 			"
          />
          <polygon
            class="st3"
            points="173,241 174.2,241.5 172.5,245.8 171.2,245.2 			"
          />
          <polygon
            class="st3"
            points="183,260.5 182.3,260.5 182,255.9 183.3,255.8 183.5,259.4 186,259.3 186.1,261 183,261.2 			"
          />
          <polygon
            class="st3"
            points="220.4,274.1 219.2,274.5 218.8,273.3 220,272.9 			"
          />
          <polygon
            class="st3"
            points="222.4,269.5 221.4,269.8 221.1,268.8 222.1,268.4 			"
          />
          <polygon
            class="st3"
            points="223.7,270.9 225.1,270.4 225.5,271.8 224.2,272.2 			"
          />
          <polygon
            class="st3"
            points="215.5,263.9 214.7,264.2 214.2,262.9 215.1,262.6 			"
          />
          <polygon
            class="st3"
            points="169.5,305.6 166,304.4 165.1,307 169.5,308.6 170.4,306 174.6,294 173.6,293.6 			"
          />

          <rect
            x="183"
            y="56.9"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -7.4761 35.7273)"
            class="st3"
            width="2.1"
            height="0.5"
          />

          <rect
            x="182.7"
            y="57.7"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -7.6613 35.7881)"
            class="st3"
            width="3.1"
            height="0.9"
          />

          <rect
            x="183.8"
            y="63.9"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -8.8518 35.8912)"
            class="st3"
            width="1.1"
            height="1.1"
          />

          <rect
            x="184.1"
            y="53.7"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -6.8648 36.0087)"
            class="st3"
            width="3.4"
            height="0.9"
          />

          <rect
            x="184.7"
            y="55.3"
            transform="matrix(0.9821 -0.1885 0.1885 0.9821 -7.1822 36.0244)"
            class="st3"
            width="2.2"
            height="1"
          />
          <rect x="176" y="75.2" class="st3" width="1.2" height="5.7" />
          <rect x="176" y="82.1" class="st3" width="1.2" height="5.7" />
          <rect
            x="164"
            y="76.6"
            transform="matrix(0.154 -0.9881 0.9881 0.154 64.8199 230.1569)"
            class="st3"
            width="5.7"
            height="1.2"
          />
          <polygon
            class="st3"
            points="178.3,85.4 177.5,84 179.8,82.6 180.6,84 			"
          />
          <polygon
            class="st3"
            points="197.7,81.6 197.7,82.8 196.3,82.8 196.3,81.6 194.7,81.6 194.7,84.6 196.3,84.6 196.3,83.7 
				197.7,83.7 197.7,84.6 199.1,84.6 199.1,81.6 			"
          />
          <rect x="181.5" y="80.9" class="st3" width="1.2" height="5.6" />
          <rect x="186.2" y="80.2" class="st3" width="1.2" height="5.2" />
          <rect x="184.3" y="86.1" class="st3" width="0.8" height="0.8" />
          <rect x="186.8" y="86.5" class="st3" width="0.9" height="0.9" />
          <rect x="169.1" y="76.4" class="st3" width="1.2" height="1.2" />
          <rect x="170.6" y="79.4" class="st3" width="3" height="1.2" />
          <rect x="178.5" y="74.3" class="st3" width="8.8" height="0.9" />
          <rect x="181.5" y="75.8" class="st3" width="0.9" height="1.9" />
          <rect x="188.6" y="73.3" class="st3" width="9.7" height="1" />
          <rect x="192.9" y="78.7" class="st3" width="3.2" height="0.6" />
          <polygon
            class="st3"
            points="200.8,92.5 201.2,92.3 202.6,94.8 203.3,94.4 200.7,89.7 201.8,89 202.1,89.4 204.5,88 203.4,86 
				200.9,87.4 201.5,88.4 200.3,89.1 200.1,88.7 199.3,89.2 200.4,91 200,91.2 			"
          />
          <polygon
            class="st3"
            points="166.1,82.4 166.4,81.4 165.5,81.1 164.9,83.4 165.8,83.6 169.9,84.8 170.2,83.5 			"
          />
          <polygon
            class="st3"
            points="173.5,149.3 179.8,150.5 179.6,151.4 182.5,152 182.7,151.1 184.2,151.4 184.5,149.4 173.9,147.4 
							"
          />
          <polygon
            class="st3"
            points="162.4,126.1 163.1,125.5 160.9,123.1 160.2,123.8 161.8,125.4 159.6,127.6 160.3,128.2 			"
          />
          <polygon
            class="st3"
            points="174.4,131.1 177.3,128.7 176.3,127.5 173.4,129.9 172.7,129.2 171.1,130.5 174.2,134.2 175.8,132.9 
							"
          />
          <polygon
            class="st3"
            points="149.4,137.2 148.1,136 148.8,135.3 150,136.6 			"
          />
          <polygon
            class="st3"
            points="168.6,127.5 166.7,125.2 168.3,123.8 170.3,126.2 			"
          />
          <polygon
            class="st3"
            points="173.4,142 172.4,140.8 171.8,141.3 170.8,140 170.2,140.5 171.2,141.8 172.2,142.9 			"
          />
          <polygon
            class="st3"
            points="160.7,157.2 158.5,155.7 161.2,151.7 163.4,153.2 			"
          />
          <polygon
            class="st3"
            points="166,129.4 166.5,129.1 165.6,128 164.7,128.8 165.5,129.7 164.8,130.3 164,129.3 162.4,130.7 
				163.7,132.3 165.3,131 166.5,130 			"
          />
          <polygon
            class="st3"
            points="171,128.7 171.8,128 170.8,126.8 170,127.5 170.8,128.4 169.2,129.8 169.6,130.3 171.2,129 			"
          />
          <polygon
            class="st3"
            points="169.4,133.2 168,131.5 167.2,132.1 168.6,133.8 			"
          />
          <polygon
            class="st3"
            points="212.6,240.2 211.9,240.2 211.9,238.6 212.2,238.6 212.2,237.7 211.7,237.7 211.6,236.1 212.2,236 
				212.2,235.1 210.3,235.2 210.3,236.1 210.6,236.1 210.6,237.7 209.4,237.7 209.4,240.2 210.8,240.2 210.8,241.1 211.2,241.1 
				211.5,241.1 212.6,241.1 			"
          />

          <rect
            x="222.9"
            y="229.2"
            transform="matrix(6.938774e-02 -0.9976 0.9976 6.938774e-02 -19.8969 438.3878)"
            class="st3"
            width="4.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="223.9,239.7 220.8,243.2 219.4,242 222.5,238.5 			"
          />
          <polygon
            class="st3"
            points="202.5,250 204.4,247.6 204.8,248 203,250.4 			"
          />
          <rect
            x="212.2"
            y="246.2"
            transform="matrix(0.9855 -0.1695 0.1695 0.9855 -38.78 40.0394)"
            class="st3"
            width="6"
            height="2"
          />
          <rect x="216.1" y="236.9" class="st3" width="1.1" height="1.7" />

          <rect
            x="207.8"
            y="246.1"
            transform="matrix(0.9854 -0.1702 0.1702 0.9854 -38.9293 39.1912)"
            class="st3"
            width="2.5"
            height="1.1"
          />
          <polygon
            class="st3"
            points="251.2,215 251.8,215.7 249.5,217.6 248.9,216.9 			"
          />
          <polygon
            class="st3"
            points="199.3,248.2 200.3,248.3 200.3,249.2 201.2,249.3 201.2,248.3 201.3,245.6 199.4,245.6 			"
          />
          <rect x="215.8" y="234.9" class="st3" width="1.3" height="1.3" />
          <rect x="218.3" y="242.8" class="st3" width="0.9" height="0.9" />

          <rect
            x="240.2"
            y="266.5"
            transform="matrix(0.9749 -0.2225 0.2225 0.9749 -53.341 60.4627)"
            class="st3"
            width="3"
            height="1.1"
          />

          <rect
            x="195.3"
            y="247.9"
            transform="matrix(2.057750e-02 -0.9998 0.9998 2.057750e-02 -55.5972 440.1579)"
            class="st3"
            width="3.1"
            height="1.1"
          />
          <polygon
            class="st3"
            points="198,250.6 198.6,250.6 199.7,250.7 199.7,249.6 198.6,249.6 198.7,247.9 198,247.9 			"
          />
          <polygon
            class="st3"
            points="186.2,207.4 187.1,207.4 187.1,208.4 188.1,208.4 188.1,207.4 188.1,204.7 186.2,204.7 			"
          />
          <polygon
            class="st3"
            points="184.8,209.8 185.4,209.8 186.5,209.8 186.5,208.7 185.5,208.7 185.5,207 184.9,207 			"
          />

          <rect
            x="229.2"
            y="232.1"
            transform="matrix(6.929204e-02 -0.9976 0.9976 6.929204e-02 -18.254 445.7106)"
            class="st3"
            width="1.2"
            height="1.1"
          />

          <rect
            x="228.2"
            y="229.3"
            transform="matrix(6.938354e-02 -0.9976 0.9976 6.938354e-02 -15.9488 442.713)"
            class="st3"
            width="2.3"
            height="1.2"
          />
          <polygon
            class="st3"
            points="288.2,202.4 287.3,201.4 290.6,198.5 291.5,199.6 			"
          />
          <polygon
            class="st3"
            points="283.3,206 282.6,205.1 283.4,204.4 284.2,205.3 			"
          />
          <polygon
            class="st3"
            points="288.7,212.4 286.8,210.1 288.5,208.6 290.5,210.9 			"
          />
          <polygon
            class="st3"
            points="296.5,204 297.3,204.9 295.5,206.4 294.7,205.5 			"
          />

          <rect
            x="206.2"
            y="248"
            transform="matrix(0.9854 -0.1703 0.1703 0.9854 -39.2907 38.9249)"
            class="st3"
            width="2.2"
            height="1.2"
          />
          <polygon
            class="st3"
            points="239.4,239.8 236.2,241.6 235.6,240.6 238.9,238.8 			"
          />
          <rect x="238.9" y="219.1" class="st3" width="1.9" height="1.9" />
          <rect x="207.8" y="204.6" class="st3" width="1.9" height="1.9" />
          <polygon
            class="st3"
            points="239.6,225.6 239.6,226.2 243.8,226.1 243.7,224.9 240.5,225 240.4,222.8 238.9,222.9 239,225.7 			
				"
          />
          <polygon
            class="st3"
            points="251.4,219.6 251.9,219 251.2,218.4 250.7,218.9 250.5,219.1 249.6,220.1 251.4,221.7 252.5,220.5 
							"
          />
          <polygon
            class="st3"
            points="252.2,214.1 251.7,213.5 252.4,213 252.9,213.6 			"
          />
          <polygon
            class="st3"
            points="255.6,214.3 255.1,213.7 255.7,213.3 256.1,213.8 			"
          />
          <polygon
            class="st3"
            points="255,215.4 255.6,216.1 254.9,216.7 254.3,216 			"
          />
          <polygon
            class="st3"
            points="220.7,235.5 220.7,235 219.7,235 219.7,235.5 219.1,235.5 219.1,236.1 218.3,236.1 218.3,236.5 
				219.1,236.5 219.3,236.5 221.3,236.5 221.3,235.5 			"
          />
          <polygon
            class="st3"
            points="225.5,234.1 224.5,234.1 224.5,234.6 222.9,234.6 222.4,234.6 222.4,236.2 222.4,237.7 222.9,237.7 
				222.9,236.2 224.8,236.2 224.8,235 225.5,235 			"
          />
          <rect x="232.9" y="248.6" class="st3" width="3" height="1" />
          <rect x="244.7" y="222.2" class="st3" width="2.3" height="2.3" />
          <rect x="233.6" y="224.4" class="st3" width="1.8" height="1.3" />
          <polygon
            class="st3"
            points="210.8,232.3 210.5,231.3 215.3,229.7 215.6,230.6 			"
          />

          <rect
            x="215.2"
            y="227.4"
            transform="matrix(0.2164 -0.9763 0.9763 0.2164 -51.984 391.3142)"
            class="st3"
            width="5.1"
            height="1.4"
          />
          <polygon
            class="st3"
            points="202.4,242 206.2,238.5 207.1,239.5 203.3,243 			"
          />
          <polygon
            class="st3"
            points="213.4,229.1 213.3,228.5 214.4,228.2 214.6,228.7 			"
          />
          <polygon
            class="st3"
            points="243.3,232.6 242.9,232.1 240.9,233.5 241.6,234.4 242.7,233.6 243.7,235 250.3,230.2 249,228.5 			
				"
          />
          <polygon
            class="st3"
            points="280,212.4 277.8,209.9 279.2,208.6 281.5,211.1 			"
          />
          <polygon
            class="st3"
            points="275,214.3 274.3,213.5 275.1,212.8 275.7,213.6 			"
          />
          <polygon
            class="st3"
            points="283.3,215.5 284,216.3 283.2,217 282.5,216.2 			"
          />
          <polygon
            class="st3"
            points="285.8,217.1 286.7,218.1 285.7,219 284.8,218 			"
          />
          <polygon
            class="st3"
            points="240.8,230.1 241,229.4 242.4,229.7 242.2,230.4 			"
          />
          <polygon
            class="st3"
            points="242.8,229.4 243,228.7 244.4,229 244.2,229.7 			"
          />
          <polygon
            class="st3"
            points="244.8,228.7 245,228 246.3,228.3 246.2,229 			"
          />
          <polygon
            class="st3"
            points="246.8,227.9 247,227.3 248.3,227.6 248.2,228.3 			"
          />
          <polygon
            class="st3"
            points="193.3,225.1 192.9,224.4 194.4,223.5 194.8,224.2 			"
          />
          <polygon
            class="st3"
            points="194.4,222.7 194,222 195.5,221.1 195.9,221.8 			"
          />
          <polygon
            class="st3"
            points="195.6,220.3 195.1,219.6 196.6,218.7 197,219.4 			"
          />
          <polygon
            class="st3"
            points="236.6,231.8 235.5,228.6 237.4,228 238.4,231.1 			"
          />
          <polygon
            class="st3"
            points="232.1,231.1 231.8,230.1 232.8,229.8 233.1,230.8 			"
          />
          <polygon
            class="st3"
            points="205.4,244.8 206.2,245.5 205.5,246.3 204.7,245.6 			"
          />
          <polygon
            class="st3"
            points="235.9,235.4 236.5,236.6 235.3,237.2 234.7,236 			"
          />
          <polygon
            class="st3"
            points="236.1,234.6 235.8,234 237.2,233.5 237.4,234.1 			"
          />
          <polygon
            class="st3"
            points="226.2,245.8 227.1,245.5 226,242.4 225.1,242.7 225.8,244.9 223,245.9 223.3,246.8 			"
          />
          <polygon
            class="st3"
            points="230.3,240.5 233.9,239.3 233.4,237.8 229.8,239.1 229.4,238.1 227.5,238.8 229,243.4 231,242.7 			
				"
          />

          <rect
            x="211.2"
            y="216.6"
            transform="matrix(0.9951 -9.899296e-02 9.899296e-02 0.9951 -20.6508 22.1603)"
            class="st3"
            width="3.5"
            height="5.1"
          />
          <polygon
            class="st3"
            points="339,259.8 339.6,258.3 342.1,259.2 341.5,260.8 			"
          />
          <polygon
            class="st3"
            points="333.1,233.9 331.6,234.9 332.7,236.3 334.1,235.3 			"
          />
          <polygon
            class="st3"
            points="323.4,236.3 321.8,237 322.5,238.6 324.1,237.9 			"
          />
          <polygon
            class="st3"
            points="357.8,261.1 357.1,261.9 356.4,261.2 357.1,260.5 			"
          />
          <polygon
            class="st3"
            points="328.8,235.5 333,232.4 332.1,231.2 327.9,234.2 			"
          />
          <polygon
            class="st3"
            points="347.1,255 348.2,254.9 348.2,255.6 351.5,255.5 351.5,254.9 348.8,254.9 348.7,254.1 347.1,254.1 
							"
          />
          <polygon
            class="st3"
            points="329.1,238.8 329.5,239.4 331.5,238 332,238.7 330,240.1 326.1,242.9 325.2,241.6 			"
          />
          <polygon
            class="st3"
            points="326.2,235.6 327,233.3 322.9,231.9 322.2,234.2 			"
          />
          <polygon
            class="st3"
            points="313,239.9 315.6,237.9 318.3,241.4 319.7,240.2 323,244.4 320.4,246.4 317.7,243 316.3,244.1 			"
          />
          <polygon
            class="st3"
            points="334.1,260.8 334.2,262.4 337.6,262 337.4,260.3 335.6,260.5 335,255.6 333.1,255.8 333.7,260.9 			
				"
          />
          <polygon
            class="st3"
            points="339.6,250.9 342.7,247.8 343.7,248.9 340.7,251.9 			"
          />
          <polygon
            class="st3"
            points="343.4,268.6 347.3,265.5 348.4,266.8 344.5,270 			"
          />
          <polygon
            class="st3"
            points="344.9,250.8 345.4,249.3 350.1,250.9 349.6,252.3 			"
          />
          <polygon
            class="st3"
            points="339.5,254.8 347.6,257.5 347.4,258.3 339.2,255.6 			"
          />
          <polygon
            class="st3"
            points="339.5,269.9 338.4,269.6 339.8,265.5 340.9,265.9 			"
          />
          <polygon
            class="st3"
            points="346.6,261.5 345.4,261.1 345.8,259.9 347,260.3 			"
          />
          <polygon
            class="st3"
            points="342.6,266.6 341.9,266.4 342.4,265 343.1,265.2 			"
          />
          <polygon
            class="st3"
            points="355.1,252.7 354.9,253.4 354.6,254.8 355.9,255.1 356.3,253.7 358.4,254.2 358.5,253.4 			"
          />
          <polygon
            class="st3"
            points="341.4,264.2 340.4,262.9 344.4,260.1 345.3,261.4 			"
          />
          <polygon
            class="st3"
            points="345.6,227 343.4,229 340.9,226.2 343.1,224.3 			"
          />
          <polygon
            class="st3"
            points="303,244.2 304.2,245.7 306.7,243.8 311,249.3 310.8,249.5 312.9,252.2 314.2,251.2 312.7,249.2 
				313,249 307.8,242.3 307.1,242.9 306.2,241.7 			"
          />
          <polygon
            class="st3"
            points="363.7,234.6 362.6,233.7 361.1,235.5 357.2,232.1 357.3,232 355.4,230.3 354.6,231.2 356,232.4 
				355.8,232.6 360.5,236.7 360.9,236.1 361.8,236.9 			"
          />
          <polygon
            class="st3"
            points="332.4,242.9 332.7,243.3 335.4,240.9 334.7,240.1 332.7,241.9 332.1,241.2 329.6,243.3 330.6,244.4 
							"
          />
          <polygon
            class="st3"
            points="367.7,274.4 368.9,276 369.6,275.4 368.4,273.9 			"
          />

          <rect
            x="339.2"
            y="306.7"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 32.4663 647.8056)"
            class="st3"
            width="1.9"
            height="1.9"
          />
          <polygon
            class="st3"
            points="343.7,296.9 343,296.9 343.1,299.5 343.8,299.4 343.7,297.6 344.9,297.6 344.9,296.8 			"
          />
          <polygon
            class="st3"
            points="366.9,274.1 368,275.5 367.3,276 366.2,274.6 			"
          />
          <polygon
            class="st3"
            points="321.5,251.2 320.7,250.3 321.7,249.5 322.4,250.5 			"
          />
          <polygon
            class="st3"
            points="351.4,296.4 351.1,296.6 352.7,298.6 353.3,298.2 352,296.6 353.1,295.7 352.5,295 351.1,296.1 			
				"
          />
          <polygon
            class="st3"
            points="363.8,289.1 363.3,289.5 363.8,290.1 364.2,289.7 364.4,289.6 365.2,288.9 364.1,287.5 363.1,288.3 
							"
          />
          <polygon
            class="st3"
            points="319.5,279.2 319,279.4 322.7,287.7 321.3,288.3 321.7,289.2 324.1,288.2 324.1,288.1 323.7,287.3 
				320.4,279.9 322.7,278.9 322,277.4 319.3,278.6 			"
          />
          <polygon
            class="st3"
            points="362.5,294.1 362.1,293.9 361.9,294.3 361.2,293.8 360.7,294.6 361.5,295 360.2,297.5 360.9,297.9 
				362.4,295 363.1,295.4 363.7,294.3 362.6,293.8 			"
          />
          <polygon
            class="st3"
            points="345.8,300.9 346.3,301.5 345.7,302 345.2,301.4 			"
          />
          <polygon
            class="st3"
            points="330.1,254.8 328.8,253.1 326.6,254.9 325.6,253.7 324.8,254.4 325.8,255.7 326,255.9 325.4,256.3 
				326.1,257.2 326.9,256.5 327.3,257 			"
          />
          <polygon
            class="st3"
            points="345.9,304.2 344.8,302.8 345.4,302.3 346.5,303.7 			"
          />
          <polygon
            class="st3"
            points="352.6,299.5 352.9,299.9 352.5,300.2 352.2,299.8 			"
          />
          <polygon
            class="st3"
            points="317,257.9 317.5,258.5 316.9,259 316.4,258.4 			"
          />
          <polygon
            class="st3"
            points="350.1,299.3 349.3,298.3 350.3,297.5 351.1,298.5 			"
          />
          <polygon
            class="st3"
            points="355.1,293.1 353.4,292.4 352.7,294 354.4,294.8 354,295.9 356.2,296.9 355.6,298.1 356.9,298.7 
				357.5,297.4 357.6,297.1 359.1,293.7 355.5,292.2 			"
          />
          <polygon
            class="st3"
            points="345,240.1 346.2,241.7 346.9,241.1 345.6,239.6 			"
          />
          <polygon
            class="st3"
            points="344.1,239.8 345.2,241.1 344.5,241.7 343.4,240.3 			"
          />
          <polygon
            class="st3"
            points="342.8,244.5 342.3,244.9 342.8,245.5 343.2,245.1 343.4,245 344.2,244.3 343.1,242.9 342.1,243.7 
							"
          />
          <polygon
            class="st3"
            points="371.3,252.4 374.1,253.2 375.2,249.3 377.3,249.8 377.7,248.3 375.4,247.7 375.1,247.6 375.3,246.6 
				373.9,246.2 373.5,247.7 372.6,247.5 			"
          />
          <polygon
            class="st3"
            points="350.2,235.4 349.3,234.4 348.3,235.2 349.2,236.3 348.5,236.9 349.7,238.2 348.9,238.8 349.6,239.6 
				350.4,239 350.6,238.8 352.6,237 350.8,234.9 			"
          />
          <polygon
            class="st3"
            points="320.4,265.7 320.8,266.9 321.1,266.7 321.8,268.7 322,269.3 323.1,269 324.2,268.6 324,268 
				322.9,268.3 322.1,266.1 321.3,266.3 321,265.5 			"
          />
          <polygon
            class="st3"
            points="370.3,263 371.7,264.8 371,265.4 369.5,263.6 			"
          />
          <polygon
            class="st3"
            points="368.6,261.6 368.9,262 368.5,262.3 368.2,261.9 			"
          />
          <polygon
            class="st3"
            points="373.1,264.9 373.6,265.5 373.3,265.8 372.8,265.2 			"
          />
          <polygon
            class="st3"
            points="348.6,223.4 351,226.2 350.7,226.5 351.8,227.8 352.1,227.6 352.7,228.3 353.3,227.7 349.2,222.9 
							"
          />
          <polygon
            class="st3"
            points="348.5,242.6 349.2,243.6 350.4,242.7 353,246 357.5,242.4 354.5,238.5 350.5,241.7 350.1,241.3 			
				"
          />
          <polygon
            class="st3"
            points="335,230.2 336.3,230.6 336.4,230.1 340.9,231.4 341.4,229.5 336.2,228.1 335.7,229.7 335.2,229.5 
							"
          />
          <polygon
            class="st3"
            points="349.5,289.7 350.7,290.1 349.7,293.8 348.5,293.5 			"
          />
          <polygon
            class="st3"
            points="315.7,255 316.8,256.4 314.6,258.1 313.5,256.7 			"
          />
          <polygon
            class="st3"
            points="361.6,246.1 362.7,245.3 364,246.9 363.6,247.2 364.3,248.1 364.9,247.7 366.2,249.3 365.2,250.1 
				366,251 367.9,249.4 367.2,248.5 366.9,248.8 365.6,247.1 366.9,246.1 366.1,245.2 364.7,246.3 362.7,243.8 362.3,244.1 
				362,244.4 360.9,245.2 			"
          />
          <polygon
            class="st3"
            points="342.1,239.9 341,238.4 343.7,236.3 344.1,236.8 345.8,235.6 345.2,234.7 348,232.6 349.1,233.9 
				350.7,232.7 348.6,230 347,231.2 347.3,231.6 344.5,233.8 343.2,232.1 341.5,233.4 343,235.3 338.6,238.7 339,239.2 339.4,239.6 
				340.5,241.1 			"
          />
          <polygon
            class="st3"
            points="349.1,300.5 351,302.9 350.2,303.5 348.2,301.2 			"
          />
          <polygon
            class="st3"
            points="348.9,303.3 350.8,305.7 350,306.4 348.1,304 			"
          />
          <polygon
            class="st3"
            points="348.8,306.2 350.7,308.5 349.9,309.2 348,306.8 			"
          />
          <polygon
            class="st3"
            points="348.8,295 349.6,295.9 348.6,296.6 347.9,295.7 			"
          />
          <polygon
            class="st3"
            points="365.8,252.8 364,250.4 363.9,250.4 363.9,250.4 362.2,250.4 362.1,252.1 363.8,252.2 364.9,253.6 
							"
          />
          <polygon
            class="st3"
            points="364.4,257.1 365.2,256.5 365.7,257.2 366.1,256.8 365.6,256.2 365.9,255.9 365,254.8 363.6,256 			
				"
          />
          <polygon
            class="st3"
            points="360,246.3 362.4,249.4 361.8,249.9 359.4,246.8 			"
          />

          <rect
            x="373.4"
            y="255.6"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 750.706 511.9756)"
            class="st3"
            width="4"
            height="0.8"
          />
          <polygon
            class="st3"
            points="359.8,249 360.6,250 360,250.5 359.1,249.5 			"
          />
          <polygon
            class="st3"
            points="357.8,246.5 359,247.9 358.5,248.3 357.4,246.8 			"
          />
          <polygon
            class="st3"
            points="354.5,247.8 355.1,248.5 354.3,249.1 353.7,248.4 			"
          />
          <polygon
            class="st3"
            points="366.2,264.3 371.5,270.3 370.9,270.8 365.6,264.8 			"
          />
          <polygon
            class="st3"
            points="364.7,260.2 365.6,260.6 363.6,264.7 362.7,264.2 			"
          />
          <rect x="363" y="269.3" class="st3" width="2.8" height="1.2" />
          <rect x="361.1" y="266.9" class="st3" width="2.4" height="1.3" />
          <polygon
            class="st3"
            points="370.7,258.6 371.4,259 371.9,258.1 371.1,257.7 370.9,257.6 369.6,257 368.5,259.3 370,260 			"
          />
          <polygon
            class="st3"
            points="329.9,272.7 332.1,274.5 330.3,276.7 328.1,274.9 			"
          />
          <polygon
            class="st3"
            points="333.7,291.6 332.6,291.6 332.5,295.4 333.7,295.4 333.7,292.7 335.5,292.8 335.5,291.6 			"
          />
          <polygon
            class="st3"
            points="337.6,297.3 337.6,297.9 341.5,297.8 341.5,296.7 338.4,296.8 338.4,294.7 337,294.7 337,297.3 			
				"
          />
          <polygon
            class="st3"
            points="336.6,289.4 336.9,288.8 336.4,288.5 337,287.4 335.9,286.8 335.3,287.9 331.6,285.8 331,286.9 
				335.2,289.3 334.7,290.3 336.2,291.1 337,289.6 			"
          />

          <rect
            x="329.2"
            y="294.6"
            transform="matrix(1.727455e-02 -0.9999 0.9999 1.727455e-02 28.9973 619.7634)"
            class="st3"
            width="1.2"
            height="1.2"
          />
          <polygon
            class="st3"
            points="328.6,309.5 333,309.6 333.1,303.6 336.4,303.6 336.5,301.3 332.9,301.3 332.4,301.3 332.4,299.7 
				330.2,299.7 330.1,302 328.8,302 			"
          />

          <rect
            x="328"
            y="292.7"
            transform="matrix(1.735820e-02 -0.9998 0.9998 1.735820e-02 29.6404 616.6469)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="325.3"
            y="280.6"
            transform="matrix(1.735671e-02 -0.9998 0.9998 1.735671e-02 41.8563 605.0489)"
            class="st3"
            width="7"
            height="1.2"
          />

          <rect
            x="338.4"
            y="291.6"
            transform="matrix(1.719323e-02 -0.9999 0.9999 1.719323e-02 41.0141 625.7374)"
            class="st3"
            width="0.8"
            height="0.8"
          />

          <rect
            x="339"
            y="284.5"
            transform="matrix(1.731480e-02 -0.9999 0.9999 1.731480e-02 48.6949 620.0104)"
            class="st3"
            width="1.5"
            height="1.4"
          />

          <rect
            x="341"
            y="287.1"
            transform="matrix(1.733150e-02 -0.9998 0.9998 1.733150e-02 49.1755 626.0807)"
            class="st3"
            width="4.1"
            height="1.9"
          />

          <rect
            x="344.3"
            y="291.8"
            transform="matrix(1.733575e-02 -0.9998 0.9998 1.733575e-02 46.6073 632.649)"
            class="st3"
            width="1.7"
            height="1.7"
          />
          <polygon
            class="st3"
            points="339.5,274.3 339.5,275.6 341.1,275.5 341.1,274.3 341,274 341,271.7 337.2,271.8 337.2,274.4 			"
          />
          <polygon
            class="st3"
            points="352.4,265 353.4,265 353.4,263 352.4,263 352.4,261.5 351.1,261.5 351.1,259.9 350.4,259.8 
				350.4,261.5 350.4,261.9 350.3,266.2 352.4,266.2 			"
          />
          <polygon
            class="st3"
            points="359.1,267.7 356.7,266.5 357.5,264.9 359.9,266.1 			"
          />
          <polygon
            class="st3"
            points="358.9,260.6 357.6,259 360.9,256.4 362.2,258 			"
          />
          <polygon
            class="st3"
            points="359.5,289.6 360,290.6 359,291.1 358.5,290.1 			"
          />
          <polygon
            class="st3"
            points="351.5,287 352.6,287.6 352.2,288.3 355.5,290.2 355.9,289.5 353.2,287.9 353.6,287.1 352,286.1 			
				"
          />
          <polygon
            class="st3"
            points="346.7,285.5 345.1,281.1 346.9,280.5 348.5,284.8 			"
          />
          <polygon
            class="st3"
            points="349,280.4 348.4,278.8 353.7,276.9 354.3,278.5 			"
          />
          <polygon
            class="st3"
            points="351,283.6 351.7,284.1 353,285.1 354,283.8 352.7,282.8 354.2,280.9 353.5,280.3 			"
          />
          <polygon
            class="st3"
            points="357.4,287.5 356.5,283.4 356.9,283.3 356.4,281.4 356,281.5 355.8,280.5 354.9,280.7 356.6,287.7 
							"
          />
          <polygon
            class="st3"
            points="348.3,273.9 349.8,273.5 350,274.4 348.5,274.8 			"
          />

          <rect
            x="343"
            y="272"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 690.6322 545.6237)"
            class="st3"
            width="4.5"
            height="1.7"
          />

          <rect
            x="344.6"
            y="278.6"
            transform="matrix(0.972 -0.2351 0.2351 0.972 -55.9614 88.9774)"
            class="st3"
            width="1.1"
            height="1.1"
          />
          <polygon
            class="st3"
            points="360,284 359.5,283.7 359,284.8 359.5,285.1 359.1,285.8 359.8,286.1 359.4,287 359.8,287.2 
				360.2,286.3 360.3,286.1 361.4,283.8 360.3,283.3 			"
          />
          <polygon
            class="st3"
            points="359.8,280.3 357.5,280.9 356.4,276.3 358.7,275.7 			"
          />
          <polygon
            class="st3"
            points="352.4,275.9 351.2,275.2 351.9,274 353.1,274.7 			"
          />
          <polygon
            class="st3"
            points="353.7,265.9 355,266.3 354.6,267.5 353.4,267.2 			"
          />
          <polygon
            class="st3"
            points="356.9,271.3 355.1,270.2 356.2,268.5 358,269.6 			"
          />
          <polygon
            class="st3"
            points="351.6,272 349.8,268.6 351.1,267.9 352.9,271.4 			"
          />
          <polygon
            class="st3"
            points="339.8,247.9 338.1,245.8 339.5,244.7 341.1,246.8 			"
          />
          <polygon
            class="st3"
            points="326.3,247.2 325.5,246.2 327.9,244.3 328.7,245.3 			"
          />
          <polygon
            class="st3"
            points="332.1,252.4 332.9,253.4 331.9,254.2 331,253.2 			"
          />
          <polygon
            class="st3"
            points="335,248.8 333.7,247.2 335.3,245.9 336.6,247.6 			"
          />
          <polygon
            class="st3"
            points="333.2,251.2 330,247.2 331.1,246.3 334.3,250.3 			"
          />
          <rect x="343" y="301.8" class="st3" width="0.9" height="3" />
          <polygon
            class="st3"
            points="359.9,270.5 364.7,271.7 364,274.7 359.2,273.6 			"
          />
          <rect x="307.7" y="225.3" class="st3" width="6.4" height="2.6" />
          <polygon
            class="st3"
            points="321.8,229.5 320.8,228.2 314.7,233 315.7,234.3 			"
          />
          <rect x="315.6" y="225.5" class="st3" width="2.4" height="1.3" />

          <rect
            x="311.7"
            y="221.6"
            transform="matrix(6.794916e-02 -0.9977 0.9977 6.794916e-02 68.9183 519.6541)"
            class="st3"
            width="1.8"
            height="2.6"
          />
          <polygon
            class="st3"
            points="303.8,230.7 302.5,229.1 305.7,226.3 307.1,227.9 			"
          />
          <rect x="310" y="236.1" class="st3" width="1.9" height="1.9" />
          <polygon
            class="st3"
            points="337.5,281.5 338.2,280.8 337.2,279.9 336.5,280.7 336.3,280.9 335,282.4 337.5,284.6 339,282.9 			
				"
          />
          <polygon
            class="st3"
            points="320.1,264.4 319.7,263.9 320.2,263.5 320.6,264 			"
          />
          <polygon
            class="st3"
            points="343.5,307.1 343.1,306.6 343.6,306.1 344.1,306.7 			"
          />
          <polygon
            class="st3"
            points="343.6,308.3 344.2,309 343.5,309.6 342.9,308.9 			"
          />

          <rect
            x="332.8"
            y="265.8"
            transform="matrix(7.394420e-03 -1 1 7.394420e-03 65.369 600.8598)"
            class="st3"
            width="5.1"
            height="3.5"
          />
          <polygon
            class="st3"
            points="216.7,242.5 217.2,242.1 216.3,241.1 215.4,241.8 216.2,242.8 215.5,243.4 214.7,242.4 213,243.8 
				214.4,245.4 216,244 217.2,243.1 			"
          />
          <polygon
            class="st3"
            points="208.7,228.2 210.4,228 210.1,225.3 208.4,225.5 208.6,227.6 205.2,227.9 205.3,229.1 208.7,228.8 
							"
          />

          <rect
            x="203.1"
            y="230.6"
            transform="matrix(0.9951 -9.898288e-02 9.898288e-02 0.9951 -22.0012 21.3281)"
            class="st3"
            width="1.6"
            height="3.6"
          />
          <polygon
            class="st3"
            points="146.4,135.3 145.3,134.2 147,132.5 148.1,133.6 			"
          />
          <polygon
            class="st3"
            points="152.5,134.6 151.7,135.3 152.3,136 154,134.4 153.4,133.7 150.4,130.6 149.5,131.5 			"
          />
          <polygon
            class="st3"
            points="196.7,90.4 197.6,94.4 198.7,94.1 197.5,88.9 196.9,89 196.4,89.2 192.4,90.1 192.7,91.3 			"
          />
          <rect x="201.3" y="80.7" class="st3" width="1.2" height="2.5" />
          <rect x="189.2" y="86.9" class="st3" width="2.6" height="0.6" />
          <rect x="188.6" y="85.3" class="st3" width="3.8" height="1.1" />
          <rect x="191.3" y="81.6" class="st3" width="1" height="2.8" />
          <rect x="189.8" y="79.3" class="st3" width="1.3" height="1.3" />
          <rect x="190.4" y="76.4" class="st3" width="5.4" height="1.3" />
          <rect x="198.2" y="75.2" class="st3" width="1" height="2.9" />
          <rect x="180.6" y="87.9" class="st3" width="4.2" height="1" />
          <polygon
            class="st3"
            points="190,89.3 187.5,90.2 187.1,89 189.6,88.1 			"
          />
          <rect x="247" y="10" class="st3" width="1.6" height="1.6" />
          <rect x="250.8" y="10" class="st3" width="1.6" height="1.6" />
          <rect x="264.2" y="11.6" class="st3" width="1.9" height="1.9" />
          <rect x="260.3" y="11.6" class="st3" width="1.9" height="1.9" />
          <rect x="257.2" y="11.6" class="st3" width="1.9" height="1.9" />
          <rect x="247" y="4.9" class="st3" width="1.1" height="2.7" />
          <rect x="260.3" y="17.1" class="st3" width="1.9" height="1.9" />
          <rect x="266" y="17.1" class="st3" width="1.9" height="1.9" />
          <rect x="246.1" y="18.1" class="st3" width="1.4" height="4" />
          <rect x="249.5" y="38.2" class="st3" width="2.1" height="3.9" />
          <rect x="254.3" y="42.1" class="st3" width="2" height="2" />
          <rect x="260.3" y="41.1" class="st3" width="2" height="2" />
          <rect x="266.3" y="43.1" class="st3" width="2.6" height="2.7" />
          <rect x="268.4" y="30.6" class="st3" width="1.3" height="2.8" />
          <rect x="255.3" y="32.4" class="st3" width="2.8" height="1.4" />
          <rect x="251.6" y="18" class="st3" width="3.7" height="2.3" />
          <rect x="246.7" y="26.5" class="st3" width="7.5" height="3.8" />
          <rect x="262.2" y="1.1" class="st3" width="3" height="3" />
          <rect x="267" y="6.3" class="st3" width="2.1" height="2.1" />
          <rect x="250.8" y="2.6" class="st3" width="2.7" height="1.5" />
          <polygon
            class="st3"
            points="148.8,63.1 148.4,64.9 147.3,64.6 147.8,62.8 			"
          />
          <polygon
            class="st3"
            points="157.8,56.1 157.3,57.9 156.3,57.6 156.8,55.8 			"
          />
          <polygon
            class="st3"
            points="152.7,51.9 152,54.1 150.6,53.7 151.3,51.5 			"
          />
          <polygon
            class="st3"
            points="166.2,160.1 165.7,161.8 164,161.2 164.6,159.6 			"
          />
          <polygon
            class="st3"
            points="172.4,161.3 171.9,161.9 171.4,161.5 171.8,160.9 			"
          />
          <polygon
            class="st3"
            points="171.4,162.5 171,163.1 170.4,162.7 170.9,162.1 			"
          />

          <rect
            x="167"
            y="162.1"
            transform="matrix(0.1083 -0.9941 0.9941 0.1083 -11.639 312.0585)"
            class="st3"
            width="2.3"
            height="0.7"
          />
          <polygon
            class="st3"
            points="172.5,164.4 171.8,164.8 171.4,164.2 172,163.8 			"
          />
          <polygon
            class="st3"
            points="173.3,162.9 173.9,162.5 174.3,163.1 173.7,163.5 			"
          />
          <polygon
            class="st3"
            points="169.3,159.3 168.6,160.2 167.6,159.5 168.4,158.6 			"
          />
          <polygon
            class="st3"
            points="170.4,157.7 172,159.3 171.4,159.9 169.9,158.3 			"
          />
          <polygon
            class="st3"
            points="167.7,167.4 166.7,165.7 168.7,164.6 169.7,166.2 			"
          />
          <polygon
            class="st3"
            points="162,160.3 162.8,162 161.8,162.5 161,160.8 			"
          />
          <polygon
            class="st3"
            points="150.2,58.3 149.8,59.7 148.4,59.3 148.8,57.9 			"
          />
          <polygon
            class="st3"
            points="149.6,59.8 149.2,61.1 148.2,60.8 148.6,59.5 			"
          />
          <polygon
            class="st3"
            points="147.2,63.1 146.9,64.3 144.5,63.6 144.9,62.4 			"
          />
          <polygon
            class="st3"
            points="154.1,57.7 153.7,59.2 152.7,58.9 153.1,57.4 			"
          />
          <polygon
            class="st3"
            points="153.4,56.1 153.8,54.7 155.1,55 154.6,56.5 			"
          />

          <rect
            x="152"
            y="67.9"
            transform="matrix(3.591048e-02 -0.9994 0.9994 3.591048e-02 78.2397 219.0326)"
            class="st3"
            width="1.3"
            height="2"
          />
          <polygon
            class="st3"
            points="144,64.7 145.1,66.6 141.9,68.5 140.8,66.5 			"
          />
          <polygon
            class="st3"
            points="161.8,175.5 161.1,177.8 158,176.9 158.7,174.6 			"
          />
          <polygon
            class="st3"
            points="142.8,179 142.7,179.4 142.3,179.3 142.4,178.8 			"
          />
          <polygon
            class="st3"
            points="163.7,184.1 162.8,187.1 161.9,186.8 162.8,183.8 			"
          />
          <polygon
            class="st3"
            points="157.1,182.1 156.8,183.1 156.5,183 156.2,184 158.6,184.7 158.9,183.7 157.7,183.4 158,182.4 			"
          />
          <polygon
            class="st3"
            points="142.9,193 142.3,195.2 141.4,195 140.8,197.2 146.4,198.8 147,196.6 144.3,195.8 144.9,193.6 			"
          />
          <polygon
            class="st3"
            points="155.3,179.6 158.9,180.7 158.8,181.1 160.4,181.5 160.5,181.2 161.4,181.4 161.6,180.6 155.6,178.9 
							"
          />
          <polygon
            class="st3"
            points="167,176.2 166.4,175.4 166.1,175.7 163.8,173.1 162.8,174 165.4,177 166.4,176.2 166.6,176.5 			"
          />
          <polygon
            class="st3"
            points="146.4,175.8 146,177 142.3,175.9 142.7,174.7 			"
          />
          <polygon
            class="st3"
            points="151.8,180.6 152.9,180.9 152.4,182.4 152.1,182.3 151.8,183.2 152.4,183.4 151.9,184.9 151,184.7 
				150.7,185.5 152.6,186.1 152.8,185.2 152.5,185.1 153,183.6 154.2,183.9 154.5,183 153.1,182.6 153.8,180.2 153.5,180.1 
				153.1,180 152.1,179.7 			"
          />
          <polygon
            class="st3"
            points="142.5,184.3 142,186 137.4,184.7 137.9,183 			"
          />
          <polygon
            class="st3"
            points="136.2,184.9 135.7,186.6 131.1,185.3 131.6,183.6 			"
          />
          <polygon
            class="st3"
            points="129.9,185.5 129.4,187.2 124.8,185.9 125.3,184.2 			"
          />
          <polygon
            class="st3"
            points="123.5,186.1 123,187.8 118.4,186.5 118.9,184.8 			"
          />
          <polygon
            class="st3"
            points="150,181.1 148.9,184.9 148.2,184.7 149.3,180.9 			"
          />
          <polygon
            class="st3"
            points="147.5,180.7 147.1,182 146.4,181.8 146.7,180.5 			"
          />
          <polygon
            class="st3"
            points="148.5,177.7 147.9,179.4 147.4,179.3 147.9,177.5 			"
          />
          <polygon
            class="st3"
            points="142.7,171.4 142.5,172.3 141.5,172.1 141.8,171.1 			"
          />
          <polygon
            class="st3"
            points="162.8,190.5 163,189.4 159.3,188.9 159.1,190 161.7,190.3 161.2,193.8 162.3,194 			"
          />
          <polygon
            class="st3"
            points="150.3,190.5 149.9,193.8 151.3,194 151.7,190.6 152.6,190.8 152.9,188.9 148.6,188.3 148.3,190.2 
							"
          />
          <polygon
            class="st3"
            points="115.5,194.5 115.5,195.2 127.8,194.9 127.9,197 129.3,197 129.2,193.4 129.1,193.4 127.8,193.4 
				116.9,193.7 116.8,190.4 114.6,190.4 114.7,194.5 			"
          />
          <polygon
            class="st3"
            points="135.8,191.7 135.7,192.3 136.3,192.3 136.1,193.6 137.4,193.8 137.6,192.5 141.6,193.1 141.8,191.9 
				137.1,191.2 137.2,190.1 135.5,189.9 135.3,191.6 			"
          />
          <polygon
            class="st3"
            points="134.1,178 135.1,177.6 135.5,178.6 134.5,179 			"
          />
          <polygon
            class="st3"
            points="127.1,182.9 128,182.5 128.3,183.4 127.4,183.8 			"
          />
          <polygon
            class="st3"
            points="131.2,181.2 130,181.7 129.6,180.5 130.7,180 			"
          />

          <rect
            x="149"
            y="196.5"
            transform="matrix(0.1324 -0.9912 0.9912 0.1324 -66.3875 319.319)"
            class="st3"
            width="0.4"
            height="2.2"
          />

          <rect
            x="145.3"
            y="190.4"
            transform="matrix(0.1324 -0.9912 0.9912 0.1324 -62.428 310.8211)"
            class="st3"
            width="2"
            height="1.2"
          />
          <polygon
            class="st3"
            points="157.1,195.1 158.2,197 154.9,198.9 153.8,197 			"
          />

          <rect
            x="144.5"
            y="67.2"
            transform="matrix(3.591048e-02 -0.9994 0.9994 3.591048e-02 71.0358 211.5137)"
            class="st3"
            width="1.3"
            height="3.5"
          />
          <polygon
            class="st3"
            points="163.6,66.2 163.2,67.4 161.6,67 161.9,65.7 			"
          />
          <polygon
            class="st3"
            points="165.4,64.2 165,65.5 164,65.2 164.4,63.9 			"
          />
          <polygon
            class="st3"
            points="162.3,61.5 162.7,60 165.7,60.8 165.3,62.3 			"
          />
          <polygon
            class="st3"
            points="164.8,58.8 165.5,56.3 166.2,56.5 165.5,58.9 			"
          />
          <polygon
            class="st3"
            points="162.8,55.9 162.5,57 161.6,56.8 161.9,55.7 			"
          />
          <polygon
            class="st3"
            points="171.5,285.6 172.8,286 171.8,288.9 170.5,288.4 			"
          />
          <polygon
            class="st3"
            points="177.4,282.2 174.4,281.2 174,282.5 177,283.5 			"
          />
          <polygon
            class="st3"
            points="179.1,280.9 176.5,280 177,278.7 179.6,279.6 			"
          />
          <polygon
            class="st3"
            points="176.6,274.5 176.9,273.6 175.8,273.2 175.5,274.1 175.4,274.3 174.8,276 177.6,276.9 178.2,275.1 
							"
          />
          <polygon
            class="st3"
            points="176.5,284.9 176.7,284.3 175.6,283.9 175.4,284.5 174.6,284.2 174.3,284.9 173.4,284.6 173.3,285 
				174.2,285.3 174.4,285.4 176.8,286.2 177.2,285.1 			"
          />
          <polygon
            class="st3"
            points="175.2,291.9 171.7,290.7 171.3,291.9 174.8,293.1 			"
          />
          <polygon
            class="st3"
            points="180.3,277.1 182.2,271.7 181.4,271.4 179.5,276.9 			"
          />

          <rect
            x="103.4"
            y="219.2"
            transform="matrix(0.9995 -3.095406e-02 3.095406e-02 0.9995 -6.7576 3.3644)"
            class="st3"
            width="3.7"
            height="1.6"
          />

          <rect
            x="109.8"
            y="216.5"
            transform="matrix(0.9995 -3.094094e-02 3.094094e-02 0.9995 -6.6592 3.5129)"
            class="st3"
            width="0.8"
            height="0.8"
          />
          <polygon
            class="st3"
            points="106.7,252.9 101.9,254.6 101.4,253.2 106.2,251.4 			"
          />
          <polygon
            class="st3"
            points="101.1,252.5 96.3,254.3 95.8,252.9 100.5,251.1 			"
          />
          <polygon
            class="st3"
            points="107.8,224.5 103,226.2 102.5,224.8 107.2,223.1 			"
          />
          <polygon
            class="st3"
            points="110.4,227.6 112,227.6 112,228.2 113.6,228.3 113.7,224.2 112.1,224.1 112.1,226.1 110.4,226.1 			
				"
          />
          <polygon
            class="st3"
            points="110.5,232.3 116.5,232.1 116.5,232.7 119.3,232.7 119.3,232 120.7,232 120.7,230.7 110.5,231 			"
          />
          <polygon
            class="st3"
            points="109.1,232.8 107.5,232.9 107.5,233.5 102,233.6 102,235.8 108.5,235.7 108.5,233.7 109.2,233.7 			
				"
          />
          <polygon
            class="st3"
            points="96.3,238.4 93.6,237.4 95.2,233.1 97.9,234.1 			"
          />

          <rect
            x="110.4"
            y="266"
            transform="matrix(4.061686e-02 -0.9992 0.9992 4.061686e-02 -158.0739 369.023)"
            class="st3"
            width="5.6"
            height="1.7"
          />

          <rect
            x="102.1"
            y="256.7"
            transform="matrix(4.059966e-02 -0.9992 0.9992 4.059966e-02 -158.6809 352.528)"
            class="st3"
            width="4.3"
            height="4.3"
          />
          <polygon
            class="st3"
            points="107.9,246 106.8,242.6 99.8,244.9 99.4,243.7 96,244.8 97.1,248.3 100.5,247.2 100.2,246.3 
				100.9,248.3 			"
          />
          <polygon
            class="st3"
            points="86.2,245.5 85.6,246.1 90.2,250.6 91.4,249.3 87.8,245.8 90.2,243.4 88.6,241.8 85.6,244.8 			"
          />

          <rect
            x="113.7"
            y="272.1"
            transform="matrix(4.061686e-02 -0.9992 0.9992 4.061686e-02 -162.7754 376.0894)"
            class="st3"
            width="1.5"
            height="1.5"
          />

          <rect
            x="120.3"
            y="271.1"
            transform="matrix(4.061686e-02 -0.9992 0.9992 4.061686e-02 -155.4765 381.5974)"
            class="st3"
            width="1.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="119.9,268.1 118.7,269.3 116.3,266.9 117.5,265.7 			"
          />
          <polygon
            class="st3"
            points="115.7,250.5 114.6,250.8 114.3,249.8 115.3,249.5 			"
          />
          <polygon
            class="st3"
            points="117,251.9 118.3,251.5 118.8,252.8 117.4,253.3 			"
          />
          <polygon
            class="st3"
            points="109.3,262.9 108.5,261.4 110,260.6 110.8,262.1 			"
          />
          <polygon
            class="st3"
            points="128.6,218.5 131.1,217.7 131.9,220.2 129.4,221 			"
          />
          <polygon
            class="st3"
            points="124.3,227.7 124.1,225.2 118.8,225.7 118.7,224.8 116.1,225.1 116.4,227.6 118.9,227.4 118.9,226.7 
				119,228.3 			"
          />
          <polygon
            class="st3"
            points="116.5,215.3 115.9,215.4 116.3,219.9 117.5,219.8 117.2,216.3 119.7,216.1 119.5,214.4 116.5,214.7 
							"
          />
          <polygon
            class="st3"
            points="125.7,214.3 126.5,215 125.8,215.8 125.1,215.1 			"
          />
          <rect x="108.8" y="252.2" class="st3" width="2.8" height="2.8" />
          <polygon
            class="st3"
            points="125.6,268.6 124.1,269.3 118.5,257.4 120,256.7 			"
          />

          <rect
            x="103.6"
            y="215.3"
            transform="matrix(0.9999 -1.016897e-02 1.016897e-02 0.9999 -2.1914 1.0687)"
            class="st3"
            width="0.9"
            height="1.4"
          />
          <polygon
            class="st3"
            points="118.8,276.4 118.1,277 119.6,278.7 120.1,278.3 122.6,281.1 124.5,279.4 122,276.6 122.8,275.8 
				121.3,274.2 120.1,275.2 120.9,276.1 119.6,277.3 			"
          />
          <polygon
            class="st3"
            points="100,230.5 98.7,230.8 98,228.4 99.3,228 			"
          />
          <polygon
            class="st3"
            points="113.9,243.1 112.6,243.5 112.3,242.2 113.6,241.9 			"
          />

          <rect
            x="156.3"
            y="271.4"
            transform="matrix(0.9866 -0.1629 0.1629 0.9866 -42.2083 29.4424)"
            class="st3"
            width="4.3"
            height="1.4"
          />

          <rect
            x="167.5"
            y="267.7"
            transform="matrix(0.9866 -0.1629 0.1629 0.9866 -41.4968 31.0155)"
            class="st3"
            width="1.8"
            height="1.8"
          />

          <rect
            x="170.2"
            y="257.9"
            transform="matrix(0.9867 -0.1628 0.1628 0.9867 -39.8205 31.2697)"
            class="st3"
            width="1.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="114.6,258.4 115.2,261.1 116.7,260.8 116,257.3 114.5,257.6 111,258.2 111.1,259 			"
          />
          <polygon
            class="st3"
            points="185.8,242.5 185,240.1 180,241.7 179.8,240.9 177.3,241.7 178.1,244.1 180.6,243.3 180.4,242.7 
				180.8,244.1 			"
          />
          <polygon
            class="st3"
            points="187.7,249.8 187.7,249.1 184.5,249.2 184.5,249.6 181.8,249.6 181.8,251.4 184.5,251.4 184.5,252.2 
				187.7,252.2 187.7,251.1 185.2,251.1 185.2,249.8 			"
          />
          <polygon
            class="st3"
            points="190.1,247.3 189.6,249.2 190.7,249.5 191.2,247 190.2,246.8 185.2,245.7 185.1,246.2 			"
          />

          <rect
            x="167.2"
            y="260.1"
            transform="matrix(0.9867 -0.1628 0.1628 0.9867 -40.5146 30.8231)"
            class="st3"
            width="1.1"
            height="5"
          />

          <rect
            x="154.6"
            y="260.7"
            transform="matrix(0.9867 -0.1629 0.1629 0.9867 -40.7342 28.783)"
            class="st3"
            width="1.2"
            height="4.2"
          />

          <rect
            x="172.6"
            y="265.3"
            transform="matrix(0.9866 -0.1629 0.1629 0.9866 -41.2644 31.7716)"
            class="st3"
            width="1.2"
            height="4.6"
          />

          <rect
            x="158.8"
            y="264.7"
            transform="matrix(0.9867 -0.1629 0.1629 0.9867 -41.215 29.5319)"
            class="st3"
            width="1.4"
            height="2.9"
          />
          <polygon
            class="st3"
            points="161.6,270.2 162.1,268.7 165.1,269.6 164.6,271.1 			"
          />
          <polygon
            class="st3"
            points="163.2,263.5 163.9,261.1 164.5,261.3 163.8,263.7 			"
          />
          <polygon
            class="st3"
            points="160.9,260.3 160.6,261.4 159.8,261.1 160.1,260 			"
          />
          <polygon
            class="st3"
            points="161.2,276.5 161,278.1 157.8,277.6 156.8,283.1 157,283.2 156.6,285.9 154.9,285.6 155.3,283.6 
				154.9,283.6 156.1,276.9 157,277.1 157.2,275.9 			"
          />
          <polygon
            class="st3"
            points="149.3,284.9 151,282.3 151.9,282.8 150.1,285.5 			"
          />

          <rect
            x="148.6"
            y="279.2"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -51.3508 34.3059)"
            class="st3"
            width="2.5"
            height="1.4"
          />

          <rect
            x="144.3"
            y="277.7"
            transform="matrix(0.9813 -0.1927 0.1927 0.9813 -50.9588 33.2379)"
            class="st3"
            width="2.1"
            height="1.7"
          />

          <rect
            x="146.3"
            y="281.3"
            transform="matrix(0.9813 -0.1927 0.1927 0.9813 -52.1715 33.6674)"
            class="st3"
            width="1.3"
            height="7.5"
          />

          <rect
            x="136"
            y="279.8"
            transform="matrix(0.9813 -0.1927 0.1927 0.9813 -51.4448 31.7977)"
            class="st3"
            width="3.6"
            height="1.3"
          />
          <polygon
            class="st3"
            points="137.4,286.9 139,283.9 140.1,284.6 138.4,287.5 			"
          />
          <polygon
            class="st3"
            points="141.4,288.3 141.2,287.8 140.9,286.8 140,287.1 140.3,288.1 138.9,288.5 139,289 			"
          />
          <polygon
            class="st3"
            points="140.2,289.8 140.7,291.9 141.3,291.8 141.8,294.1 142.4,294 142,291.7 141.5,289.5 			"
          />
          <rect
            x="139.5"
            y="297.9"
            transform="matrix(0.9873 -0.1587 0.1587 0.9873 -45.5751 26.166)"
            class="st3"
            width="3"
            height="1"
          />

          <rect
            x="144.2"
            y="283.7"
            transform="matrix(0.9811 -0.1936 0.1936 0.9811 -52.5459 33.385)"
            class="st3"
            width="0.8"
            height="3.9"
          />
          <polygon
            class="st3"
            points="143.4,280.4 144.4,280.8 144,281.8 143,281.4 			"
          />
          <polygon
            class="st3"
            points="138.7,293.5 139.2,293.9 140.4,292.5 139.5,291.7 138.3,293 137.6,292.4 138.8,291.1 137.2,289.6 
				135.3,291.7 136.9,293.2 138.1,294.3 			"
          />
          <polygon
            class="st3"
            points="131.2,285 131.5,286.9 134.4,286.4 134.1,284.5 131.9,284.9 131.3,281.2 130,281.4 130.6,285.1 			
				"
          />
          <polygon
            class="st3"
            points="140.7,304.4 139.5,304.4 139.3,308 141.7,308.1 141.9,304.8 143.7,304.9 143.5,308.2 147.8,308.4 
				148,302.9 143.8,302.7 140.8,302.5 			"
          />

          <rect
            x="147.2"
            y="291.7"
            transform="matrix(0.9996 -2.968328e-02 2.968328e-02 0.9996 -8.6079 4.5122)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="148.7,293.2 151,294.6 150.5,295.4 148.2,294 			"
          />
          <polygon
            class="st3"
            points="148.3,289.6 150.6,291.1 150.1,291.8 147.8,290.4 			"
          />
          <polygon
            class="st3"
            points="156.9,291 154.3,290 153.8,291.5 157.1,292.8 157.7,291.3 160.2,284.6 159.5,284.3 			"
          />
          <polygon
            class="st3"
            points="145.7,297.6 149.1,297 149.3,298 144.9,298.7 144.8,298.2 144.7,297.7 144.2,294.4 145.2,294.2 			
				"
          />

          <rect
            x="135.3"
            y="294.3"
            transform="matrix(0.9808 -0.1953 0.1953 0.9808 -55.3692 32.2667)"
            class="st3"
            width="1.3"
            height="5.3"
          />

          <rect
            x="154.3"
            y="305.7"
            transform="matrix(2.127489e-02 -0.9998 0.9998 2.127489e-02 -156.1099 456.1725)"
            class="st3"
            width="1.4"
            height="4.3"
          />

          <rect
            x="165"
            y="306.2"
            transform="matrix(2.128715e-02 -0.9998 0.9998 2.128715e-02 -144.6953 466.3713)"
            class="st3"
            width="1.8"
            height="1.8"
          />
          <polygon
            class="st3"
            points="170.3,294.7 169,299.5 167.9,299.2 169.2,294.4 			"
          />

          <rect
            x="151.1"
            y="301"
            transform="matrix(2.129138e-02 -0.9998 0.9998 2.129138e-02 -151.5265 448.3747)"
            class="st3"
            width="4.2"
            height="1.2"
          />

          <rect
            x="156.1"
            y="302.4"
            transform="matrix(2.129197e-02 -0.9998 0.9998 2.129197e-02 -148.7635 454.101)"
            class="st3"
            width="2.9"
            height="1.4"
          />
          <polygon
            class="st3"
            points="159.1,304.9 159.8,303.5 162.5,304.9 161.8,306.3 			"
          />
          <polygon
            class="st3"
            points="160.2,301.1 160.9,299.7 163.7,301.1 163,302.5 			"
          />
          <polygon
            class="st3"
            points="161.3,297.3 162,295.9 164.8,297.3 164.1,298.7 			"
          />
          <polygon
            class="st3"
            points="162.5,293.6 163.2,292.2 166,293.6 165.3,295 			"
          />
          <polygon
            class="st3"
            points="158.8,295.2 159.9,293 160.5,293.3 159.4,295.5 			"
          />
          <polygon
            class="st3"
            points="157.6,296.3 157,297.3 156.3,297 156.8,295.9 			"
          />

          <rect
            x="156.5"
            y="66.6"
            transform="matrix(3.596100e-02 -0.9994 0.9994 3.596100e-02 84.2199 223.7819)"
            class="st3"
            width="3.2"
            height="3.2"
          />
          <polygon
            class="st3"
            points="153.3,60.7 151.8,66.3 150.6,65.9 152.2,60.4 			"
          />
          <polygon
            class="st3"
            points="147.5,52.5 146.6,55.7 144,54.9 144.9,51.8 			"
          />
          <polygon
            class="st3"
            points="150.4,52.5 149.6,55.1 148,54.7 148.8,52 			"
          />
          <polygon
            class="st3"
            points="146.1,59.7 145.4,62.1 144.1,61.7 144.8,59.3 			"
          />
          <polygon
            class="st3"
            points="438.1,22.3 435.7,18.1 438.1,16.7 440.5,20.9 			"
          />
          <polygon
            class="st3"
            points="433.4,22.4 432.2,20.7 432.9,20.2 434.1,21.9 			"
          />
          <polygon
            class="st3"
            points="431.2,23.9 430,22.2 430.7,21.7 431.9,23.4 			"
          />
          <polygon
            class="st3"
            points="432.8,28 431,26.9 431.3,26.3 433.1,27.4 			"
          />
          <polygon
            class="st3"
            points="431.7,29.6 430,28.6 430.4,27.8 432.2,28.9 			"
          />
          <polygon
            class="st3"
            points="471.2,21.4 469.5,20.3 469.9,19.6 471.7,20.7 			"
          />
          <polygon
            class="st3"
            points="432.7,33.8 429,31.5 429.7,30.4 433.4,32.6 			"
          />
          <polygon
            class="st3"
            points="437.6,37.7 433.9,35.4 434.6,34.3 438.3,36.5 			"
          />
          <polygon
            class="st3"
            points="427.6,27 426,24.5 429,22.4 430.7,24.9 			"
          />
          <polygon
            class="st3"
            points="421.5,29.3 420.5,27.7 423.8,25.5 424.8,27 			"
          />
          <polygon
            class="st3"
            points="413.9,32.8 416.9,30.8 416,29.5 413,31.5 413,31.5 411.2,32.7 413,35.3 414.7,34.1 			"
          />

          <rect
            x="415.8"
            y="37.1"
            transform="matrix(8.434010e-02 -0.9964 0.9964 8.434010e-02 342.6055 451.0098)"
            class="st3"
            width="1.9"
            height="4"
          />

          <rect
            x="421.2"
            y="43.4"
            transform="matrix(8.429336e-02 -0.9964 0.9964 8.429336e-02 341.3764 462.244)"
            class="st3"
            width="1.9"
            height="4"
          />

          <rect
            x="396"
            y="50.5"
            transform="matrix(8.434010e-02 -0.9964 0.9964 8.434010e-02 311.075 443.601)"
            class="st3"
            width="1.9"
            height="4"
          />
          <polygon
            class="st3"
            points="432.9,30.6 435,26.6 436,27.2 433.9,31.2 			"
          />
          <polygon
            class="st3"
            points="437.8,33.4 436.4,31.7 437.4,30.9 438.8,32.6 			"
          />
          <polygon
            class="st3"
            points="419.6,30.7 418.9,29.6 419.6,29.2 420.3,30.2 			"
          />
          <polygon
            class="st3"
            points="446.8,33.7 446,34.2 445.4,33.1 445.8,32.9 444.9,31.3 444.5,31.5 443,32.4 443.9,34 444.5,35 
				445.3,36.6 446.9,35.7 447.7,35.3 			"
          />
          <polygon
            class="st3"
            points="402.3,45.2 402.9,45.8 402,46.7 401.7,46.4 400.4,47.6 400.7,47.9 402,49.2 403.2,48 404.1,47.1 
				405.4,45.9 404.2,44.6 403.6,44 			"
          />
          <polygon
            class="st3"
            points="442.9,41 443.6,40.5 444.3,41.4 444,41.7 445.1,43.1 445.4,42.8 446.9,41.7 445.7,40.3 445,39.4 
				443.9,37.9 442.5,39.1 441.8,39.6 			"
          />
          <polygon
            class="st3"
            points="447,33.6 449.6,30.6 450.7,31.6 448.1,34.6 			"
          />
          <polygon
            class="st3"
            points="473.6,38.3 470,33.9 470.6,33.4 474.2,37.8 			"
          />
          <polygon
            class="st3"
            points="436.3,25.3 434.4,24.4 434.8,23.7 436.7,24.5 			"
          />
          <polygon
            class="st3"
            points="460.2,68.5 459.4,67.8 462.9,63.6 463.7,64.2 			"
          />
          <polygon
            class="st3"
            points="437.9,28.3 438.5,29.1 437.8,29.7 437.1,28.9 			"
          />
          <polygon
            class="st3"
            points="411.5,1.8 414.9,6 413.9,6.8 410.5,2.7 			"
          />
          <polygon
            class="st3"
            points="446.6,14.3 447.9,15.9 446.3,17.3 445,15.6 			"
          />
          <polygon
            class="st3"
            points="453.8,39.3 450.5,34.9 451.7,34.1 455,38.4 			"
          />
          <polygon
            class="st3"
            points="455.1,46.6 455.6,46.2 453.8,44 452.7,44.9 454,46.5 453.5,46.9 455.4,49.2 456.5,48.3 			"
          />
          <polygon
            class="st3"
            points="459.4,35.3 459.2,35.5 458.5,34.6 459.1,34 457.8,32.4 457.1,32.9 454.6,35 456,36.6 456.7,37.5 
				458.1,39.2 460.6,37.1 460.8,36.9 			"
          />
          <polygon
            class="st3"
            points="455.9,40.1 456.4,40.7 453.3,43.2 452.8,42.7 			"
          />
          <polygon
            class="st3"
            points="450.4,11 451.1,11.3 449.6,15.3 448.9,15 			"
          />
          <polygon
            class="st3"
            points="466.6,29.6 466.2,29.1 465,27.6 466.2,26.6 464.4,24.5 463.2,25.5 462.8,25 460.5,26.9 461.5,28 
				463.7,26.1 464.5,27 463.7,27.7 465.4,29.8 461.2,33.3 463.6,36.2 468.7,32.1 			"
          />
          <polygon
            class="st3"
            points="447.4,26.5 445.6,26.3 445.5,27.2 447.2,27.5 447.1,28.2 449.6,28.5 449.8,26.8 			"
          />
          <polygon
            class="st3"
            points="446.6,22.2 447.4,21.6 446.5,20.5 443,23.4 443.9,24.5 444.4,24 444.9,24.6 447.1,22.8 			"
          />
          <polygon
            class="st3"
            points="442.4,25.3 443.7,27 443.1,27.6 441.7,25.9 			"
          />
          <polygon
            class="st3"
            points="441.1,22.7 441.6,23.2 441,23.7 440.5,23.1 			"
          />
          <polygon
            class="st3"
            points="471,24.9 474.8,30.2 473.2,31.4 469.4,26.1 			"
          />
          <polygon
            class="st3"
            points="470.4,41.5 471.2,42.4 470.3,43.2 469.5,42.3 			"
          />
          <polygon
            class="st3"
            points="468.3,45.3 469.1,46.2 468.1,47 467.3,46.1 			"
          />
          <polygon
            class="st3"
            points="426.5,30.4 428.1,32.3 427,33.2 425.4,31.2 			"
          />
          <polygon
            class="st3"
            points="429.3,36.3 430.9,38.2 429.8,39.1 428.2,37.2 			"
          />
          <polygon
            class="st3"
            points="430.8,42.3 432.2,41.3 433.4,43 432,44 			"
          />
          <polygon
            class="st3"
            points="429.3,46 430.6,45 431.9,46.7 430.5,47.7 			"
          />
          <polygon
            class="st3"
            points="466.5,59.9 467.3,60.9 466.3,61.7 465.6,60.7 			"
          />
          <polygon
            class="st3"
            points="444.3,20.5 443.5,19.5 443.5,19.5 443.3,19.2 443.3,19.2 442.6,18.4 441.9,18.9 442.6,19.7 441,21 
				441.3,21.4 442.9,20.1 443.6,21 			"
          />
          <polygon
            class="st3"
            points="424.6,36.5 422.9,34.4 421.5,35.6 422.1,36.3 422.8,35.7 423.9,37.1 			"
          />
          <polygon
            class="st3"
            points="440.2,15.8 446.6,12.1 447.1,13 440.7,16.7 			"
          />
          <polygon
            class="st3"
            points="445.1,6.9 445.3,6.5 444.6,6.1 444.4,6.6 442.2,5.3 444.1,2 442.9,1.3 440.4,5.7 441.6,6.4 
				446.1,8.9 446.7,7.9 			"
          />
          <polygon
            class="st3"
            points="450.4,18.7 449.3,17.3 449.6,17.1 450.7,18.4 			"
          />
          <polygon
            class="st3"
            points="464.1,42.5 461.8,39.7 462.1,39.4 464.4,42.2 			"
          />
          <polygon
            class="st3"
            points="450.5,24.6 449.8,23.9 450.6,23.2 451.2,24 			"
          />

          <rect
            x="454.7"
            y="21.7"
            transform="matrix(0.1543 -0.988 0.988 0.1543 361.7765 469.0193)"
            class="st3"
            width="0.3"
            height="3.1"
          />
          <polygon
            class="st3"
            points="451.9,20.2 451.3,20.8 450.9,20.4 448.9,22 449.3,22.4 450.9,21 451.4,21.6 452.4,20.7 			"
          />
          <polygon
            class="st3"
            points="428,42.3 426.2,41.6 426.5,40.7 421.1,38.5 420.8,39.3 425.3,41.2 424.9,42.3 427.6,43.4 			"
          />
          <polygon
            class="st3"
            points="466.2,49.8 467.7,50.4 467.3,51.3 471.9,53.2 472.3,52.2 468.5,50.7 468.9,49.5 466.6,48.6 			"
          />
          <polygon
            class="st3"
            points="454.4,27.3 453.4,26.1 453.1,25.7 452.6,26.1 452,26.6 452.4,26.9 452.9,26.5 453.9,27.7 			"
          />
          <polygon
            class="st3"
            points="454,31.9 453.6,32.2 453,31.6 452.6,31.9 453.1,32.6 454.3,33.9 455.1,33.2 			"
          />
          <polygon
            class="st3"
            points="463.6,44.9 463.2,45.3 462.1,44 461.7,44.3 462.7,45.6 464.9,48.2 465.8,47.5 			"
          />
          <polygon
            class="st3"
            points="451.6,30.3 450.8,29.4 451.4,29 452.1,29.9 			"
          />
          <polygon
            class="st3"
            points="416.6,42.6 415.8,41.7 416.3,41.3 417.1,42.2 			"
          />
          <polygon
            class="st3"
            points="464.5,22.7 463.7,21.8 464.3,21.4 465,22.3 			"
          />
          <polygon
            class="st3"
            points="454.3,31 453.9,30.5 455.5,29.2 455.9,29.7 			"
          />
          <polygon
            class="st3"
            points="470.9,39.3 470.5,38.8 472.1,37.5 472.5,38 			"
          />
          <polygon
            class="st3"
            points="486.6,33 486.5,33.1 485.4,31.9 485.8,31.5 483.9,29.2 483.5,29.6 482.7,30.2 482.5,30 480.9,31.3 
				483,33.9 483.8,33.2 484.8,34.5 486.7,36.8 488.4,35.5 488.5,35.3 			"
          />
          <polygon
            class="st3"
            points="475,45.9 474.8,45.7 476.1,44.7 476.4,45.1 478.7,43.1 478.4,42.7 477.7,41.9 478,41.7 476.6,40.1 
				474.1,42.3 474.7,43 473.5,44.1 471.2,46 472.5,47.6 472.7,47.8 			"
          />
          <polygon
            class="st3"
            points="458.1,28.8 457.2,27.8 459.6,25.8 460.5,26.9 			"
          />
          <polygon
            class="st3"
            points="458.4,16.1 460.9,18.2 457.6,22.3 455.1,20.2 			"
          />
          <polygon
            class="st3"
            points="478.7,36.7 477.5,37.5 475.3,34.4 476.4,33.6 			"
          />
          <polygon
            class="st3"
            points="483.2,26.2 485.2,21 478.2,18.3 479.3,15.4 470.9,12.1 468.9,17.3 475.8,20 474.7,23 			"
          />
          <polygon
            class="st3"
            points="466.9,59.4 468.2,58.1 466.6,56.3 467.3,55.7 465.3,53.5 464,54.7 465.7,56.5 464.9,57.2 			"
          />
          <polygon class="st3" points="454.8,14.3 451.9,10.8 454,9 457,12.5 			" />
          <polygon
            class="st3"
            points="452.2,19.3 451,17.9 451.7,17.3 452.9,18.7 			"
          />
          <polygon
            class="st3"
            points="425.7,39.5 424.6,38.2 425,37.9 426.1,39.2 			"
          />
          <polygon
            class="st3"
            points="429.1,34.5 427.7,35.4 427.5,35 428.9,34.2 			"
          />
          <polygon
            class="st3"
            points="425.7,47.5 426,47.3 427.8,49.8 427.5,50 			"
          />
          <polygon
            class="st3"
            points="421.3,34.1 421.1,33.9 423.4,31.9 423.6,32.2 			"
          />
          <polygon
            class="st3"
            points="427.6,40.1 426.3,38.7 427,38.1 428.2,39.6 			"
          />
          <polygon
            class="st3"
            points="442,36.3 436.2,41.1 435.4,40 441.1,35.3 			"
          />
          <polygon
            class="st3"
            points="451,50.3 446.9,45.3 448.6,44 452.7,48.9 			"
          />
          <polygon
            class="st3"
            points="449.1,41.3 447.9,39.5 449.6,38.3 450.9,40 			"
          />
          <polygon
            class="st3"
            points="461.2,48.8 461.1,47.1 457.5,47.5 457.7,49.3 459.7,49.1 460.2,54.2 462.2,54 461.7,48.7 			"
          />
          <polygon
            class="st3"
            points="454.1,53.8 451.8,50.8 453.2,49.7 455.5,52.7 			"
          />
          <polygon
            class="st3"
            points="460.4,63 456.7,57.8 458.4,56.5 462.2,61.7 			"
          />
          <polygon
            class="st3"
            points="478.4,47.4 477.9,46.7 480.3,44.9 480.8,45.7 			"
          />
          <polygon
            class="st3"
            points="477,53.4 475.9,51.9 481.7,47.7 482.8,49.2 			"
          />
          <polygon
            class="st3"
            points="485.8,57.1 487.2,56.2 491.9,63.2 490.4,64.2 			"
          />
          <polygon
            class="st3"
            points="483.8,68.4 478.5,61.5 479.4,60.8 484.7,67.7 			"
          />
          <polygon
            class="st3"
            points="491.2,49.7 498.7,60.1 495.7,62.2 488.3,51.8 			"
          />
          <polygon
            class="st3"
            points="480,58 479.3,57.1 482.7,54.5 483.4,55.4 			"
          />
          <polygon
            class="st3"
            points="493.8,68.5 494.6,69.7 492.1,71.6 491.2,70.5 			"
          />

          <rect
            x="497.2"
            y="65.1"
            transform="matrix(0.9754 -0.2206 0.2206 0.9754 -2.4509 111.4964)"
            class="st3"
            width="1.4"
            height="3.3"
          />
          <polygon
            class="st3"
            points="497.2,50.5 496.6,50.9 495.8,49.9 495.3,50.3 496,51.3 498.2,54.1 499.3,53.3 			"
          />
          <polygon
            class="st3"
            points="492.4,64.1 493.7,66.4 490,68.5 488.7,66.2 			"
          />
          <polygon
            class="st3"
            points="478.7,56.1 477.9,55.1 478.9,54.4 479.6,55.3 			"
          />
          <polygon
            class="st3"
            points="476.4,58.6 475.7,57.7 476.7,56.9 477.4,57.9 			"
          />
          <polygon
            class="st3"
            points="484.5,53.9 482.9,51.8 484,51 485.6,53.1 			"
          />
          <polygon
            class="st3"
            points="495.2,46.6 494.5,47.1 493.4,47.9 494.3,49 495.4,48.2 496.7,49.9 497.4,49.4 			"
          />
          <polygon
            class="st3"
            points="497.9,60.4 497,59.2 498.2,58.2 499.1,59.4 			"
          />

          <rect
            x="461.4"
            y="1.1"
            transform="matrix(0.1551 -0.9879 0.9879 0.1551 385.4768 462.0757)"
            class="st3"
            width="3"
            height="9.2"
          />
          <polygon
            class="st3"
            points="454.8,3.1 455.2,2.7 452.8,0.1 452,0.8 453.8,2.8 453.2,3.4 455.4,5.8 456.4,4.8 			"
          />
          <polygon
            class="st3"
            points="473.7,7.3 474,6.4 472.9,5.9 472.5,6.8 472.4,7.1 471.8,8.8 474.7,9.9 475.4,7.9 			"
          />
          <polygon
            class="st3"
            points="486.2,73.6 487.3,74.5 485.3,76.8 484.2,75.9 			"
          />
          <polygon
            class="st3"
            points="493.2,75.3 490.7,73.2 489.8,74.3 492.3,76.4 			"
          />
          <polygon
            class="st3"
            points="494.6,75.6 492.4,73.8 493.3,72.6 495.5,74.5 			"
          />
          <polygon
            class="st3"
            points="491,91.9 490.4,92.1 490.7,92.8 491.3,92.6 491.4,92.5 492.4,92.1 491.7,90.4 490.5,90.9 			"
          />
          <polygon
            class="st3"
            points="491.9,78.8 492.3,78.4 491.3,77.6 490.9,78 490.2,77.5 489.7,78.1 489,77.4 488.7,77.8 489.4,78.4 
				489.6,78.6 491.6,80.3 492.4,79.3 			"
          />
          <polygon
            class="st3"
            points="488.6,84 486.5,80.8 485.4,81.5 487.5,84.7 			"
          />
          <polygon
            class="st3"
            points="356.1,62.1 354.1,61.9 354.2,61.1 352.2,60.9 351.7,65.9 353.6,66.1 353.9,63.7 355.9,63.9 			"
          />
          <polygon
            class="st3"
            points="473.9,79.2 470,81.8 469.2,80.5 473.1,78 			"
          />
          <polygon
            class="st3"
            points="471.2,73.2 473.2,71.9 474.5,73.9 472.5,75.2 			"
          />
          <polygon
            class="st3"
            points="467.3,66.8 467,66.3 463.5,68.6 464.1,69.6 466.9,67.8 468.1,69.7 469.4,68.8 467.8,66.5 			"
          />
          <polygon
            class="st3"
            points="469.7,73.3 468.7,74 469,74.6 467.3,75.7 466.8,76.1 467.9,77.7 468.9,79.4 469.5,79 468.4,77.4 
				470.4,76.1 469.6,74.9 470.3,74.4 			"
          />
          <polygon
            class="st3"
            points="477.6,89.2 474.5,91.2 473.6,89.9 476.7,87.9 			"
          />
          <polygon
            class="st3"
            points="483,77.7 481.2,78.9 480.1,77.1 481.9,76 			"
          />
          <polygon
            class="st3"
            points="478.8,79.8 477.7,80.5 477.3,79.8 478.3,79.2 			"
          />
          <polygon
            class="st3"
            points="486.6,67.5 485.3,68.3 485.6,68.8 481,71.8 482.2,73.7 487.6,70.2 486.5,68.5 487.1,68.2 			"
          />
          <polygon
            class="st3"
            points="478.3,63.1 476.7,64.4 472.9,59.4 474.5,58.2 			"
          />
          <polygon
            class="st3"
            points="480.8,74.2 478.5,76.1 475.6,72.5 477.8,70.7 			"
          />
          <polygon
            class="st3"
            points="476.1,69.2 474.3,70.4 473.3,69 475.2,67.8 			"
          />
          <polygon
            class="st3"
            points="468.7,37.6 466.8,38.8 465.9,37.4 467.8,36.1 			"
          />

          <rect
            x="498"
            y="71.6"
            transform="matrix(0.9921 -0.1253 0.1253 0.9921 -5.2785 63.0832)"
            class="st3"
            width="1.6"
            height="3.7"
          />
          <polygon
            class="st3"
            points="494.4,85.3 493.7,85.1 494,84.3 494.7,84.6 			"
          />
          <polygon
            class="st3"
            points="495.4,78.9 494.7,78.7 495,78 495.7,78.2 			"
          />
          <polygon
            class="st3"
            points="484.6,81.4 483.9,81.1 484.2,80.4 484.9,80.6 			"
          />
          <polygon
            class="st3"
            points="490,90 489.2,89.7 489.5,89 490.2,89.2 			"
          />
          <polygon
            class="st3"
            points="489.2,92 488.5,91.7 488.8,91 489.5,91.3 			"
          />

          <rect
            x="328.6"
            y="89.6"
            transform="matrix(6.940549e-02 -0.9976 0.9976 6.940549e-02 215.0861 413.3682)"
            class="st3"
            width="0.9"
            height="3.7"
          />
          <polygon
            class="st3"
            points="328.8,86.1 330.4,86.1 330.4,86.8 332,86.8 332,82.7 330.4,82.7 330.4,84.7 328.8,84.7 			"
          />
          <polygon
            class="st3"
            points="331.1,66.7 330.8,72.7 330.2,72.7 330,75.5 330.6,75.5 330.6,76.9 331.9,77 332.4,66.8 			"
          />
          <polygon
            class="st3"
            points="320.7,83.1 320.7,81.5 320.2,81.5 320.4,75.9 318.2,75.8 317.9,82.3 319.9,82.4 319.8,83.1 			"
          />

          <rect
            x="320.4"
            y="69"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 393.6086 -249.3363)"
            class="st3"
            width="2.1"
            height="6.3"
          />
          <polygon
            class="st3"
            points="465.5,16.9 462.8,15.9 464.3,11.5 467.1,12.5 			"
          />
          <polygon
            class="st3"
            points="345.3,68.7 345.2,70.4 342.6,70.2 342.7,69.6 341.2,69.5 341.1,70.4 338.5,70.2 338.6,68.7 
				337.1,68.5 336.9,71.7 338.4,71.8 338.4,71.3 341,71.5 340.8,73.5 342.4,73.7 342.6,71.4 346.6,71.7 346.6,71.1 346.6,70.6 
				346.8,68.8 			"
          />

          <rect
            x="319.2"
            y="87.5"
            transform="matrix(6.941723e-02 -0.9976 0.9976 6.941723e-02 207.4557 403.5331)"
            class="st3"
            width="1.7"
            height="6.1"
          />
          <polygon
            class="st3"
            points="471.3,60.4 472.1,61.4 468.6,64.4 467.7,63.3 			"
          />

          <rect
            x="316.6"
            y="111.7"
            transform="matrix(0.1432 -0.9897 0.9897 0.1432 160.5085 412.3871)"
            class="st3"
            width="3.6"
            height="3.6"
          />
          <polygon
            class="st3"
            points="481.8,96.3 482.5,94.5 478.7,92.9 478.9,92.3 477.1,91.5 476.3,93.4 478.2,94.2 478.4,93.7 
				477.9,94.8 			"
          />
          <polygon
            class="st3"
            points="327.9,103.5 328.2,102.7 322.3,100.3 321.7,101.9 326.3,103.8 325,107 327.2,107.8 328.8,103.9 			
				"
          />

          <rect
            x="346.2"
            y="65.6"
            transform="matrix(0.1431 -0.9897 0.9897 0.1431 231.5957 399.9435)"
            class="st3"
            width="1.2"
            height="1.2"
          />

          <rect
            x="328.8"
            y="92.6"
            transform="matrix(6.938690e-02 -0.9976 0.9976 6.938690e-02 212.8273 416.0127)"
            class="st3"
            width="1.1"
            height="2.7"
          />

          <rect
            x="316.3"
            y="100.3"
            transform="matrix(6.943449e-02 -0.9976 0.9976 6.943449e-02 194.2857 410.2472)"
            class="st3"
            width="1.4"
            height="1.4"
          />

          <rect
            x="343.1"
            y="61.1"
            transform="matrix(0.9882 -0.1529 0.1529 0.9882 -5.402 53.2863)"
            class="st3"
            width="1.4"
            height="1.4"
          />
          <polygon
            class="st3"
            points="328,100.2 325.4,99.1 326.5,96.5 329.1,97.6 			"
          />

          <rect
            x="327"
            y="83.3"
            transform="matrix(6.937881e-02 -0.9976 0.9976 6.937881e-02 227.6427 412.2955)"
            class="st3"
            width="15.7"
            height="1.7"
          />
          <polygon
            class="st3"
            points="460.5,95.3 459.4,94.4 460.1,93.6 461.2,94.5 			"
          />
          <polygon
            class="st3"
            points="484.8,16.5 482.6,14.6 485,11.6 487.3,13.5 			"
          />
          <polygon
            class="st3"
            points="488.5,12.8 487.2,11.7 487.8,10.9 489.2,12 			"
          />
          <polygon
            class="st3"
            points="499.3,10.6 495.5,5.7 498,3.7 495.7,0.8 493.2,2.9 491.6,0.9 487.4,4.3 495.1,13.9 			"
          />
          <rect
            x="490.6"
            y="16.8"
            transform="matrix(0.9924 -0.123 0.123 0.9924 1.4276 60.6564)"
            class="st3"
            width="2.7"
            height="4"
          />
          <polygon
            class="st3"
            points="498.1,18.9 496,17.1 497.9,14.9 500,16.6 			"
          />
          <polygon
            class="st3"
            points="483.9,4.1 482.5,2.9 483.9,1.3 485.3,2.5 			"
          />
          <polygon
            class="st3"
            points="477.3,1.5 477.5,0.5 480.8,1.3 480.6,2.2 			"
          />
          <polygon
            class="st3"
            points="460.8,76.7 460.4,77 460.1,76.6 460.5,76.3 			"
          />
          <polygon
            class="st3"
            points="473.3,94.4 471.8,95.1 470.2,91.1 471.6,90.5 			"
          />
          <polygon
            class="st3"
            points="462.6,85.7 461.9,86.5 461.6,86.2 461,87 462.9,88.6 463.6,87.9 462.6,87.1 463.3,86.3 			"
          />
          <polygon
            class="st3"
            points="473.1,86.9 475.1,83.8 475.4,84 476.4,82.6 476.1,82.4 476.6,81.6 475.9,81.2 472.4,86.4 			"
          />
          <polygon
            class="st3"
            points="467.9,94.2 467.1,95.5 467.4,95.7 464.9,100 466.1,100.7 469.1,95.6 468,95 468.3,94.5 			"
          />
          <polygon
            class="st3"
            points="465.5,76.5 464.5,77.2 462.3,74 463.4,73.3 			"
          />
          <polygon
            class="st3"
            points="459.8,82.3 459,83.1 457.9,82 458.2,81.7 457.5,81.1 457.1,81.5 455.9,80.4 456.6,79.7 455.9,79.1 
				454.6,80.5 455.2,81.1 455.4,80.9 456.6,82 455.8,82.9 456.4,83.6 457.4,82.5 459.2,84.2 459.5,84 459.7,83.7 460.4,82.9 			"
          />
          <polygon
            class="st3"
            points="463.1,71 459.2,75.6 458.4,75 462.3,70.3 			"
          />
          <polygon
            class="st3"
            points="453.4,77.8 452,76.7 455.1,73 456.4,74.1 			"
          />
          <polygon
            class="st3"
            points="469.3,86.6 466,88.8 462.2,83 465.5,80.8 			"
          />
          <polygon
            class="st3"
            points="462.7,113.6 457.2,117.3 453.9,112.3 459.4,108.7 			"
          />

          <rect
            x="460.1"
            y="79.4"
            transform="matrix(0.1895 -0.9819 0.9819 0.1895 295.6757 518.265)"
            class="st3"
            width="3.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="465.9,79.3 464.4,80.3 464.1,79.8 465.6,78.8 			"
          />
          <polygon
            class="st3"
            points="459.9,81.6 459.1,82.2 458.5,81.4 459.3,80.8 			"
          />
          <polygon
            class="st3"
            points="430.7,9.3 428,5.3 429.2,4.5 426.4,0.5 418.5,5.9 421.2,9.9 425.1,7.3 427.9,11.3 			"
          />
          <polygon
            class="st3"
            points="442,11.2 436.8,13.7 436.6,13.1 434.1,14.3 434.4,14.8 433.1,15.4 433.7,16.5 442.6,12.4 			"
          />
          <polygon
            class="st3"
            points="439.3,7.4 437.9,8.4 436.5,6.4 437,6 436.2,4.8 435.5,5.3 434,3.2 435.3,2.4 434.5,1.2 432,2.9 
				432.8,4.1 433.2,3.8 434.6,5.9 433,7 433.8,8.2 435.7,7 437.8,10.1 438.3,9.8 438.7,9.5 440.1,8.6 			"
          />
          <polygon
            class="st3"
            points="449.9,7.9 446.4,2.8 447.4,2.1 450.8,7.2 			"
          />
          <polygon
            class="st3"
            points="487.5,87.6 489.5,86.5 489.1,85.7 487.1,86.8 486.8,86.3 485.6,86.9 487,89.5 488.2,88.8 			"
          />
          <polygon
            class="st3"
            points="491.8,96.5 491.4,95.9 493.5,94.5 493.8,95.1 			"
          />
          <polygon
            class="st3"
            points="494.7,89.5 494.3,88.4 493.7,88.6 493.3,87.4 492.7,87.6 493.2,88.8 493.6,89.9 			"
          />

          <rect
            x="492.9"
            y="82.9"
            transform="matrix(0.999 -4.446455e-02 4.446455e-02 0.999 -3.2164 22.0236)"
            class="st3"
            width="1.1"
            height="0.9"
          />

          <rect
            x="491.5"
            y="82.9"
            transform="matrix(0.999 -4.430729e-02 4.430729e-02 0.999 -3.2643 21.8763)"
            class="st3"
            width="0.8"
            height="3.3"
          />
          <polygon
            class="st3"
            points="496.1,85 496.1,84.6 495.1,84.6 495.2,85.4 496,85.4 496.1,86 495.2,86 495.3,87.5 496.7,87.4 
				496.6,86 496.6,84.9 			"
          />
          <polygon
            class="st3"
            points="496.7,88.7 496.7,88 495.7,88.1 495.7,88.7 496.5,88.7 496.5,90 497,89.9 496.9,88.7 			"
          />

          <rect
            x="495.2"
            y="82.3"
            transform="matrix(1.762810e-02 -0.9998 0.9998 1.762810e-02 403.8322 577.2488)"
            class="st3"
            width="1"
            height="1.6"
          />

          <rect
            x="495.9"
            y="90.5"
            transform="matrix(0.999 -4.447748e-02 4.447748e-02 0.999 -3.6032 22.1636)"
            class="st3"
            width="0.7"
            height="3"
          />
          <polygon
            class="st3"
            points="499.5,81.7 498.7,81.4 499.2,80.1 500,80.4 			"
          />
          <polygon
            class="st3"
            points="458.4,101.9 459.4,102.4 461.2,99.2 460.2,98.6 459,100.9 455.9,99.3 455.4,100.2 			"
          />
          <polygon
            class="st3"
            points="463,93.1 461.2,96.1 462.4,96.8 464.2,93.8 464.9,94.3 465.9,92.7 462.2,90.5 461.2,92.1 			"
          />
          <polygon
            class="st3"
            points="433.6,80.2 433.2,80.7 440.9,86.3 440,87.6 440.9,88.2 442.5,86 442.4,86 441.6,85.4 434.8,80.4 
				436.3,78.3 434.9,77.3 433,79.9 			"
          />
          <polygon
            class="st3"
            points="448.4,88.6 447.5,89.6 448.4,90.5 446.3,92.7 448.3,94.6 450.4,92.4 456.9,98.4 458.8,96.3 
				451.3,89.3 453.1,87.3 450.4,84.8 447.6,87.8 			"
          />
          <polygon
            class="st3"
            points="452.3,82.5 452.8,83 452.3,83.5 451.8,83 			"
          />
          <polygon
            class="st3"
            points="449.9,81.2 450.3,81.7 449.8,82.1 449.4,81.6 			"
          />
          <polygon
            class="st3"
            points="450,78.3 449.5,77.6 450.1,77.1 450.7,77.7 			"
          />
          <polygon
            class="st3"
            points="452.7,95.7 454.2,97.3 453.9,97.6 452.4,96 			"
          />
          <polygon
            class="st3"
            points="457.6,90.4 458.5,91.3 457,92.7 456.1,91.7 			"
          />
          <polygon
            class="st3"
            points="429.8,59.7 429,59 432.1,55.9 432.8,56.6 			"
          />
          <polygon
            class="st3"
            points="428.1,60.6 424.9,56.5 425.4,56.1 428.6,60.2 			"
          />
          <polygon
            class="st3"
            points="431.5,50.4 434.2,54.7 433.8,55 431.1,50.6 			"
          />
          <polygon
            class="st3"
            points="427.1,53.6 426.7,53.1 428.7,51.5 429.1,52 			"
          />
          <polygon
            class="st3"
            points="433.6,61.8 434.2,62.5 432.7,63.6 432.1,62.9 			"
          />
          <polygon
            class="st3"
            points="444.2,57.2 444.6,57.7 443.1,58.8 442.7,58.4 			"
          />
          <polygon
            class="st3"
            points="442.9,51.6 442.4,51 440.4,52.6 440.9,53.2 442.3,52.1 443.7,54 444.3,53.5 			"
          />
          <polygon
            class="st3"
            points="422.1,71.6 423.7,70 423.1,69.3 421.4,70.9 421,70.5 420,71.4 422.1,73.5 423,72.6 			"
          />
          <polygon
            class="st3"
            points="437.6,52 437,51.2 437.4,50.9 438,51.7 			"
          />
          <polygon
            class="st3"
            points="432,59.7 432.7,60.5 432.5,60.6 431.9,59.8 			"
          />
          <polygon
            class="st3"
            points="426.7,55.5 426.2,54.9 426.8,54.4 427.2,55 			"
          />
          <polygon
            class="st3"
            points="429.5,55.4 429.3,55.1 429.9,54.5 430.2,54.9 			"
          />
          <polygon
            class="st3"
            points="435.5,50.8 434,48.9 434.7,48.3 436.2,50.2 			"
          />
          <polygon
            class="st3"
            points="436.1,53.8 436.1,53.3 436.3,52.5 435.5,52.3 435.3,53.2 434,53 434,53.4 			"
          />
          <polygon
            class="st3"
            points="433.8,59.5 433.2,58.8 433.9,58.2 434.5,59 			"
          />
          <polygon
            class="st3"
            points="435.6,56.3 436.2,57 434.8,58.1 434.2,57.4 			"
          />
          <polygon
            class="st3"
            points="438.7,62.4 437.7,61 438.4,60.5 439.5,61.9 			"
          />
          <polygon
            class="st3"
            points="425.9,76.1 428.1,74.3 428.7,74.9 426.4,76.8 			"
          />
          <polygon
            class="st3"
            points="443.5,63.4 444.4,64.6 443.2,65.5 442.3,64.3 			"
          />
          <polygon
            class="st3"
            points="446.3,46.2 446.6,46 445,43.9 444.4,44.4 445.7,46 444.5,46.9 445.1,47.6 446.5,46.5 			"
          />
          <polygon
            class="st3"
            points="440.2,57.7 440.7,57.3 440.2,56.7 439.7,57.1 439.6,57.2 438.7,57.8 439.9,59.3 440.9,58.5 			"
          />
          <polygon
            class="st3"
            points="450.8,55.1 451.1,54.9 446.8,49.3 447.8,48.5 447.3,47.9 445.7,49.1 445.7,49.2 446.1,49.8 
				450,54.8 448.4,55.9 449.2,56.9 451.1,55.5 			"
          />
          <polygon
            class="st3"
            points="428.9,71.5 429.2,71.2 428.9,70.9 429.6,70.4 429.1,69.7 428.4,70.2 426.7,68 426.1,68.5 428,71.1 
				427.4,71.5 428.2,72.5 429.1,71.8 			"
          />
          <polygon
            class="st3"
            points="440,43.2 439,44 439.4,44.4 438.4,45.2 438.8,45.7 439.8,44.9 440.7,44.2 			"
          />
          <polygon
            class="st3"
            points="442.4,43 440.8,40.9 441.5,40.4 443.1,42.5 			"
          />
          <polygon
            class="st3"
            points="437.4,42.9 436.9,42.2 440,39.9 440.5,40.5 			"
          />
          <polygon
            class="st3"
            points="433.8,47 433.1,46.2 433.9,45.5 434.6,46.4 			"
          />
          <polygon
            class="st3"
            points="441.2,46.2 441.6,45.9 441,45.2 440.3,45.7 440.9,46.3 440.4,46.7 439.8,46 438.7,46.9 439.6,48.1 
				440.7,47.2 441.5,46.5 			"
          />
          <polygon
            class="st3"
            points="438.1,48.5 438.6,48.1 438,47.3 437.5,47.7 437.9,48.3 436.9,49.1 437.2,49.5 438.2,48.7 			"
          />
          <polygon
            class="st3"
            points="439.3,55.8 438.8,55.2 439.4,54.7 439.9,55.3 			"
          />
          <polygon
            class="st3"
            points="439.9,53.2 440.3,53.9 439.7,54.3 439.2,53.7 			"
          />
          <polygon
            class="st3"
            points="444.7,59 444.4,58.6 444.8,58.3 445.1,58.7 			"
          />
          <polygon
            class="st3"
            points="446.8,59.2 446.5,58.8 446.8,58.6 447.1,58.9 			"
          />
          <polygon
            class="st3"
            points="446.4,59.9 446.8,60.3 446.3,60.7 445.9,60.2 			"
          />
          <polygon
            class="st3"
            points="436.7,64 437,63.7 436.6,63.1 436.2,63.4 435.9,62.9 435.5,63.2 435.2,62.8 435,62.9 435.3,63.4 
				435.4,63.5 436.4,64.8 437,64.3 			"
          />
          <polygon
            class="st3"
            points="427.7,66.8 428.2,67.4 429.2,66.5 428.8,66 429.5,65.3 428.9,64.6 429.8,63.9 429.4,63.4 
				428.6,64.1 428.3,64.3 426.1,66.1 427,67.3 			"
          />
          <polygon
            class="st3"
            points="442.7,67 441.8,65.9 441.2,66.4 439.7,64.5 439.3,63.9 437.4,65.3 435.5,66.8 436,67.3 437.9,65.9 
				439.6,68.1 440.9,67.1 441.6,67.9 			"
          />
          <polygon
            class="st3"
            points="432.4,71 431,69.2 431.8,68.6 433.2,70.4 			"
          />
          <polygon
            class="st3"
            points="440.4,70.6 440.1,70.2 440.5,69.9 440.8,70.3 			"
          />
          <polygon
            class="st3"
            points="433.5,69.3 433.1,68.7 433.4,68.4 433.9,69 			"
          />
          <polygon
            class="st3"
            points="434.3,66.4 432.9,64.5 432.3,65 433.7,66.9 			"
          />
          <polygon
            class="st3"
            points="395.9,56.8 402.9,58.5 402.5,60.1 395.5,58.4 			"
          />
          <polygon
            class="st3"
            points="409.8,57.8 411.6,60.2 410.1,61.4 408.3,59 			"
          />
          <polygon
            class="st3"
            points="417,47 416.5,46.4 414.5,48 414.9,48.6 416.4,47.5 417.8,49.4 418.4,48.9 			"
          />
          <polygon
            class="st3"
            points="410.5,41.5 412.1,39.9 411.5,39.2 409.8,40.8 409.4,40.4 408.4,41.3 410.5,43.4 411.4,42.5 			"
          />

          <rect
            x="405"
            y="48.3"
            transform="matrix(0.1713 -0.9852 0.9852 0.1713 286.8446 440.8024)"
            class="st3"
            width="1"
            height="3.1"
          />
          <polygon
            class="st3"
            points="405,54.8 403.3,53 404,52.4 405.6,54.2 			"
          />
          <polygon
            class="st3"
            points="401.5,50.1 401.1,50.4 400.4,50.9 400.9,51.6 401.6,51.1 402.4,52.1 402.8,51.8 			"
          />
          <polygon
            class="st3"
            points="405.7,59.6 406.3,60.3 405,61.5 404.3,60.8 			"
          />
          <polygon
            class="st3"
            points="411.1,65.1 410,63.7 410.7,63.2 411.8,64.5 			"
          />
          <polygon
            class="st3"
            points="405.1,56.5 405.6,56.1 405.2,55.5 404.7,55.9 404.6,56 403.7,56.7 404.8,58.1 405.8,57.4 			"
          />
          <polygon
            class="st3"
            points="412.5,50.7 412.2,51 412.5,51.2 411.9,51.9 412.6,52.5 413.2,51.9 415.2,53.8 415.7,53.2 413.4,51 
				413.9,50.4 413.1,49.6 412.3,50.4 			"
          />
          <polygon
            class="st3"
            points="410.1,46.6 410.4,46.4 409.8,45.6 409.2,46.2 409.7,46.8 409.3,47.2 408.7,46.6 407.6,47.5 
				408.5,48.6 409.6,47.7 410.4,47 			"
          />
          <polygon
            class="st3"
            points="406.4,47.9 406.9,47.5 406.3,46.7 405.8,47.2 406.3,47.8 405.3,48.6 405.6,48.9 406.6,48.1 			"
          />
          <polygon
            class="st3"
            points="408.6,57 408.1,56.3 408.8,55.9 409.2,56.5 			"
          />
          <polygon
            class="st3"
            points="412.5,45.1 413,45.7 412.3,46.2 411.9,45.5 			"
          />
          <polygon
            class="st3"
            points="412.8,72.4 412.3,71.8 411,72.9 411.6,73.6 410.7,74.4 411.4,75.2 410.4,76.1 410.9,76.6 
				411.9,75.6 412.1,75.4 414.7,73 413.6,71.7 			"
          />
          <polygon
            class="st3"
            points="447.3,81.2 444.8,79.2 445.4,78.5 447.9,80.5 			"
          />
          <polygon
            class="st3"
            points="450.3,75.3 449.7,74.5 450,74.3 449.4,73.5 447.4,75 448,75.8 449,75.1 449.6,75.9 			"
          />
          <polygon
            class="st3"
            points="448,82.4 445.1,84.7 444.9,84.4 443.5,85.4 443.7,85.7 443,86.3 443.5,86.9 448.5,83 			"
          />
          <polygon
            class="st3"
            points="446.6,73.4 445.8,72.8 445.6,73.1 442.9,70.9 442,72 445.1,74.5 445.9,73.6 446.2,73.8 			"
          />
          <polygon
            class="st3"
            points="434.3,75.1 433.5,74.1 436.6,71.7 437.3,72.7 			"
          />
          <polygon
            class="st3"
            points="447.2,70.6 446.1,69.2 448.4,67.4 449.5,68.8 			"
          />
          <polygon
            class="st3"
            points="448.5,65.9 447.6,64.8 448.4,64.2 449.3,65.3 			"
          />
          <polygon
            class="st3"
            points="448.6,62.7 447.9,61.8 448.8,61.1 449.5,62 			"
          />
          <polygon
            class="st3"
            points="452.4,65.7 450.6,63.4 451.4,62.7 453.2,65.1 			"
          />
          <polygon
            class="st3"
            points="453.8,65.2 452.7,63.8 453,63.6 454,65.1 			"
          />
          <polygon
            class="st3"
            points="453,59.7 452.2,60.3 451.7,59.6 451.3,59.9 451.8,60.6 451.5,60.8 452.4,62 453.8,60.8 			"
          />
          <polygon
            class="st3"
            points="456.8,71 454.4,67.9 455,67.4 457.4,70.6 			"
          />
          <polygon
            class="st3"
            points="457.1,68.4 456.3,67.3 456.9,66.8 457.7,67.9 			"
          />
          <polygon
            class="st3"
            points="453.6,72.9 452.5,71.5 453,71.1 454.1,72.6 			"
          />
          <polygon
            class="st3"
            points="456.3,65 455.7,64.3 456.4,63.7 457,64.5 			"
          />
          <polygon
            class="st3"
            points="443.4,48.8 442.5,47.6 442.7,47.4 443.7,48.6 			"
          />
          <polygon
            class="st3"
            points="467.5,117.7 467,116.9 464.3,118.4 464.6,118.9 462.4,120.2 463.7,122.4 465.9,121.1 466.4,122.1 
				469.1,120.6 468.3,119.3 466.3,120.4 465.4,118.8 			"
          />
          <polygon
            class="st3"
            points="491.5,46.7 492.4,45.6 489.5,43.2 488.9,43.9 486.5,41.9 483.9,45 486.3,47 485.2,48.4 488.1,50.8 
				489.7,48.9 487.5,47.1 489.3,44.9 			"
          />
          <polygon
            class="st3"
            points="320.8,67.2 321.7,66.5 320.5,65 323.9,62.4 323.9,62.5 325.6,61.2 325,60.5 323.8,61.4 323.7,61.2 
				319.7,64.3 320,64.8 319.3,65.3 			"
          />
          <polygon
            class="st3"
            points="327.4,67.2 328.4,66.5 327.2,65 330.5,62.4 330.6,62.5 332.3,61.2 331.7,60.5 330.5,61.4 
				330.4,61.2 326.4,64.3 326.7,64.8 326,65.3 			"
          />
          <polygon
            class="st3"
            points="320,88.8 321,88.1 319.8,86.6 323.1,84 323.2,84.1 324.8,82.8 324.2,82 323,83 322.9,82.8 
				318.9,85.9 319.3,86.4 318.6,86.9 			"
          />
          <polygon
            class="st3"
            points="337.1,86.4 336.5,85.8 338.4,84.1 339,84.8 			"
          />
          <polygon
            class="st3"
            points="337.2,83.8 336.7,83.2 338.6,81.5 339.1,82.2 			"
          />
          <polygon
            class="st3"
            points="337.4,81.2 336.8,80.6 338.7,78.9 339.3,79.6 			"
          />
          <polygon
            class="st3"
            points="337.5,78.6 337,78 338.9,76.3 339.4,77 			"
          />
          <polygon
            class="st3"
            points="339.1,94.8 337.9,94.3 338.5,93.1 339.7,93.6 			"
          />

          <rect
            x="341.7"
            y="77.7"
            transform="matrix(4.637893e-02 -0.9989 0.9989 4.637893e-02 248.2254 416.6611)"
            class="st3"
            width="1.3"
            height="1.3"
          />

          <rect
            x="342.1"
            y="77.3"
            transform="matrix(0.1425 -0.9898 0.9898 0.1425 219.2579 408.7426)"
            class="st3"
            width="6.9"
            height="1.1"
          />

          <rect
            x="322.6"
            y="82.7"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 409.6139 -243.4073)"
            class="st3"
            width="7.8"
            height="0.8"
          />

          <rect
            x="340.9"
            y="80.8"
            transform="matrix(4.632379e-02 -0.9989 0.9989 4.632379e-02 244.3659 419.2158)"
            class="st3"
            width="1.7"
            height="1.7"
          />
          <polygon
            class="st3"
            points="330,89.9 329.3,89.6 330,87.9 330.8,88.3 			"
          />
          <polygon
            class="st3"
            points="343.6,66.6 342.8,66.2 343.6,64.6 344.3,64.9 			"
          />
          <polygon
            class="st3"
            points="370.1,60.9 371.7,61.7 371.3,62.5 369.7,61.7 			"
          />
          <polygon
            class="st3"
            points="497.2,99.4 496.5,98.8 497.1,98 497.8,98.6 			"
          />
          <polygon
            class="st3"
            points="486.8,99.9 486.3,99.1 487.1,98.6 487.6,99.4 			"
          />
          <polygon
            class="st3"
            points="490.9,95.5 490.2,94.9 490.8,94.2 491.6,94.8 			"
          />
          <polygon
            class="st3"
            points="483.6,101.9 483.1,101.1 483.9,100.6 484.4,101.4 			"
          />
          <polygon
            class="st3"
            points="495.2,100.4 495.8,101.2 495,101.8 494.4,101 			"
          />
          <polygon
            class="st3"
            points="492.2,104.7 490.7,102.6 492.8,101.1 494.3,103.2 			"
          />
          <polygon
            class="st3"
            points="489.3,108.6 486.5,104.6 488,103.6 490.8,107.5 			"
          />
          <polygon
            class="st3"
            points="332.8,123.6 331.4,121.1 332.7,120.4 334,122.9 			"
          />
          <polygon
            class="st3"
            points="323.2,86.1 324.1,86.9 323.4,87.7 322.6,86.9 			"
          />
          <polygon
            class="st3"
            points="333.5,102.4 331.2,101.5 332,99.5 334.3,100.5 			"
          />
          <polygon
            class="st3"
            points="338.6,98 333,95.4 333.5,94.4 339.1,97 			"
          />
          <polygon
            class="st3"
            points="409.4,36.7 408.7,37 407.9,35.1 408.6,34.8 			"
          />
          <polygon
            class="st3"
            points="486.8,78.9 487.4,77.8 488.2,78.2 487.5,79.3 			"
          />
          <polygon
            class="st3"
            points="481.6,80.7 482.7,80 485,83.4 483.9,84.2 			"
          />
          <polygon
            class="st3"
            points="478.9,91.2 479.7,89.8 480.5,90.3 479.7,91.7 			"
          />
          <polygon
            class="st3"
            points="480.7,85.7 482.1,85 482.5,85.9 481.1,86.5 			"
          />
          <polygon
            class="st3"
            points="477.1,85.8 479.2,82 480.2,82.5 478.1,86.3 			"
          />
          <polygon
            class="st3"
            points="483.5,60.9 483.9,60.1 485.1,60.7 484.7,61.5 			"
          />
          <polygon
            class="st3"
            points="478.2,113.7 480.4,114.6 479.9,116 477.6,115 			"
          />
          <polygon
            class="st3"
            points="482.2,112 484.5,112.9 483.9,114.3 481.6,113.3 			"
          />
          <polygon
            class="st3"
            points="486.3,110.3 488.5,111.2 487.9,112.6 485.7,111.6 			"
          />
          <polygon
            class="st3"
            points="490.3,108.5 492.6,109.5 492,110.9 489.7,109.9 			"
          />
          <polygon
            class="st3"
            points="446.8,127.9 452.2,125.2 452.7,126.1 447.3,128.9 			"
          />

          <rect
            x="487.6"
            y="80.9"
            transform="matrix(5.934178e-02 -0.9982 0.9982 5.934178e-02 378.5925 564.2371)"
            class="st3"
            width="2.2"
            height="0.7"
          />
          <polygon
            class="st3"
            points="415.4,23.5 415,22.9 414.2,21.9 412.3,23.4 413,24.4 410,26.7 410.4,27.3 			"
          />
          <polygon
            class="st3"
            points="395.4,68 394.7,67.6 395.1,66.8 395.9,67.2 			"
          />
          <polygon
            class="st3"
            points="393.9,67.8 391.5,69.1 391,68.3 393.4,67 			"
          />
          <polygon
            class="st3"
            points="397.5,68.9 395.2,70.3 394.7,69.5 397.1,68.1 			"
          />
          <polygon
            class="st3"
            points="400.5,70.1 398.1,71.4 397.7,70.6 400.1,69.3 			"
          />
          <polygon
            class="st3"
            points="368.9,75 369,74.1 365.4,73.8 365.3,74.7 366.1,74.8 366.1,75.6 365.9,75.6 365.4,81.6 368.2,81.9 
				368.7,75.8 366.6,75.7 366.6,74.8 			"
          />
          <polygon
            class="st3"
            points="333.2,110.4 329.8,108.5 330.8,106.7 334.2,108.7 			"
          />
          <polygon
            class="st3"
            points="330.6,110.2 330.9,109.6 330.1,109.2 329.6,110 330,110.2 329.3,111.6 332,113.2 332.9,111.5 			"
          />
          <polygon
            class="st3"
            points="333,115.1 332.8,115.9 329.5,115.1 329.1,116.9 333.3,117.8 333.9,115.3 			"
          />
          <polygon
            class="st3"
            points="339.5,62.6 338.9,63.1 338,62 337.3,62.6 336.1,61.1 335.5,61.6 337,63.4 337.6,62.9 338.3,63.7 
				338.6,64.1 339.3,64.9 340.6,63.8 			"
          />
          <polygon
            class="st3"
            points="422.9,61.1 424.3,60 426.3,62.7 424.9,63.8 			"
          />
          <polygon
            class="st3"
            points="419.3,66 418.8,65.5 416.1,68.4 417,69.2 419,67.1 419.7,67.7 422.2,65.1 421,64.1 			"
          />
          <polygon
            class="st3"
            points="413.8,63.8 414.8,62.8 415.8,63.7 414.9,64.8 			"
          />
          <polygon
            class="st3"
            points="414.2,56.4 415.4,57.8 414.9,58.2 416,59.5 419.4,56.7 418.2,55.4 416.6,56.8 415.5,55.4 			"
          />
          <polygon
            class="st3"
            points="421.2,61.5 422.1,54.1 422.9,54.2 423.3,50.7 422.6,50.6 422.8,48.9 421.2,48.7 419.6,61.3 			"
          />
          <polygon
            class="st3"
            points="365.2,238.8 365.8,237 369.6,238.3 369,240.1 			"
          />
          <polygon
            class="st3"
            points="333.5,207 334.9,206 336.1,207.7 334.7,208.7 			"
          />
          <polygon
            class="st3"
            points="409.5,81 411.1,79.2 413.4,81.3 411.8,83.1 			"
          />
          <polygon
            class="st3"
            points="408.3,90.9 408.6,89.9 412.8,91.2 412.5,92.2 			"
          />
          <polygon
            class="st3"
            points="406.7,89.9 408.9,85.1 409.5,85.4 407.3,90.1 			"
          />
          <polygon
            class="st3"
            points="341.3,214.3 344.6,218.2 344.3,218.5 340.9,214.6 			"
          />
          <polygon
            class="st3"
            points="418.9,83.5 419.7,84.3 416.9,87.4 416,86.7 			"
          />
          <polygon
            class="st3"
            points="350.7,153.3 351.2,154 349.7,155.1 349.2,154.4 			"
          />
          <polygon
            class="st3"
            points="398.3,111.9 400.7,111.8 400.6,110.9 398.3,110.9 398.3,110.3 397,110.3 397.1,113.3 398.4,113.3 
							"
          />
          <polygon
            class="st3"
            points="338.9,213.7 338.3,213 338.7,212.6 339.3,213.4 			"
          />
          <polygon
            class="st3"
            points="409.4,92.9 408.9,93.9 408.8,93.8 409.2,92.8 			"
          />
          <polygon
            class="st3"
            points="419,80.3 419.9,81.1 419.1,82.1 418.2,81.2 			"
          />
          <polygon
            class="st3"
            points="414.1,80.7 414.6,81.2 413.7,82.3 413.1,81.8 			"
          />
          <polygon
            class="st3"
            points="339.7,211.7 338.2,209.8 339,209.3 340.4,211.2 			"
          />
          <polygon
            class="st3"
            points="349,213.4 348.4,212.8 347.4,211.6 346.3,212.7 347.3,213.8 345.6,215.4 346.2,216 			"
          />
          <polygon
            class="st3"
            points="352.1,152.9 351.5,152.2 352.3,151.7 352.8,152.4 			"
          />
          <polygon
            class="st3"
            points="355.6,147.6 356.4,148.2 355.2,149.6 354.5,148.9 			"
          />
          <polygon
            class="st3"
            points="356.3,159 355.3,157.6 356,157 357,158.4 			"
          />
          <polygon
            class="st3"
            points="392,95.1 394.7,96.2 394.4,97 391.6,95.9 			"
          />
          <polygon
            class="st3"
            points="358.5,154.3 359.6,154.7 360.1,153.3 359,152.9 358.7,152.8 356.7,152 355.4,155.5 357.7,156.4 			
				"
          />
          <polygon
            class="st3"
            points="397.5,95.6 397.9,95.7 398,95.4 398.8,95.7 399.2,94.9 398.4,94.6 399.6,92 398.8,91.7 397.5,94.7 
				396.8,94.3 396.3,95.4 397.3,95.9 			"
          />
          <polygon
            class="st3"
            points="340.3,204.9 341.4,204.5 341.2,203.9 342.4,203.5 342.2,202.9 341,203.4 339.9,203.8 			"
          />
          <polygon
            class="st3"
            points="338,208 337.3,207.2 338.2,206.5 338.8,207.3 			"
          />
          <polygon
            class="st3"
            points="345.5,207.1 345.8,206.9 345.2,206.1 344.6,206.6 345.1,207.3 344.6,207.7 344.1,207 342.9,207.9 
				343.8,209 345,208.2 345.8,207.5 			"
          />
          <polygon
            class="st3"
            points="342.2,210.3 342.7,209.8 342,209.1 341.5,209.6 342.1,210.1 341.2,211 341.5,211.3 342.4,210.4 			
				"
          />
          <polygon
            class="st3"
            points="374.7,241.8 374.3,241.2 374.9,240.8 375.4,241.4 			"
          />
          <polygon
            class="st3"
            points="346.7,209.1 347.3,209.7 346.8,210.2 346.2,209.7 			"
          />
          <polygon
            class="st3"
            points="363,156.3 362.3,155.5 360.8,156.9 361.5,157.7 360.4,158.7 361.3,159.6 360.1,160.7 360.6,161.3 
				361.8,160.2 362.2,159.9 365.3,157 363.9,155.4 			"
          />
          <polygon
            class="st3"
            points="401.1,92.3 400.7,93.1 402,93.6 402.3,92.9 403.2,93.3 403.6,92.4 404.6,92.8 404.8,92.3 
				403.8,91.9 403.6,91.8 400.9,90.6 400.3,92 			"
          />
          <polygon
            class="st3"
            points="370.1,162.7 369.3,161.6 368.6,162.1 367.2,160.1 366.7,159.6 364.8,161 363,162.4 363.5,163 
				365.3,161.6 367,163.8 368.4,162.7 369,163.6 			"
          />
          <polygon
            class="st3"
            points="399.6,98.5 400.5,96.4 401.4,96.8 400.5,98.9 			"
          />
          <polygon
            class="st3"
            points="363.9,165.4 363.5,164.8 363.8,164.5 364.3,165.1 			"
          />
          <polygon
            class="st3"
            points="353.7,158.8 352.3,156.9 351.7,157.4 353.1,159.2 			"
          />
          <polygon
            class="st3"
            points="403.5,94.3 405.3,96.7 403.8,97.8 401.9,95.4 			"
          />
          <polygon
            class="st3"
            points="416.6,75.2 416.1,74.7 414.3,76.5 414.9,77 416.1,75.7 417.8,77.4 418.4,76.8 			"
          />
          <polygon
            class="st3"
            points="384.9,98.8 384.4,97.8 389.8,95.3 390.3,96.3 			"
          />
          <polygon
            class="st3"
            points="418.9,78.2 418.6,78.5 418.9,78.8 418.3,79.4 419,80 419.6,79.4 421.6,81.3 422.1,80.7 419.8,78.5 
				420.3,77.9 419.5,77.1 418.7,78 			"
          />
          <polygon
            class="st3"
            points="346.6,220.2 347.1,220.8 346.5,221.3 346,220.7 			"
          />
          <polygon
            class="st3"
            points="361.8,65.5 361.8,64.7 360.1,64.7 360.1,65.6 358.9,65.6 358.9,66.7 357.6,66.7 357.6,67.3 
				358.9,67.3 359.3,67.3 362.8,67.2 362.8,65.5 			"
          />
          <polygon
            class="st3"
            points="384.6,177.4 385.3,177.9 386.4,176.6 385.7,176 386.5,175.1 385.7,174.4 386.5,173.4 386,173 
				385.2,174 385,174.3 382.7,177 384,178.1 			"
          />

          <rect
            x="368.4"
            y="167.6"
            transform="matrix(0.9952 -9.800714e-02 9.800714e-02 0.9952 -14.6951 37.2503)"
            class="st3"
            width="6.9"
            height="1.3"
          />
          <polygon
            class="st3"
            points="403.9,85.5 405.5,86.2 404.1,89.2 402.5,88.5 			"
          />
          <polygon
            class="st3"
            points="344.9,149.4 340.6,153 342.3,155 346.6,151.4 			"
          />
          <polygon
            class="st3"
            points="350.8,149.3 350.1,148.5 353.2,145.4 353.9,146.2 			"
          />
          <polygon
            class="st3"
            points="349.2,150.2 347.2,147.6 347.8,147.1 349.8,149.7 			"
          />
          <polygon
            class="st3"
            points="348.5,144.2 348.1,143.7 350.1,142.1 350.5,142.7 			"
          />
          <polygon
            class="st3"
            points="347.3,111.5 349.6,111.7 349.7,110.8 347.4,110.5 347.5,109.9 346.2,109.8 345.9,112.7 347.1,112.9 
							"
          />
          <polygon
            class="st3"
            points="355.4,112.4 356.2,113 358.4,109.9 357.6,109.3 			"
          />
          <polygon
            class="st3"
            points="347.7,145 347.3,144.4 347.9,144 348.3,144.6 			"
          />
          <polygon
            class="st3"
            points="350.6,145 350.3,144.6 351,144.1 351.3,144.4 			"
          />
          <polygon
            class="st3"
            points="349.1,126.5 351.4,124.7 352,125.4 349.7,127.2 			"
          />
          <polygon
            class="st3"
            points="349.1,129.7 351.4,127.8 352,128.5 349.7,130.4 			"
          />
          <polygon
            class="st3"
            points="349.1,132.9 351.4,131 352,131.7 349.7,133.6 			"
          />
          <polygon
            class="st3"
            points="349.1,136.1 351.4,134.2 352,134.9 349.7,136.8 			"
          />
          <polygon
            class="st3"
            points="420.2,94.6 419.7,93.5 418.6,94 417.4,91.7 415.1,92.8 416.3,95.2 408.7,99 409.7,101.2 418.6,96.8 
				419.6,98.9 422.7,97.3 421.2,94.1 			"
          />
          <polygon
            class="st3"
            points="347,117.8 347.5,118.4 348.6,117.6 348.1,117 348.9,116.4 348.3,115.6 349.1,114.9 348.8,114.5 
				347.9,115.2 347.7,115.3 345.4,117.2 346.4,118.4 			"
          />
          <polygon
            class="st3"
            points="346.7,142.2 345.3,140.4 346.1,139.8 347.5,141.7 			"
          />
          <polygon
            class="st3"
            points="350.5,112.1 351.9,111 354,113.6 352.5,114.7 			"
          />
          <polygon
            class="st3"
            points="391.3,99.8 391.1,99.2 387.4,100.5 387.8,101.7 390.6,100.7 390.9,101.6 394.3,100.4 393.7,98.9 			
				"
          />
          <polygon
            class="st3"
            points="403,110.3 404,109.3 405.1,110.3 404.1,111.3 			"
          />
          <polygon
            class="st3"
            points="380.2,99.6 380.4,101.4 379.8,101.5 379.9,103.2 384.3,102.7 384.1,101 382,101.2 381.8,99.5 			"
          />
          <polygon
            class="st3"
            points="346.9,134.4 346.9,127 347.6,127 347.6,123.5 346.8,123.5 346.8,121.7 345.2,121.7 345.3,134.4 			
				"
          />
          <polygon
            class="st3"
            points="396.8,42.9 398.6,45.3 397.5,46.1 395.7,43.8 			"
          />
          <polygon
            class="st3"
            points="399.3,40.9 401.2,43.2 400,44.1 398.2,41.8 			"
          />
          <polygon
            class="st3"
            points="401.9,38.9 403.7,41.2 402.6,42.1 400.7,39.8 			"
          />
          <polygon
            class="st3"
            points="404.4,36.9 406.2,39.2 405.1,40.1 403.3,37.8 			"
          />
          <polygon
            class="st3"
            points="391.5,57.4 391.9,57.4 393,57.6 393.4,54.7 392.2,54.6 392.7,51 390.3,50.7 389.4,57.1 			"
          />

          <rect
            x="390.1"
            y="61.5"
            transform="matrix(8.473085e-02 -0.9964 0.9964 8.473085e-02 294.7684 447.748)"
            class="st3"
            width="2"
            height="3.9"
          />
          <polygon
            class="st3"
            points="401.6,66.4 401.7,65.8 400.7,65.7 400.7,66.7 401.2,66.7 401.1,68.3 404.2,68.5 404.3,66.6 			"
          />
          <polygon
            class="st3"
            points="408.3,72.9 408.7,73.7 406.3,74.7 405.9,73.8 			"
          />
          <polygon
            class="st3"
            points="405,67.9 405.6,68.4 403.6,71.1 405.1,72.1 407.6,68.6 405.5,67.1 			"
          />

          <rect
            x="306.9"
            y="59.4"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 369.2802 -246.4617)"
            class="st3"
            width="1.9"
            height="4"
          />
          <polygon
            class="st3"
            points="329.8,143.3 331.2,142.3 332.4,144 331,145 			"
          />

          <rect
            x="331.8"
            y="138.5"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 663.8694 280.021)"
            class="st3"
            width="0.3"
            height="3.1"
          />
          <polygon
            class="st3"
            points="336.8,149.1 336.1,148.3 339.4,145.4 340.1,146.2 			"
          />
          <polygon
            class="st3"
            points="335.2,150.1 332.1,146 332.6,145.6 335.8,149.7 			"
          />

          <rect
            x="334.7"
            y="141.7"
            transform="matrix(0.9848 -0.1735 0.1735 0.9848 -19.5983 60.8097)"
            class="st3"
            width="6.7"
            height="1.6"
          />
          <polygon
            class="st3"
            points="302.3,87.8 302.2,92.8 304.3,92.8 304.4,87.8 305.7,87.9 305.7,85.1 299.4,85 299.4,87.8 			"
          />
          <polygon
            class="st3"
            points="333.8,144.9 333.4,144.4 333.9,143.9 334.4,144.5 			"
          />
          <polygon
            class="st3"
            points="336.8,145.1 336.6,144.8 337.3,144.2 337.5,144.6 			"
          />
          <polygon
            class="st3"
            points="302.7,81.8 305.3,80.3 305.7,81.1 303.2,82.6 			"
          />
          <polygon
            class="st3"
            points="300.3,91 301.2,90.4 301.7,91.3 300.8,91.9 			"
          />
          <polygon
            class="st3"
            points="316.9,131.3 319.2,129.4 319.8,130.1 317.5,132 			"
          />
          <polygon
            class="st3"
            points="311.1,90.8 311.5,90.8 311.5,90.4 312.4,90.4 312.4,89.5 311.5,89.5 311.5,86.7 310.7,86.7 
				310.7,90 309.9,90 309.9,91.2 311.1,91.1 			"
          />
          <polygon
            class="st3"
            points="309.8,85.5 310.2,86.2 311.4,85.6 311.1,84.9 312,84.5 311.5,83.6 312.5,83.1 312.2,82.6 
				311.3,83.1 311,83.2 308.4,84.5 309.1,85.9 			"
          />
          <polygon
            class="st3"
            points="334.2,129.1 336.1,131.5 335.3,132.1 333.5,129.7 			"
          />
          <polygon
            class="st3"
            points="287.2,73.4 286.4,73.6 287.3,76 288,75.8 287.4,74.1 289.7,73.3 289.4,72.6 			"
          />
          <polygon
            class="st3"
            points="322.7,143.2 321.7,143 322.6,140 323.5,140.2 			"
          />

          <rect
            x="307.6"
            y="89.3"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 398.302 -218.7265)"
            class="st3"
            width="1.8"
            height="0.9"
          />

          <rect
            x="312"
            y="80.6"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 393.5181 -231.3307)"
            class="st3"
            width="0.9"
            height="0.9"
          />
          <polygon
            class="st3"
            points="333.1,125.5 332.8,125.8 333.1,126 332.5,126.7 333.1,127.3 333.7,126.7 335.7,128.6 336.3,128 
				333.9,125.8 334.5,125.2 333.6,124.4 332.8,125.2 			"
          />
          <polygon
            class="st3"
            points="319.8,136.6 319.6,136.3 318.8,136.9 319.3,137.5 320,137 320.4,137.5 319.6,138 320.5,139.2 
				321.6,138.4 320.8,137.2 320.3,136.4 			"
          />
          <polygon
            class="st3"
            points="320.8,140.4 320.4,139.9 319.6,140.5 319.9,141 320.6,140.6 321.3,141.6 321.7,141.4 320.9,140.3 
							"
          />
          <polygon
            class="st3"
            points="339.1,113.1 338.3,113.7 336.4,111.2 337.2,110.6 			"
          />
          <polygon
            class="st3"
            points="336,121.1 335.6,121.1 335.5,122 336.3,122.1 336.4,121.2 337,121.2 337,122.1 338.4,122.2 
				338.5,120.7 337,120.7 336,120.6 			"
          />
          <polygon
            class="st3"
            points="339.5,122.7 338.9,122.6 338.9,123.6 339.5,123.7 339.5,122.9 340.8,122.9 340.8,122.5 339.6,122.5 
							"
          />
          <polygon
            class="st3"
            points="338.2,126.5 337.8,125.9 338.4,125.4 338.9,126 			"
          />
          <polygon
            class="st3"
            points="318.5,134.1 319.2,133.7 319.6,134.4 319,134.8 			"
          />
          <polygon
            class="st3"
            points="324.5,111.2 323.7,110.9 323.2,112.6 324.1,112.8 323.7,114 324.7,114.3 324.4,115.6 325,115.7 
				325.4,114.4 325.5,114.1 326.5,110.7 324.8,110.2 			"
          />

          <rect
            x="307.6"
            y="80.2"
            transform="matrix(1 -4.039011e-03 4.039011e-03 1 -0.3282 1.2468)"
            class="st3"
            width="1.8"
            height="3.3"
          />
          <polygon
            class="st3"
            points="319.6,119 320.4,118.4 320.9,119.2 320.1,119.8 			"
          />
          <polygon
            class="st3"
            points="318.4,122.2 322.3,119.4 324.1,121.9 320.2,124.6 			"
          />
          <polygon
            class="st3"
            points="328.2,123.3 326.9,124 323.9,118.8 325.2,118.1 			"
          />
          <polygon
            class="st3"
            points="327.7,125.6 326,129.1 325.2,128.8 326.8,125.2 			"
          />
          <polygon
            class="st3"
            points="315.9,124.8 315.1,125.1 313.7,121.8 314.6,121.5 			"
          />
          <polygon
            class="st3"
            points="317,122.9 316.1,123.2 314.8,119.9 315.7,119.6 			"
          />
          <polygon
            class="st3"
            points="317.9,120.7 317,121.1 315.7,117.8 316.5,117.4 			"
          />
          <polygon
            class="st3"
            points="325,126.8 324.1,127.2 323.7,126.2 324.6,125.8 			"
          />

          <rect
            x="317.5"
            y="127.3"
            transform="matrix(0.9746 -0.2241 0.2241 0.9746 -20.4648 74.7419)"
            class="st3"
            width="3.1"
            height="0.4"
          />
          <polygon
            class="st3"
            points="320.6,105.7 320.2,105.2 322.2,103.6 322.6,104.2 			"
          />
          <polygon
            class="st3"
            points="320.1,107.6 319.7,107 320.3,106.6 320.7,107.2 			"
          />
          <polygon
            class="st3"
            points="323,107.6 322.7,107.2 323.4,106.7 323.7,107 			"
          />

          <rect
            x="328.8"
            y="128.6"
            transform="matrix(0.1256 -0.9921 0.9921 0.1256 158.8407 440.8091)"
            class="st3"
            width="1.4"
            height="3.4"
          />
          <polygon
            class="st3"
            points="307.2,69 306.6,69.2 307.9,72.9 309,72.5 308.1,69.7 309,69.4 307.9,66 306.4,66.5 			"
          />
          <polygon
            class="st3"
            points="324.7,134.5 327,134 326.8,133.1 324.5,133.5 324.4,132.9 323.1,133.2 323.7,136.1 325,135.8 			"
          />

          <rect
            x="330.6"
            y="132.3"
            transform="matrix(5.908209e-02 -0.9983 0.9983 5.908209e-02 177.973 456.4187)"
            class="st3"
            width="0.9"
            height="2.9"
          />
          <polygon
            class="st3"
            points="340.6,137.7 340.5,137.1 339.9,137.2 339.8,136 338.4,136.1 338.6,137.3 334.3,137.7 334.4,138.9 
				339.4,138.4 339.5,139.4 341.3,139.3 341.1,137.6 			"
          />
          <polygon
            class="st3"
            points="327.2,137.9 327.7,138.5 328.8,137.6 328.3,137 329.1,136.4 328.5,135.6 329.3,135 329,134.5 
				328.1,135.2 327.9,135.4 325.6,137.2 326.6,138.4 			"
          />
          <polygon
            class="st3"
            points="326.5,140.4 327.7,139.1 330.2,141.3 329,142.6 			"
          />
          <polygon
            class="st3"
            points="336.1,134.6 335.7,134.2 334.6,135.4 335.4,136.2 336.3,135.3 336.9,135.9 338,134.8 336.9,133.8 
							"
          />
          <polygon
            class="st3"
            points="308.9,65.3 309.9,64.3 310.9,65.3 309.9,66.3 			"
          />
          <polygon
            class="st3"
            points="337.7,131.6 339.5,131.6 339.5,132.2 341.2,132.3 341.3,127.8 339.5,127.8 339.5,130 337.7,130 			
				"
          />

          <rect
            x="338.7"
            y="101.7"
            transform="matrix(0.1425 -0.9898 0.9898 0.1425 191.4603 426.2474)"
            class="st3"
            width="6.1"
            height="1.9"
          />
          <polygon
            class="st3"
            points="337.7,104.3 339.7,101.4 340.4,102 338.4,104.8 			"
          />
          <polygon
            class="st3"
            points="342.8,85.1 342.1,85.1 342.1,85.8 340.7,85.7 340.7,87.1 342.1,87.2 341.9,91.7 343.3,91.8 
				343.4,86.5 344.7,86.5 344.7,84.6 342.8,84.5 			"
          />
          <polygon
            class="st3"
            points="340.3,109.4 339.4,109.3 339.2,111 340.1,111.1 339.9,112.3 341,112.5 340.8,113.8 341.4,113.9 
				341.6,112.6 341.7,112.2 342.1,108.7 340.4,108.4 			"
          />

          <rect
            x="342.3"
            y="94.6"
            transform="matrix(0.1914 -0.9815 0.9815 0.1914 183.7923 413.741)"
            class="st3"
            width="1.4"
            height="1.4"
          />
          <polygon
            class="st3"
            points="336.3,114.5 336.3,116.2 335.6,116.2 335.5,118 340,118.1 340,116.4 337.8,116.3 337.9,114.5 			"
          />
          <polygon
            class="st3"
            points="312,61.1 311.6,70.3 310.5,70.2 310.3,74.6 311.4,74.6 311.3,76.8 313.6,76.9 314.3,61.2 			"
          />

          <rect
            x="292.6"
            y="92.5"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 197.6008 388.518)"
            class="st3"
            width="1"
            height="5.9"
          />
          <polygon
            class="st3"
            points="287.7,94.9 286.9,94.6 287.9,91.4 286.2,90.9 284.9,95 287.4,95.8 			"
          />
          <polygon
            class="st3"
            points="334.9,106.2 332.5,105.1 332.9,104.2 335.3,105.4 			"
          />

          <rect
            x="497.7"
            y="41.5"
            transform="matrix(0.9918 -0.1277 0.1277 0.9918 -1.5154 63.9918)"
            class="st3"
            width="1"
            height="4.7"
          />
          <polygon
            class="st3"
            points="494.9,34 496.3,33.4 496.9,35.2 495.5,35.7 496.3,37.6 499.7,36.3 499,34.4 497.9,34.8 497.3,33.1 
				498.4,32.7 497.8,31.1 494.3,32.4 			"
          />
          <polygon
            class="st3"
            points="493.1,36.1 493.6,37.5 488.4,39.4 487.9,38.1 			"
          />
          <polygon
            class="st3"
            points="484.3,37.4 485.2,38.7 481.1,41.4 480.3,40.1 			"
          />
          <polygon
            class="st3"
            points="460.6,9.7 460.9,10.6 460,11 459.7,10.1 			"
          />
          <polygon
            class="st3"
            points="479.4,28.4 479.8,29.6 478.7,30 478.3,28.9 			"
          />
          <polygon
            class="st3"
            points="325.8,71.1 326.7,71.6 326.2,72.4 325.3,72 			"
          />
          <polygon
            class="st3"
            points="335.1,100.9 335.9,101.4 335.5,102.2 334.6,101.8 			"
          />
          <polygon
            class="st3"
            points="479.9,7.1 478.7,11.2 481,11.9 482.5,6.6 480.2,5.9 469.6,2.9 469.3,4.1 			"
          />
          <polygon
            class="st3"
            points="498,20.5 498.4,22.1 491.8,23.8 491.4,22.2 			"
          />
          <rect
            x="488.1"
            y="22.2"
            transform="matrix(0.9924 -0.123 0.123 0.9924 0.3887 60.3318)"
            class="st3"
            width="1.4"
            height="9.6"
          />
          <polygon
            class="st3"
            points="480.7,25.8 481.3,27.4 479.7,28 479.1,26.4 			"
          />
          <rect
            x="494.4"
            y="25"
            transform="matrix(0.9856 -0.1691 0.1691 0.9856 2.815 84.4079)"
            class="st3"
            width="5.3"
            height="1.3"
          />
          <polygon
            class="st3"
            points="493.5,26.7 494.7,29.8 493.3,30.4 492.1,27.2 			"
          />
          <polygon
            class="st3"
            points="470.8,107.4 469.3,108.4 468.7,107.5 470.2,106.5 			"
          />
          <polygon
            class="st3"
            points="483.6,107.5 480.8,109.2 480.2,108.2 483,106.6 			"
          />
          <polygon
            class="st3"
            points="480.9,101.2 480.2,101.7 479.4,100.5 480.1,100 			"
          />
          <polygon
            class="st3"
            points="472.8,103.8 471.4,103.4 472.1,101 473.5,101.4 			"
          />
          <polygon
            class="st3"
            points="473.4,105.3 472.3,106.1 471.8,105.2 472.8,104.5 			"
          />
          <polygon
            class="st3"
            points="469.5,106.3 468.5,107 467.1,105 468.2,104.3 			"
          />
          <polygon
            class="st3"
            points="490.2,97.6 488.9,98.5 484.7,92.2 486,91.3 			"
          />
          <polygon
            class="st3"
            points="476.4,104.8 481.7,101.2 482.5,102.3 477.1,105.9 			"
          />
          <polygon
            class="st3"
            points="450.1,113 445.2,108.9 446.5,107.4 451.4,111.4 			"
          />
          <polygon
            class="st3"
            points="465.7,105.6 464.3,107.3 461.4,104.9 462.8,103.2 			"
          />
          <polygon
            class="st3"
            points="469.6,109.8 468.8,110.8 466.2,108.5 467.1,107.5 			"
          />

          <rect
            x="365.8"
            y="60.9"
            transform="matrix(6.855693e-02 -0.9976 0.9976 6.855693e-02 280.2079 423.6608)"
            class="st3"
            width="2.3"
            height="1.7"
          />

          <rect
            x="365.9"
            y="66.6"
            transform="matrix(1 -3.224395e-03 3.224395e-03 1 -0.215 1.1957)"
            class="st3"
            width="9.7"
            height="1.4"
          />

          <rect
            x="384.8"
            y="64"
            transform="matrix(1 -3.261915e-03 3.261915e-03 1 -0.2137 1.2588)"
            class="st3"
            width="2.1"
            height="4.2"
          />

          <rect
            x="383.3"
            y="61.5"
            transform="matrix(1 -3.237824e-03 3.237824e-03 1 -0.1986 1.2468)"
            class="st3"
            width="3.4"
            height="0.9"
          />
          <polygon
            class="st3"
            points="485.8,106.4 484.8,107 484.3,106.3 485.3,105.6 			"
          />
          <polygon
            class="st3"
            points="430.9,110.9 435.3,106.1 440.1,110.5 435.8,115.3 			"
          />
          <polygon
            class="st3"
            points="475.8,106.9 470.8,109.8 470.2,108.8 475.2,105.9 			"
          />
          <polygon
            class="st3"
            points="475.3,97.8 472.6,99.6 471.1,97.4 473.8,95.6 			"
          />
          <polygon
            class="st3"
            points="478.7,100.2 476.5,101.8 475.5,100.4 477.8,98.9 			"
          />
          <polygon
            class="st3"
            points="469.9,101.9 467.8,103.4 467,102.2 469.1,100.8 			"
          />
          <polygon
            class="st3"
            points="53.9,175.6 52.9,176 52.2,174.1 53.2,173.8 			"
          />
          <polygon
            class="st3"
            points="56.7,175.2 55.8,175.5 55.5,174.5 56.4,174.2 			"
          />
          <polygon
            class="st3"
            points="58.7,173.4 59.7,173.1 60,174 59.1,174.4 			"
          />
          <polygon
            class="st3"
            points="61.2,171.7 62.4,171.2 62.9,172.5 61.6,172.9 			"
          />
          <polygon
            class="st3"
            points="60.6,165.7 60,165.9 59.5,164.6 60.2,164.4 			"
          />
          <polygon
            class="st3"
            points="52.6,167.1 53.9,166.6 54.1,167.3 52.8,167.8 			"
          />
        </g>
        <g class="st2">
          <defs>
            <rect id="SVGID_3_" x="0.2" y="0.2" width="499.6" height="309.6" />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlink:href="#SVGID_3_" style="overflow: visible" />
          </clipPath>
          <g class="st4">
            <path
              class="st5"
              d="M126,90.8c4.8-0.3,9.6-0.7,14.4-1c1.3-0.1,2.8-0.1,3.7,0.7c0.8,0.7,1.1,1.9,1.4,2.9
					c1.8,5.5,7.3,9.2,8.6,14.8c0.3,1.1,0.3,2.3,0,3.4c-0.3,0.9-0.8,1.8-1,2.7c-1.2,4,1.8,7.9,4.2,11.3c0.6,0.9,1.2,2,0.7,2.9
					c-0.5,0.8-1.6,1-2.5,1.1c-6.7,0.4-14-0.8-18.7-5.6c-2.6-2.7-2.2-4.9-3.6-8.4c-1.9-4.8-5.6-11-7.5-15.8
					C124.7,97.1,120.6,91.2,126,90.8z"
            />
            <path
              class="st5"
              d="M-15.8,79.8c1.3-0.3,2.7-0.4,3.9-1c1.2-0.7,2-2,3-3c4.7-4.9,14.5-1.8,15.7,4.9c0.5,3.1-0.2,7.1,2.6,8.7
					c2.4,1.4,5.9-0.6,8.2,1.1c1.7,1.3,1.6,3.8,1.6,5.9c0,9.1,5.6,18,13.9,21.8c1.4,0.7,3,1.2,4,2.5c1.6,1.9,1.2,4.7,1.8,7.1
					c1.1,4.5,8.4,6,8.5,10.6c0.6,20.5-13.7,29.1-11.5,34.4c3.8,9,4.5,10.8,0,19.9c-1,2,0.2,8.4-2,8.7c-2.4,0.2-12.2,12.4-14.6,12.2
					c-11.3-1.2-16.4,6.8-27.3,3.7c-6-1.7-9.4-8.1-11.7-13.9c-9.9-25.3-12-53-11.7-80.2C-31.4,111.4-30.9,83.1-15.8,79.8z"
            />
            <path
              class="st5"
              d="M20.7,217.9c5.6-2.3,11.9-2.5,17.3-5.3c3.7-1.9,6.8-4.9,10.6-6.7c3.8-1.8,8.8-2.2,11.7,0.9
					c1.9,2,2.3,4.8,2.7,7.5c0.9,6.3,1.9,12.6,2.8,18.9c0.4,2.4,0.9,5.1,2.9,6.6c1.3,0.9,3,1.1,4.6,1.5c8.5,1.8,15.4,8,21,14.6
					c5.7,6.6,10.7,13.9,17.7,19.1c4.4,3.2,9.5,5.6,13.2,9.5c6.3,6.6,7.7,16.3,8.8,25.3c0.6,5,1.2,10.1,1.9,15.1
					c0.4,3.3,0.8,6.7,0.1,9.9c-1.9,9.2-11.6,14.5-20.7,16.6c-14.1,3.2-29,1.7-43.1-1.5c-9.2-2.1-18.3-4.9-27.1-8.3
					c-7.9-3.1-15.8-6.8-24.3-7.6c-6.6-0.6-13.4,0.6-19.8-0.9c-11.3-2.6-19.4-13-22.6-24.2c-3.2-11.1-2.2-23-0.9-34.5
					c1.5-14.2,4.5-28.6,11.8-41c3.1-5.2,5.9-5.1,11.6-6.3C8.4,225.5,13.8,220.8,20.7,217.9z"
            />
            <path
              class="st5"
              d="M104.4,238.4c-0.9,1.1-2.7,2,0,2.9c2.7,0.9,10.2-1.5,11.7,0c1.5,1.5,5.3,17.2,10.2,22.2
					c4.9,5-0.4-1.2,0-4.5c0.4-3.3-4.6-10.4-4.6-12.9c0-2.5-1.1-7.7-3.4-7.6C116.2,238.5,104.4,238.4,104.4,238.4z"
            />
            <path
              class="st5"
              d="M354.8,0.8c-3.2,7.1-7.1,9.3-13.2,13.1c-5.4,3.4-9,8.9-14,13.2c-3.1,2.6-7.2,4.5-9.6,7.7
					c-1.9,2.5-2.6,6.1-4.7,8.7c-4.6,5.7-12.2,10.5-19.4,6.3c-1.8-1-3.2-2.9-3.1-5c0-1.5,0.8-2.9,1.4-4.3c1.2-2.8,1.9-5.9,2.5-8.9
					c1.9-9-1.5-26.9-1.5-36.1c-0.1-3,5.3,3.5,6.1,0.6c1.7-6,7.1-10.5,13-12.7c7.8-2.9,21.2-5,29.5-2.4
					C350.1-16.4,358.5-7.6,354.8,0.8z"
            />
            <path
              class="st5"
              d="M235.8,64.2c4.4-1.3,9.7-1.8,12.9,1.4c3.6,3.6,2.5,9.4,2.9,14.5c0.6,7.9,5.6,15.1,5.3,23
					c0,0.8-0.1,1.6-0.6,2.3c-0.4,0.6-1,0.9-1.6,1.3c-2.5,1.4-4.9,2.8-7.4,4.2c-5.1,2.9-11.5,5.8-16.6,2.9
					c-4.8-2.7-11.6-4.9-11.6-11.1c0.1-9-9.9-20.7-4.6-27.5C219.3,69.1,228.8,66.2,235.8,64.2z"
            />
            <path
              class="st5"
              d="M266.7,220.1c-2.7,3.3-4.3,7.5-7.6,10.3c-1.4,1.2-3,2.1-4.3,3.4c-5.6,5.6-2,15.6,3.9,20.8
					c5.9,5.2,13.7,8.3,18.8,14.4c6.2,7.4,7,17.9,6.4,27.6c-0.6,9.7-2.3,19.5-0.1,28.9c0.6,2.4,1.5,4.9,3.6,6.2
					c3.1,2,7.2,0.4,10.8,0.7c4.1,0.4,7.9,3.3,11.9,2.6c6.3-1.1,7.7-9.4,7.7-15.9c0-14.7-0.1-29.5-0.1-44.2c0-3.5,0-7-1.1-10.3
					c-1.1-3.1-3-5.9-5-8.5c-8.3-11.3-16.7-22.7-25-34C281.8,215.4,272.5,213,266.7,220.1z"
            />
            <path
              class="st5"
              d="M379.3,251.8c1.3-2.8,2.7-6.1,1.2-8.8c-1-2-3.2-3-5.2-3.9c-6.7-2.8-13.6-5.5-18.9-10.5
					c-5.2-5-8.4-13.1-5.3-19.7c2.2-4.8,7-7.7,11.4-10.6c11.7-7.8,22.1-17.6,30.5-28.9c2.1-2.8,4.1-5.7,6.8-8
					c4.6-3.9,10.8-5.4,16-8.6c3.7-2.2,6.8-5.2,10.1-7.9c9.2-7.5,20.2-12.6,31-17.6c13.1-6.1,26.1-12.1,39.2-18.2
					c4.6-2.1,10.3-4.1,14.3-1c2.5,2,3.3,5.4,3.8,8.6c5.3,30.2,4.6,61.1,3.9,91.8c-0.9,38.1-1.8,76.2-2.7,114.3
					c-0.1,3.5-0.3,7.3-2.6,10c-1.8,2.1-4.5,3-7.1,3.8c-16.6,5.4-33.3,10.9-50.6,13.5c-28.2,4.3-57.3,1-84.2-8.5
					c-7.7-2.7-16.1-6.5-18.9-14.9c-2.4-7.2,1.4-11,4.6-17C366.3,291.8,370.6,270.4,379.3,251.8z"
            />
            <path
              class="st5"
              d="M377.9,186.4c-3.6-2.2-7.3-4.4-11.4-5c-1.5-0.2-3-0.2-4.5-0.6c-3.9-1.1-6.3-5-9.3-7.9
					c-4.1-4-9.7-6.1-15-8.2c-0.1-1.8-1.6-3.1-3-4.2c-3.8-3-7.7-6.1-11.5-9.1c-6.1-4.9-12-9.8-16.7-16.2c-2.4-3.3-3.7-5.1-7.6-2.5
					c-2.2,1.5-3.8,5.7-6.4,6.4c-1.5,0.4-3.2-0.5-4.5,0.3c-0.3,0.2-0.5,0.5-0.9,0.5c-0.6,0.1-1-0.5-1.3-0.9c-1.3-2-2.4-4.4-4-6.3
					c-0.9-1-9-8.2-9.5-9.9c-2-6.4,0.1-11.7-0.5-18.4c-0.1-1.2,9.4-12,9.9-13.1c1.1-2.4-1.6,13.3,1.1,13.1c0.8,0,1.6-0.1,2.3,0.2
					c1.2,0.4,1.9,1.5,2.6,2.6c4.4,6.1,11,10.6,18.2,12.5c3.6,0.9,8.5,10.4,10.4,13.9c1.5,2.6,1.8,5.8,3.5,8.3c0.3,0.5,0.7,1,1.2,1.3
					c0.9,0.5,2,0.4,3,0.6c6.9,1.1,7.1,10.8,13.3,12.9c4.3,1.4,11.8-0.7,13.8,5.1c0.3,0.8,0.3,1.8,0.9,2.4c0.7,0.8,1.9,0.9,3,1
					c5.7,1,9.1,7.7,14.9,8.5c2.1,0.3,4.5-0.2,6.4,1c1,0.7,1.6,1.7,2.4,2.6c2.5,2.6,6.8,3.6,7.9,7c1,3.1-1.6,7.1-4.1,8.4
					C382.4,189.7,380.2,187.8,377.9,186.4z"
            />
            <path
              class="st5"
              d="M420.2,137.4c-8.9,5.2-17.3,11.2-25.1,18.1c-1.6,1.4-3.2,2.9-5.2,3.7c-2,0.8-4.4,0.8-6.1-0.5
					c-1.9-1.5-2.1-4.2-2.3-6.7c-0.4-7.2-0.9-14.4-1.3-21.7c-0.1-0.9-0.1-1.9,0.2-2.8c0.3-0.9,1.1-1.7,1.8-2.3
					c6.7-6.3,14.8-11.1,23.5-14c1.8-0.6,4.6,0.9,6,2.2c1.4,1.2,2.5,2.8,3.5,4.4c3,4.7,6,9.3,9.1,14c0.3,0.4,0.6,0.9,0.5,1.4
					c-0.1,0.5-0.4,0.9-0.8,1.2C422.1,136.3,422.7,136,420.2,137.4z"
            />
            <path
              class="st5"
              d="M200.2,161.2c1.8,3.1,3.7,6.3,3.8,9.8c0,1.4-0.2,2.8,0,4.3c0.5,4,5.1,6.8,8.9,5.5c0-1.7-2.2-2.8-2.5-4.5
					c-0.3-1.9,1.7-3.4,1.9-5.3c0.3-1.8-1.1-3.5-2.2-5c-1.1-1.5-1.7-3.9-0.3-5.1c0.8-0.6,1.8-0.7,2.8-0.7c2.3,0,4.6,0,6.9,0.2
					c1-2.1-0.8-4.5-2.7-6c-6.9-5.5-16.7-10-25.6-8.4C193.9,151.2,197.2,156.2,200.2,161.2z"
            />
            <path
              class="st6"
              d="M297.8-12.1c-3.8,4.2-2.2,10.8-1.1,16.4c2.6,13.1,1.1,27.1-4.4,39.3c-1.1,2.5-2.4,5-2.3,7.8
					c0.1,3.2,2.2,6.3,1.3,9.4c-0.7,2.5-3.1,4.1-4.7,6.2c-4.2,5.5-2.3,13.3-3.6,20.1c-1,5.6-5,9.9-6.3,15.1c-1.4,5.2,0.9,11.6,1.8,17
					c0.3,1.5,0.6,3.2,1.8,4.2c1.3,1,3.1,0.9,4.7,0.8c5.3-0.4,10.7-0.8,16-1.3c1.9-0.1,3.9-0.3,5.6,0.4c4,1.6,5.3,6.4,7.1,10.3
					c2.4,5.2,6.3,9.4,10.2,13.6c4.7,5,13.6,10.6,19.4,14.3c4.6,2.9,5.5,4.4,10.3,6.9c3.1,1.6,6,3.5,9,5.3c6,3.7,13.8,4.5,19.8,8.2
					c2.2,1.3,3.6,6.9,4.3,9.4c1.3,4.7-4.8,9.8-0.9,12.8c0.8,0.7,1.9,0.9,3,1.3c6.6,2.3,11.9,8.4,15.3,14.5c1.2,2.2,1.2,5.2,0.6,7.7
					c-0.5,2.1-2,5.6-3.5,7.1c-9.7,10.1-11.3,23.5-11.2,37.5c0,6.6,0,13.9-4.6,18.6c-1.8,1.9-4.2,3.1-5.9,5.1
					c-2.1,2.6-2.8,6.1-3.3,9.4c-1.1,6.6-2.1,13.1-3.2,19.7c-0.6,3.6-1.1,7.4,0.7,10.5c-2.3-0.9-5.2-1.9-7.7-2.2
					c-2.5-0.3-5.1-0.2-6.4-2.3c1.6-13.4,3.5-27.4,11.4-38.3c5-6.9,12.3-12.5,14.5-20.7c1.9-7-0.4-14.7,2.1-21.4
					c3.9-10.4,18.2-16.6,16.8-27.6c-1-7.7-9.2-11.9-16.4-14.7c-2-0.8-4.1-1.8-4.8-3.8c-0.8-2.7,1.5-5.2,2.6-7.8
					c2.2-5.5-2-11.7-7.1-14.7c-5.1-3-11.1-4.3-16.1-7.4c-5-3.2-24.6-15.9-32.4-20.6c-2.1-1.3-8.3-4.9-9.9-6.8
					c-5.4-6.3-6.3-11-11.7-17.3c-3.7-4.4-10.4-9-14.4-4.8c-3.2,3.4-18.7,8.6-18.5-2.7c0.1-5.3-2.5-10.4-2.3-15.7
					c0.2-3.7,1.7-7.2,2.2-10.9c1.6-11.2-6.1-23.6-0.3-33.3c1.4-2.3,3.5-4.4,3.5-7c0-6.1-6.6-12.1-2.6-18.5c1.2-1.9,3.1-3.1,4.6-4.7
					c1.6-1.5,2.9-3.7,2.3-5.8c-0.4-1.6-1.8-2.7-2.6-4.1c-2.6-4.3-0.2-9.7,1.9-14.3c3.1-6.7,5.4-13.8,6.9-21.1
					C292.1-12.2,295.2-12.7,297.8-12.1z"
            />
            <path
              class="st6"
              d="M455.4,206.4c1.4-1.8,2.5-3.7,4-5.3c1.6-1.6,3.9-2.6,6-2c1.2,0.4,2.2,1.2,3.1,2.1c2.3,2.1,4.8,4.5,5,7.6
					c0.2,3.8-3.1,6.9-6.5,8.7c-3.3,1.8-7.2,3.3-9.4,6.4c-3.6,5.2-1.1,12.5-2.7,18.6c-0.3,1.3-0.9,2.5-1.9,3.4
					c-0.9,0.8-2.1,1.2-3.2,1.4c-4.1,0.8-17.1,0.4-19.4-4.1c-1.5-3,2.8-11.7,2.6-16.1C432.4,211.3,447.2,216.8,455.4,206.4z"
            />
            <path
              class="st6"
              d="M409.8,165.2c-2.3,2.4-3.8,5.6-4,8.9c0,0.3,0,0.6,0.1,0.8c0.4,0.9,1.9,0.7,2.5-0.2c0.6-0.8,0.7-1.9,1.2-2.8
					c1.3-2.3,4.5-2.4,6.6-3.8c2.3-1.6,3.1-5.1,1.7-7.6c-0.3-0.5-0.7-1-1.3-1.3c-1.6-0.8-3.5,0.5-4.6,2s-1.8,3.3-3.3,4.2"
            />
            <path
              class="st6"
              d="M69,288.4c1.1-0.3,2.2-1,2.6-2.1c0.6-1.5-0.2-3.4-1.5-4.4c-1.3-1-3-1.4-4.6-1.7c-3.5-0.7-7.3-1.6-9.4-4.5
					c-4.7-6.5-6.6-5.7-13.7-3.3c-5.1,1.7-5,1.1-9.8-0.3c0.1,4.4,4.7,9.4,9,10.1c5.5,1,8.9-2,14.6,1C60.2,285.5,63.8,290,69,288.4z"
            />
            <path
              class="st6"
              d="M25.9,259.8c1.3-0.5,2.3-1.5,3.4-2.4c3.3-2.5,7.7-3.3,11.9-3.2c7.7,0.1,15.3,2.5,21.6,7
					c0.6,0.4,1.3,0.8,1.9,0.5c1.1-0.6,0.2-2.2-0.7-3c-2.9-2.5-6.1-5.1-9.9-5.3c-3.4-0.2-6.7,1.5-10.1,1.4
					c-5.6-0.1-10.6-2.9-16.3-1.2C23.3,255,20.1,262.2,25.9,259.8z"
            />
            <path
              class="st6"
              d="M27,287.6c-1.7-0.7-3.3-1.4-4.8-2.6c-2.1-1.6-3.5-3.9-5-6.1c-6.7-9.8-15.6-19.3-27.3-21.1
					c-9.8-1.5-20.7,2.5-29.2-2.7c-1-1.9,1.5-4,3.7-4c2.2,0,4.2,1.2,6.3,1.4c2.9,0.3,5.6-1.1,8.5-1.6c5-0.8,21.8,3.1,22.8-4.7
					c0.2-1.4-0.3-3.1,0.7-4.2c0.3-0.4,0.8-0.6,1.3-0.8c3.7-1,7.3,2.7,8.1,6.5c0.8,3.8-0.1,7.8,0.1,11.6c0.4,6.5,5.5,15.6,10.1,19.9
					c2.4,2.3,4.9,4.6,7.3,6.8c3,2.8,12.8,1.1,17.8,2.7c1.6,0.5,13.3,4.5,7.4,6.7C48.7,298,32.7,290,27,287.6z"
            />
          </g>
        </g>
        <g class="st2">
          <defs>
            <rect id="SVGID_5_" x="0.2" y="0.2" width="499.6" height="309.6" />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlink:href="#SVGID_5_" style="overflow: visible" />
          </clipPath>
          <g class="st7">
            <path
              class="st8"
              d="M208.5,34.8c-0.1-0.2-0.3-0.3-0.5-0.3l-18.4,5.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.2,0.3,0.4,0.3
					c0,0,0.1,0,0.1,0l11.2-3.1l2.3,6.9c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.3,0.2-0.5l-2.3-6.8l6.5-1.8
					C208.5,35.2,208.6,35,208.5,34.8z"
            />
            <path
              class="st8"
              d="M319.5,59.3c0.2,0,0.4-0.2,0.4-0.4v-7.1H335c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-15.6
					c-0.2,0-0.4,0.2-0.4,0.4v7.5C319.1,59.1,319.3,59.3,319.5,59.3z"
            />
            <path
              class="st8"
              d="M495.8,44c-0.2-4.1-4-13.2-5.2-15.3c-0.2-0.4-0.4-1.6-0.6-3.2c0.1,0,0.3,0,0.4,0c7,0,16.2-5.3,16.6-5.5
					c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.2-0.5-0.1c-0.1,0.1-9.9,5.7-16.7,5.4c-0.2-1.9-0.4-4.3-0.6-6.5
					c-0.4-4.9-0.9-9.6-1.5-10.7c-0.9-1.6-9.9-3.8-17.4-5.4c-1.4-0.3-2.5-1.4-2.8-2.8L464.4-17c0-0.2-0.2-0.3-0.4-0.3
					c-0.2,0-0.3,0.2-0.3,0.4l3.3,16.4c0.3,1.7,1.7,3.1,3.4,3.4c11.8,2.5,16.4,4.2,16.8,5c0.5,1,1,6,1.4,10.4
					c0.4,4.9,0.9,9.6,1.5,10.7c0.9,1.6,3.1,6.7,4.3,10.9c-1.1-0.2-3.4-0.4-4.9,0.4c-0.6,0.4-1.1,0.1-1.8-0.4
					c-0.6-0.5-1.4-1.1-2.4-0.4c-1.3,0.9-4.4,3.2-5.4,4c-9.7-14.3-21.5-31.4-25.2-35.1c0.6-0.2,1.1-0.2,1.5-0.2
					c6.9,0.5,17.1,3.1,22.9,5.7c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0-0.4-0.2-0.5c-5.8-2.7-16.2-5.3-23.1-5.8c-0.5,0-1.2,0.1-2.2,0.4
					c-3.2-2.8-10-13.9-14.2-21l6.9-3.3c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2l-6.9,3.3c-1.9-3.2-3.2-5.5-3.2-5.6
					c-0.1-0.2-0.3-0.2-0.5-0.1c-0.2,0.1-0.2,0.3-0.1,0.5c0.2,0.3,1.4,2.5,3.2,5.5l-37.5,18c-3.6-0.4-9.9-15.6-12.1-21.4
					c-0.1-0.2-0.3-0.3-0.5-0.2c-0.2,0.1-0.3,0.3-0.2,0.5c0.8,2.1,7.2,19.1,11.9,21.6l-45.9,22c-1.1-0.1-9.3-1.2-11.4-1.2
					c-0.7,0-1-0.4-1.4-1.2c-0.6-1.1-1.4-2.6-4.3-3.7c-4.8-1.9-13.4-3.1-13.7-3.2c-0.2,0-0.4,0.1-0.4,0.3l-4,26.1
					c0,0.2,0.1,0.4,0.3,0.4c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3l3.9-25.7c1.7,0.3,9,1.4,13.2,3.1c2.6,1,3.3,2.3,3.9,3.4
					c0.4,0.8,0.9,1.6,2.1,1.6c2.2,0,11.3,1.2,11.4,1.2c0.1,0,0.1,0,0.2,0l46.8-22.5c4.5,0.3,27.9,9.6,31.3,12.8
					c-4.1,2.2-8,4.5-11.3,6.3c-14.7,8.5-29.9,17.9-31.2,19.4c-2.6,2.9-3.1,15.7-3.2,16.3v9.4l-22.6-1l0.9-9.6c0-0.2-0.1-0.4-0.3-0.4
					c-0.2,0-0.4,0.1-0.4,0.3l-0.9,9.6l-14.7-0.6l-6.7-0.7l0.3-8.2c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4l-0.3,8.6
					c0,0.2,0.1,0.4,0.3,0.4l7.1,0.8l7.1,0.3l-1.4,14.2h-7.6l-6.8-0.1c0.2-2.5,0.5-5.7,0.8-8.1c0-0.1,0-0.2-0.1-0.3
					c-0.1-0.1-0.2-0.1-0.3-0.1h-6.7l0.7-15.8c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4l-1.3,30l-0.8,3.8l-6.2-0.3
					c0.7-0.9,2-2.6,2.3-3c0.3-0.4,0.3-5.2,0.1-14.4c0-0.2-0.2-0.4-0.4-0.4h-11.7c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h11.3
					c0.1,5,0.2,12.7,0,13.6c-0.4,0.5-2.1,2.7-2.6,3.3l-9.7-0.4h-7.5V79.4l8.2,0.5c0.2,0,0.4-0.1,0.4-0.4c0-0.2-0.1-0.4-0.4-0.4
					l-8.2-0.5v-4.5c0-0.1,0-0.1,0-0.1l-2.5-5.8c0.6-1.5,0.1-7.6,0-9.6c0-0.2-0.2-0.4-0.4-0.3c-0.2,0-0.4,0.2-0.3,0.4
					c0.4,4,0.5,8.7,0.1,9.2c-0.4,0.4-4.2,1.9-7.2,3c-7.7,3-8.4,3.3-8.2,4c0.2,0.7-0.4,6.3-1.7,8.3c-1.2,1.9-3.3,10.2-3.8,12.2
					c0,0,0,0-0.1,0c-4.8-2.1-9.2-1-10.5-0.5c-0.7-2.5-4.8-16.3-5.1-17.9c-0.2-0.7,0.2-1.6,0.6-2.6c0.5-1.1,1-2.2,0.8-3.4
					c-0.4-2-2.4-13.8-2.4-14c0,0,0,0,0-0.1c-0.6-1.9-2.1-7-1.9-9.1c3.6,1,5.4,1.4,6.1,0.9c0.2-0.1,0.3-0.3,0.3-0.5l0-0.2
					c0-6.6,0.1-13.8,0.5-20.2c1.5-0.1,4.6-0.3,5.4,0c0.5,0.2,0.7,2.6,0.5,5.2c0,0.2,0.1,0.4,0.4,0.4c0,0,0,0,0,0
					c0.2,0,0.4-0.2,0.4-0.4c0.1-1.7,0.3-5.6-1-6c-1-0.3-4.4-0.1-5.6-0.1c0.7-10.4,2-18.9,4.3-20c5.1-2.7,10.1-9.2,9.8-17.8
					c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4c0.3,8.3-4.5,14.6-9.4,17.1c-5,2.6-5.3,32.5-5.3,41.7l0,0.2
					c-0.5,0.2-3.1-0.5-5.5-1.1c0.3-3.4-1.2-19.9-2.2-22.4c-0.2-0.5-0.7-1.8-11.1-1.4c-4.1,0.1-8.1,0.5-9.5,0.6l6-28.4c0,0,0,0,0,0
					l2.6-12.4c0-0.2-0.1-0.4-0.3-0.5c-0.2,0-0.4,0.1-0.5,0.3l-2.6,12.2l-29,6.4L213.5-17c0-0.2-0.3-0.3-0.5-0.3
					c-0.2,0-0.3,0.3-0.3,0.5l4.5,18.6l-21.7,4.8h0l0,0l-17,3.7c-0.2,0-0.3,0.2-0.3,0.5c0,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0
					l16.6-3.7l2.4,10.6l-15.2,2.8c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0.1,0l15.6-2.8c0.1,0,0.2-0.1,0.2-0.2
					c0.1-0.1,0.1-0.2,0.1-0.3l-2.5-11l9.1-2l10.9,38.6c-15,4.1-40.5,10.8-46.7,11c-0.2-2.6-1.4-18.2-1.8-21.4
					c-0.3-2.3,10.2-5.4,17-6.8c0.2,0,0.3-0.2,0.3-0.5c0-0.2-0.2-0.3-0.5-0.3c-3,0.6-18.1,4-17.6,7.7c0.4,3.2,1.6,18.5,1.8,21.3
					c-1.8-0.1-4.7-0.8-8.3-1.8V40.5c0,0,0-0.1,0-0.1l-4.1-15.6c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3,0l-14.4,3.9
					c-0.9-4.8-1.7-9.2-2-11.3c-1-6,0-33.7,0-34c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4c0,1.1-1,28.1,0,34.2
					c0.9,5.5,4.8,26.5,5.5,30.4c-4.8-1.6-9.6-3.3-13.8-4.8c-5.6-2-10.1-3.6-12.1-4.1l12.4-55.5c0-0.2-0.1-0.4-0.3-0.5
					c-0.2,0-0.4,0.1-0.5,0.3l-12.4,55.5c-1.8-0.5-4.9-1.6-8.4-2.8c-13.1-4.5-24.2-8-26.3-7c-1.5,0.7-2.2,3.9-2.5,7.6l-19.9-7.7
					l-37-32.7l11-11.5c0.1-0.2,0.1-0.4,0-0.5c-0.2-0.1-0.4-0.1-0.5,0l-11,11.6L12-14c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.2-0.1,0.4,0,0.5
					l10.6,9.4L3.4,15.5c-0.1,0.2-0.1,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1L22.7-3.5L42,13.5L26,34.5
					c0,0,0,0.1-0.1,0.1l-2.8,9.5l-11.3-2.8c0,0,0,0,0,0s0,0,0,0l-33.4-6.8c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4l27.6,5.6
					l-2,9.8l-25.5-4.6c-0.2,0-0.4,0.1-0.4,0.3s0.1,0.4,0.3,0.4l25.5,4.6v11.7c-0.3,0.8-0.8,2.1-1.1,2.5c-1.3,0.3-15.2-0.7-24.3-1.4
					c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4c0.1,0,6.1,0.5,12.1,0.9c6.4,0.4,9.6,0.6,11.2,0.6c1.4,0,1.5-0.1,1.6-0.2
					c0.5-0.5,1-1.9,1.3-2.6l2,0.6l-0.7,3.4C5.6,67.8,5.8,68,6,68l7.5,1.6v10.8c0,0.7,0.3,1.4,0.9,1.8l1.3,0.9c0.1,0,0.1,0.1,0.2,0.1
					c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.1-0.4-0.1-0.5l-1.3-0.9c-0.4-0.3-0.6-0.7-0.6-1.2V69.8l1.8,0.4c0,0,0.1,0,0.1,0
					c0.2,0,0.3-0.1,0.4-0.3l1.3-4.5l0,0v0l1.1-4l8,1.9c-2,9-4.2,18.4-4.4,19.4c-0.5,2,0,19.8,0,20.6c0,0.2,0.2,0.4,0.3,0.4l8.3,0.8
					c-0.3,2.2-1.7,12.9-1.7,14.3c0,1.6,5.5,15.1,6.1,16.6c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.3,0.2-0.5
					c-2.4-6-6-15.3-6-16.3c0-1.3,1.4-12.1,1.7-14.2l13.3,1.3c0.7,0.1,7.5,0.6,15.6,1.2c-0.3,4.7-0.5,9-0.6,9.7c0,0.1,0,0.2,0,0.3
					c0.1,0.9,0.7,4.7,1.2,6.5l-5.8,2.3l-2.7-7.4c0.8-0.2,2.2-0.9,3-1.3c0.1-0.1,0.2-0.2,0.2-0.3l0.1-7.1c0-0.2-0.2-0.4-0.4-0.4
					c-0.2,0-0.4,0.2-0.4,0.4l-0.1,6.8c-1,0.5-2.2,1-2.7,1.2l-0.8-2.2c-0.1-0.2-0.2-0.3-0.4-0.3h-6.8c-0.2,0-0.4,0.2-0.4,0.4
					c0,0.2,0.2,0.4,0.4,0.4h6.5l2.4,6.8l-1.7,0.9c-0.5,0.3-0.7,0.8-0.5,1.4l2.8,7.2c-1,0.4-2.7,1.5-3.1,1.7
					c-0.2,0.1-0.2,0.3-0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1c1.1-0.7,2.8-1.7,3.2-1.7c0.9-0.1,5.4,0.1,5.9,0.6
					c0,0.4,0.1,0.7,0.1,0.9c0.3,0.7,0.7,2.4,1.1,4c0.4,1.7,0.8,3.3,1.1,4.1l-7.3,3.3c-0.2,0.1-0.3,0.3-0.2,0.5
					c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0l7.4-3.4c0.5,0.5,1.7,0.6,3,0.7c1.1,0.1,2.6,0.2,2.7,0.7c0,0,0,0,0,0c0,0,0,0,0,0
					c0.3,0.8,4.1,11.6,8.1,22.8c-1,0.3-3.7,1.4-11.6,4.7l-12.1,5.2c-0.3-0.9-1.3-3.2-4.1-9.4l6.8-3l1.8,5.1c0.1,0.2,0.2,0.3,0.4,0.3
					c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.3,0.2-0.5l-1.8-5.2l5.1-2.2c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2l-5,2.2l-3-8.8
					l4.9-2c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2l-5.2,2.2c-0.2,0.1-0.3,0.3-0.2,0.5l3.2,9.2l-7.3,3.2
					c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3c1.5,3.3,3.8,8.4,4.3,9.7l-6.8,2.9c-2-5-4.9-12.8-5.1-13.8c0.3-0.6,2.8-3.9,4.4-6.1
					c1.5-2,2.3-3.1,2.4-3.3c0.3-0.5,1.5-11.3,2-16.2c1.5-0.5,2.8-1,3.7-1.3c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2
					c-1.3,0.4-12.3,4-13.9,6.3c-1.5,2.2-11.3,11.1-14.6,12.8c-3.3,1.7-31.2,2.9-31.5,2.9c-0.2,0-0.4,0.2-0.4,0.4
					c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0c1.2-0.1,28.4-1.3,31.8-3c3.5-1.8,13.4-10.8,14.9-13c0.8-1.2,5.2-3,8.9-4.4
					c-0.7,6.2-1.7,15-1.8,15.6c-0.1,0.2-1.2,1.7-2.3,3.2c-3.5,4.8-4.5,6.1-4.6,6.4c-0.1,0.4,1.7,5.3,5.3,14.6c0,0.1,0.1,0.2,0.2,0.2
					c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0l7.5-3.2h0l0,0l2.8-1.2c0.9,2.4,2,5.2,2.9,7.7c-1.7,0.7-9.1,3.6-10.4,4.1
					c-0.9,0.4-1.3,2-1.9,4.7c0,0.2-0.1,0.3-0.1,0.5c-2.2-0.2-14.1-1.2-15.6-1c-1.4,0.1-3.7-2.3-4-3.9c-0.4-1.6-1.2-12.5-1.3-12.6
					c0-0.2-0.2-0.4-0.4-0.3c-0.2,0-0.4,0.2-0.3,0.4c0,0.5,0.9,11.1,1.3,12.8c0.4,1.7,2.9,4.6,4.8,4.5c1.4-0.1,13,0.8,15.4,1
					c-0.3,1.2-0.7,3-1.3,3.3c-1.6,0.9-11.7,10.8-12.7,11.7c-1.2,0.5-2.2,0.9-2.9,1.1c-5,1.8-38.1,12.6-38.4,12.7
					c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0c0.3-0.1,33.5-10.9,38.5-12.7c0.7-0.3,1.8-0.7,3-1.1
					c0,0,0,0,0,0c1.9-0.7,4.3-1.7,6.8-2.7c6.6-2.6,15.6-6.2,16.6-6.2c0,0,0,0,0,0c0.4,0,2,0.3,4.2,0.7l0,0l0,0
					c6.3,1.1,17.3,3.1,17.4,3.1c0.1,0,0.3,0,0.4-0.1l8.6-11.1c2.1,2.6,14.1,17.2,15.9,19.2L91.6,239c-3.9-0.1-8.7-0.2-8.8-0.2
					c-1.3-0.1-32.8-2.3-36.6-2.3c-3.9,0-19.1,6.7-20.9,7.6c-1.4,0.7-11-3.4-16.7-6.1c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5
					c2.5,1.2,15.3,7.1,17.4,6.1c2.5-1.2,17-7.6,20.6-7.6c3.8,0,36.2,2.3,36.5,2.3c0.1,0,4.7,0.1,8.6,0.2c-0.2,1.4-0.7,5.5-0.7,6.3
					c0,0.6,0.7,1.1,1.7,1.9c0.5,0.4,1.6,1.2,1.6,1.5c-0.1,0.8,0,3.7,0,4.8c-4.4-0.3-8.9-0.6-12.8-0.8c-0.2,0-0.4,0.1-0.4,0.4
					c0,0.2,0.1,0.4,0.4,0.4c3.8,0.3,17.3,1.2,27.1,1.7c0,0,0,0,0,0c0,0,0,0,0,0c3.9,0.2,7.2,0.3,9.1,0.3c0.9,0,1.5,0,1.7-0.1
					c1-0.5,5.1-2.9,6.3-3.7c1.7,3.1,4.2,11.8,4.7,14c0.2,0.8-1.3,1.6-2.7,2.3c-1.8,1-3.9,2.1-3.3,3.8c0.9,2.3,1.5,6.1,1.6,7.1
					l-28.6,21.4c-0.2,0.1-0.2,0.4-0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.2-0.1l28.5-21.2c0.8,1.3,3.5,5.6,4.1,6.4
					c0.6,0.7,3.1,10.1,3.9,13.2c0.2,0.7,0.3,1.2,0.4,1.4c0.3,1.1,1.2,10.9,1.7,15.9c0,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0,0
					c0.2,0,0.4-0.2,0.3-0.4c-0.1-0.6-1.2-12.9-1.6-15.7l9.1-1.6c0,0,0,0,0,0c0,0,0,0,0,0l7.4-1.3l-2.1,18.5c0,0.2,0.1,0.4,0.3,0.4
					c0,0,0,0,0,0c0.2,0,0.4-0.1,0.4-0.3l2.1-18.7c1.6-0.4,3.7-2.8,5.6-5c1.2-1.3,2.5-2.8,3-3c1.2-0.3,5.9-0.4,7.5-0.4l-9.6,26.9
					c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3l16.6-46.5l9.4-1.7c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.4-0.3
					l-9.2,1.7l-3-14l18.2-2.5c0.2,0,0.4-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.3l-10.2,1.4l-1.2-6.6c-0.2-1.1-0.9-2-2-2.5l-1.9-0.8
					c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5l1.9,0.8c0.8,0.3,1.4,1.1,1.5,1.9l1.2,6.6l-7.3,1l-3-14.3l15.3-3.7l8.5,9
					c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0l12.5-2.9c0.1,0,0.2-0.1,0.3-0.3c0.1-0.3,2-6.9,2.3-8.8c0.3-0.1,0.6-0.2,0.8-0.3
					c12.3-3.7,12.5-4.4,12.6-4.8c0.2-0.8,2.8-6.4,5.1-11.3c0.7,2.9,1.5,5.9,2,6.5c0,0,0,0,0,0.1c0,0,0,0,0,0
					c0.5,0.7,1.9,8.1,1.7,9.2c-0.1,0.4-2,1.6-3,2.2c-1.9,1.1-2.5,1.6-2.4,2.1c0.1,0.6,1.7,8.5,1.7,8.6l2.1,11.8
					c-1,0.3-2.2,1.2-3.4,2.1c-1.1,0.8-2.3,1.7-2.9,1.7c-0.4,0-1-1.6-1.4-2.6c-0.7-1.9-1.3-3.6-2.6-3.5c-0.9,0.1-3.3,0.3-5.5,0.4
					c-2.3,0.2-4.7,0.3-5.7,0.5c-1.3,0.1-2.2,1.7-3,3.1c-0.4,0.7-1,1.7-1.2,1.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					c-1.2,0-16.2,1.1-16.9,1.2c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0c0.1,0,4.5-0.3,8.9-0.7l-8.4,29.1l-9.5-3.1
					c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5l9.5,3.1l-6,21.1c-0.1,0.2,0.1,0.4,0.3,0.5c0,0,0.1,0,0.1,0
					c0.2,0,0.3-0.1,0.4-0.3l6.1-21.1h16.1l-0.5,6.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.4,0,9,0.2,11.1,0l2.4,5.5
					c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.3,0.2-0.5l-2.4-5.4c1.8-0.7,7-4,8.6-5c3.4,7.9,7.1,16.7,10.1,23.9
					c-1.4,1.6-2.4,2.6-2.6,2.9c-0.1,0.2-0.1,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,13.3-14.9,23.7-25.6
					l11.6,8.9c-0.5,2.5-0.5,15.8-0.5,18.5c0,0.2,0.2,0.4,0.4,0.4h0c0.2,0,0.4-0.2,0.4-0.4c0-6.7,0-17.7,0.5-18.5
					c0.3-0.5,1.8-3.4,3.5-6.6l6.8,2.8v5.1c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-5.1c1.2-0.8,3.1-2.1,3-3.3
					c-0.1-1,0-7.1,0-7.2c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0c-0.2,0-0.4,0.2-0.4,0.4c0,0.3-0.1,6.2,0,7.3c0,0.5-1,1.6-2.6,2.6
					l-6.8-2.8c2.1-3.9,4.2-8,4.3-8.1c0.1-0.1,0.1-0.2,0-0.3l-3.3-6.8c9.1-4,24.9-10.3,29.1-10.6c5.3-0.4,22.8-1.3,23-1.3h6.4
					c0.2,4.8,0.3,9.3,0.2,10.8c-0.1,1.5,1.7,2.8,3.7,4.2c2.5,1.8,5.4,3.9,5.3,6.8c0,1.1,0,2.9,0,5.2H320l0.2-9.4
					c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0c-0.2,0-0.4,0.2-0.4,0.4l-0.2,9.8c0,0,0,0,0,0s0,0,0,0l-0.5,23.6c0,0.2,0.2,0.4,0.4,0.4
					c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4l0.5-23.2h21.4c0,8.9,0.1,23,0.1,23.2c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0
					c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-23.8,0-29.1c0.1-3.3-3.1-5.6-5.6-7.4c-1.8-1.3-3.4-2.5-3.4-3.6c0-1.2-0.1-5.7-0.2-10.8h8.4
					c0,1.6,0,7.9,0,9.2c0,0.8,2.9,4.5,4.8,6.7c0,9.5,0.1,33.6-0.1,34.9c0,0.1,0,0.2,0,0.2c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0
					c0.3-0.1,0.3-0.1,0.4-5.9c0-2.9,0-7,0-12.1c0-7.4,0-14.8,0-16.9l12.5,5.7c-3.5,10.4-9.8,28.4-9.9,28.7c-0.1,0.2,0,0.4,0.2,0.5
					c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3c0.3-1,6.5-18.7,9.9-28.9c2.2-0.9,11.2-4.9,12-6.2c0.1-0.1,5.7-9.2,5.2-11.3
					c-0.1-0.3-0.3-0.4-0.4-0.5c-1.2-0.6-8.3-4.2-9.7-4.9c0-0.7-0.8-1.6-1.7-2.5c-0.7-0.8-1.8-2-1.6-2.4c0.6-1.1,3.9-7.6,4.7-9.1
					c1.8,0.6,3.2,1.1,3.7,1.3c0.7,0.3,1.5,0.4,2.4,0.4c3.1,0,6.8-1.4,7.6-2.5c0.9-1.3,2.9-7.9,3-8.2c0.1-0.2-0.1-0.4-0.3-0.5
					c-0.2-0.1-0.4,0.1-0.5,0.3c-0.6,1.9-2.2,7-2.9,8c-0.7,1-6.1,3-9,1.9c-0.5-0.2-1.9-0.7-3.7-1.3l3.2-12l2.2-12.1
					c0-0.1,0-0.2-0.1-0.3c0,0-3.1-4.5-6.3-9c-6.5-9.1-6.9-9.2-7.2-9.2c-0.7,0-9.2,0-10.7,0l-2.8-5.6c1.9-1.2,11.9-7.7,13.1-8.8
					c1.5-1.3,0.2-5,0.1-5.4c0,0-1.1-3.5-2.5-7.3c1.7-1.9,4.3-4.5,5.2-4.4c1.5,0.2,6,1,6,1c0.2,0,0.4-0.1,0.4-0.3
					c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-4.5-0.9-6.1-1c-1.4-0.2-4.4,3-5.6,4.3c-2.2-5.6-4-8.9-5.4-9.9c0,0,0,0,0,0c0,0,0,0,0,0l-13.7-7.1
					c-0.6-0.3-1.3-0.5-2-0.5c0,0,0,0,0-0.1c1.2-2.4,2.8-4.6,2.8-4.7c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.4-0.1-0.5,0.1
					c0,0-1.6,2.3-2.9,4.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.5,0.1-1,0.2-1.5,0.4c-1.9,0.9-5.5,2.3-8.2,1.2c-1.4-0.6-2.5-1.9-3.1-3.9
					c0,0,0,0,0,0c-0.3-0.7-1-2.7-0.3-3.5c0.3-0.3,0.7-0.6,1.1-1.1c1.5-1.3,3.5-3,3.2-4.5c-0.3-2.1-10.2-9.1-10.3-9.2
					c-0.2-0.1-0.4-0.3-0.7-0.6c-1.4-1.1-3.5-2.9-5-2.2c-0.7,0.3-4.2,1.9-8,3.6l-6.7-7.6c-0.1-0.2-0.4-0.2-0.5,0
					c-0.2,0.1-0.2,0.4,0,0.5l6.5,7.4c-4.3,2-8.7,4-9.1,4.2c-0.1,0-0.1,0.1-0.2,0.2l-8.9,17.5l-10.4-11.2c-0.2-0.2-5-5.4-6.4-6.8
					l7.8-5.8c0.2-0.1,0.2-0.4,0.1-0.5c-0.1-0.2-0.4-0.2-0.5-0.1l-7.8,5.8c-0.7-1.8-2.7-10-4-15.4c1.9-0.2,9.9-0.3,15.2-0.3
					c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h0c-1.4,0-13.1,0.1-15.4,0.3l-4.2-8.8l-4-12.9l8.8-2.3c0.1,0,0.3-0.2,0.3-0.3
					l1.6-11.8l7.1-3.3c0,0,5-2,10.3,0.4c2.2,1,4.6,1.3,6.8,1.3c-0.2,2.4-0.4,6,0.1,6.6c0.3,0.4,2.4,3.8,3.9,6.2
					c-1,0.8-2.4,1.9-4.1,3.5c-0.2,0.2-0.4,0.3-0.5,0.4c-0.5,0.3-4.5-0.4-7.9-1.3c-0.2-0.1-0.4,0.1-0.5,0.3s0.1,0.4,0.3,0.5
					c2.9,0.7,7.8,1.8,8.6,1.1c0.1-0.1,0.3-0.2,0.5-0.4c5.1-4.5,6.5-5.2,6.8-5.2c0.3,0.2,1.2,1.2,2,2.1c2.7,2.9,4.6,5,5.7,5
					c0.2,0,0.4-0.1,0.5-0.2c0.8-0.9,2.9-3.4,3.6-4.3l4.9,4.4c0.1,0,0.1,0.1,0.2,0.1l12.1,1.8l6.9,14.3l-7.8-1
					c-0.2-1-0.8-3.4-1.9-3.8c-0.6-0.2-1.8,0.1-3.6,0.6c-1.1,0.3-3,0.9-3.3,0.7c-0.5-0.5-3-4-4.6-6.3c-0.1-0.2-0.4-0.2-0.5-0.1
					c-0.2,0.1-0.2,0.4-0.1,0.5c0.4,0.6,3.9,5.8,4.7,6.5c0.5,0.5,1.8,0.1,4-0.5c1.2-0.4,2.8-0.8,3.2-0.7c0.6,0.2,1.1,1.7,1.4,2.9
					l-2.5-0.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0l-3.7,1.7c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2
					c0.1,0,0.1,0,0.2,0l3.2-1.5c0.3,1.9,1.3,7,1.9,7.8c0.3,0.4,1,1,1.9,1.8c1.6,1.4,3.7,3.2,4.5,4.5c1.1,2,3.7,5.9,5.8,7.9
					c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.4,0-0.5c-1.9-1.9-4.4-5.7-5.6-7.8c-0.8-1.5-3.1-3.4-4.7-4.7
					c-0.8-0.7-1.6-1.4-1.8-1.7c-0.4-0.6-1.3-4.7-1.8-7.5l10.9,1.4v3.9h-3.7c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h3.7v4.2
					c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0,0,0.1,0l9.9-1.8c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.4-0.3l-9.4,1.7
					v-8.3l9.2,1.2c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4l-9.5-1.2l-6.9-14.2l7,1.4l0.5,4.5c0,0.2,0.2,0.4,0.4,0.3
					c0.2,0,0.4-0.2,0.3-0.4l-0.5-4.3h8.6l8.9,2.1l0.7,16.3l-9.3,8c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.2-0.1l6.1-5.2l4.7,4.7c-0.7,0.7-5.9,6.1-6.7,7.3c-1.2,1.6,1.6,5.1,3.5,6.5c1,0.8,2.2,0.7,3.3,0.7
					c1.4-0.1,2.7-0.1,3.9,1.2c1.6,1.7,4.1,4.7,5.9,6.8c0.8,1,1.5,1.7,1.8,2.1c0.3,0.3,1,0.4,1.9,0.4c2.8,0,7.9-1.1,10-1.5l4.2,4.2
					c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.4,0-0.5l-4.1-4.1l6.3-7.4c0.1-0.2,0.1-0.4,0-0.5
					c-5.8-5.3-14.5-13.6-15.1-15.1c0-0.1,0.1-1.1,5.4-6c3.2-3,7.2-6.3,10-8.7c2-1.7,3.3-2.8,3.6-3.1c0.1-0.2,0.2-0.3,0.1-0.6
					c-0.4-1.7-11.1-6.3-14.7-7.9v-10.8c15.1-0.1,30.6-0.2,33.4-0.2c2,0,5.8,3.9,9.9,8.8c-0.9,0.6-3.3,2.3-4.9,3.5
					c-1.4,1-1.1,2.9-0.9,4.3c0.1,0.4,0.2,1.4,0.1,1.4c-0.6,0.2-13.9,7.7-14.6,10.1c-0.1,0.3,0,0.5,0.1,0.6c1,1.5,8,12.8,8,12.9
					c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.2-0.3,0.1-0.5c-0.3-0.5-7-11.3-8-12.9c0.1-1.5,12.6-8.9,14.2-9.6
					c0.7-0.3,0.6-1.2,0.4-2.3c-0.2-1.2-0.4-2.8,0.6-3.6c1.6-1.2,4.1-3,4.9-3.6c4.9,6,10.2,13.4,12.8,17.4c0.1,0.1,0.2,0.2,0.3,0.2
					c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.2-0.4,0.1-0.5c-0.4-0.6-6.6-9.9-12.8-17.4l6.1-3.9l12,17.4c0.1,0.2,0.4,0.2,0.5,0.1
					c0.2-0.1,0.2-0.4,0.1-0.5l-5.9-8.5l5.5-4.2l6.6,9.2c1.6,2.4,5.6,9,5.4,10.7c-0.2,2.1-5.9,17.1-6.5,18.8
					c-2.7,1.2-20.2,8.8-21.2,9.8c-1.1,1-10.9,12.3-11.3,12.8c-0.1,0.2-0.1,0.4,0,0.5c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1
					c0.1-0.1,10.2-11.8,11.2-12.7c0.7-0.7,12.7-6,20.8-9.6c4.8,17.4,11.7,43.5,11.3,45.4c-1,1.2-10.4,7.2-14.2,8.4
					c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0c3.8-1.2,13.6-7.3,14.6-8.7c0.3-0.3,0.7-0.9-5.3-23.9
					c-2.8-10.6-5.7-21.2-6-22.5c0.6-1.6,6.3-16.7,6.5-19c0.3-2.4-4.9-10.3-5.5-11.2l-6.8-9.5c-0.1-0.1-0.2-0.1-0.3-0.2
					c-0.1,0-0.2,0-0.3,0.1l-5.8,4.4l-6-8.6c0,0,0,0,0,0c0,0,0,0,0,0l-6.4-7.9l12.7-4.1l24.5,19.2c0.1,0.1,0.2,0.1,0.2,0.1
					c0.1,0,0.2-0.1,0.3-0.1c0.1-0.2,0.1-0.4-0.1-0.5l-24.4-19l3.7-3.9l18.4,13.7c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1
					l4.2-4.2c0,0,0-0.1,0.1-0.1c2.3-3.9,5-8.6,7.7-13c0,0,0,0,0,0c0,0,0,0,0,0c1.2-2,2.4-3.9,3.5-5.7c1,2.3,3.8,9.3,4.7,10.3
					c0.6,0.7,2.3,0.4,4.2,0.1c1.3-0.2,3.1-0.5,3.5-0.1c1,1.1,3.1,4.4,3.1,4.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1
					c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.1-2.1-3.4-3.2-4.5c-0.6-0.7-2.3-0.4-4.2-0.1c-1.3,0.2-3.1,0.5-3.5,0.1
					c-0.4-0.4-1.2-2.2-2.1-4.2l9.4-6.5c3.7,5.9,7.7,12.4,7.7,12.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1
					c0.2-0.1,0.2-0.3,0.1-0.5c-0.5-0.8-11.1-18-13.4-21.3c3.1-1.5,13.6-8,15.9-9.4c0.7,3,1.2,7.2,1.7,11.4l-7.4,3.7
					c-0.2,0.1-0.3,0.3-0.2,0.5l6.3,12.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.3,0.2-0.5l-6.2-12.6l6.8-3.4
					c0.7,7.3,1.1,14.2,1.1,14.4c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4c-0.1-0.9-1.1-18.9-3-27.2c0,0,0,0,0,0
					c-0.4-1.7-0.8-2.9-1.3-3.6c-0.4-0.6-1.4-2.2-2.9-4.3c-2.5-3.7-6.1-9.2-10.2-15.1c1-0.7,4.1-3.1,5.4-4c0.5-0.3,0.9-0.1,1.5,0.4
					c0.7,0.5,1.5,1.1,2.6,0.5c1.4-0.8,3.9-0.4,4.7-0.3c0.3,1.3,0.6,2.4,0.6,3.3c0.2,4.1,11,22.6,11.4,23.4c0.1,0.1,0.2,0.2,0.3,0.2
					c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.2-0.3,0.1-0.5C507,66.9,496,47.9,495.8,44z M260.5,100.4l-3.4-14.2c2.3-0.8,4.9-1.5,5.5-1.5
					c0,0,0,0,0,0c0.2,0.6,0.8,3.2,1.1,4.9c0.3,1.3,0.5,2.4,0.6,2.8c0.2,0.9,2.5,1.1,4.6,1.1c0.7,0,1.4,0,2.1-0.1
					c0.2,0.8,0.4,1.4,0.5,1.7L260.5,100.4z M259.9,68.6l1.1,8.1l-5.7,1.5l-1.9-8L259.9,68.6z M261.3,77.4l6.7,4.9
					c1,3.4,2.2,7.6,3,10.4c-2.9,0.1-5.5-0.1-5.8-0.5c-0.1-0.4-0.3-1.5-0.6-2.8c-0.6-2.9-1.1-4.8-1.2-5.2c-0.1-0.3-0.4-0.8-6.4,1.2
					l-1.5-6.5L261.3,77.4z M267.2,74.1c-0.4,1-0.9,2.1-0.7,3c0.1,0.5,0.5,2,1.1,3.9l-5.9-4.4l-1.1-8.4l6.6-1.6
					c0.3,2,0.6,3.7,0.8,4.4C268.1,72.1,267.7,73.2,267.2,74.1z M265.6,57.3c0.1,0.4,0.8,4.9,1.5,8.8l-13.9,3.4l-5.4-22.9
					c1.9-0.4,3.7-0.7,4.9-0.8c2.7-0.2,7.3,1,11,2.1C263.4,50.3,265.4,56.7,265.6,57.3z M261.6,25c0.9,2.3,2.5,18.6,2.2,21.9
					c-3.7-1-8.3-2.3-11.1-2.1c-1.3,0.1-3,0.4-5,0.8l-1.6-6.7c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-3.9-10.5-4.3-14.3
					C251.1,23.8,261.1,23.7,261.6,25z M232.4,49.7c-0.6-2.8-0.5-7.5-0.4-8.7l13.4-1.4l1.5,6.4C241.1,47.2,234.1,49.2,232.4,49.7z
					 M240.9,24.3C240.9,24.3,240.9,24.3,240.9,24.3C240.9,24.4,240.9,24.4,240.9,24.3c0.2,3.2,3.4,12.3,4.2,14.5L232,40.2
					c0.1-1.4,0.4-5.9,1.3-9.7c0.8-3.4-2.9-4.9-5.9-6c-1.4-0.6-2.8-1.1-3.4-1.7c-1.7-1.6-4.7-14.9-5.8-20.4L246.9-4L240.9,24.3z
					 M205.9,5l11.5-2.5c0.6,2.8,4,18.8,6.1,20.8c0.8,0.7,2.2,1.3,3.7,1.9c3,1.2,6.1,2.4,5.4,5.1c-1,3.9-1.3,8.7-1.3,9.9l-3.9,0.4
					c0,0,0,0-0.1,0c-0.1,0-4.3,1.2-10.4,2.9L205.9,5z M163.3,84.2c0.3,0.1,4.5,1.6,8.7,3.1c-2.8,1.2-10.7,4.8-11.4,5.7
					c-0.5,0.6-0.1,1.3,0.3,2c0.6,1.1,1.2,2.2-0.2,3.5c-0.8,0.7-1.5,2.2-2.2,4c-0.3-1.1-0.7-2.8-1.2-4.5c-1.7-5.9-2.3-5.9-2.7-5.9
					c-0.5-0.1-1.8-2.8-2.8-6c1.4-1.3,2.8-11.9,3.1-14.1l5.5-18.4c3.6,1,6.6,1.7,8.4,1.9L165,71.8L163,83.8
					C163,83.9,163.1,84.1,163.3,84.2z M180.7,90.3c0.2,0.1,0.7,0.2,1.2,0.3c0.6,0.2,1.4,0.4,2.2,0.6c-0.2,0.8-0.9,2.6-1.6,3.8
					c-0.5,0.8-2.6,1.1-4.1,1.3c-1.4,0.2-2.2,0.3-2.5,0.6c-0.1,0.1-0.4,1-0.7,2.3l-8.1-0.8c-0.2,0-0.4,0.1-0.4,0.3
					c0,0.2,0.1,0.4,0.3,0.4l7.9,0.8c-0.5,2.1-1.2,5.1-1.9,8.3c-3.5-0.3-7.2-1.1-7.4-1.9c-0.2-1.6-3.5-6.3-4.4-7.5
					c1.5-1.6,0.7-3.2,0.1-4.2c-0.3-0.6-0.5-1-0.3-1.2c0.6-0.7,7.5-3.9,11.9-5.8C176.7,88.9,180.2,90.2,180.7,90.3z M197.9,88
					c1,3.6,2.6,9.1,3.4,9.6c0.6,0.3,3,4.9,3.9,7c-1.7,0.4-3.4,0.9-5,1.3h0h0c-6.1,1.5-11.4,2.7-12.2,2.6c-1.1-1.1-10.2-7.6-12-8.9
					c0.3-1.1,0.5-1.9,0.6-2c0.2-0.2,1.2-0.3,2-0.4c1.9-0.2,4-0.5,4.7-1.6c0.8-1.3,1.4-3.3,1.7-4c0.8,0.2,1.7,0.3,2.4,0.3
					c0.5,0,1-0.1,1.3-0.2c0.6-0.2,1.4-0.6,2.3-1.1c2-1,4.4-2.2,5.8-2.2c0,0,0,0,0.1,0C197.1,88.2,197.5,88.1,197.9,88z M212.7,128.5
					c-1.5,0.5-6.5,2-7.5,2.7c-1.1,0.8,0.5,5.6,1.4,8l-7.7,2.5c-0.6-1.9-3.4-10.6-3.8-12.1c-0.2-1,1.5-3.6,2.5-5
					c0.5-0.7,0.8-1.2,0.9-1.4c0.3-0.8,3.7-7.2,4.3-8.3c1.7-0.4,3.3-0.9,4.9-1.2L212.7,128.5z M221.6,151.9c-2.8-1.1-12-4.6-13.1-4.9
					c-0.9-0.3-2.4,0.9-4.7,3c-1.1,1-2.4,2.1-3,2.2c-0.7,0.2-5.1-1.2-9.5-2.9c0,0,0,0,0,0c-0.7-0.3-1.3-0.5-2-0.8c0,0,0,0-0.1,0
					c-5.6-1.1-14.3-2.9-15.1-3.2c-0.8-0.4-8.6-9.6-13.5-15.5c1.4-1.3,2.7-2.5,4-3.7c1.3,1.6,7.3,8.7,8.1,9.4
					c0.3,0.3,1.4,0.4,3.7,0.4c1.8,0,4.4-0.1,7.9-0.3c0.8,0,1.5-0.1,1.7-0.1c0.8,0,1-1.4,1.5-4.5c0.3-2.1,0.6-4.5,1.2-5.4
					c1.2-1.8,3.8-7.2,4.2-8.3c2.9-0.7,6-1.5,8.9-2.3c-1,2-3.7,7-4,7.7c-0.1,0.2-0.5,0.7-0.8,1.3c-1.5,2.2-2.9,4.3-2.6,5.6
					c0.4,1.7,3.7,12.1,3.9,12.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0l16.8-5.5L221.6,151.9z M227.1,155.3
					c0.5,2.1,1.5,6.4,1.6,7.1c-0.3,0.5-2.8,1.5-3.8,1.9c-0.6,0.3-1.1,0.5-1.3,0.6c-0.7,0.4-3.1,4.3-4,5.6c-2.9,1.1-5.1,1.8-5.7,1.8
					c0,0,0,0,0,0c-1.1,0-11.2,0.2-12.6,1.6c-1.1,1.1-6.6,9-6.8,9.3c-0.1,0.2-0.1,0.4,0.1,0.5c0.1,0,0.1,0.1,0.2,0.1
					c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,5.7-8.1,6.8-9.2c0.9-0.9,8.9-1.4,12-1.4c0,0,0,0,0,0c0.7,0,3-0.7,5.8-1.8l3.6,5.8l-13.3,9.1
					l-10.4,2.7c-0.1,0-0.1,0-0.2,0.1l-6.3,5.9c-3.3-3.8-6.4-7.1-7-7.6c-0.8-0.7-4.4-1.7-8.9-2.8l0.7-7.7l6.8-4.9l7.1-5.3
					c0.2-0.1,0.2-0.3,0.1-0.5l-6.9-12.5l6.2-3.4c3,1.1,7.9,2.9,9.5,2.9c0.1,0,0.2,0,0.3,0c0.7-0.2,1.9-1.2,3.2-2.4
					c1.3-1.1,3.5-3,4-2.8c1.2,0.3,12.3,4.6,13.7,5.1l1.4,3.4c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0,0.1,0L227.1,155.3z M250.2,212h7.7
					l1.8,2l-11.1,10.7l0.2-5.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4l-0.2,6.1l-8.2,2.6c-0.2-0.3-0.5-0.7-1-1.4
					c-0.9-1.1-2.9-3.8-2.9-4.3c0.2-0.3,0.8-1.6,1.9-4c0.5-1,1-2,1.5-3.1l5.3,3.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.3-0.1
					L250.2,212z M266.8,265.4c0.5,1,0.7,2.5,0.9,3.7c0.2,1.4,0.3,2.6,0.9,3.2c0.3,0.3,1,1.8,1.7,3.6c-1.7,0.3-5-0.2-7.5-0.5
					c-1.7-0.2-2.9-0.4-3.4-0.4c-0.5,0.1-1.9-1.2-2.9-2.1c-1.6-1.5-2.7-2.3-3.4-2.1c-1.1,0.3-5.9,1-7.4,1.1l-0.7-3
					c1-0.2,3.1-0.4,5-0.6c3.5-0.4,5.6-0.6,6.1-0.8c0.9-0.4,1.8-4.2,2.3-6.7C260.7,261.5,265.8,263.6,266.8,265.4z M252.2,300
					l-6.4-27.2c1.8-0.1,6.3-0.8,7.5-1.1c0.4-0.1,1.8,1.2,2.7,2c1.7,1.5,2.7,2.3,3.5,2.3c0.4,0,1.8,0.1,3.2,0.3
					c2.1,0.3,4.5,0.6,6.3,0.6c0.6,0,1.1,0,1.6-0.1c0.8,1.8,1.6,4,2.2,5.7c-2.1,0.9-3.7,1.7-4.6,2.2
					C265.9,285.8,259.2,292.6,252.2,300z M218.2,277.7c1.5-0.5,6.4-2.2,7.4-2.6c0.6-0.2,1.1-1.1,2.1-2.7c1.2-1.9,2.7-4.5,4.3-4.8
					l11.4-2.2l4.5,19.3l-8.1,1.4c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0.1,0l8.1-1.4l3.4,14.6
					c-2.2-1.3-8.5-5-9.9-5.3c-1-0.2-3.3,0.2-5.6,0.6c-1.3,0.3-2.8,0.5-3.2,0.5c-0.8-0.2-6.4-1.6-7.5-1.9
					C222,286.2,219.7,281.1,218.2,277.7z M214.7,286h-9.4l-0.3-13.6c0.6,0,1.6-0.2,2.9-0.3c2.3-0.2,5.4-0.6,6.4-0.5
					c0.4,0.4,1.6,2.6,3.1,6c0,0,0,0,0,0c0,0,0,0,0,0.1c0.8,1.6,1.6,3.6,2.6,5.7L214.7,286z M255.8,266.9c-0.4,0.2-3.4,0.5-5.9,0.8
					c-2.1,0.2-4.1,0.4-5.1,0.6L244,265l4.4-8.9l7.1,0.8c0.1,1.5,0.2,3.1,2.1,3.8C257.1,263.5,256.2,266.6,255.8,266.9z M255.7,256.2
					l-7.2-0.8c-0.2-1.2-0.9-5.8-1.2-7.5c-0.3-1.3-1.7-1-2.9-0.9c-1.4,0.2-2.3,0.3-2.5-0.5c-0.5-2.2-1-6-1-6c-0.1-1.7-0.7-9.9-1-11.8
					l8.1-2.6l3.6,4.8c4.8,6.6,9.8,14.1,9.8,15.1C261.2,246.9,257.4,253.5,255.7,256.2z M244.6,218l-5.1-3.5c1.3-2.7,2.6-5.3,3-5.8
					c0.2-0.3,1.1-1.1,2.2-2.2l4.8,5.2L244.6,218z M242.1,208.1c-1,0.2-3.4,0.5-3.9,0.5c-0.5,0-0.9,0.5-1.5,1.3
					c-0.5,0.6-1.2,1.6-1.6,1.4c-0.8-0.5-2.6-1.1-3.5-1.4c1.2-1.4,5.7-5.4,9-8.1l3.6,4.1C243.2,207,242.4,207.8,242.1,208.1z
					 M231,209.6l-9.2-7.3c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5l9.3,7.3c-1.1,2.3-7,14.8-8.4,18.1l-4.5-6.4
					c1.4-3,1.2-3.5,0.8-3.8c-0.8-0.6-6.9-5.7-6.9-5.7c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.2-0.1,0.4,0,0.5c0.2,0.2,6,5,6.9,5.7
					c0.1,0.7-1.6,4.2-3.4,7.3l-9-4.3c-0.5-0.6-3.6-4.1-7.4-8.5l5.5-5.9l6.7-5.9c0,0,0.1-0.1,0.1-0.1c2.6-5.6,5.7-11.8,6.2-12.2
					c0.3-0.2,1.1-0.8,2.1-1.5l3,4.9l-3,3c-0.1,0.2-0.1,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l3.2-3.3
					c0.1-0.1,0.1-0.3,0.1-0.5l-3.2-5.2c2.5-1.8,5.9-4.2,7-5l6.5,10.5c0,0,0,0,0,0l7.2,8.4C238.5,202.6,232.4,207.8,231,209.6z
					 M190.9,225.6l-3.4-1.6c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5l3.3,1.6l-5,9.5l-3-13.4l4.7-10.4l4.9-4.3
					c1.7,1.9,3.4,3.9,5,5.6L190.9,225.6z M176.8,218.2l-1.1-5.6c0-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.3,0.2-0.3,0.4l1.1,5.5l-2.1,5.1
					l-7.4,1.2l-3.6-16.9l15.7,0.5l3.1,13.9l-6.9,1.1l2-4.7C176.8,218.3,176.8,218.3,176.8,218.2z M152.6,259.8
					c-0.3-0.9-0.9-3-1.1-4.1c-0.2-1-2.3-1.5-5.8-2.3c-1.5-0.3-3.6-0.7-3.9-1.1c-0.1-0.6-1.4-7.8-2.9-15.4l21.7-9.2
					c0.5,1.8,2.2,9,2.4,10.7c0.1,0.7-0.8,1.5-1.7,2.3c-1.2,1.1-2.6,2.3-2.4,3.8c0.2,2.4,2.4,10.8,2.9,12.8
					C157.7,258.3,154,259.2,152.6,259.8z M125.3,228.8h-9.2l-0.7-15.1c0.8,0,1.5-0.1,2.3-0.1c0,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0,0
					c0.2,0,0.3-0.1,0.4-0.3c4.8-0.3,8.9-0.6,9-0.6c0,0,0,0,0,0c1.2,0,5.6,4.5,6.8,6.2L125.3,228.8z M87.2,193.4
					c-0.2-0.7-1.6-4.4-3.4-9.5c5.7,0.2,15.6,0.7,16.2,0.7c0.2,0.1,1.1,0.6,1.9,1.2c2.6,1.7,6.6,4.3,8.9,4.3c0.1,0,0.1,0,0.2,0
					c3-0.2,35.1-3.1,35.7-3.2c1.3,0,9.5,0.8,17.2,2l-0.8,7.7l-12.1,4.3l-23.7-3.7c0,0,0,0,0,0c0,0,0,0,0,0L103,198
					c-0.2,0-0.4,0.2-0.4,0.4l0.3,14C100.4,209.4,91.9,199.2,87.2,193.4z M69.8,144.5c-0.2-0.8,4.3-4.6,6.8-6.6
					c3.2-2.7,4.5-3.9,4.9-4.6c0.9,0,3.4,0,6.5-0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1c6.9-0.1,16.1-0.2,16.7-0.3
					c0.4,0,0.8-0.1,2.6-6.4c0.6-2.3,1.2-4.6,1.5-5.6c2.5,1.3,4.8,3.2,6.7,5.6c-0.1,1.5,1.9,5.7,5,12.2c1.4,2.8,2.7,5.7,3.7,8
					c0.6,1.4,2,4.3,3.8,8c-2.4,0.1-12,0.6-21.5,1.1c-22.4,1.3-22.6,1.9-22.8,2.3c-0.5,1-4.8,7.2-6.1,9
					C73.9,155.9,70.1,145.3,69.8,144.5z M88.5,124.7l8.7,0.3l-1.1,7.3c-2.5,0-5.2,0.1-7.6,0.1V124.7z M98,125.1l8.9,1.6
					c-0.8,2.8-1.5,5-1.9,5.4c-0.6,0-4.1,0.1-8.1,0.1L98,125.1z M107,126l-9-1.6l1-6.5c3.2,0.5,6.4,1.3,9.4,2.8
					C108,122.4,107.5,124.3,107,126z M97.3,124.2l-8.8-0.3v-6.4c3-0.2,6.4-0.2,9.8,0.2L97.3,124.2z M87.8,123.9l-7,0.6
					c-0.1-2.1-0.3-4.2-0.4-6c1.9-0.4,4.5-0.7,7.4-1V123.9z M87.8,124.7v7.7c-3,0-5.5,0.1-6.3,0.1c-0.2-0.9-0.4-3.9-0.7-7.2
					L87.8,124.7z M118.7,84.8c-0.1,0-12.8,0.9-15.2,0.8c-1,0-1.6-0.6-2.3-1.2c-0.9-0.8-1.9-1.7-3.8-1.9c-1.1-0.1-4.6-0.2-8.8-0.3
					c-0.1-0.9-0.3-3.6-0.6-6.3h14c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H87.9c-0.2-2.3-0.5-4.4-0.6-5.3
					c-0.2-1.4,3.9-4.6,6.6-6.3c0.1-0.1,0.1-0.1,0.2-0.2l4.6-17.4l16.2,4.5l-4.1,18.5c0,0.2,0.1,0.4,0.3,0.5c0,0,0.1,0,0.1,0
					c0.2,0,0.3-0.1,0.4-0.3l4.1-18.5l10.2,2.8v9.7c0,0.2,0.1,0.4,0.3,0.4l13.9,1.5l-1.5,6.3c-0.1,0.2,0.1,0.4,0.3,0.5
					c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3l5.6-22.9c3,1,6,2,8.9,2.9c-1.7,4.4-6,18.7-6.2,19.3c-0.1,0.2,0.1,0.4,0.3,0.5
					c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3c0-0.2,4.5-15,6.2-19.3c1.8,0.6,3.5,1.1,5.1,1.5l-5.6,18.5c0,0,0,0,0,0.1
					c-0.4,2.8-0.8,5.6-1.3,8l-10.5-0.2l0.6-2.2c0.1-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4,0.1-0.5,0.3l-0.6,2.3l-9.4-3.2
					c-0.1,0-0.2,0-0.3,0l-6.7,3.4c-0.6-1-1.3-2.1-2.2-3.2c-0.1-0.2-0.4-0.2-0.5-0.1c-0.2,0.1-0.2,0.4-0.1,0.5
					c2.8,3.7,4.2,7.3,4.5,8.2c-1.8,2-9,9.1-10.5,10.5l-8.4-9.5c4.5-0.2,10.6-0.7,11-0.7c0.2,0,0.4-0.2,0.4-0.4
					C119.1,85,118.9,84.8,118.7,84.8z M43.7,66.6l-15.9-3.8c1.7-7.3,3.2-14.1,3.6-15.9L47.9,51L43.7,66.6z M48.6,51.2l6.2,1.5
					l6.1,1.4l-1.5,9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1l6,0.7l-0.7,5.2l-20.6-2.7L48.6,51.2z M77.7,65.6c0,0-0.1,0-0.1,0
					l-11.7,3.7l1.1-7.9l10.8,2.1L77.7,65.6z M77.8,62.7l-10.7-2l0.7-5.1l10.3,0.8L77.8,62.7z M65.9,63.5l-5.7-0.7l1.4-8.6l5.4,1.2
					L65.9,63.5z M65.3,70.3C65.3,70.3,65.4,70.3,65.3,70.3C65.4,70.3,65.4,70.3,65.3,70.3c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0
					c0,0,0,0,0,0l12.2-3.9l-0.4,9.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0,0,1.6-0.1,2.8,1.1c1,1,1.5,2.7,1.5,4.9
					c-5.7-0.1-11.1-0.2-12.5-0.3V78c0-0.2-0.2-0.4-0.4-0.4l-8.9-0.6l0.7-7.4L65.3,70.3z M69.4,82.5c3,0.1,23.4,0.5,27.5,0.7
					l-0.1,4.2l-27.6-1.8L69.4,82.5z M151.1,85.6l-0.3-0.1c-4-0.7-7.3-1-9.8-1.1l1-4l4.7,0.1l-0.3,1.9c0,0.2,0.1,0.4,0.3,0.4
					c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3l0.3-2l5.1,0.1C152,83.3,151.5,85.3,151.1,85.6z M127.3,84.7c-0.3-0.8-0.9-2.3-2-4.2
					l6.5-3.3l9.3,3.1l-1,4.1c-1.4,0-2.6,0.2-3.4,0.5c-1.6,0.6-3.5,0.1-5.1-0.3C130,84.3,128.4,84,127.3,84.7z M127.5,85.6
					c0.8-0.9,2.3-0.5,4-0.1c1.8,0.4,3.7,0.9,5.5,0.2c1.5-0.5,5-0.8,13.6,0.6l0.2,0c0.9,2.5,2.4,6.5,3.5,6.6
					c0.7,0.5,2.3,5.8,3.5,10.8c-0.4,1-0.7,1.9-1,2.9l-7.4-1c-0.1,0-0.2,0-0.2,0.1l-2.3,1.4l-3.1-7.6c-0.1-0.2-0.3-0.3-0.5-0.2
					c-0.2,0.1-0.3,0.3-0.2,0.5l3.1,7.7l-5.6,3.4c-1.2-2.7-6.9-16-7-16.1c-0.1-0.2-0.3-0.3-0.4-0.2l-8.8,2.3
					c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.3l8.5-2.3c0.9,2,5.9,13.6,6.9,15.9l-6.6,4l-16.7-18.8
					C118.3,94.9,125.9,87.5,127.5,85.6z M160.8,99.4c1.3,1.8,4,5.8,4.2,7c0.3,1.8,5.7,2.4,7.9,2.5c-1,4.5-2,9.2-2.5,11.7
					c-0.3,0-0.6,0.1-0.9,0.3c-0.7,0.4-2.4,2-4.7,4l-3.5-4.7l3.2-2.5c0.2-0.1,0.2-0.4,0.1-0.5c-0.1-0.2-0.4-0.2-0.5-0.1l-3.2,2.5
					l-4.9-6.6C156.2,111.8,158.6,102.2,160.8,99.4z M175.7,100.3c4.6,3.3,11.2,8,11.7,8.6c0.1,0.1,0.2,0.2,0.7,0.2
					c1,0,3.8-0.5,11.9-2.5l1.1,3.9c-4.6,0.8-15.4,2.8-16.5,2.7c-1.2,0-9.7-0.3-11.8-0.4C173.9,108,175,103.2,175.7,100.3z
					 M174.2,124.8c1.4,1.7,2.7,3.2,3.9,3.3c1.8,0.1,2.1-2.7,2.3-4.9c0.1-0.9,0.2-2.3,0.5-2.5c0.4-0.1,5.1-1.4,11-2.9
					c-0.8,1.7-2.9,6-3.9,7.6c-0.7,1.1-1,3.4-1.3,5.7c-0.2,1.5-0.5,3.5-0.8,3.8c-0.3,0-0.8,0-1.6,0.1c-2.5,0.1-10.3,0.5-11,0
					c-0.6-0.6-5.4-6.2-8-9.4c2.2-2,4-3.6,4.6-4C171,120.9,172.7,122.9,174.2,124.8z M180.7,119.9c-0.7,0.2-0.8,1.4-1,3.2
					c-0.2,1.8-0.4,4.3-1.5,4.2c-0.9-0.1-2.2-1.6-3.4-3.1c-1.2-1.5-2.5-2.9-3.7-3.4c0.5-2.2,1-4.7,1.5-7.2c1.8,0.1,10.6,0.3,11.9,0.4
					c0,0,0,0,0,0c1.5,0,14.4-2.3,16.7-2.7l0.8,3.1C192.4,116.9,181.4,119.7,180.7,119.9z M154.5,229.5c-0.2-1.3-0.7-4.6-1-6
					l11.1-4.4l1.2,5.7L154.5,229.5z M153.2,222.7c-0.6-0.6-2.1-0.6-3.9-0.5c-0.9,0-3.1,0.1-3.1-0.2c-0.2-1.2-1.2-5.3-1.5-6.7
					l17.5-7.6l2.2,10.5L153.2,222.7z M134.9,218.8c-1.1-1.4-5.2-5.9-7.1-6.5V198l10.3,1.6c0,1.4-0.1,6.6,0,7.6c0,0.5,0.7,1.1,1.6,2
					c1.4,1.4,3.4,3.2,4.1,5.6L134.9,218.8z M118.4,212.8l-0.2-14.6l8.8-0.3v14.3C125.5,212.4,122.2,212.6,118.4,212.8z M111.4,213.1
					l-0.2-14.7l6.3-0.2l0.2,14.6C115.6,212.9,113.4,213.1,111.4,213.1z M103.7,213.1l-0.3-14.4l7-0.2l0.2,14.7
					C107.2,213.3,104.4,213.3,103.7,213.1z M140.2,208.7c-0.6-0.6-1.4-1.3-1.4-1.5c-0.1-0.9,0-5.9,0-7.4l12.2,1.9c0.1,0,0.1,0,0.2,0
					l11.9-4.2l-1,9.5l-17.6,7.7C143.7,212.1,141.6,210.1,140.2,208.7z M163.9,197.3c1.7,0.2,7.2,1.1,8,1.1c0.5,0.2,3,2.4,5.1,4.5
					c0.2,0.1,0.4,0.1,0.5,0c0.1-0.2,0.1-0.4,0-0.5c-3.2-3.1-5.1-4.7-5.6-4.7c-0.4,0-4.8-0.6-7.9-1.1l0.8-7.5c6.2,1,11.9,2.1,13.1,3
					c0.9,0.8,4.4,4.5,8.6,9.2l-7.6,6.1l-15.9-0.5L163.9,197.3z M111.4,156.3c5.9-0.3,12-0.6,17.3-0.9c2,4,4.5,8.9,7.1,13.8
					c0.3,0.5,0.5,1,0.8,1.5c-0.6,0.1-1.5,0.4-3,0.9c-2.5,0.8-6,2-7.3,1.6c-1.3-0.4-14.4-8.4-14.8-9.9
					C111,161.8,111.3,157.5,111.4,156.3z M137.5,170.9C137.5,170.9,137.5,170.9,137.5,170.9c0-0.1,0-0.1-0.1-0.1
					c-2.8-5.2-5.7-10.9-8.1-15.6c1.3-0.5,6.5-1.8,7.9-2.1l13.4,24.1c-1.6,1.6-3.5,7.1-4.1,8.9C145.3,184.8,141.5,178.4,137.5,170.9z
					 M162.3,160l4.5,8.4l-5.5,4.5c-2.5-3.6-5.2-7.6-7.5-10.9C155,161.9,157.3,161.5,162.3,160z M152.8,161.7L152.8,161.7
					c1.1,1.7,4.6,6.8,7.9,11.6c-1.8,0.5-7.4,2.3-9.4,3.4l-13-23.4C141.4,154.7,152.1,160.6,152.8,161.7z M151.3,177.5
					c1.5-1.1,8.4-3.2,9.8-3.6c2.8,4,5.5,7.7,6.6,8.7l-3.5,5.6c-7.2-1.1-14.8-1.9-17-2C148.1,183.7,150.1,178.4,151.3,177.5z
					 M187,201.8c1.5,1.7,3.2,3.5,4.8,5.4l-5,4.3c0,0-0.1,0.1-0.1,0.1l-4.3,9.5l-3-13.2L187,201.8z M178.3,191.5
					c-1.3-1.1-7-2.2-13.3-3.2l3.3-5.3c0.7,0.2,2.2,0.6,4.2,1.1c4.5,1.1,12.1,3,13.1,3.8c0.6,0.5,3.6,3.8,6.9,7.6l-5.6,5.3
					C182.8,196.1,179.3,192.3,178.3,191.5z M172.8,183.3c-2.2-0.5-4-1-4.4-1.1c-0.6-0.3-3.3-4.1-6.6-8.7l5.5-4.6l9.9,7.7l-0.7,7.6
					C175.3,184,174.1,183.7,172.8,183.3z M177.6,176l-9.6-7.5l9.8-5.1c0.1,0,0.1-0.1,0.2-0.2l4-7.9l2.5-1.4l6.7,12.2l-6.8,5.1
					L177.6,176z M181.5,154.7c-0.1,0-0.1,0.1-0.2,0.2l-4,7.9l-9.9,5.2l-4.4-8.2c4.6-1.4,9.1-3,9.1-3c0.2-0.1,0.3-0.3,0.2-0.5
					c-0.1-0.2-0.3-0.3-0.5-0.2c-7.8,2.7-16.3,5.3-18.2,5.2l8.5-12.2c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.4-0.1-0.5,0.1l-8.5,12.2
					c-2.3-1.9-13.7-8.3-15.5-8.6c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.1-6.6,1.6-8.3,2.3
					c-1.7-3.5-3.1-6.4-3.8-8.1c-0.9-2.2-2.3-5.2-3.7-8c-2.2-4.7-4.8-10-4.9-11.6l21.9,0h0c0.1,0,0.2,0,0.2-0.1l7-5
					c1.8,3.2,3.4,6.1,3.9,7l-6.3,5.4c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1l6.2-5.4l4.7,5
					c-3.1,2.9-5.5,5.2-5.8,5.5c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,5.4-5.2,10.7-10.1
					c2.6,3.2,12.7,15.2,13.8,15.7c1.1,0.5,13.6,3,15.2,3.3c0.3,0.1,0.7,0.3,1.2,0.5L181.5,154.7z M146.6,108.1
					c1.8,3.2,4.1,7.8,4.1,8.4c-0.1,0.5-2.5,2.5-4.8,4.1c-2.1-3.8-4.3-7.8-4.9-9.1L146.6,108.1z M155.1,113.5l5.1,6.7l-10,8
					c-0.6-1-2.1-3.9-3.8-6.9c1.8-1.2,5.3-3.7,5.2-4.8c-0.1-1-3.2-6.9-4.2-8.7l2.2-1.3l7.1,1c-0.9,3-1.5,5.6-1.6,5.8
					C155,113.3,155,113.4,155.1,113.5z M155.3,133.8l-4.7-5l10.1-8l3.5,4.7C161.4,128,158.1,131.1,155.3,133.8z M133,115.9
					C133,115.9,133,115.9,133,115.9c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0h0h0h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					c0,0,0,0,0,0l6.8-4.1c0.7,1.3,2.9,5.3,5,9.1l-7,5l-21.9,0c-2-2.5-4.4-4.4-7-5.8c0.5-1.5,2.3-6.7,2.4-7.9c0.5-0.2,2.6-0.3,4-0.3
					c2.9,0,4.2-0.1,4.6-0.5c0.1-0.1,0.2-0.3,0.2-0.4c0-0.7,0.8-4.6,1.5-7.5L133,115.9z M81.2,163.7c1.4-2,3-4.4,3.4-5.2
					c1.2-0.6,13.1-1.4,26-2.2c-0.1,1.4-0.4,5.6,0.1,7.2c0.6,2.1,14.5,10.2,15.4,10.5c0.2,0.1,0.5,0.1,0.8,0.1c1.7,0,4.6-0.9,6.9-1.7
					c1.3-0.4,2.7-0.9,3.1-0.9c5.4,10,7.7,13.6,8.9,14.8c-5.4,0.5-32,2.9-34.8,3.1c-2,0.1-6.2-2.5-8.6-4.1c-1.5-1-2-1.3-2.2-1.3
					c-0.7-0.1-14.4-0.7-16.5-0.8c-1.6-4.5-3.5-9.9-5.4-15.3C78.5,167.4,79.3,166.3,81.2,163.7z M103.5,213.8
					c1.1,0.2,4.1,0.2,7.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c1.2,0,2.4-0.1,3.6-0.1l0.7,15.1h-9.5l-7.4-3.8L103.5,213.8z
					 M144,215.6c0.3,1.4,1.3,5.4,1.5,6.5c0.1,1,1.9,1,3.9,0.9c1,0,3.2-0.1,3.3,0.3c0.3,1.2,0.9,5.4,1.1,6.5l-15.1,6.4
					c-1.4-7.4-2.9-14.9-3.5-16.8L144,215.6z M162.5,257.3c-0.5-1.9-2.8-10.4-2.9-12.7c-0.1-1.2,1.1-2.2,2.2-3.2
					c0.9-0.8,1.7-1.5,2-2.4l5.5,2.1l3,14.4C169.8,255.8,166.1,256.5,162.5,257.3z M163.8,238.1c-0.3-2.1-2-9.1-2.4-10.7l4.6-2
					l3.1,14.7L163.8,238.1z M187.5,201.3l5.6-5.3c4.2,4.7,8.8,10,9.8,11.2l-5.2,5.6C194.5,209.1,190.8,205,187.5,201.3z
					 M215.8,183.1l2.6,4c-1.1,0.8-1.9,1.4-2.2,1.6c-1,0.8-5.5,10.3-6.4,12.4l-6.3,5.6c-1.1-1.3-5.7-6.5-9.8-11.2l6.2-5.9l10.3-2.7
					c0,0,0.1,0,0.1-0.1L215.8,183.1z M219,186.6l-2.6-3.9l7.2-4.9l2.4,3.9C225,182.3,221.6,184.8,219,186.6z M202.8,114.2l-2.1-7.8
					c0.1,0,0.1,0,0.2,0c1.6-0.4,3.1-0.8,4.6-1.2c0.5,1.7,1.7,6.4,2,7.8C206,113.4,204.4,113.8,202.8,114.2z M169.4,55.5
					c2,0,6.1-0.8,11.1-1.9l1.6,9.2l-14.7,1.5L169.4,55.5z M159.6,40.5v12.1c-1.5-0.4-3.2-0.9-4.9-1.5c1-4.4-5.2-21-6.3-23.8l7.2-2
					L159.6,40.5z M141.4,29.2l6.1-1.7c2.2,5.7,7.2,19.8,6.4,23.4c-2.9-0.9-6-2-9.1-3C144.6,46.4,142.9,37.3,141.4,29.2z M130,43.5
					c4.2,1.5,9.2,3.2,14.1,4.9l-3.9,15.9l-13.7-1.5v-9.2l9.3,2.6c0.2,0.1,0.4-0.1,0.5-0.3c0.1-0.2-0.1-0.4-0.3-0.5l-20.3-5.7
					l2.3-10.4C120,40,124.4,41.6,130,43.5z M83,29.4c2-1,16.9,4,25.8,7c3.6,1.2,6.7,2.3,8.5,2.8L115,49.6l-16.5-4.6
					c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.1-0.2,0.2l-4.7,17.6c-1.5,0.9-7.2,4.8-6.8,7c0.3,1.8,1.1,10.2,1.3,12.2
					c-1.6,0-3.3-0.1-5-0.1c0.1-2.4-0.5-4.3-1.7-5.5c-1.1-1.1-2.3-1.3-3-1.4l0.7-18.9c0.4-0.7,2.8-4.7,2.3-7.6
					C80.5,45.4,80.3,30.8,83,29.4z M26.6,34.9l7.9-10.3l12.2,6.5l-2.2,4.4l-8.4-3.9c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5
					l8.7,4.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.3-0.2l2.4-4.7l10.7,5.7c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.3-0.2
					c0.1-0.2,0-0.4-0.2-0.5L35,24l7.6-9.9l17.2,15.2c0,0,0.1,0.1,0.1,0.1l20.2,7.8c-0.3,4.8,0,10.2,0.3,11.6c0.5,2.5-1.6,6.1-2.1,7
					l-10.7-0.9L54.9,52l-6.5-1.6l0,0c0,0,0,0,0,0l-24.6-6.1L26.6,34.9z M18.5,60.3l-8.6-8.3l2.1-9.8l10.9,2.7l-0.1,0.5L18.5,60.3z
					 M17.1,64.9l-4.4-1.2l0.8-4.4c0-0.2-0.1-0.4-0.3-0.4l-4.6-1.2l1.1-4.8l8.5,8.3L17.1,64.9z M6.7,41l4.6,0.9l-2.1,9.7l-4.5-0.8
					L6.7,41z M9,52.4l-1.1,5.1l-3.3-1v-4.9L9,52.4z M4.6,62.9v-5.6l3.1,0.9l-1.2,5.3L4.6,62.9z M15.8,69.4l-9.3-2.1l2-9l4.2,1.1
					l-0.8,4.4c0,0.2,0.1,0.4,0.3,0.4l4.8,1.3L15.8,69.4z M19.1,60.7l4.4-15.2l0.2-0.5l7,1.7c-0.4,1.6-1.9,8.5-3.6,15.9L19.1,60.7z
					 M45.2,105.1l-22-2.1c-0.2-6.5-0.4-18.5,0-20c0.3-1,2.4-10.4,4.4-19.4l16.3,3.9c0,0,0,0,0,0c0,0,0,0,0,0l16.2,2.1L59.5,77
					l-9.6-0.6c-0.2,0-0.4,0.1-0.4,0.4c0,0.2,0.1,0.4,0.4,0.4l18.9,1.2v3.7l-0.2,3.5l-20.1-1.3c-0.2,0-0.4,0.1-0.4,0.4
					c0,0.2,0.1,0.4,0.4,0.4l20.1,1.3l-0.4,6.9l-21-1.1c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4l14.5,0.8
					c0,0.8-0.2,2.8-0.3,5.3c-8-0.5-14.9-1-15.1-1c-0.2,0-0.4,0.1-0.4,0.4c0,0.2,0.1,0.4,0.4,0.4c0.6,0,7.4,0.5,15.1,1
					c-0.1,2.1-0.3,4.4-0.4,6.6C52.4,105.7,45.4,105.1,45.2,105.1z M61.6,106.4c0.1-2.3,0.3-4.6,0.4-6.7c1.3,0.1,2.5,0.2,3.8,0.3
					l-0.4,6.7C64.1,106.6,62.8,106.5,61.6,106.4z M86.9,108.9L86.9,108.9L86.9,108.9c1.4,0.1,2.5,0.1,3.2,0.1c0.2,0,0.4,0,0.5,0
					c3-0.3,18.6-5.7,19.3-6c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2c-0.2,0.1-16.2,5.7-19.1,5.9c-0.4,0-1.6,0-3.2-0.1
					v-4.1c0-0.2-0.2-0.4-0.4-0.4L73,103.4c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4l13.6,0.3v3.7c-4.7-0.3-12.7-0.8-20.4-1.4
					l0.4-6.7c8.7,0.6,17,1.1,17.8,1.1c0,0,0,0,0,0c0.9,0,1.4-1.3,1.9-3c0.7-1.9,1.5-4.3,3-4.3c3.1,0.1,10.4,0,10.5,0
					c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.1,0-7.4,0.1-10.5,0c-2.1-0.1-3.1,2.6-3.8,4.8c-0.3,1-0.8,2.4-1.2,2.4
					c0,0,0,0,0,0c-0.9,0-12.2-0.8-22.3-1.4c0.1-2.5,0.3-4.5,0.3-5.3l6.1,0.3c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4l0.4-7.2l28,1.9
					c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3l0.1-4.5c1.4,0.2,2.2,0.9,3,1.6c0.7,0.7,1.5,1.4,2.8,1.4
					c0.1,0,0.3,0,0.5,0c0.7,0,1.7,0,2.9-0.1l14.6,16.5c-0.3,1.3-1.6,6.9-1.7,8.1c-0.5,0.2-2.6,0.3-4,0.3c-3.3,0-4.7,0.1-4.7,0.9
					c0,0.7-1.4,4.9-2.4,7.7c-9.8-4.8-22.7-3.2-28.8-2c-2.3,0.4-6.9,0.6-11.2,0.4c-5.8-0.3-7.5-1-7.7-1.3c0,0,0-0.1,0-0.1
					c0.1-0.9,0.3-5.1,0.6-9.7C70.7,107.9,81.3,108.6,86.9,108.9z M54.9,132.2l-2.8-7.3c-0.1-0.2,0-0.3,0.2-0.4l1.6-0.9l1.1,3
					c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l6.1-2.4c0.1,1-0.2,2.9-0.4,4.6c-0.2,1.4-0.4,2.7-0.4,3.8
					C59.3,132.2,56,132.1,54.9,132.2z M66.4,143.3c-1-0.1-2.4-0.2-2.6-0.6c-0.3-0.6-0.8-2.5-1.2-4.4c-0.4-1.7-0.8-3.3-1.1-4.1
					c-0.3-0.7,0-3.1,0.3-5.2c0.3-2.3,0.6-4.4,0.3-5.3c-0.4-1.2-0.9-4.2-1.1-5.8c3.1,1.4,14.2,1.4,18.5,0.7
					c0.2,2.5,0.8,12.9,1.2,14.3c-0.2,0.6-2.7,2.6-4.7,4.3c-3.9,3.3-6.2,5.3-6.9,6.6C68.6,143.5,67.5,143.4,66.4,143.3z M39.5,207.1
					c-1.8,0.7-3.5,1.4-5.1,2c3.6-3.5,10.1-9.7,11.2-10.3c0.8-0.4,1.3-1.9,1.8-4.3c0.1-0.3,0.1-0.5,0.2-0.8c0.4-1.7,0.9-3.9,1.4-4.2
					c1.3-0.5,8.7-3.4,10.4-4.1c0.3,0.9,0.6,1.8,0.9,2.5c0,0,0,0,0,0c0,0,0,0,0,0c0.7,1.8,1.1,3.2,1.1,3.5c0.1,0.9-0.8,7.2-1.2,10
					c-2-0.3-3.5-0.6-3.8-0.6C55.5,200.8,49.1,203.3,39.5,207.1z M77.8,204.7c-1.7-0.3-11-2-16.8-3c0.1-0.9,0.5-3.2,0.7-5.4l7.1,1
					c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4l-7.1-1c0.2-1.9,0.4-3.6,0.4-4.1c0-0.4-0.5-1.7-1-3.3l9.1-3.5
					c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2l-9.1,3.5c-1.4-3.8-3.4-9.1-4-10.7l9-3.8c4.1-1.7,10.2-4.2,11.6-4.7
					c4.1,11.7,8.4,23.6,9,25.3L77.8,204.7z M94.7,249.9l13.2,0.4v4.8c-3.9-0.2-8.5-0.5-13.1-0.8C94.7,252.9,94.6,250.8,94.7,249.9z
					 M118.7,255.3c-0.5,0.2-4.5,0-10.1-0.3v-5c0.6-0.5,3.1-2.9,3.9-3.7c0.5-0.5,2.2-0.3,3.4,0.1l1.7,3.7c0.1,0.1,0.2,0.2,0.3,0.2
					c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.3,0.2-0.5l-1.7-3.8c0-0.1-0.1-0.2-0.2-0.2c-0.3-0.1-3.3-1-4.3,0.1c-0.8,0.8-3.4,3.2-3.9,3.7
					l-13.4-0.4c-0.2-0.6-1-1.1-1.8-1.8c-0.5-0.4-1.4-1.1-1.4-1.3c0-0.6,0.4-4.2,0.7-6.2c1.9,0,3.6,0.1,4.3,0.1c1,0,2.1-0.5,3.4-1
					c1.7-0.7,3.7-1.6,6-1.6c0.5,0,1.2,0,1.9,0c4.5-0.1,13.9-0.4,15.3,1.4c0.8,1,0.9,3.8,1,6.4c0.1,2.5,0.2,5.1,0.9,6.7
					C123.8,252.4,119.6,254.9,118.7,255.3z M127.7,269.4c1.8-0.9,3.4-1.8,3.1-3.2c-0.6-2.3-3.2-11.7-5-14.5c-0.8-1.3-0.9-4-1-6.6
					c-0.1-2.9-0.2-5.6-1.2-6.8c-1.5-1.9-9-1.9-15.9-1.7c-0.7,0-1.4,0-1.9,0c-2.4,0-4.6,0.9-6.3,1.6c-1.2,0.5-2.3,1-3.1,1
					c-0.7,0-2.2,0-4.1-0.1l5.9-13.3l7.4,3.8c0.1,0,0.1,0,0.2,0h19.4l2.9,4.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1
					c0.2-0.1,0.2-0.4,0.1-0.5l-2.9-4.4l8.7-9.3c1.2,4.1,6,30.4,6.4,32.6c0.1,0.7,1.3,1,4.5,1.7c1.8,0.4,5.1,1.1,5.3,1.7
					c0.3,1.6,1.2,4.6,1.3,4.7l0,0c0.2,0.8,1.6,9,2.4,13.5l-28,5.2c-0.2-1.2-0.8-4.8-1.6-7.1C124.4,271.2,125.8,270.4,127.7,269.4z
					 M130.7,286.2c-0.6-0.8-3.1-4.8-4-6.2l14.3-2.7l2.8,21.9l-8.8,1.5c-0.1-0.3-0.2-0.6-0.3-1.1
					C132.4,290.7,131.2,286.8,130.7,286.2z M144.6,299.1l-2.8-21.9l12.6-2.3l-2.6,23L144.6,299.1z M157.5,293
					c-1.6,1.8-3.5,4.1-4.9,4.7l2.6-23l7.9-1.5l-2.5,16.7C159.9,290.3,158.9,291.3,157.5,293z M168.7,289.4c-1.1,0-5.5,0.1-7.4,0.3
					l2.5-16.7l11.4-2.1L168.7,289.4z M155.1,273.9c-0.3-2.1-2-11.7-2.4-13.4c0.8-0.5,4.9-1.4,9.5-2.4c0,0,0,0,0,0l0,0
					c3.4-0.7,7.1-1.4,10.1-2l3,14L155.1,273.9z M166.7,225.2l15.2-2.5l3.1,13.9l-15.1,3.7L166.7,225.2z M206.4,242.8l-12.1,2.8
					l-8.4-8.8l11.9-22.8c4.1,4.7,7.3,8.3,7.4,8.4c0,0,0.1,0.1,0.1,0.1l6.9,3.3c-0.8,1.5-3.3,6.3-3.6,8c-3.7,1.1-7.5,2.2-10.7,3
					c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,5-1.4,10.4-3C208.2,236.3,206.9,240.8,206.4,242.8z
					 M209.4,233.5c0.5-1.5,2.2-5.1,3.5-7.4l1.8,0.9c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.3-0.2c0,0,1.1-1.9,2.1-3.8
					c0-0.1,0.1-0.1,0.1-0.2l4.5,6.5C220.8,230,215.4,231.7,209.4,233.5z M231.2,210.6c0.8,0.2,2.7,0.9,3.5,1.4
					c0.2,0.1,0.3,0.1,0.5,0.1c0.7,0,1.4-0.9,2.1-1.7c0.3-0.4,0.7-1,0.9-1c0.5,0,2.3-0.2,3.3-0.4c-0.9,1.5-2.5,5-4.4,9
					c-0.9,1.8-1.6,3.3-1.8,3.8l-5,2.1c-0.4-0.9-1.2-4-1.9-7C229.6,214,230.8,211.5,231.2,210.6z M230.6,224.5l4.6-1.9
					c0.3,0.8,1.2,2.2,2.9,4.4c0.5,0.6,1,1.3,1.1,1.5c0.1,0.4,0.4,3.6,0.7,7.1l-4.8,2.3l-2.8-4.2
					C232.3,232.4,231.3,226.4,230.6,224.5z M226.9,238c0.2-0.3,1.3-0.9,2-1.4c1.4-0.9,2.5-1.5,3-2.1l2.7,4.1
					c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0l4.8-2.3c0.1,1.5,0.2,2.9,0.3,4.2c0,0,0.6,3.9,1,6.1c0.3,1.5,2,1.3,3.3,1.1
					c1.4-0.2,2-0.2,2,0.3c0.3,1.7,1.1,6.8,1.2,7.6l-4.4,9l-11.2,2.2l-3.6-20.2C228.6,246.3,227.2,239.1,226.9,238z M206.2,260.6
					c0.7-1.2,1.5-2.6,2.4-2.7c1-0.1,3.4-0.3,5.7-0.5c2.4-0.2,4.7-0.3,5.6-0.4c0.6-0.1,1.3,1.7,1.8,3c0.6,1.7,1.2,3.1,2.1,3.1
					c0.9,0,2-0.9,3.3-1.9c1-0.8,2.1-1.6,3-1.9l1.4,7.7c-1.7,0.6-3.2,3.1-4.4,5.1c-0.6,1-1.3,2.2-1.7,2.3c-1.1,0.4-6.2,2.2-7.5,2.6
					c-2.9-6.1-3.3-6.1-3.6-6.1c-1-0.1-4.1,0.3-6.6,0.5c-1.1,0.1-2.2,0.2-2.8,0.3l-0.2-9.2C205.2,262.2,205.6,261.5,206.2,260.6z
					 M197.1,263.1c3-0.2,5.8-0.4,6.8-0.5l0.7,29.8h-15.9L197.1,263.1z M204.9,299.2l0.5-6.4c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-5.9h9.1
					l1,12.4C213.8,299.3,208.2,299.2,204.9,299.2z M216.1,299l-1-12.4l5-2.6c1.3,3,2.8,6.4,4.4,10
					C221.8,295.8,217.6,298.4,216.1,299z M225.8,294.9c0,0,0-0.1-0.1-0.1c1.8,0.5,6.2,1.6,7,1.8c0.5,0.1,1.6-0.1,3.5-0.5
					c2-0.4,4.4-0.8,5.3-0.6c1.2,0.3,7.3,3.8,10,5.4c-6.1,6.4-12.2,13.2-15.8,17.2C234.6,315.6,230.2,305.2,225.8,294.9z
					 M264.1,288.9l9,7c-1.8,3.4-3.8,7.2-4.5,8.6l-11.3-8.7C259.8,293.2,262.2,290.8,264.1,288.9z M276.4,289.8
					c-0.3,0.6-1.5,2.9-2.9,5.5l-8.8-6.9c1.7-1.6,3.1-2.7,3.9-3.1c1-0.5,2.6-1.3,4.6-2.2L276.4,289.8z M325.9,270.1
					c-0.2,0-17.8,1-23,1.3c-4.4,0.3-20.3,6.7-29.3,10.7c-0.4-1-1.3-3.4-2.2-5.6l5.3-1.7c1.3-0.4,2-1.7,1.7-3
					c-0.1-0.5-0.2-0.9-0.3-1.3c-0.3-1.6-4.2-4.2-4.6-4.4c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5c1.6,1,4.1,3,4.3,3.9
					c0.1,0.4,0.2,0.9,0.3,1.4c0.2,0.9-0.3,1.9-1.2,2.1l-5.4,1.7c-0.8-1.9-1.5-3.5-1.9-4c-0.4-0.4-0.5-1.6-0.7-2.8
					c-0.2-1.2-0.3-2.6-0.8-3.6c1.1-0.5,4-2.2,8-4.5c2.6-1.5,5-2.9,5.5-3.1c0.6-0.2,6.1,0.7,10.5,1.5c7.2,1.2,10.7,1.7,11.3,1.5
					c1.2-0.5,14.5-9.5,15-9.9l5.8-4.6c2.3,2.6,6.2,7.2,7.2,8.6l-6.3,5.2c-0.2,0.1-0.2,0.4-0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.2-0.1l6.1-5c0.3,1.9,0.7,8.3,0.9,14.4L325.9,270.1z M333.9,238.4l3.3,3.6l-4.9,4.6c-0.1,0.1-0.2,0.4,0,0.5
					l3.2,4.1l-4,3.3c-1.3-1.8-6-7.2-7.2-8.6L333.9,238.4z M348.2,268.4c0,0.7,0,1.3,0,1.7h-6.4h0H333c-0.2-6.4-0.6-13.2-1-14.9
					l4.2-3.5c1.5,0.6,10.5,4.5,11.6,5C348.5,257.4,348.3,265.4,348.2,268.4z M336.1,250.7l-3-3.8l4.6-4.3l3.5,3.9L336.1,250.7z
					 M346.9,286.4c-2.2-2.7-4.5-5.8-4.6-6.3c0-1.3,0-7.6,0-9.2h6.1c0.6,1.6,5,3,14.7,4.7c0.6,0.1,1.1,0.2,1.3,0.2c0,0,0,0,0,0
					c0.6,0.6-1.3,6.6-1.9,8.6c-0.2,0.6-0.4,1.2-0.5,1.5c-0.2,0.5-1,2.9-2.1,6.3L346.9,286.4z M371.7,286.5
					c-0.5,0.8-6.8,3.7-11.1,5.6c1.1-3.1,1.8-5.4,2-5.9c0.1-0.4,0.3-0.9,0.5-1.5c1.4-4.2,2.5-8.1,1.9-9.2c0,0-0.1-0.1-0.1-0.1
					c0.5-0.8,1.8-3.2,2.3-4.4c1.6,0.8,8.4,4.2,9.6,4.8C377.3,276.6,373.2,284.2,371.7,286.5z M363.4,265c-0.4,0.9,0.6,2,1.8,3.3
					c0.6,0.7,1.7,1.8,1.5,2.1c-0.4,0.9-2,3.9-2.5,4.7c-0.3,0-0.6-0.1-1.1-0.2c-1.9-0.3-5.6-1-8.7-1.8c-5.1-1.3-5.5-2.3-5.5-2.6
					c0-0.5,0-1.2,0-2.1c0.2-8.8,0-11.9-0.9-12.3c-1-0.6-9-4-11.3-4.9l5.1-4.2c2,0.7,18,6.2,26.1,9
					C367.2,257.6,363.9,263.9,363.4,265z M360.8,213.7c1,0.8,8.4,11.2,12.9,17.7l-2.2,11.9l-3.2,12c-7.7-2.7-22.4-7.7-25.7-8.9
					l12.9-10.7c0.2-0.1,0.2-0.4,0.1-0.5c-0.1-0.2-0.4-0.2-0.5-0.1L341.9,246l-7.3-8.1l13.6-10.8c0.2-0.1,0.2-0.4,0.1-0.5
					c-0.1-0.2-0.4-0.2-0.5-0.1l-24,19.1c0,0,0,0,0,0l0,0l-6,4.8c-3.8,2.6-13.9,9.4-14.9,9.8c-0.6,0.2-6.3-0.8-10.9-1.5
					c-7.1-1.2-10.3-1.7-10.9-1.4c-0.5,0.2-2.9,1.6-5.6,3.1c-3,1.7-6.9,4-8,4.5c-1.6-2.3-7.5-4.3-9.1-4.7c-1.7-0.4-1.8-1.7-1.9-3.2
					l0-0.1c0.8-1.3,5.5-9.3,5.9-10.6c0.4-1.3-6.8-11.4-9.9-15.7l-3.6-4.8l11.5-11.1l4.3,4.6c0.1,0.1,0.1,0.1,0.2,0.1
					c4.9,0.5,12.6,1.3,15.3,1.3c0.5,0,0.9,0,1-0.1c0.7-0.4,6.3-5.2,6.9-5.8l10.4-9.2l7.7,7.8l-11.1,9.6c-0.2,0.1-0.2,0.4,0,0.5
					c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1l11.2-9.7l6.7,6.9l10,8.5l-13.9,11c-0.2,0.1-0.2,0.4-0.1,0.5
					c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1l14-11.1l3.8,3.3c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l15-11.6
					c0,0,0,0,0,0l6.9-7.7C351.4,213.7,359.6,213.7,360.8,213.7z M342.7,220.8L328,232.2l-14.1-12.1L298.9,205l9-8l21.2-17.4l5.7,7.8
					L322.4,198c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1l12.3-10.5l8,12.4l3.3,6.7l-8.5,8.4
					c-0.1,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l8.4-8.2l2.7,5.4L342.7,220.8z M290.6,177.8
					c-2.4,2.1-5.8,5.1-9,7.9l-5.8-7l8.6-7.7L290.6,177.8z M291.1,178.4l8.1,8.7l7.9,9.6l-8.7,7.8l-8.5-8.7l-7.8-9.4
					C285.3,183.5,288.7,180.5,291.1,178.4z M303.7,160.8c0.6,1.5,2.7,6.3,3.3,7.4c0.6,0.9,0.2,4-0.1,5.1c0,0.2,0.1,0.4,0.3,0.4
					c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3c0.1-0.4,0.8-4.3-0.1-5.6c-0.7-1.1-2.8-6.1-3.3-7.4l3.1-1.9l4.1-0.4v9.7
					c0,0.8,2.2,4.1,2.8,5.1c0,0,1.7,2.6,3.1,4.5c1,1.4,3.8-0.1,6-1.4c0.8-0.4,1.8-1,2.1-1.1c0.5,0.9,2.4,3.2,3,4l-20.9,17.2
					l-7.9-9.7l-8.1-8.6c1.2-1.1,2.1-1.8,2.4-2.1c1.6-1.2,4.4-10.5,4.9-12.2L303.7,160.8z M357.4,185.9c1.5,3.9,2.5,7.4,2.6,7.5
					c0.3,0.9,1,3.8,0.1,4.6c-1.1,1-10.9,7.4-12.9,8.7l-3.2-6.5c0,0,0,0,0,0l-8.1-12.6c1.4-2.4,4.8-8.3,5.6-9.1
					c0.8-0.7,6.9-1.8,10.8-2.4C353.1,176.6,354.7,178.9,357.4,185.9z M338.4,168.7l12.6,6.6c-2.8,0.4-9.2,1.5-10.2,2.4
					c-1,1-4.5,7.1-5.6,9l-5.6-7.7c0.8-1.3,5-7.8,6.7-10.8C337,168.2,337.7,168.4,338.4,168.7z M326.4,156.2c0.2,1.1-1.7,2.8-3,3.9
					c-0.5,0.4-0.9,0.8-1.2,1.1c-1.2,1.3,0,3.9,0.1,4.3c0.7,2.2,1.9,3.6,3.5,4.3c2.8,1.2,6.4,0,8.8-1.2c0.2-0.1,0.4-0.2,0.7-0.3
					c-1.7,2.9-5.2,8.4-6.2,10c-1-1.3-2.6-3.3-2.9-3.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.1-1.2,0.3-2.8,1.2c-1.7,1-4.3,2.5-5,1.6
					c-1.4-1.9-3.1-4.4-3.1-4.5c-1.3-1.9-2.7-4.3-2.7-4.7v-9.9l7.2-8.1C322.7,152.3,326.2,155.3,326.4,156.2z M311.4,144.8
					c1.1-0.5,3.1,1.2,4.2,2.1c0.3,0.3,0.6,0.5,0.8,0.6c0.7,0.5,1.6,1.1,2.5,1.8l-7.1,8l-4.2,0.4l-4.1-9.4
					C307.2,146.7,310.7,145.1,311.4,144.8z M293.8,152.9c0.8-0.4,4.9-2.3,9-4.2l4,9.3l-8.4,5c-0.1,0-0.1,0.1-0.2,0.2
					c-1.3,4.3-3.6,11.1-4.7,11.9c-0.3,0.2-1.2,1-2.5,2.1l-6.3-6.8L293.8,152.9z M283.8,170.6l-8.5,7.6L265.1,166
					c0,0-9.3-11.1-10.7-12.8c-0.6-0.7-2.4-6.2-4.4-12.5c3.8-1.5,10.5-4,12.3-4.6c1.2,4.8,3.6,14.9,4.3,16c0,0,0,0,0,0c0,0,0,0,0,0
					c0,0,0,0,0,0c0.6,0.5,6.6,7.1,6.7,7.1L283.8,170.6z M262.9,110.6l-8.9,2.3c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2,0,0.3
					l4.1,13.3c0,0,0,0,0,0.1l4.1,8.6c-2.2,0.7-10.3,3.8-12.2,4.5c-1.3-4.1-2.6-8.6-3.7-12.2l-4.1-13.3c-0.1-0.2-0.3-0.3-0.5-0.3
					c-0.2,0.1-0.3,0.3-0.3,0.5l4.1,13.3c0.3,1.2,1.9,6.7,3.7,12.3c-4.8,2.3-10.3,4.4-11,4c-0.6-1.5-4.4-15.1-4.4-15.3l-4.1-13.3
					c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0.1-0.3,0.3-0.3,0.5l4.1,13.3c0.2,0.6,3.9,13.9,4.5,15.4c0.1,0.1,0.2,0.3,0.4,0.4
					c0.1,0,0.3,0.1,0.5,0.1c2.3,0,8.9-3,11-4c1.7,5.4,3.5,10.8,4.3,12.4l-9.1,7.4c-0.2,0.1-0.2,0.4-0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.2-0.1l9.1-7.4c2,2.4,10.5,12.5,10.5,12.5l10.2,12.3l-8.1,7.3c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.2,0.4,0.2,0.5,0
					l8.1-7.2l5.8,7c-4.1,3.6-7.6,6.8-7.9,7.1c-0.2,0.1-0.2,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1
					c0.1-0.1,3.7-3.3,7.9-7l7.8,9.4l8.5,8.7l-10.4,9.2c-1.2,1.1-2.8,2.5-4.2,3.6l-4.1-4.7c-0.1-0.2-0.4-0.2-0.5,0
					c-0.2,0.1-0.2,0.4,0,0.5l4.1,4.7c-1,0.8-1.7,1.4-2,1.6c-0.9,0.2-10-0.6-15.7-1.2l-4.2-4.5l11.8-11.1c0.2-0.1,0.2-0.4,0-0.5
					c-0.1-0.2-0.4-0.2-0.5,0l-11.8,11.1l-1.9-2.1c-0.1-0.1-0.2-0.1-0.3-0.1h-7.9l-4.9-5.3c3.2-3.2,8.1-7.9,8.2-8
					c0.1-0.1,0.2-0.4,0-0.5l-11-12.7c-0.1-0.2-0.4-0.2-0.5,0c-0.2,0.1-0.2,0.4,0,0.5l10.7,12.5c-1.1,1-5.1,4.9-7.9,7.7l-11.2-13
					L220.4,171c7.1-2.7,17.3-7,17.9-7.3c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2c-3.8,1.6-11.5,4.9-17.2,7.1
					c1.4-2.2,2.8-4.3,3.2-4.5c0.2-0.1,0.6-0.3,1.2-0.5c3.4-1.4,4.4-2,4.3-2.6c-0.1-0.7-1.3-5.9-1.7-7.4l5.3-2
					c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.2l-8.9,3.4l-3.3-8.2l8.6-2.6c0.2-0.1,0.3-0.3,0.3-0.5
					c-0.1-0.2-0.3-0.3-0.5-0.3l-8.7,2.7l-4.2-10.2c-0.1-0.2-0.3-0.3-0.5-0.2l-8.3,2.7c-1.3-3.5-2.1-6.8-1.7-7.1
					c0.8-0.6,5.8-2.2,7.6-2.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0-0.3l-1.5-4.5l8.4-2.5c0.2-0.1,0.3-0.3,0.3-0.5
					c-0.1-0.2-0.3-0.3-0.5-0.3l-8.5,2.5l-3.4-9.9c4.7-1.2,8.1-2.1,8.2-2.1c0.2-0.1,0.3-0.3,0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.3
					c-0.4,0.1-3.7,1-8.2,2.1c-0.3-1.4-1.4-6-2-7.8c4-1.1,7.2-1.9,7.2-1.9c0.2-0.1,0.3-0.3,0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.3
					c-2.2,0.6-4.7,1.3-7.3,1.9c-0.7-1.8-3.3-6.9-4.2-7.4c-0.5-0.4-1.9-4.9-3.1-9.2c2.8-1.3,6.8-4.4,7.3-4.8c0.2-0.1,0.2-0.4,0.1-0.5
					c-0.1-0.2-0.4-0.2-0.5-0.1c-1.5,1.2-5.2,3.9-7.4,4.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.2-0.9,0.3-1.2,0.3c0,0,0,0,0,0
					c-1.6,0-4.2,1.2-6.2,2.3c-0.9,0.4-1.7,0.8-2.3,1.1c-1.4,0.5-4.5-0.4-6.2-0.9c-0.5-0.2-0.9-0.3-1.2-0.3c-0.5-0.1-3.9-1.3-7.8-2.7
					l0,0c-3.2-1.1-6.7-2.4-9.3-3.3l1.9-11.6l1.5-6.9l14.9-1.5v0.5c0,0.7,0.3,1.5,0.9,1.9c0.5,0.4,1.1,0.7,1.8,0.7c0.1,0,0.2,0,0.3,0
					l7.6-0.8c0.2,0,0.4-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.3L185,66c-0.5,0.1-1-0.1-1.4-0.5c-0.4-0.4-0.6-0.9-0.6-1.4v-0.6l15.3-1.5
					c0.2,0,0.4-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.3l-15.3,1.5l-1.6-9.3c16.9-3.8,44.3-11.5,46.1-12l3.8-0.4c-0.1,1.4-0.2,6,0.4,8.9
					c-2.1,0.6-13.3,3.7-15.6,4.1c-2.5,0.5-9.4-2-9.5-2.1c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5c0.3,0.1,6,2.2,9,2.2
					c0.3,0,0.6,0,0.8-0.1c2.4-0.4,13.5-3.5,15.7-4.1c0.9,3.4,2.6,11.6,2.6,11.6c0,0.2,0.2,0.3,0.4,0.3c0,0,0.1,0,0.1,0
					c0.2,0,0.3-0.2,0.3-0.5c0-0.1-1.7-8.2-2.6-11.7c1.7-0.5,8.8-2.5,14.5-3.8l7.5,31.6c-2.2,0-11,0-12.9,0c-1.6,0-4.7,3-8.4,6.6
					c-1,1-1.8,1.8-2.3,2.2c-1.9,1.6-0.3,10.7-0.1,11.7c0,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0.1,0c0.2,0,0.3-0.2,0.3-0.4
					c-0.7-3.7-1.3-10-0.1-11c0.5-0.4,1.3-1.2,2.4-2.3c2.4-2.4,6.4-6.4,7.8-6.4c2,0,11.3,0,13.1,0l5.2,22c0,0.1,0.1,0.2,0.2,0.3
					c0.1,0,0.2,0.1,0.3,0l4-1.8L262.9,110.6z M485.7,53.7l-6.6,5.4l-2.7-3.6l7-5.3C484.2,51.4,485,52.6,485.7,53.7z M475.9,54.9
					l-3.9-5.2l7.6-5.2c1.2,1.7,2.3,3.4,3.4,5L475.9,54.9z M466.4,92.3l-14.7-11l13.8-17.4c1.4,1.6,2.7,3.2,4,4.6
					c3.6,4.1,6.4,7.4,7.4,8.5C475.1,78.6,470.6,85.6,466.4,92.3z M436.1,69.6c-0.1-0.1-0.3-0.1-0.5,0l-10.8,9.2c0,0,0,0,0,0
					l-8.4,9.3l-8.8-6.8l9.2-8.6c0.9-0.9,23-21,24.7-22.7c0,0,0.1-0.1,0.1,0c0.7,0.1,2.3,2.4,3.5,4.3c1.7,2.6,3.3,5,4.6,5.2
					c0.3,0,0.6-0.1,0.9-0.3c1.7-1.6,4.8-4.8,5.8-5.8c2.8,3.2,5.8,6.7,8.6,10l-13.9,17.5L436.1,69.6z M374.1,108
					c-0.1,0-0.2,0.1-0.3,0.2c-2.4,0-4.7,0-7,0.1c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4,0c-2,0-3.9,0-5.8,0l0.9-9h16.5v8.9
					c-1.2,0-2.3,0-3.5,0C374.3,108,374.2,108,374.1,108z M343.8,108.4c-0.6-0.3-3.5-1.9-7.2-3.7l4.5-7.1l3.5,1.6c0,0,0.1,0,0.2,0
					h15.8l-0.9,9C351.2,108.3,344.7,108.4,343.8,108.4z M330.5,101.8l1.6-8.3l8.4,3.8l-4.5,7.1C334.2,103.5,332.3,102.6,330.5,101.8
					z M362.1,83.9l-1.4,14.5H354l1.1-14.5H362.1z M387.7,70.3c10.9,0.6,17.6,9.3,18.8,11c-1.3,0.9-6.6,4.9-12.6,9.6
					c-0.6,0.5-1.3,1-1.9,1.4l-4.2-10.4c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0l0,0l0,0L375.1,75c0.5-0.1,1.3-0.2,2-0.3
					C381.1,74.2,387.2,73.3,387.7,70.3z M362,92.5c0,0,0.1,0,0.1,0h10.8l4.6,5.9h-16L362,92.5z M378.4,98.4l-4.9-6.3v-8l10.5,4.4
					c0.2,0.1,0.4,0,0.5-0.2l2.8-5.3l3.9,9.8C385.6,96.4,379.6,98.1,378.4,98.4z M374.2,75.4l12.6,6.8l-2.8,5.4l-10.5-4.4
					C373.5,80,373.8,76.4,374.2,75.4z M372.7,91.8h-10.6c0,0,0,0,0,0l0.8-7.9h9.9V91.8z M378.6,99.1c1.6-0.4,9.4-2.7,15.7-7.6
					c6.1-4.8,11.5-8.8,12.7-9.6l8.8,6.9c-8.5,10-21,15.1-37.2,15.1V99.1z M439.2,29.4c2.5,4.6,4.9,8.9,5.9,10.4
					c0.8,1.2,5.3,6.6,10.8,12.9c-0.9,0.9-4,4.3-5.8,5.9c-0.1,0.1-0.2,0.1-0.3,0.1c-1-0.1-2.7-2.7-4.1-4.8c-1.8-2.8-3-4.5-4.1-4.6
					c-0.3,0-0.5,0.1-0.7,0.3c-0.6,0.6-3.8,3.5-7.7,7.1c-1-1.7-3.5-5.8-5.9-9.3l9.9-21.3C437.9,27.1,438.6,28.3,439.2,29.4z
					 M445.9,39.6l5-6.5l19.4,26.1l-4.6,3.5C457.3,53.2,447.6,42,445.9,39.6z M451.3,32.5l3.4-4.4l20.7,27.2l-4.5,3.5L451.3,32.5z
					 M471.6,49.1l-16.4-21.6l6.6-8.7c5.1,7.1,11.7,16.6,17.4,25.1L471.6,49.1z M461.3,18.2l-6.7,8.7l-3.5-4.7
					c-0.1-0.2-0.4-0.2-0.5-0.1c-0.2,0.1-0.2,0.4-0.1,0.5l3.7,4.8L445.4,39c-1.1-1.8-3.4-5.9-5.6-9.9c-2.4-4.4-4.9-9-6.1-10.8
					c9.5-5.2,16.4-8.5,20-9.6C455,9.7,457.8,13.3,461.3,18.2z M402.5,4.6l36.5-17.5c4.1,6.9,10.6,17.6,14,21
					c-4.5,1.5-12.3,5.5-19.8,9.6C430.3,14.5,409.2,6,402.5,4.6z M390.7,44.3C391.9,43,406,34.1,421.7,25c4.1-2.4,7.9-4.5,11.4-6.4
					c0.8,1.2,2.2,3.7,3.8,6.6l-9.9,21.4c-2-2.9-3.9-5.2-4.8-5.2c0,0,0,0,0,0c-2.3,0-14.3,8.4-15.1,9.8c-0.8,1.3-0.2,12.3-0.1,14.5
					c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4c-0.4-6.3-0.5-13.3-0.1-14c0.7-1.1,12.4-9.4,14.4-9.4c0,0,0,0,0,0
					c1.2,0,6.3,7.6,10.6,15c-7.1,6.6-16.3,14.9-16.5,15.1l-9.2,8.6c-1.4-1.9-8.2-10.6-19.3-11.3l0-9.4C387.7,60,388.3,47,390.7,44.3
					z M386.9,70.2c-0.6,2.4-6.6,3.3-9.9,3.7c-1.9,0.3-2.8,0.4-3.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,0.8-1.1,6.3-1.1,8.5h-9.8
					l1.3-13.9L386.9,70.2z M356.6,68.9l6.9,0.3l-1.3,13.9h-7L356.6,68.9z M346.8,83.9h7.6l-1.1,14.5h-8.3l-3.6-1.6
					c-0.6-3.1-1.7-8.7-1.7-9.4c0-0.3,0.1-1.7,0.2-3.5L346.8,83.9z M333,88.8C333,88.8,333,88.8,333,88.8l0.6-13.5h6.3
					c-0.2,2.3-1.1,11.2-1,12.1c0.1,0.8,1.2,6.9,1.6,9l-8.3-3.7L333,88.8z M329.7,101.4c-1.7-0.7-3.4-1.4-4.8-1.9V93l6.5,0.3
					L329.7,101.4z M287.5,83.7c1.3-2.1,1.9-7.4,1.8-8.7c0.7-0.5,4.7-2.1,7.7-3.2c3.9-1.5,6.1-2.4,7.1-2.9l2.3,5.5v4.5
					c-1.6,0.7-9,3.8-9.7,4.4c-0.4,0.3-0.9,1.3-1.8,3.1c-0.8,1.7-2.1,4.2-2.7,4.5c-0.6,0.3-3.8-0.6-6.7-1.6
					C286.2,86.8,286.9,84.5,287.5,83.7z M285.3,89.9c1.4,0.5,4.9,1.7,6.6,1.7c0.3,0,0.5,0,0.7-0.1c0.7-0.3,1.7-2.1,3-4.8
					c0.6-1.2,1.3-2.7,1.6-2.9c0.5-0.4,5.6-2.6,9.2-4.2v12.6c0,0.2,0.2,0.4,0.4,0.4l7.8,0l9.6,0.4v6.4c-1.1-0.3-2.1-0.5-2.7-0.5
					c-4,0-9.9-0.1-15-0.4c0,0,0,0,0,0c0,0,0,0,0,0c-5.1-0.3-9.4-0.6-9.9-1.1c-0.4-0.8-1.8-0.7-4-0.6c-2.5,0.1-5.7,0.2-8.7-0.9
					C283.9,94.9,284.5,92.4,285.3,89.9z M304.3,115.4C304.3,115.4,304.3,115.4,304.3,115.4c-0.8,0-3.8-3.3-5.1-4.8
					c-1.2-1.4-1.9-2.1-2.2-2.3c-0.3-0.2-0.9-0.2-2.9,1.4c-0.8-1.4-3.5-5.7-3.9-6.3c-0.2-0.4-0.2-3.4,0.1-6.1c0.8,0,1.6,0,2.3,0
					c1.5,0,3.1-0.1,3.3,0.3c0.2,0.3,0.6,1,10.2,1.5l-0.6,9.5c0,0.1,0,0.2,0.1,0.3l2.4,2.1C307.2,112,305.1,114.5,304.3,115.4z
					 M306.1,108.6l0.5-9.4c0.2,0,0.3,0,0.5,0c5.6,0.3,11.7,0.4,14.2,0.4c0.7,0,1.8,0.2,3,0.6c0,0,0,0,0,0c1.5,0.5,3.3,1.2,5.2,2
					l-3.9,14.9l-11.8-1.8L306.1,108.6z M326.4,117.3l3.9-14.7c1.8,0.8,3.7,1.7,5.4,2.5l-2,13.7L326.4,117.3z M343,118.8h-8.6l2-13.4
					c3.9,1.9,7.1,3.6,7.1,3.7c0.1,0,0.1,0,0.2,0c0.2,0,6.8-0.1,15.7-0.1l-7.3,11.9L343,118.8C343.1,118.8,343.1,118.8,343,118.8z
					 M352.6,121.4l7.7-12.4c1.8,0,3.6,0,5.5,0c-1.3,2.3-5.4,9.9-5.7,10.9c-0.4,1.1-0.1,9.9,0,11.6l-6.7,5.8L352.6,121.4z
					 M354.6,159.7c-1.1,0-2,0.1-2.8-0.5c-2-1.6-4-4.5-3.3-5.5c0.8-1.1,6-6.5,6.7-7.2l10.6,10.6l-5.4,5.4c-0.4-0.5-0.8-0.9-1.2-1.3
					C357.6,159.6,356,159.7,354.6,159.7z M366.8,170.1c-0.3-0.4-1-1.1-1.8-2.1c-1.2-1.5-2.8-3.3-4.2-4.9l5.5-5.5l11.2,11.2
					C372.6,169.9,367.3,170.7,366.8,170.1z M388.4,127.7c-0.4,0.4-1.8,1.5-3.5,3c-10.3,8.7-16.2,14-15.6,15.6
					c0.7,2.1,12.6,13,15,15.2l-6.1,7l-27.8-27.8l23.7-20.3C380.9,123.3,387.9,126.7,388.4,127.7z M373.7,119.8l-12.9,11.1
					c-0.1-4.1-0.2-10.1,0-10.8c0.3-0.8,4.2-8.1,5.9-11.2c2.3,0,4.6,0,7-0.1V119.8z M424.5,113.2l-6.1,3.9c-3.4-4.1-6.8-7.6-9.2-8.7
					l9-2.9L424.5,113.2z M418.2,104.6L418.2,104.6l-10.4,3.3c-2.6,0-15.5,0.1-29.1,0.1v-3.5c16.4,0,29.1-5.2,37.8-15.3l14.5,11.3
					L418.2,104.6z M416.9,88.7l8.4-9.3l10.6-9l14.7,11.1l-15,14.5c0,0,0,0,0,0c0,0,0,0,0,0l-4,4.2L416.9,88.7z M458.5,105.7
					l-3.9,3.9l-18.1-13.5l14.8-14.3L466,93C462.5,98.9,459.3,104.4,458.5,105.7z M473.3,92.3c-0.9-2.1-1.8-4.3-2.4-5.7
					c2.9-4.6,5.4-8.2,6.4-9c0.8,1.1,2.9,4.5,5.3,8.2L473.3,92.3z M492.7,64c0.4,0.6,0.8,1.7,1.1,3.1c-1.8,1.1-13.3,8.2-16.2,9.6
					c-0.8-1-3.7-4.3-7.5-8.6c-1.3-1.5-2.6-3-4-4.7l4.6-3.5l6.4,8.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.2-0.1
					c0.2-0.1,0.2-0.4,0.1-0.5l-6.4-8.6l4.5-3.5l6.5,8.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.2-0.4,0.1-0.5
					l-3.3-4.4l6.6-5.4c1.4,2.1,2.6,3.9,3.6,5.4C491.2,61.9,492.3,63.4,492.7,64z"
            />
            <path
              class="st8"
              d="M230.7,76.7c-0.1-0.2-0.3-0.3-0.5-0.2c-3.1,1.1-8.4,2.6-9.4,2.1c0,0-0.1,0-0.1-0.1c-0.4-1.6-3-12.1-3-12.2
					c0-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.3,0.3-0.3,0.5c0.1,0.4,2.6,10.6,3,12.3c0,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.6,0.2,1.1,0.2
					c2.7,0,8.3-2,9-2.2C230.7,77.1,230.8,76.9,230.7,76.7z"
            />
            <path
              class="st8"
              d="M456.6,247.6c-0.2-0.1-0.4,0-0.5,0.1c-0.2,0.4-0.6,2.5-1.4,7.6c-0.8,5.3-2.3,14.1-3,14.8
					c-1.3,0.7-20.4,11.3-20.6,11.5c-0.2,0.1-0.3,0.3-0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0
					c0.8-0.4,19.3-10.7,20.6-11.5c0.8-0.5,1.7-5.1,3.3-15.3c0.5-3.4,1.1-6.9,1.3-7.3C456.8,247.9,456.8,247.7,456.6,247.6z"
            />
          </g>
        </g>
        <path
          class="st9"
          d="M383.9,174c4.5-6.2,9.6-13.2,19.2-20.3c8.7-6.4,25.5-16.1,43.2-26.3c19.8-11.4,40.3-23.3,51.8-31.9
			c21.1-15.8,70.5-24.3,71-24.3l-0.4-2.6c-2.1,0.3-50.5,8.7-72.2,24.8c-6.9,5.1-17.1,11.5-28.5,18.3c-6-4.8-47.5-38.5-61.1-47.9
			c-7.7-5.4-30.4-6.2-55.3-6.3c5.3-39.1,11.7-84.5,11.9-85.2l-2.6-0.4c-0.1,0.7-6.6,46.4-11.9,85.6c-4.7,0-9.4,0-14.1,0.1
			c-18.8,0.1-36.6,0.2-48-1.2c-22-2.8-45,2.8-65.4,7.7c-7,1.7-13.7,3.3-19.6,4.4l-29.9-79.3l-2.5,0.9l29.7,78.8
			c-23.6,3.6-68.3,1.3-82.5-0.3c-15.4-1.7-29.3-10.1-29.4-10.2L87,58.3C86.8,58.2,73,52.3,57.6,46c5.5-25.4,11.7-51.4,15.5-66.5
			l-2.6-0.6c-0.1,0.3-7.9,31.9-15.1,64.6c-0.1,0.5-0.2,1-0.3,1.6c-9.4-3.9-19.1-7.8-26.5-10.7C8.8,26.6-14.5-10.8-14.7-11.2L-17-9.8
			c1,1.6,24,38.5,44.6,46.5c7.5,2.9,17.4,6.9,26.9,10.8C50.8,65,47.9,79.4,46,90.5c-3.5,20.6-2.9,25.6-2.2,27.6
			c1,2.8,5.1,13.6,10.7,28.5c6.6,17.4,15.5,41.1,24.6,65.3c4.3,11.4,2.7,24.1,1.2,36.4c-1.4,11.7-2.7,22.7,0.7,32.2
			c5.4,14.7,19.5,25.6,28.8,32.8c4.4,3.4,8.5,6.6,9,8.2l2.6-0.7c-0.7-2.5-4.3-5.3-9.9-9.6c-9.1-7-22.8-17.6-27.9-31.6
			c-3.3-8.9-2-19.6-0.6-31c1.5-12.6,3.1-25.7-1.4-37.7c-9.1-24.2-18.1-48-24.6-65.3c-5.6-14.9-9.7-25.7-10.7-28.5
			C44.3,111.7,50,81,57,48.6c14.6,6,27.7,11.5,28.9,12c1.2,0.7,11.4,6.7,24.2,9.5c2.4,4.9,18.8,39.5,27.1,56.8
			c5.5,11.4,14.5,17.1,22.4,22.1c5.5,3.5,10.7,6.8,14.4,11.6c8.3,11,34.8,41.2,39.9,47c-19.6,19.7-43.5,102.6-44.5,106.1l2.6,0.7
			c0.1-0.5,9.1-31.4,20.3-60.1c5.8-0.2,36.9-1.8,52.2-15.3c16.3-14.3,33.1-30.3,33.3-30.4l1.9-1.7c2.1,2.5,7.9,9.2,14.6,17.1
			c24.2,28.6,28.9,35.4,29.6,36.9c1.8,4.1,0.9,38.6-0.1,60.7l2.7,0.1c0.3-5.7,2.5-56-0.1-61.9c-1.1-2.6-9.5-13.2-24.8-31.4
			l27.1-21.6c7.9,8.7,25.9,29,27.4,31.7c1.1,2,8.1,4.1,26.2,9.1c13.6,3.7,28.9,8,33.1,10.9c6.6,4.6,29.6,53.1,38,71.4l2.4-1.1
			c-1.2-2.7-30.3-66.5-38.9-72.5c-4.5-3.2-19.4-7.3-33.9-11.3c-10.3-2.9-23.2-6.4-24.6-7.9c-2-3.4-21.3-24.9-27.1-31.4
			c6.5-1.9,26.3-8.1,38-16.1C375.8,185.1,379.7,179.7,383.9,174z M334.8,60.2c4.5,0,9.1-0.1,13.7-0.1c-3.1,23.2-5.8,43.7-6.3,50.6
			c-1.3,16.8-0.3,31.7-0.1,34.9l-35,32.3l-15.4-32c4.4-3.5,23.4-19.3,23.4-32.7c0-12.6,2.2-46.2,2.7-53.1
			C323.3,60.2,329,60.2,334.8,60.2z M225.6,131.4l39.8-5.4l22.6,19.5c-4,3.3-17.4,14.2-32,26.3l-26.6-30.2L225.6,131.4z
			 M253.9,173.5c-1,0.8-2,1.7-3,2.5l-15-17.2l-3.8-10.1L253.9,173.5z M255.7,175.5l24.2,27.6l-3,2.7L252.7,178
			C253.7,177.2,254.7,176.3,255.7,175.5z M281.8,201.3l-24.1-27.5c14.6-12.1,27.8-22.9,31.9-26.2l15.5,32.2L281.8,201.3z
			 M222.1,66.6c20.1-4.9,42.9-10.4,64.4-7.7c7.4,0.9,17.5,1.2,28.7,1.3c-0.5,7.3-2.7,40.5-2.7,53.1c0,12-18.2,27.2-22.4,30.5
			l-23.9-20.6l-41.6,5.6l-21.8-57.9C208.7,69.8,215.2,68.2,222.1,66.6z M161.1,146.8c-8-5.1-16.3-10.3-21.4-21
			c-7.6-15.9-22.2-46.4-26.3-55c1,0.2,2,0.3,3,0.4c14.4,1.6,60,4,83.8,0.2l33.4,88.6l0.1,0.2l15.3,17.6
			c-12.6,10.5-25.2,21.1-33,28.1c-5.2-5.9-31.5-36-39.7-46.8C172.2,153.8,166.8,150.4,161.1,146.8z M242.7,237.1
			c-13.7,12-41.7,14.2-49.4,14.5c7.5-18.8,15.9-36.2,23.4-43c7.7-7,20.8-18.1,34-29l24.3,27.9C271.2,211.2,256.7,224.8,242.7,237.1z
			 M296.2,222.4c-6.7-7.9-12.5-14.7-14.7-17.2l25.1-23.2l20.1,22.9l-27,21.6C298.6,225.2,297.4,223.8,296.2,222.4z M329.1,203.6
			l-20.5-23.4l36.3-33.5l-0.1-0.7c0-0.2-1.5-16.4,0-35.1c0.6-6.9,3.2-27.5,6.4-50.8c23.9,0,47,0.8,54.1,5.8
			c13.1,9.1,52.1,40.6,60.2,47.2c-6.7,3.9-13.7,8-20.6,12c-17.8,10.3-34.6,20-43.4,26.5c-10,7.3-15.2,14.5-19.8,20.9
			c-4.2,5.8-7.8,10.8-14.2,15.1C355.5,195.7,333.5,202.3,329.1,203.6z"
        />
      </g>
    </g>
    <g id="linea">
      <path
        class="st10 linea"
        d="M183.5,273.8c0,0,17.5-48.1,28.3-62c0,0,13.4-12.5,19-17c5.6-4.5,19.8-17,19.8-17l25.9,30l30.2-27.5
		l22.1,24.7c0,0,23.1-6.5,36.6-14.6c0,0,8-3.3,17.6-17.2"
      />
    </g>
    <g id="glovo">
      <g>
        <circle class="st11" cx="382.9" cy="172.6" r="1.7" />
        <circle class="st12" cx="382.9" cy="172.6" r="2.7" />
        <circle class="st13" cx="382.9" cy="172.6" r="0.8" />
      </g>
      <g class="hover-animations">
        <g class="st14">
          <path
            class="st11"
            d="M408.4,156.1v5.1c0,2.8-2.4,5-5.4,5h-16.6l-2.5,2.4l-2.5-2.4h-16.3c-2.9,0-5.4-2.3-5.4-5v-5.1
				c0-2.8,2.4-5,5.4-5h38C406,151.1,408.4,153.3,408.4,156.1z"
          />
        </g>
        <path
          class="st15"
          d="M409,155.9v5.6c0,3-2.5,5.5-5.5,5.5h-17.1l-2.6,2.6l-2.6-2.6h-16.8c-3,0-5.5-2.5-5.5-5.5v-5.6
			c0-3,2.5-5.5,5.5-5.5h39.1C406.5,150.4,409,152.9,409,155.9z"
        />
        <circle class="st16" cx="366.7" cy="158.9" r="6.4" />
        <text
          transform="matrix(1 0 0 1 375.2502 161.0007)"
          class="st11 st17 st18"
        >
          Punto 2
        </text>
        <g id="Group_164_1_" transform="translate(-1331.334 -594.481)">
          <g>
            <path
              id="Path_59_11_"
              class="st11"
              d="M1696.7,751.3c0,0,0,0.1,0,0.1v5.1c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
					c0,0,0,0,0,0v-5.1c0,0,0-0.1,0-0.1c-0.3-0.1-0.4-0.4-0.4-0.6c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
					C1697,750.9,1696.9,751.2,1696.7,751.3z"
            />
            <path
              id="Path_60_11_"
              class="st11"
              d="M1702.3,752.4c0,0-0.5,1.5-1.9,1.6c-1.2,0.1-1.8-0.3-3.1,0.5v-3.4c0,0,1.5,0.2,2,0.6
					C1699.3,751.7,1700.4,752.3,1702.3,752.4z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="glovo_copy">
      <g>
        <circle class="st11" cx="183.3" cy="275.2" r="1.7" />
        <circle class="st12" cx="183.3" cy="275.2" r="2.7" />
        <circle class="st13" cx="183.3" cy="275.2" r="0.8" />
      </g>
      <g class="hover-animations">
        <g class="st14">
          <path
            class="st11"
            d="M207.8,258.7v5.2c0,2.8-2.4,5-5.4,5l-16.9-0.1l-2.3,2.9l-2.5-2.9l-16.4,0.1c-2.9,0-5.4-2.3-5.4-5v-5.2
				c0-2.8,2.4-5,5.4-5h38C205.4,253.6,207.8,255.9,207.8,258.7z"
          />
        </g>
        <path
          class="st15"
          d="M208.4,258.4v5.6c0,3-2.5,5.5-5.5,5.5h-17.1l-2.6,2.6l-2.6-2.6h-16.8c-3,0-5.5-2.5-5.5-5.5v-5.6
			c0-3,2.5-5.5,5.5-5.5h39.1C205.9,252.9,208.4,255.4,208.4,258.4z"
        />
        <circle class="st16" cx="167.2" cy="261.4" r="6.4" />
        <text
          transform="matrix(1 0 0 1 175.6831 263.5385)"
          class="st11 st17 st18"
        >
          Punto 1
        </text>
        <g id="Group_164_2_" transform="translate(-1331.334 -594.481)">
          <g>
            <path
              id="Path_59_10_"
              class="st11"
              d="M1497.1,853.8c0,0,0,0.1,0,0.1v5.1c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
					c0,0,0,0,0,0v-5.1c0,0,0-0.1,0-0.1c-0.3-0.1-0.4-0.4-0.4-0.6c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
					C1497.5,853.5,1497.3,853.7,1497.1,853.8z"
            />
            <path
              id="Path_60_10_"
              class="st11"
              d="M1502.7,854.9c0,0-0.5,1.5-1.9,1.6c-1.2,0.1-1.8-0.3-3.1,0.5v-3.4c0,0,1.5,0.2,2,0.6
					C1499.8,854.2,1500.9,854.9,1502.7,854.9z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Mapa",

  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.st0 {
  clip-path: url(#SVGID_2_);
  fill: #f9f9f9;
}
.st1 {
  clip-path: url(#SVGID_2_);
  fill: #d9edce;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #eaeaea;
}
.st4 {
  clip-path: url(#SVGID_4_);
}
.st5 {
  fill: #d9edce;
}
.st6 {
  fill: #c7e4ef;
}
.st7 {
  clip-path: url(#SVGID_6_);
}
.st8 {
  fill: #ffffff;
  stroke: #efefef;
  stroke-width: 9.5166e-2;
  stroke-miterlimit: 10;
}
.st9 {
  clip-path: url(#SVGID_2_);
  fill: #ffffff;
  stroke: #ffd5c2;
  stroke-width: 0.1903;
  stroke-miterlimit: 10;
}
.st10 {
  fill: none;
  stroke: #00cdbc;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #ff5900;
}
.st12 {
  fill: none;
  stroke: #ff5900;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #fcf7f3;
}
.st14 {
  filter: drop-shadow(2px 4px 6px #ff5900);
}
.st15 {
  fill: #ffffff;
}
.st16 {
  fill: #fcbfb1;
}
.st17 {
  font-family: "poppins-bold";
}
.st18 {
  font-size: 7.3247px;
}

#linea {
  &:hover {
    .linea {
      fill: #ff5900;
    }
  }
  .linea {
    animation: dash 3s alternate-reverse infinite;
    stroke-dasharray: 500;
    stroke-dashoffset: 1;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 500;
  }
}
</style>

